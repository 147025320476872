import React, {useState, useEffect, useRef} from "react";
import Icon from "../Icon";
import PropTypes from "prop-types";
import {
  ChevronDown,
  ChevronRight
} from "react-feather";

import styles from "../../assets/scss/layout/dashboard/sidebarSubMenu.module.scss";

const SidebarSubMenu = (props) => {
  const {title, icon, children} = props;
  const [isOpen, setIsOpen] = useState(true);
  const [transition, setTransition] = useState(true);

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setTransition(true);
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  },[isOpen]);

  useEffect(() => {
    if (!ref.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      if (isOpen) setTransition(false);
      else setTransition(true);
      if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
      else setHeight(0);

    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={`${styles.sidebarLink} ${!isOpen ? styles.selected : ""}`} onClick={handleClick}>
        {icon ?
          <Icon size={35} 
            icon={icon}
            className="f-c-light" />
          : null}
        <span style={{color: "#510059", marginLeft: "5px", fontWeight: 700, fontSize: "17px"}}>{title ? title.toLocaleUpperCase() : "aucun titre"}</span>
        <Icon size={30} 
          icon={isOpen ? ChevronDown : ChevronRight}
          className={styles.icon} />
      </div>
      <div style={{height: height, transition: transition ? "height 0.2s ease-in-out" : "none", overflow: "hidden"}}>
        <div className={styles.sidebarSubLink} ref={ref}>
          {children}
        </div>
      </div>
      {/*isOpen ? children : null*/}
    </div>
  );
};

SidebarSubMenu.propTypes = {
  icon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default SidebarSubMenu;