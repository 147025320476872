import React, {useState} from "react";
import PropTypes from "prop-types";
import QuestionResult from "../questionResult";
import styles from "./multipleChoice.module.scss";
import { Circle, Square, Star ,Triangle, Info } from "react-feather";
import * as c from "../colors";
import { Modal, ModalHeader, ModalBody } from "lib/components";

const Answering = ({question}) => {
  const [modalInfo, setModalInfo] = useState(false);

  const Icons = [
    <Circle key="circle" stroke={c.COLOR_PALETTE_1[0]} width={65} height={65} fill={c.COLOR_PALETTE_1[0]} />,
    <Square key="square" stroke={c.COLOR_PALETTE_1[1]} width={65} height={65} fill={c.COLOR_PALETTE_1[1]} />,
    <Star key="star" stroke={c.COLOR_PALETTE_1[2]} width={65} height={65} fill={c.COLOR_PALETTE_1[2]} />,
    <Triangle key="triangle" stroke={c.COLOR_PALETTE_1[3]} width={65} height={65} fill={c.COLOR_PALETTE_1[3]} />
  ];
  return (
    <div className={styles.displayAnswer}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      {question.explications && question.explications != "" ?
      <div className={styles.info} onClick={() => setModalInfo(true)}>
        <Info width={50} height={50}/>
        {/* <span>{question.explications}</span> */}
      </div>
      : null}
      <div className={styles.mediaDiv}>
        <QuestionResult question={question}/>
      </div>
      <div className={styles.answers}>
        {question.answers.map((answer, index) => (
          <div key={index} className={`${styles.answer}`} style={index == question.answer ? {backgroundColor : c.COLOR_PALETTE_RESULT[0]} : {}}>
            {Icons[index]}
            <span>{answer}</span>
          </div>
        ))}
      </div>
      <Modal isOpen={modalInfo} toggle={()=> setModalInfo(false)} size="xl" className={styles.modalInfo}>
          <ModalHeader>
            Informations supplémentaires
          </ModalHeader>
          <ModalBody>
            {question.explications && question.explications != "" ?
              <div className={styles.explications}>
                {question.explications}
              </div>
              : null
            }
          </ModalBody>
      </Modal>
    </div>
  );
};

Answering.propTypes = {
  question : PropTypes.object.isRequired,
};

export default Answering;