import React, { useState } from "react";
import { Button } from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";

import { getAllUsers } from "@/services/ressources/user";

const firestore = firebase.firestore;

export default function GuestSandbox() {


    // récupération ou création du guest
    const getGuest = async (users, etabId, type, number, userId) => {
        // type: ["resident", "guest", "establishmentGuest"];

        const _guestFitting = users.filter(_u => _u.establishment === etabId).filter(_u => _u.guestTransitionNumber === number).filter(_u => type === "resident" ? _u.linkedTo === userId : type === _u.type);

        if (_guestFitting && _guestFitting.length === 1) {
            return _guestFitting[0].uid;
        } else {
            const _userLinked = users.find(_u => _u?.uid === userId);

            const addedUser = await firestore().collection("users").add(
                {
                    createdAt: new Date(),
                    establishment: etabId,
                    name: type === "resident" ? `Invité de ${_userLinked ? _userLinked?.name ?? "" : "supprimé"} ${_userLinked ? _userLinked?.surname ?? "" : "supprimé"} ${(number + 1)}` : `Invité${type === "establishmentGuest" ? " de la résidence" : ""} ${(number + 1)}`,
                    surname: "",
                    role: "guest",
                    type,
                    generatedByTransitionScript: true,
                    guestTransitionNumber: number,
                    ...(type === "resident" ? { linkedTo: userId } : {})
                }
            )

            return addedUser.id;
        }
    };

    // On commence par récupérer tous les menus.
    const handleReservationEtab = async (etab, users) => {
        console.log(" ");
        console.log("attaque de " + etab?.name);

        const docs = await firestore()
            .collection("establishments")
            .doc(etab.uid)
            .collection("blocks")
            .doc("menu")
            .collection("menu")
            .get();

        const menuList = [];

        docs.forEach(doc => {
            const _menu = ({ ...doc.data(), uid: doc.id });
            // on garde uniquement ceux qui ont des réservations.
            if (_menu.reservation && Object.keys(_menu.reservation) && Object.keys(_menu.reservation).length > 0) {
                menuList.push(_menu);
            }
        });

        // on cherche maintenant tous les invités :

        /*
        infos nécessaires :
        "userId" : [
            {
                occurences : [
                    {
                        uid: "2024-11-03",
                        repas: "Déjeuner",
                    },
                ]
            }
           
        ]
    
        */

        const guests = {};

        menuList.forEach(menu => {
            Object.entries(menu.reservation).forEach(([_userId, _data]) => {
                if (_data) {
                    Object.entries(_data).forEach(([_repas, _arrayResa]) => {
                        if (_arrayResa && _arrayResa.length > 1) {
                            if (!guests[_userId]) guests[_userId] = [];

                            for (let i = 0; i < _arrayResa.length - 1; i++) {
                                if (!guests[_userId][i]) guests[_userId].push({ occurences: [] });

                                guests[_userId][i].occurences.push({
                                    uid: menu.uid,
                                    repas: _repas,
                                    data: _arrayResa[i + 1]
                                });
                            }
                        }
                    });
                }
            });
        });



        // Maintenant qu'on a trouvé tous les invités, il faut les créer en BDD ou alors récupérer leur uid s'ils existent déjà.

        for (const _userId of Object.keys(guests)) {
            for (let i = 0; i < guests[_userId].length; i++) {
                const _uid = await getGuest(users, etab.uid, "resident", i, _userId);

                guests[_userId][i].userId = _uid;
            }
        }


        // Une fois qu'on a tous les uid des invités, on peut rajouter leurs réservations dans la BDD.

        const dataToBatchUpdate = {};

        Object.entries(guests).forEach(([_userId, _guestList]) => {
            _guestList.forEach(_guestInfo => {
                const guestUid = _guestInfo.userId;

                _guestInfo.occurences.forEach(_occ => {
                    if (!dataToBatchUpdate[_occ.uid]) dataToBatchUpdate[_occ.uid] = {};

                    if (!dataToBatchUpdate[_occ.uid][guestUid]) dataToBatchUpdate[_occ.uid][guestUid] = {};

                    dataToBatchUpdate[_occ.uid][guestUid][_occ.repas] = [_occ.data];
                });
            });
        });



        const dataIdList = Object.keys(dataToBatchUpdate);
        // on update les données dans firebase :

        const batchSize = 500;
        for (let i = 0; i < dataIdList.length; i += batchSize) {
            const batch = firestore().batch();
            const batchDocs = dataIdList.slice(i, i + batchSize);

            batchDocs.forEach((doc) => {
                const ref = firestore()
                    .collection("establishments")
                    .doc(etab.uid)
                    .collection("blocks")
                    .doc("menu")
                    .collection("menu")
                    .doc(doc);

                const _toUpdate = {};

                Object.entries(dataToBatchUpdate[doc]).forEach(([_userId, _data]) => {
                    _toUpdate[`reservation.${_userId}`] = _data;
                });

                batch.update(ref, _toUpdate);
            });

            // Commit the batch
            await batch.commit().then(() => {
                console.log("Batch committed successfully. for " + etab?.name);
            }).catch((error) => {
                console.error("Error committing batch:", error);
            });
        }


    };

    const handleReservations = async () => {
        alert("désactivé par sécurité");
        return;

        const users = await getAllUsers();
        const docs = await firestore()
            .collection("establishments")
            .get();

        const etabList = [];

        docs.forEach(doc => {
            etabList.push({ ...doc.data(), uid: doc.id });
        });
        
        // const sliced = etabList.slice(etabList.findIndex(_e => _e?.name === "Démo1"), etabList.length -1);

        for (const etab of etabList) {
            await handleReservationEtab(etab, users);
        }

        // handleReservationEtab({ uid: "KcF49xhTQZXBtx72pw6G" }, users);
    };



    const handeOrdersEtab = async (etab, users) => {
        console.log(" ");
        console.log("attaque de " + etab?.name);

        const docs = await firestore()
            .collection("establishments")
            .doc(etab.uid)
            .collection("blocks")
            .doc("restaurant")
            .collection("orders")
            .where("userId", "==", null)
            .get();

        const orderList = [];

        docs.forEach(doc => {
            const _order = ({ ...doc.data(), uid: doc.id });
            // on garde uniquement ceux qui ont des réservations.
            if (_order.guest) {
                orderList.push(_order);
            }
        });

        // console.log(orderList);

        const aggregateDay = {};

        orderList.forEach(_order => {

            const _uid = _order.date + _order.service;

            if (!aggregateDay[_uid]) aggregateDay[_uid] = {};


            const _ref = _order.guest.type === "resident" ? _order.guest.linkedTo : _order.guest.type;

            if (!aggregateDay[_uid][_ref]) aggregateDay[_uid][_ref] = [];

            aggregateDay[_uid][_ref].push({ uid: _order.uid });

        });


        const _guests = {};

        Object.entries(aggregateDay).forEach(([_dayId, _data]) => {
            Object.entries(_data).forEach(([_guest, _list]) => {
                if (!_guests[_guest]) _guests[_guest] = [];

                for (let index = 0; index < _list.length; index++) {
                    if (!_guests[_guest][index]) _guests[_guest].push({ orders: [] });

                    _guests[_guest][index].orders.push(_list[index].uid);
                }
            });
        });

        // Maintenant qu'on a trouvé tous les invités, il faut les créer en BDD ou alors récupérer leur uid s'ils existent déjà.

        for (const _userId of Object.keys(_guests)) {
            for (let i = 0; i < _guests[_userId].length; i++) {
                let _uid = null;
                if (["establishmentGuest", "guest"].includes(_userId)) {
                    _uid = await getGuest(users, etab.uid, _userId, i);
                } else {
                    _uid = await getGuest(users, etab.uid, "resident", i, _userId);
                }

                _guests[_userId][i].userId = _uid;
            }
        }

        const dataBatch = [];

        Object.entries(_guests).forEach(([_guest, _array]) => {
            _array.forEach(element => {
                element.orders.forEach(_orderId => {
                    dataBatch.push({
                        uid: _orderId,
                        userId: element.userId
                    });
                });
            });
        });


        const batchSize = 500;
        for (let i = 0; i < dataBatch.length; i += batchSize) {
            const batch = firestore().batch();
            const batchDocs = dataBatch.slice(i, i + batchSize);

            batchDocs.forEach((doc) => {
                const ref = firestore()
                    .collection("establishments")
                    .doc(etab.uid)
                    .collection("blocks")
                    .doc("restaurant")
                    .collection("orders")
                    .doc(doc.uid);

                batch.update(ref, {userId: doc.userId});
            });

            // Commit the batch
            await batch.commit().then(() => {
                console.log("Batch committed successfully for " + etab?.name);
            }).catch((error) => {
                console.error("Error committing batch:", error);
            });
        }

    };

    const handleOrders = async () => {
        alert("désactivé par sécurité");
        return;

        const users = await getAllUsers();
        const docs = await firestore()
            .collection("establishments")
            .get();

        const etabList = [];

        docs.forEach(doc => {
            etabList.push({ ...doc.data(), uid: doc.id });
        });
        
        for (const etab of etabList) {
            await handeOrdersEtab(etab, users);
        }

        // handeOrdersEtab({ uid: "KcF49xhTQZXBtx72pw6G" }, users);

    };

    return (
        <div>
            <h2>Les Guest</h2>
            <Button onClick={handleReservations}>Guest Transition Reservations</Button>
            <Button onClick={handleOrders}>Guest Transition Commandes</Button>

        </div>
    );
}
