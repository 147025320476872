import React, { useState } from "react";
import useUI from "hooks/ui.hook";
import firebase from "firebase/app";
import 'firebase/firestore';
import styles from "assets/scss/pages/Gazette/gazettes.module.scss";
import { Plus, Trash } from "react-feather";
import { Button, DemoModal } from "lib/components";
import GazetteView from "./GazetteView";

const GazetteDemo = () => {
    const [ui] = useUI();
    const [gazetteView, setGazetteView] = useState(false);

    if (gazetteView) return (<div>
        <GazetteView setGazetteView={setGazetteView}></GazetteView>
    </div>);
    return (
        <div className={styles.container} style={{ paddingTop: 40 }}>
            {ui?.establishment?.demo === true ?
                <DemoModal type="gazette"></DemoModal>
                : null}
            <div className={styles.createContainer} style={{ cursor: "not-allowed" }}>
                <div></div>
                <Plus size={120} color="white" />
                <Button color="secondaryDark" style={{ cursor: "not-allowed" }}>Créer une gazette</Button>
            </div>

            <div className={styles.gazette}>
                <div className={styles.header}>
                    <h2>Gazette Démo</h2>
                    <Trash color="red" size={26} style={{ cursor: "not-allowed" }} />
                </div>
                <div style={{ width: "100%", maxHeight: "70%", display: "flex", flexDirection: "row" }}>
                    <div style={{ height: "100%", flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", width: "90%", maxWidth: 200, flexDirection: "row" }}>
                            <div>Balade à Saint-Malo</div>
                            <div style={{ flex: 1, overflow: "hidden", marginLeft: 5, marginRight: 5 }}>
                                {new Array(500).fill(".").join("")}
                            </div>
                            <div style={{ color: "#D26F47" }}>2</div>
                        </div>
                        <div style={{ display: "flex", width: "90%", maxWidth: 200, flexDirection: "row" }}>
                            <div>Poème</div>
                            <div style={{ flex: 1, overflow: "hidden", marginLeft: 5, marginRight: 5 }}>
                                {new Array(500).fill(".").join("")}
                            </div>
                            <div style={{ color: "#D26F47" }}>3</div>
                        </div>
                        <div style={{ display: "flex", width: "90%", maxWidth: 200, flexDirection: "row" }}>
                            <div>Visite de la SPA</div>
                            <div style={{ flex: 1, overflow: "hidden", marginLeft: 5, marginRight: 5 }}>
                                {new Array(500).fill(".").join("")}
                            </div>
                            <div style={{ color: "#D26F47" }}>4</div>
                        </div>
                        <div style={{ display: "flex", width: "90%", maxWidth: 200, flexDirection: "row" }}>
                            <div>Mots fléchés</div>
                            <div style={{ flex: 1, overflow: "hidden", marginLeft: 5, marginRight: 5 }}>
                                {new Array(500).fill(".").join("")}
                            </div>
                            <div style={{ color: "#D26F47" }}>5</div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <span className={styles.createdAt}>Publié le 19/10/2024</span>
                    <Button color="primary" size="sm" disabled={true}>Editer la gazette</Button>
                    <Button color="primary" size="sm" onClick={() => setGazetteView(true)}>Visualiser la gazette</Button>
                </div>
            </div>
        </div>
    );
};

export default GazetteDemo;