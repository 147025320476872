import React, {useState, useContext} from "react";
import PropTypes from "prop-types";
export const SidebarContext = React.createContext();

export const SidebarContextProvider = ({children, defaultOpen = true}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <SidebarContext.Provider value={[open, setOpen]}>
      {children(open)}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);

SidebarContextProvider.propTypes = {
  children  : PropTypes.func,
  defaultOpen: PropTypes.bool,
};