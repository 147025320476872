import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const servicesInit = async ({ ui }, err) => {
  try {
    const doc = await firestore().collection("establishments").doc(ui.user.establishment)
      .collection("blocks").doc("services").get();
    if (!doc.exists) {
      await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("blocks").doc("services").set({
          type: "services"
        });
    }
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

//-------------------------------------------CategorieService

export const createCategorieService = async ({ui,data},err)=>{
  try{
    const res = await firestore()
    .collection("establishments")
    .doc(ui.user.establishment)
    .collection("blocks")
    .doc("services")
    .collection("categories")
    .add({
      ...data,
    });
  return { uid: res.id, ...data };
  }catch(e){
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};
export const updateCategorieService = async ({ ui, categorieId, data }, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("categories")
      .doc(categorieId)
      .update({
        ...data,
      });
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};
export const deleteCategorieService = async ({ ui, categorieId }, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("categories")
      .doc(categorieId)
      .delete();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

//-------------------------------------------Prestataire

export const createPrestataire = async ({ ui, data }, err) => {
  try {
    const res = await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("prestataires")
      .add({
        ...data,
      });
    return { uid: res.id, ...data };
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const updatePrestataireBDD = async ({ ui, id, data }, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("prestataires")
      .doc(id)
      .update({
        ...data,
      });
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const deletePrestataire = async ({ ui, id }, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("prestataires")
      .doc(id)
      .delete();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

//-------------------------------------------Availability in Planning

export const addAvailabiliyPlanning = async ({ ui, data }, err) => {
  try {
    const res = await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("planning")
      .add(data);
    return { uid: res.id, ...data };
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const updateAvailabiliyPlanning = async ({ ui, data, prestationId }, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("planning")
      .doc(prestationId)
      .update({
        ...data,
      });
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const removeAvailabiliyPlanning = async ({ ui, prestationId }, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("services")
      .collection("planning")
      .doc(prestationId)
      .delete();

  }  catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};