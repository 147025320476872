import React, {useState, useEffect} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import { useFirebaseCollection } from "lib/hooks/firebase";
import {Table, Modal, ModalHeader, ModalBody, ModalFooter, Button} from "lib/components";
import moment from "moment";
import { Edit } from "react-feather";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

const ErrorReport = () => {
  const [reportRef] = useState(firestore().collection("errorReports").orderBy("createdAt", "desc"));
  const {data} = useFirebaseCollection(reportRef, []);

  const [reportOpened, setReportOpened] = useState(null);

  const _renderItem = (item) => (
    <tr key={item.uid}>
      <td>{item.establishment}</td>
      <td>{item.user}</td>
      <td>{moment(item.createdAt.toDate()).format("LLLL")}</td>
      <td>{item.status}</td>
      <td>
        <Edit style={{cursor: "pointer"}} onClick={() => setReportOpened(item)} />
      </td>
    </tr>
  );

  const _handleDelete = async () => {
    try {
      setReportOpened(false);
      await firestore().collection("errorReports").doc(reportOpened.uid).delete();
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Establishment</th>
            <th>User</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map(_renderItem)}
        </tbody>
      </Table>

      <Modal isOpen={reportOpened} toggle={() => setReportOpened(null)} size="lg">
        <ModalHeader>Report</ModalHeader>
        <ModalBody>
          <h3>{reportOpened?.message}</h3>
          <h5>Etablissement: {reportOpened?.establishment}</h5>
          <h5>Utilisateur: {reportOpened?.user}</h5>
          <span>
            {reportOpened?.stack}
          </span>
        </ModalBody>
        <ModalFooter style={{display: "flex", justifyContent: "center"}}>
          <Button onClick={() => setReportOpened(null)}>Fermer</Button>
          <Button color="danger" onClick={_handleDelete}>Supprimer</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ErrorReport;