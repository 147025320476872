import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getEstablishmentById = async ({id}, err) => {
  try {
    const res = await firestore()
                        .collection("establishments")
                        .doc(id)
                        .get();
    if (!res.exists) return 0;
    return {uid: res.id, ...res.data()};
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const getEstablishmentById_rt = ({id}, cb, err) => (
  firestore()
    .collection("establishments")
    .doc(id)
    .onSnapshot(doc => {
      const data = {uid: doc.id, ...doc.data()};
      cb(data);
    }, err ?? (() => {}))
);


export const updateEstablishmentById = async ({id, data}, err) => {
  try {
    await firestore()
          .collection("establishments")
          .doc(id)
          .update(data); 
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

export const getAllEtablishments = async(err) => {
  try{
    let res = await firestore()
                        .collection("establishments")
                        .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  }catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

export const getAllEstablishments_rt = (_, cb, err) => (
  firestore()
    .collection("establishments")
    .onSnapshot(snap => {
      const data = snap.docs.map(doc => ({
        uid: doc.id,
        ...doc.data()
      }));
      cb(data);
    }, err ?? (() => {}))
);