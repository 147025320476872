import React, { useState, useEffect } from "react";
import useUI from "hooks/ui.hook";

import firebase from "firebase/app";
import 'firebase/firestore';

import styles from "../../../Basic/Assets/scss/menu.module.scss";

import moment from "moment";

import { Resume, ReservationTable } from "../../../router_components";
import { useMenuReservation } from "../../../router_context";

import { VideoTuto } from "lib/components/YoutubeModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "lib/components";
import DropDown from "lib/components/DropDown";
import { Download } from "react-feather";
import { filterRepas, sortRepas, sortCategories } from "pages/Major/Menu/helpers/operations";
import { ReservationTableGuest, ExportTable, GuestSelectorModal, GuestSelectorModalStore } from "../../../router_components";
import ExportDay from "../Components/ExportDay";
import { findSubscriptionForDate } from "helpers/subscription"
import useSupplements from "../../../Basic/Stores/useSupplements";
import ExportRestaurant from "../Components/Exports/ExportRestaurant";
import ExportDayRestaurant from "../Components/Exports/ExportDayRestaurant";
import ExportReservations from "../Components/Exports/ExportReservations";



const ReservationMenus = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();
  const [showTotal, setShowTotal] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [modalExportOpen2, setModalExportOpen2] = useState(false);
  const [modalExportOpen3, setModalExportOpen3] = useState(false);
  const template = ui?.establishment?.template;
  const {
    isOpen: isGuestSelectorModalOpen,
    setIsOpen: setIsGuestSelectorModalOpen,
    props: guestSelectorModalProps,
    setProps: setGuestSelectorModalProps
  } = GuestSelectorModalStore((state) => ({
    isOpen: state.isOpen,
    setIsOpen: state.setIsOpen,
    props: state.props,
    setProps: state.setProps
  }));

  const sortedRepas = sortRepas(template, Object.keys(template));

  const initSupplements = useSupplements(state => state.initSupplements);

  useEffect(() => {
    const unsubscribe = initSupplements(ui?.establishment?.uid, ui?.groupement?.uid);
    return unsubscribe;
  }, [ui?.establishment?.uid, ui?.groupement?.uid, initSupplements])

  //const [ui] = useUI();
  //const [page, setPage] = useState(0);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx.data, ctx.users]);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx?.selectedWeek, ctx.daysToDisplay]);

  const selectDate = () => {

    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      let _dateArray = [];

      for (let i = 0; i < 7; i++) {
        if (ctx.daysToDisplay.includes(i + 1)) {
          _dateArray.push(ctx?.selectedWeek?.clone().add(i, "days").format("YYYY-MM-DD"));
        }
      }

      dispatch({ type: "setProperty", property: "dateArray", value: _dateArray });
    }
  };

  const changeWeek = (direction) => {
    // direction = -1 || 1
    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      dispatch({ type: "setProperty", property: "selectedWeek", value: ctx?.selectedWeek?.clone().add(direction, "week") });
    }
  };



  const generateResumeRepas = (data, repas) => {

    let retour = "";

    if (data[repas]) {
      const _categories = sortCategories(ui.establishment.template, repas, Object.keys(data[repas]));

      _categories.forEach(_categ => {
        const dishes = Object.keys(data[repas][_categ]);
        if (dishes.length > 0) {

          dishes.forEach(_dishId => {
            let count = 0;

            Object.keys(ctx.users).forEach(_uid => {
              if (data.reservation != undefined) {
                if (data.reservation[_uid] && data.reservation[_uid][repas] && data.reservation[_uid][repas].length > 0) {
                  data.reservation[_uid][repas].forEach(element => {
                    if (element[_categ] === _dishId) {
                      count++;
                    }
                  });
                }
              }
            });

            retour += data[repas][_categ][_dishId].name + " : " + count + "\n";

          });

        }
      });
    }


    return retour;
  };

  const isRepasUnite = (userId, _repas, date) => {
    const _user = ctx.users[userId];
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription2;
    const subscription = findSubscriptionForDate(_user?.subscriptions, date);
    const _subscription = subscription?.subscription;

    let retour = true;

    if (template && templateSubscription) {
      if (_subscription) {
        // L'utilisateur a un abonnement pour ce mois.

        // si jamais l'abonnement prend en compte le repas, alors ce n'est pas un repas à l'unité.
        if (templateSubscription[_subscription].repas[_repas]) {
          retour = false;
        }
      }
    }

    return retour;
  };

  const isSupplement = (userId, data, _repas, date) => {
    const _user = ctx.users[userId];
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription2;
    const subscription = findSubscriptionForDate(_user?.subscriptions, date);
    const _subscription = subscription?.subscription;

    let retour = true;

    if (template && templateSubscription) {
      if (_subscription) {
        // L'utilisateur a un abonnement pour ce mois.

        // si le repas est pris en compte dans l'inscription
        if (templateSubscription[_subscription].repas[_repas]) {

          const _formules = templateSubscription[_subscription].repas[_repas]?.formules;

          let _maxPlats = 0;

          // On considère qu'il y a un supplément dans le cas où il y a plus de catégories réservés que de catégories
          // dans l'abonnement
          // ON ne prend PAS en compte les invitations. 
          _formules.forEach(_formule => {
            const _formuleInfo = template[_repas].formules.find(_f => _f.name === _formule);

            if (_formuleInfo.categories.length > _maxPlats) _maxPlats = _formuleInfo.categories.length;
          });


          let countResa = 0;

          template[_repas].categories.forEach((_categ) => {
            if (data[_repas][0][_categ] != null) {
              countResa++;
            }
          });

          if (countResa > _maxPlats) {
            retour = true;
          } else {
            retour = false;
          }

        } else {
          retour = false;
        }

      } else {
        retour = false;
      }
    } else {
      retour = false;
    }


    return retour;
  };


  const generateExportDataOvelia = async (period, date, identifiedBySurname, identifiedByName, identifiedByNumero, total, signature) => {
    const start = date.clone().toDate();
    const end = date.clone().add(1, period).toDate();
    const data = await ctx.getData(start, end);

    const sortedRepas = sortRepas(ui.establishment.template, Object.keys(ui.establishment.template));

    let generatedData = null;

    let header = {};

    if (identifiedByNumero) {
      header["Appartement"] = [];
    }
    if (identifiedBySurname) {
      header["Prénom"] = [];
    }
    if (identifiedByName) {
      header["Nom"] = [];
    }

    if (total) {
      header["Total"] = [{ "Total": [] }];
      sortedRepas.forEach(_repas => {
        header["Total"].push({ [_repas]: [] });
      });
      header["Portage"] = [];
      header["Invitations"] = [];
      header["Supplément"] = [];

    }



    let _users = {};
    let sumUsers = {};

    Object.entries(ctx.users).forEach(([key, value]) => {
      _users[key] = { surname: value.surname, name: value.name, room: value.room, table: [] };
      if (!sumUsers[key]) {
        sumUsers[key] = {
          sumPortage: 0,
          sumInvitations: 0,
          sumSupplement: 0,
        };
        sortedRepas.forEach(_repas => {
          sumUsers[key]["sum" + _repas] = 0;
        });
      }

    });


    let totalList = [];
    let portageList = [];
    let invitationList = [];
    let customStyles = [];



    let localDate = date.clone();
    let numDays = - localDate.diff(localDate.clone().add(1, period), "days");
    for (let i = 1; i <= numDays; i++) {
      let formated = localDate.format("YYYY-MM-DD");
      header[localDate.format("ddd DD/MM")] = [];
      if (data[formated] != undefined) {
        const dataDay = data[formated];
        sortRepas(ui.establishment.template, filterRepas(ui.establishment.template, Object.keys(data[formated]))).forEach(_repas => {
          let obj = {};
          let obj_content = {};
          //let content = generateResumeRepas(dataDay, _repas);

          //obj_content[content] = [];
          obj[_repas] = [];//[obj_content];
          header[localDate.format("ddd DD/MM")].push(obj);

          let tot = 0;
          let totPortage = 0;
          let totInvitation = 0;

          Object.entries(_users).forEach(([key, value], _indx) => {

            if (dataDay.reservation != undefined) {
              if (dataDay.reservation[key] && dataDay.reservation[key][_repas] && dataDay.reservation[key][_repas].length > 0) {
                value.table.push(dataDay.reservation[key][_repas].length);
                tot += dataDay.reservation[key][_repas].length;

                sumUsers[key]["sum" + _repas] += dataDay.reservation[key][_repas].length;

                let _customStyle = {};

                if (dataDay.reservation[key][_repas].length > 1) {
                  totInvitation += dataDay.reservation[key][_repas].length - 1;
                  sumUsers[key].sumInvitations += dataDay.reservation[key][_repas].length - 1;
                }

                if (dataDay.reservation[key][_repas][0].homeDelivery === true) {
                  totPortage++;
                  _customStyle = { fill: { fgColor: { rgb: "f2ea00" } } };
                  sumUsers[key].sumPortage++;

                  if (isSupplement(key, dataDay.reservation[key], _repas, localDate)) {
                    _customStyle = Object.assign(_customStyle, {
                      font: {
                        color: { rgb: "be00f2" },
                        bold: true,
                        sz: "13"
                      },
                      fill: { fgColor: { rgb: "f2ce00" } }
                    });
                    sumUsers[key].sumSupplement++;
                  }
                }
                // }else{
                //   // if(isSupplement(key, dataDay.reservation[key], _repas, localDate)){
                //   //   _customStyle =  Object.assign(_customStyle, {fill: {fgColor: {rgb: "e33119"}}});
                //   // }
                // }

                if (isRepasUnite(key, _repas, localDate)) {
                  _customStyle = Object.assign(_customStyle, {
                    font: {
                      color: { rgb: "e33119" },
                      bold: true,
                      sz: "13"
                    }
                  });
                }



                if (Object.keys(_customStyle).length > 0) {
                  customStyles.push({
                    x: value.table.length - 1,
                    y: _indx,
                    style: _customStyle
                  });
                }
              }
              else value.table.push("");
            } else value.table.push("");

          });


          if (total) {
            if (tot > 0) {
              totalList.push(tot);
            } else totalList.push("");

            if (totPortage > 0) {
              portageList.push(totPortage);
            } else portageList.push("");

            if (totInvitation > 0) {
              invitationList.push(totInvitation);
            } else invitationList.push("");
          }

        });
      } else { // aucun événement ce jour
        Object.entries(_users).forEach(([key, value]) => {
          value.table.push("");
        });
        if (total) {
          totalList.push("");
          portageList.push("");
          invitationList.push("");
        }
      }
      localDate = moment(localDate).add(1, "day");
    }


    if (signature) {
      header["Signature"] = [];
    }

    let _data = [];
    Object.entries(_users).forEach(([key, value], _indx) => {
      let table = value.table;
      let sum = 0;


      if (total) {
        table.forEach(element => {
          if (element != "" && Number.isInteger(parseInt(element))) sum += parseInt(element);
        });

      }
      if (total) {
        table = [sumUsers[key].sumSupplement, ...table];
        table = [sumUsers[key].sumInvitations, ...table];
        table = [sumUsers[key].sumPortage, ...table];
        sortedRepas.reverse().forEach(_repas => {
          table = [sumUsers[key]["sum" + _repas], ...table];
        });
        table = [sum, ...table];

      }

      if (identifiedByName) {
        table = [value.name, ...table];
      }
      if (identifiedBySurname) {
        table = [value.surname, ...table];
      }
      if (identifiedByNumero) {
        table = [value.room, ...table];
      }

      if (signature) {
        table = [...table, ""];
      }
      _data.push(table);
    });
    let colStart = 0 + identifiedBySurname + identifiedByName + identifiedByNumero; // true == 1, false == 0;
    total ? colStart += (4 + sortedRepas.length) : null;
    let colEnd = 0 + signature;

    if (total) {
      let total_total = 0;
      totalList.forEach(element => {
        let num = parseInt(element);
        if (!isNaN(num)) total_total += num;
      });

      // totalList = ["Total",...totalList];


      // totalList = [...totalList,total_total.toString()];






      portageList = ["Portage", ...portageList];

      invitationList = ["Invitations", ...invitationList];


      const _categReverseOrder = ["Supplément", "Invitations", "Portage"];
      sortedRepas.reverse().forEach(_repas => {
        _categReverseOrder.push(_repas);
      });
      _categReverseOrder.push("Total");

      for (let i = 0; i < colStart - 1; i++) {

        if (_categReverseOrder[i]) {
          if (_categReverseOrder[i] === "Supplément") {
            let _sum = 0;
            Object.values(sumUsers).forEach(_obj => {
              _sum += _obj.sumSupplement;
            });
            totalList = [_sum, ...totalList];

          } else if (_categReverseOrder[i] === "Invitations") {
            let _sum = 0;
            Object.values(sumUsers).forEach(_obj => {
              _sum += _obj.sumInvitations;
            });
            totalList = [_sum, ...totalList];

          } else if (_categReverseOrder[i] === "Portage") {
            let _sum = 0;
            Object.values(sumUsers).forEach(_obj => {
              _sum += _obj.sumPortage;
            });
            totalList = [_sum, ...totalList];

          } else if (_categReverseOrder[i] === "Total") {
            totalList = [total_total, ...totalList];

          } else {
            if (sortedRepas.includes(_categReverseOrder[i])) {
              let _sum = 0;
              Object.values(sumUsers).forEach(_obj => {
                _sum += _obj["sum" + _categReverseOrder[i]];
              });
              totalList = [_sum, ...totalList];
            } else {
              totalList = ["", ...totalList];
            }
          }
        } else {
          totalList = ["", ...totalList];
        }

        portageList = ["", ...portageList];
        invitationList = ["", ...invitationList];

      }
      totalList = ["Total", ...totalList];

      _data.push(totalList);
      _data.push(portageList);
      _data.push(invitationList);

    }

    generatedData = {
      header: header,
      data: _data,
      depth: 2,
      bottomLength: 3,
      colStart: colStart,
      colEnd: colEnd,
      styles: customStyles
    };

    return generatedData;
  };




  if (!ctx || !ctx.users || !ctx.data) return (<div></div>);
  return (
    <>
      <VideoTuto url="https://youtu.be/1P61Re4pRx4" />
      <div className={styles.menu}>
        <div className={styles.weekSelector}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowLeft}
            onClick={() => {
              changeWeek(-1);
            }}
          />
          <div className={styles.weekText}>
            {ctx?.selectedWeek?.clone().format("DD MMMM YYYY")} - {ctx?.selectedWeek?.clone().endOf("week").format("DD MMMM YYYY")}
          </div>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowRight}
            onClick={() => {
              changeWeek(1);
            }}
          />
        </div>

        <div className={styles.buttonContainer} style={{ marginBottom: -52, marginLeft: 210 }}>
          <Button onClick={() => setIsDropDownOpen(!isDropDownOpen)} style={{ position: "relative", display: "flex", gap: 10 }}>
            Exporter
            <FontAwesomeIcon
              className={styles.angle}
              icon={isDropDownOpen ? faAngleDown : faAngleRight}
            />
            <DropDown isOpen={isDropDownOpen} style={{ top: 38, paddingLeft: 30, paddingRight: 30 }} toggle={() => setIsDropDownOpen(false)}>

              <Button className={styles.exportButton} color="primary" onClick={() => setModalExportOpen3(true)}>
                <span>
                  Export journalier
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>

              <Button className={styles.exportButton} style={{ marginTop: 10 }} color="primary" onClick={() => setModalExportOpen(true)}>
                <span>
                  Export des réservations
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>

              <Button className={styles.exportButton} style={{ marginTop: 10 }} color="primary" onClick={() => setModalExportOpen2(true)}>
                <span>
                  Export restauration
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>


            </DropDown>
          </Button>

          <div className={styles.filterContainer}>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "senior" })} className={`${styles.filterElement} ${ctx?.filter?.role === "senior" ? styles.active : ""}`}>
              Résidents
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "employee" })} className={`${styles.filterElement} ${ctx?.filter?.role === "employee" ? styles.active : ""}`}>
              Collaborateurs
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "guest" })} className={`${styles.filterElement} ${ctx?.filter?.role === "guest" ? styles.active : ""}`}>
              Invités
            </div>


          </div>


          <Button style={{ zIndex: 600 }} onClick={() => setShowTotal(!showTotal)}>{!showTotal ? "Afficher les totaux" : "Cacher les totaux"}</Button>
          <Button onClick={() => {
            console.log(GuestSelectorModalStore)
            setGuestSelectorModalProps({
              ...guestSelectorModalProps,
              linkedTo: null,
              date: null,
              service: null,
              guestTypeFilter: [],
              onSubmit: (guest, date, service) => {
                // dispatch({ type: "setProperty", property: "filter", value: {...ctx.filter, role: "guest"} });
                dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
                dispatch({
                  type: "setProperty", property: "modalInfos", value: {
                    uid: guest.uid,
                    date: moment(date).format("YYYY-MM-DD"),
                    repas: service,
                  }
                });
              }
            });
            setIsGuestSelectorModalOpen(true);

          }}>Ajouter invité</Button>
          <GuestSelectorModal
            {...guestSelectorModalProps}
            isOpen={isGuestSelectorModalOpen}
            onClose={() => setIsGuestSelectorModalOpen(false)}
          />
        </div>

        {ctx.filter?.role === "guest" ? <ReservationTableGuest showTotal={showTotal} /> : <ReservationTable showTotal={showTotal} />}

        {/* <ReservationTable showTotal={showTotal} /> */}

        <ExportReservations modalExportOpen={modalExportOpen} setModalExportOpen={setModalExportOpen} />
        <ExportDayRestaurant modalExportOpen={modalExportOpen3} setModalExportOpen={setModalExportOpen3} />
        <ExportRestaurant modalExportOpen={modalExportOpen2} setModalExportOpen={setModalExportOpen2} />
        {/* {modalExportOpen2 ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportData2} infos={{ title: "Export des Menus", type: "menuDishes", date: ctx?.selectedDate }} />
        ) : null} */}
        {/*<Tab render={render} activeTab={page} onClick={pressHeaderHandler}/>*/}
        {/* <Details data={dataSelected} users={etabUsers} allData={dataMenus} updateData={updateData} selectedDate={selectedWeek}/> */}
      </div>
    </>

  );
};
export default ReservationMenus;
