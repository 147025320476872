import React from "react";
import PropTypes from "prop-types";
import styles from "../assets/scss/component/progress.module.scss";

const Progress = (props) => {
  const {
    color="success",
    className="",
    style,
    min=0,
    max=100,
    label,
    value,
  } = props;

  const colorClass = color === "primary"   ? styles.primary   :
    color === "secondary" ? styles.secondary :
      color === "success"   ? styles.success   :
        color === "warning"   ? styles.warning   :
          color === "info"      ? styles.info      :
            color === "danger"    ? styles.danger    : styles.primary;

  return (
    <div className={`${className ?? ""}`} style={style}>
      {label ? 
        <span style={{fontWeight: "600", fontSize: 18}}>{label}</span>
        : <></>}
      <div className={`${styles.progress}`}>
        <div className={`${styles.bar} ${colorClass}`}
          style={{
            width: `${value * 100 / max}%`
          }}>
        </div>
      </div>
    </div>

  );
};

Progress.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.number,
};

export default Progress;