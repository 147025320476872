import React, {useState, useEffect} from "react";
import useCtx from "../../Context/ctx";
import Menu from "pages/Major/Reservation/Menu/Basic/Components/WeekReservationTable";
import Planning from "@/pages/Major/Reservation/Planning/Basic/WeekReservationTable";
import moment from "moment";
import firebase from "firebase/app";
import 'firebase/firestore';
import { Button } from "lib/components";
import {pdf, PDFViewer} from "@react-pdf/renderer";
import WeekMenuPdf from "../../../../Reservation/Menu/Basic/Components/_weekPdf";
import WeekAnimPdf from "../../../../Reservation/Planning/Basic/weekPdf";

import { PDFDownloadLink } from "@react-pdf/renderer";
import printJS from "print-js";
import useUI from "hooks/ui.hook";
import useFeature from "hooks/useFeature";
import useAnimationEvents from "../../../../../../resources/animations";

const firestore = firebase.firestore;

const Reservations = () => {
  const [ui] = useUI();
  const {state} = useCtx();
  const [dataMenu, setDataMenu] = useState({});
  const {events} = useAnimationEvents();

  const [dataPlanning, setDataPlanning] = useState({});

  const week = moment().startOf("week");

  const isReservationMenu = useFeature("reservationMenu");
  const isReservationPlanning = useFeature("reservationPlanning");

  useEffect(()=> {
    if(events){
      let _dataPlanning = {};
      events.forEach(_e => {
        _dataPlanning[_e.uid] = _e;
      });
      setDataPlanning(_dataPlanning);
    }
  },[events]);

  useEffect(()=> {
    if(state?.establishment?.uid){
      firestore()
      .collection("establishments")
      .doc(state?.establishment?.uid)
      .collection("blocks")
      .doc("menu")
      .collection("menu")
      .where("published", "==", true)
      .onSnapshot(s => {
        let _dataMenus = {};
        s.forEach(doc => {
          _dataMenus[doc.id] = doc.data();
        });
        setDataMenu(_dataMenus);
      });
    }
  },[state]);

//<WeekMenuPdf data={dataMenu} uid={state.user.uid} name={`${state.user.surname} ${state.user.name}`} week={state.selectedWeek} template={ui.establishment.template}/>
  return (
    <div style={{width: "100%", minHeight: "80vh"}}>
    {state?.user ?
    <>
      {isReservationMenu ? 
        <>
          <div style={{display: "flex", justifyContent: "center", marginBottom: -15, marginTop: 15}}>
            <PDFDownloadLink
                document={
                  <WeekMenuPdf data={dataMenu} uid={state.user.uid} name={`${state.user.surname} ${state.user.name}`} week={week} template={ui.establishment.template}/>
                }
                fileName="planning.pdf"
              >
                {({ /* blob, url,*/ loading /*, error */ }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <>
                      <Button color="secondary">Télécharger</Button>
                    </>
                  )
                }
              </PDFDownloadLink>
          </div>
          <Menu _data={dataMenu} uid={state.user.uid} week={week}/>
        </>
      : null}

      
      {isReservationPlanning ? 
        <>
          <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
          <PDFDownloadLink
              document={
                <WeekAnimPdf data={events} uid={state.user.uid} name={`${state.user.surname} ${state.user.name}`} week={week}/>
              }
              fileName="planning.pdf"
            >
              {({ /* blob, url,*/ loading /*, error */ }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <>
                    <Button color="secondary">Télécharger</Button>
                  </>
                )
              }
            </PDFDownloadLink>
          </div>
        
          <Planning _data={dataPlanning} uid={state.user.uid} week={week}/>
        </>
      : null}
      
    </> 
    : null}
    </div>
  );
};

export default Reservations;