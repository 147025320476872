import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
//import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
//import deepClone from "helpers/deepClone";
//import deepClone from "../../../../helpers/deepClone";
import { getDefaultDay } from "../../../../../Menu/helpers/operations";
import moment from "moment";
import { getMenusByPeriod } from "services/ressources/menu";
import { findSubscriptionForDate } from "helpers/subscription"

const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  dataBase: null,
  dataBaseSubscription: null,
  data: null,
  dataOrders: null,
  dataOrdersSupplements: null,
  dataGuestOrders: null,
  dataGuestOrdersSupplements: null,
  usersUnfiltered: null,
  users: null,
  filteredUsers: null,
  guests: null,
  filteredGuests: null,
  selectedWeek: null,
  dateArray: null,
  selectedRepas: null,
  daysToDisplay: [1, 2, 3, 4, 5, 6, 7],
  loadingMenu: true,
  modalResaOpen: false,
  modalWeekOpen: false,
  printPDF: false,
  modalInfos: null,
  menuType: "category",
  legendForms: ["square", "circle", "hexagon", "diamond"],
  legendColors: ["#201E50", "#B23A48", "#8C86AA", "#F79D5C"], //["#2274a5", "#816C61", "#FCAB10", "#DAC4F7"],
  updateMenu: null,
  filter: {
    role: "senior", //or employee
  },
};

function Reducer(state, action) {
  switch (action.type) {
    case "setProperty":
      return { ...state, [action.property]: action.value };
    case "setFilter":
      return { ...state, filter: action.value };
    case "setFilterProperty":
      return {
        ...state,
        filter: { ...state.filter, [action.property]: action.value },
      };
    default:
      return { ...state };
  }
}

const Provider = ({ children }) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);

  const template = ui?.establishment?.template;
  const templateSubscription = ui?.establishment?.templateSubscription2;

  const isGuest = (_data) => {
    if (!_data.userId && _data.guest) return true;
    return false;
  };

  const getGuestInformation = (_data) => {
    if (!isGuest(_data)) return {};

    const guestInformation = {};

    if (_data.guest.guestId) guestInformation.id = _data.guest.guestId;
    if (_data.guest.type) guestInformation.type = _data.guest.type;
    if (_data.guest.name) guestInformation.name = _data.guest.name;
    if (_data.guest.linkedTo) guestInformation.linkedTo = _data.guest.linkedTo;
    if (_data.guest.hasPaid) guestInformation.hasPaid = _data.guest.hasPaid;



    return guestInformation;
  };

  //init


  useEffect(() => {
    firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("menu")
      .get()
      .then(res => {
        let data = res.data();
        if (data.daysToDisplay && data.daysToDisplay.length > 0) {
          dispatch({ type: "setProperty", property: "daysToDisplay", value: data.daysToDisplay });
        }
        if (data.type === "menu")
          dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
      })
      .catch(error => {
        console.log("error", error);
      });

    try {
      firestore()
        .collection("users")
        .where("establishment", "==", ui.user.establishment)
        .where(
          "role",
          "in",
          ["employee", "owner", "senior", "seniorTotem", "guest"]
        )
        .onSnapshot((res) => {
          let _dataUsers = {};
          res.forEach((doc) => {
            _dataUsers[doc.id] = doc.data();
          });
          dispatch({
            type: "setProperty",
            property: "usersUnfiltered",
            value: _dataUsers,
          });
        });
    } catch (e) {
      console.error(e);
    }

    if (ui.establishment.menuType && ui.establishment.menuType === "menu")
      dispatch({ type: "setProperty", property: "menuType", value: "menu" });
  }, [ui]);

  useEffect(() => {
    if (ctx.selectedWeek && ctx.usersUnfiltered) {
      let _dataUsers = {};
      // console.log(ctx.usersUnfiltered);
      Object.entries(ctx.usersUnfiltered).forEach(([key, user]) => {
        if (user.isDeleted && moment(user.deletedAt.toDate()).isBefore(moment(ctx.selectedWeek).startOf("week"))) return;
        _dataUsers[key] = { uid: key, ...user };

      });
      dispatch({
        type: "setProperty",
        property: "users",
        value: _dataUsers,
      });
    }

  }, [ctx.selectedWeek, ctx.usersUnfiltered]);

  useEffect(() => {
    if (ctx.selectedWeek) {
      const _start = ctx.selectedWeek.toDate();
      const _end = ctx.selectedWeek.clone().endOf("week").toDate();


      const unsubscribe = firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("menu")
        .collection("menu")
        .where("published", "==", true)
        .where("day", ">=", _start)
        .where("day", "<=", _end)
        .onSnapshot(s => {
          let _dataMenus = {};
          s.forEach(doc => {
            _dataMenus[doc.id] = doc.data();
          });
          dispatch({ type: "setProperty", property: "dataBase", value: _dataMenus });
        });

      const unsubscribe2 = firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("restaurant")
        .collection("orders")
        .where("day", ">=", _start)
        .where("day", "<=", _end)
        // .where("status", "==", "")
        .onSnapshot((s) => {
          const filteredDocs = [];

          s.forEach(doc => {
            filteredDocs.push({ uid: doc.id, ...doc.data() });
          });

          const orderedDocs = filteredDocs.sort((a, b) => a.day.toDate() - b.day.toDate());

          const _processedData = handleOrdersData(orderedDocs);

          if (_processedData) {
            dispatch({ type: "setProperty", property: "dataOrders", value: _processedData.dataOrders });
            dispatch({ type: "setProperty", property: "dataOrdersSupplements", value: _processedData.dataOrdersSupplements });
            dispatch({ type: "setProperty", property: "guests", value: _processedData.guests });
            dispatch({ type: "setProperty", property: "dataGuestOrders", value: _processedData.dataGuestOrders });
            dispatch({ type: "setProperty", property: "dataGuestOrdersSupplements", value: _processedData.dataGuestOrdersSupplements });
          }
        });

      return () => {
        unsubscribe();
        unsubscribe2();
      };
    }
  }, [ctx.selectedWeek]);

  useEffect(() => {
    if (ctx.dataBaseSubscription) {
      const _data = deepClone(ctx.dataBaseSubscription);

      if (ctx.dataOrders) {
        Object.entries(ctx.dataOrders).forEach(([_date, _resas]) => {
          if (_data[_date]) {
            Object.entries(_resas).forEach(([_uid, _resa]) => {
              if (!_data[_date].reservation) _data[_date].reservation = {};
              if (!_data[_date].reservation[_uid]) _data[_date].reservation[_uid] = {};

              Object.entries(_resa).forEach(([_repas, _resaData]) => {
                if (!_data[_date].reservation[_uid][_repas]) _data[_date].reservation[_uid][_repas] = [];
                _data[_date].reservation[_uid][_repas] = _resaData;
              });
            });
          }
        });

      }

      dispatch({ type: "setProperty", property: "data", value: _data });
      // console.log("_data", _data["2024-04-08"]);

      if (ctx.loadingMenu)
        dispatch({ type: "setProperty", property: "loadingMenu", value: false });
    }
  }, [ctx.dataOrders, ctx.dataBaseSubscription]);

  useEffect(() => {
    if (ctx.dataBase) {
      const _data = deepClone(ctx.dataBase);
      if (ctx.users) {
        // Gestion des abonnements :
        if (templateSubscription) {
          // on rajoute toutes les réservations nécessaires.

          Object.entries(_data).forEach(([_day, _menu]) => {
            const _defaultDay = getDefaultDay(template, _menu);
            if (!_menu.reservation) _menu.reservation = {};
            const _reservations = _menu.reservation;

            // on check tous les utilisateurs ayant un abonnement sur le jour en cours.
            Object.entries(ctx.users).forEach(([_uid, _user]) => {
              const subscription = findSubscriptionForDate(_user?.subscriptions, _day);
              const _subscription = subscription?.subscription;
              const _subscriptionHomeDelivery = subscription?.homeDelivery ?? {};
              const _subscriptionDays = subscription?.days ?? [1, 2, 3, 4, 5, 6, 7];

              // si l'utilisateur a des abonnements.
              if (_subscription) {
                // on vérifie s'il est abonné pour ce jour.
                if (_subscriptionDays.includes(moment(_day).isoWeekday())) {


                  // on vérifie maintenant s'il a déjà un réservation pour les repas compris dans son abonnement. 
                  // Si il a déjà une réservation on ne change rien. S'il n'en a pas on rajoute la réservation de base.


                  if (templateSubscription[_subscription] && templateSubscription[_subscription].repas) {
                    Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

                      // si il n'y a pas de resa pour ce repas on la rajoute.
                      if (!_reservations[_uid] || !_reservations[_uid][_repas]) {

                        if (!_reservations[_uid]) _reservations[_uid] = {};

                        const _newResa = {};

                        const _formules = _repasInfos?.formules;

                        if (_formules.length === 1) {
                          const _formule = _formules[0];
                          const _formuleInfo = template[_repas].formules.find(_f => _f.name === _formule);

                          _formuleInfo.categories.forEach((_category) => {
                            _newResa[_category] = _defaultDay[_repas] && _defaultDay[_repas][_category] ? _defaultDay[_repas][_category] : null;
                          });

                        }
                        _reservations[_uid][_repas] = [{ ..._newResa, homeDelivery: typeof (_subscriptionHomeDelivery) === "object" ? _subscriptionHomeDelivery[_repas] ?? false : _subscriptionHomeDelivery ?? false, createdLocally: true }];
                      }

                    });
                  }

                }

              }

            });
          });
        }
      }

      dispatch({ type: "setProperty", property: "dataBaseSubscription", value: _data });

    }
  }, [ctx.dataBase, ctx.users]);

  useEffect(() => {
    if (ctx.users) {
      let filteredUsers = {};

      Object.keys(ctx.users).forEach(uid => {
        const user = ctx.users[uid];

        if (ctx.filter.role === "senior" && ["senior", "seniorTotem"].includes(user.role)) {
          filteredUsers[uid] = user;
        } else if (ctx.filter.role === "employee" && ["owner", "employee"].includes(user.role)) {
          filteredUsers[uid] = user;
        }
      });

      dispatch({ type: "setProperty", property: "filteredUsers", value: filteredUsers });


      // dispatch({ type: "setProperty", property: "filteredGuest", value: filteredDataOrders });
    }
  }, [ctx.users, ctx.filter, ctx.dataOrders, ctx.dateArray]);

  useEffect(() => {
    const filteredDataOrders = Object.keys(ctx.dataGuestOrders ?? [])
      .filter(date => (ctx.dateArray ?? []).includes(date))
      .reduce((acc, date) => {
        acc[date] = ctx.dataGuestOrders[date];
        return acc;
      }, {});

    let filteredGuests = {};

    if (ctx.users) {
      Object.keys(ctx.users).forEach(uid => {
        const user = ctx.users[uid];
        if (ctx.filter.role === "guest" && ["guest"].includes(user.role)) {
          filteredGuests[uid] = user;
        }
      });
    }

    dispatch({ type: "setProperty", property: "filteredGuests", value: filteredGuests });
  }, [ctx.filter, ctx.dataOrders, ctx.dateArray, ctx.guests, ctx.dataGuestOrders]);


  useEffect(() => {
    if (!ctx.modalResaOpen && ctx.modalInfos != null) {
      dispatch({ type: "setProperty", property: "modalInfos", value: null });
    }
  }, [ctx.modalResaOpen]);

  const updateMenu = (_resa, date, uid, repas) => {
    // date = "2022-07-15" options = {"midi" : 0, "soir" : 2} action = ["update", "remove"]
    //let docId = moment(date).format("YYYY-MM-DD");
    let map = {};

    let _date = date ?? ctx.modalInfos.date ?? null;
    let _uid = uid ?? ctx.modalInfos.uid ?? null;
    let _repas = repas ?? ctx.modalInfos.repas ?? null;

    const guestResa = Object.entries(ctx.data[_date].reservation ?? {})
      .filter(([idResa, resa]) =>
        resa?.[_repas]?.find(_resa => _resa.isGuest)
        && ctx?.users[idResa]?.linkedTo === _uid
      )
      .map(([idResa, resa]) => ({ uid: idResa, ...resa[_repas][0] }));

    //let isMapEmpty = true;

    if (_resa === "delete") {

      if (ctx.data[_date].reservation[_uid][_repas][0].createdLocally) {
        //cancel
        map["reservation." + _uid + "." + _repas] = [{ status: "canceled", homeDelivery: false }];
      } else {
        map["reservation." + _uid + "." + _repas] = firestore.FieldValue.delete();
        guestResa.forEach(element => {
          map["reservation." + element.uid + "." + _repas] = firestore.FieldValue.delete();;
        })
      }
    } else {
      if (_resa[0].status === "canceled") delete _resa[0].status;
      if (_resa[0].createdLocally === true) delete _resa[0].createdLocally;

      // Gestion des invités

      // 1: Mise à jour des commandes
      _resa.forEach(element => {
        // Cas resa invité
        if (element.uid) {
          if (!map["reservation." + element.uid + "." + _repas]) map["reservation." + element.uid + "." + _repas] = [];
          map["reservation." + element.uid + "." + _repas].push(element)
        }
        // Cas resa résident
        else {
          if (!map["reservation." + _uid + "." + _repas]) map["reservation." + _uid + "." + _repas] = [];
          map["reservation." + _uid + "." + _repas].push(element)
        }
      })

      // 2: Suppression de commande invités
      guestResa.forEach(element => {
        if (!map["reservation." + element.uid + "." + _repas]) {
          map["reservation." + element.uid + "." + _repas] = firestore.FieldValue.delete();;
        }
      })

    }


    firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("menu")
      .collection("menu")
      .doc(_date)
      .update(map);
  };

  const updateGuestPaid = async (guestData) => {
    try {
      if (guestData?.orderId) {
        const docRef = firestore()
          .collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("restaurant")
          .collection("orders")
          .doc(guestData.orderId)

        const doc = (await docRef.get()).data();

        await docRef.update({ ...doc, hasPaid: !doc.hasPaid ?? false });
      }
    } catch (error) {
      console.error("Erreur", error);
    }
  };


  const handleOrdersData = (orderedDocs) => {
    let _dataOrders = {};
    let _dataOrdersSupplements = {};

    orderedDocs.forEach((_data) => {

      let id = _data.userId ?? null;

      if (id !== null) {
        if (!_dataOrders[_data.date]) _dataOrders[_data.date] = {};

        if (!_dataOrders[_data.date][id])
          _dataOrders[_data.date][id] = {};

        //handle plates
        let _resa = {};
        _data.plates.forEach((element) => {
          if (!_resa[element.category]) _resa[element.category] = [];
          if (["garniture", "dessert"].includes(element.category)) {
            _resa[element.category] = [..._resa[element.category], element.uid];
          }
          else {
            _resa[element.category] = element.uid;
          }
        });

        //handle supplements
        let _supplements = [];

        if (
          _data.meta &&
          _data.meta.find((i) => i.name === "suppléments")
        ) {
          _supplements = [
            ..._data.meta.find((i) => i.name === "suppléments").value,
          ];
        }

        if (_supplements.length > 0) {
          if (!_dataOrdersSupplements[_data.date])
            _dataOrdersSupplements[_data.date] = {};
          if (!_dataOrdersSupplements[_data.date][id])
            _dataOrdersSupplements[_data.date][id] = {};

          if (!_dataOrdersSupplements[_data.date][id][_data.service])
            _dataOrdersSupplements[_data.date][id][_data.service] = [];



          // Des supplements peuvent déjà être présent 
          _dataOrdersSupplements[_data.date][id][_data.service].push(..._supplements);



          _dataOrdersSupplements[_data.date][id][_data.service] = _dataOrdersSupplements[_data.date][id][_data.service].reduce((acc, supplement) => {
            const existingItem = acc.find(item => item.id === supplement.id);
            if (existingItem) {
              existingItem.number += supplement.number;
            } else {
              acc.push(supplement);
            }
            return acc;
          }, []);

        }

        if (!_dataOrders[_data.date][id][_data.service])
          _dataOrders[_data.date][id][_data.service] = [];

        _dataOrders[_data.date][id][_data.service] = [{ orderId: _data.uid, plates: _resa, hasSignature: _data.signature ? true : false, hasPaid: _data.hasPaid }, ..._dataOrders[_data.date][id][_data.service]];

        _dataOrders[_data.date][id][_data.service] = _dataOrders[_data.date][id][_data.service].sort((a, b) => {
          if (a.userId === undefined)
            return 1; // Move null or undefined to the end

          if (b.userId === undefined)
            return -1; // Keep null or undefined at the end

          return a - b;
        });


      }

    });

    return {
      dataOrders: _dataOrders,
      dataOrdersSupplements: _dataOrdersSupplements,
    };
  };

  const getData = async (start, end) => {
    const _rawData = await getMenusByPeriod({ ui, start, end });

    const _data = Object.fromEntries(_rawData.map(_d => [_d.uid, _d]));

    // apply subscriptions : 
    if (ctx.users) {
      // Gestion des abonnements :
      if (templateSubscription) {
        // on rajoute toutes les réservations nécessaires.

        Object.entries(_data).forEach(([_day, _menu]) => {
          const _defaultDay = getDefaultDay(template, _menu);
          if (!_menu.reservation) _menu.reservation = {};
          const _reservations = _menu.reservation;

          // on check tous les utilisateurs ayant un abonnement sur le jour en cours.
          Object.entries(ctx.users).forEach(([_uid, _user]) => {
            const subscription = findSubscriptionForDate(_user?.subscriptions, _day);
            const _subscription = subscription?.subscription;
            const _subscriptionHomeDelivery = subscription?.homeDelivery ?? {};
            const _subscriptionDays = subscription?.days ?? [1, 2, 3, 4, 5, 6, 7];

            // si l'utilisateur a des abonnements.s
            if (_subscription && (!_user.isDeleted  || (_user.isDeleted && _user?.deletedAt?.toDate() > moment(_day).toDate()))) {
              // on vérifie s'il est abonné pour ce jour.
              if (_subscriptionDays.includes(moment(_day).isoWeekday())) {


                // on vérifie maintenant s'il a déjà un réservation pour les repas compris dans son abonnement. 
                // Si il a déjà une réservation on ne change rien. S'il n'en a pas on rajoute la réservation de base.


                if (templateSubscription[_subscription] && templateSubscription[_subscription].repas) {
                  Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

                    // si il n'y a pas de resa pour ce repas on la rajoute.
                    if (!_reservations[_uid] || !_reservations[_uid][_repas]) {

                      if (!_reservations[_uid]) _reservations[_uid] = {};

                      const _newResa = {};

                      const _formules = _repasInfos?.formules;

                      if (_formules.length === 1) {
                        const _formule = _formules[0];
                        const _formuleInfo = template[_repas].formules.find(_f => _f.name === _formule);

                        _formuleInfo.categories.forEach((_category) => {
                          _newResa[_category] = _defaultDay[_repas] && _defaultDay[_repas][_category] ? _defaultDay[_repas][_category] : null;
                        });

                      }
                      _reservations[_uid][_repas] = [{ ..._newResa, homeDelivery: typeof (_subscriptionHomeDelivery) === "object" ? _subscriptionHomeDelivery[_repas] ?? false : _subscriptionHomeDelivery ?? false, createdLocally: true }];
                    }

                  });
                }

              }

            }

          });
        });
      }
    }

    const s = await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("restaurant")
      .collection("orders")
      .where("day", ">=", start)
      .where("day", "<=", end).get();

    const filteredDocs = [];

    s.forEach(doc => {
      filteredDocs.push(doc.data());
    });

    const orderedDocs = filteredDocs.sort((a, b) => a.day.toDate() - b.day.toDate());

    const _processedData = handleOrdersData(orderedDocs);

    return {
      data: _data,
      ..._processedData
    };
  };

  ctx.getData = getData;
  ctx.updateMenu = updateMenu;
  ctx.updateGuestPaid = updateGuestPaid;

  return (
    <Context.Provider value={[ctx, dispatch]}>{children}</Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};
const useMenuReservation = () => {
  const contexte = useContext(Context);

  if (!contexte)
    throw new Error(
      "Le contexte n'est pas défini, il n'est probablement pas dans un provider !"
    );

  return contexte;
};
export default useMenuReservation;
export { Provider, Context };
