import React from "react";
import {FormGroup as BootstrapFormGroup} from "reactstrap";
import PropTypes from "prop-types";

const FormGroup = (props) => {
  return (
    <BootstrapFormGroup {...props}>
      {props.children}
    </BootstrapFormGroup>
  );
};

FormGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default FormGroup;