import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";
import useUI from "../../../../../../hooks/ui.hook";
import { getDefaultDay } from "../../../../Menu/helpers/operations";
import { getMenusByPeriod } from "services/ressources/menu";
import { findSubscriptionForDate } from "helpers/subscription"

import deepClone from "helpers/deepClone";

const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  dataBase: null,
  data: null,
  usersUnfiltered: null,
  users: null,
  filteredUsers: null,
  selectedWeek: null,
  dateArray: null,
  selectedRepas: null,
  daysToDisplay: [1, 2, 3, 4, 5, 6, 7],
  loadingMenu: true,
  modalResaOpen: false,
  modalWeekOpen: false,
  printPDF: false,
  modalInfos: null,
  menuType: "category",
  updateMenu: null,
  filter: {
    role: "senior" //or employee
  }
};

function Reducer(state, action) {
  switch (action.type) {
    case "setProperty": return ({ ...state, [action.property]: action.value });
    case "setFilter": return ({ ...state, filter: action.value });
    case "setFilterProperty": return ({ ...state, filter: { ...state.filter, [action.property]: action.value } });
    default: return { ...state };
  }
}

const Provider = ({ children }) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);

  const template = ui?.establishment?.template;
  const templateSubscription = ui?.establishment?.templateSubscription;
  //init


  useEffect(() => {
    firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("menu")
      .get()
      .then(res => {
        let data = res.data();
        if (data.daysToDisplay && data.daysToDisplay.length > 0) {
          dispatch({ type: "setProperty", property: "daysToDisplay", value: data.daysToDisplay });
        }
        if (data.type == "menu")
          dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
      })
      .catch(error => {
        console.log("error", error);
      });


    return () => {

    };
  }, []);


  useEffect(() => {
    try {
      firestore()
        .collection("users")
        .where("establishment", "==", ui.user.establishment)
        .where("role", "in", ctx?.filter?.role === "employee" ? ["employee", "owner"] : ["senior", "seniorTotem", "guest"])
        .onSnapshot(res => {
          let _dataUsers = {};
          res.forEach((doc) => {
            _dataUsers[doc.id] = doc.data();
          });
          dispatch({ type: "setProperty", property: "usersUnfiltered", value: _dataUsers });
        });
    } catch (e) {
      console.error(e);
    }


    if (ui.establishment.menuType && ui.establishment.menuType === "menu") dispatch({ type: "setProperty", property: "menuType", value: "menu" });
  }, [ctx.filter]);

  useEffect(() => {
    if (ctx.selectedWeek) {
      let _dataUsers = {};
      Object.entries(ctx.usersUnfiltered).forEach(([key, user]) => {
        if (user.isDeleted && moment(user.deletedAt.toDate()).isBefore(moment(ctx.selectedWeek).startOf("week"))) return;
        _dataUsers[key] = user;

      });
      dispatch({
        type: "setProperty",
        property: "users",
        value: _dataUsers,
      });
    }

  }, [ctx.selectedWeek, ctx.usersUnfiltered]);

  useEffect(() => {
    if (ctx.selectedWeek) {
      const _start = ctx.selectedWeek.toDate();
      const _end = ctx.selectedWeek.clone().endOf("week").toDate();
      try {
        const unsubscribe = firestore()
          .collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("menu")
          .collection("menu")
          .where("published", "==", true)
          .where("day", ">=", _start)
          .where("day", "<=", _end)
          .onSnapshot(s => {
            let _dataMenus = {};
            s.forEach(doc => {
              _dataMenus[doc.id] = doc.data();
            });
            dispatch({ type: "setProperty", property: "dataBase", value: _dataMenus });
          });

        return () => {
          unsubscribe();
        };
      } catch (e) {
        console.error(e);
      }
    }
  }, [ctx.selectedWeek]);

  useEffect(() => {
    if (ctx.dataBase) {
      const _data = deepClone(ctx.dataBase);
      if (ctx.users) {
        // Gestion des abonnements :
        if (templateSubscription) {
          // on rajoute toutes les réservations nécessaires.

          Object.entries(_data).forEach(([_day, _menu]) => {
            const _defaultDay = getDefaultDay(template, _menu);
            if (!_menu.reservation) _menu.reservation = {};
            const _reservations = _menu.reservation;

            // on check tous les utilisateurs ayant un abonnement sur le jour en cours.
            Object.entries(ctx.users).forEach(([_uid, _user]) => {

              const subscription = findSubscriptionForDate(_user?.subscriptions, _day);
              const _subscription = subscription?.subscription;
              const _subscriptionHomeDelivery = subscription?.homeDelivery ?? {};
              const _subscriptionDays = subscription?.days ?? [1, 2, 3, 4, 5, 6, 7];

              // si l'utilisateur a des abonnements.
              if (_subscription) {
                // on vérifie s'il est abonné pour ce jour.
                if (_subscriptionDays.includes(moment(_day).isoWeekday())) {

                  // on vérifie maintenant s'il a déjà un réservation pour les repas compris dans son abonnement. 
                  // Si il a déjà une réservation on ne change rien. S'il n'en a pas on rajoute la réservation de base.


                  if (templateSubscription[_subscription]) {
                    Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

                      // si il n'y a pas de resa pour ce repas on la rajoute.
                      if (!_reservations[_uid] || !_reservations[_uid][_repas]) {

                        if (!_reservations[_uid]) _reservations[_uid] = {};

                        const _newResa = {};

                        _repasInfos.forEach((_category) => {
                          _newResa[_category] = _defaultDay[_repas] && _defaultDay[_repas][_category] ? _defaultDay[_repas][_category] : null;
                        });

                        _reservations[_uid][_repas] = [{ ..._newResa, homeDelivery: _subscriptionHomeDelivery[_repas] ?? false, createdLocally: true }];
                      }

                    });
                  }

                }

              }

            });
          });
        }
      }

      dispatch({ type: "setProperty", property: "data", value: _data });


      if (ctx.loadingMenu)
        dispatch({ type: "setProperty", property: "loadingMenu", value: false });
    }
  }, [ctx.dataBase, ctx.users]);

  useEffect(() => {
    // on initialise les nouveaux jours qui sont vides

    if (ctx.data && ctx.users && ctx.dateArray) {
      if (templateSubscription) {

        const _data = {};

        ctx.dateArray.forEach((_date) => {
          if (!ctx.data[_date]) {
            console.log("cc", _date, deepClone(ctx.data));
            _data[_date] = {
              day: moment(_date).toDate(),
              createdLocally: true
            };

            // const _reservations = _data[_date].reservation;

            // Object.entries(ctx.users).forEach(([_uid, _user]) => {
            //   const _subscription = _user.subscription;
            //   const _subscriptionMonths = _user.subscriptionMonths;
            //   const _subscriptionHomeDelivery = _user.subscriptionHomeDelivery ?? false;

            //   // si l'utilisateur a des abonnements.s
            //   if (_subscription && _subscriptionMonths) {
            //     // on vérifie s'il est abonné pour ce jour.
            //     if (_subscriptionMonths.includes(_currentMonth)) {

            //       if (templateSubscription[_subscription]) {
            //         Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

            //             _reservations[_uid] = {};

            //             _reservations[_uid][_repas] = [{homeDelivery: _subscriptionHomeDelivery}];
            //         });
            //       }

            //     }

            //   }

            // });
          }
        });

        if (Object.keys(_data).length > 0)
          dispatch({ type: "setProperty", property: "data", value: { ...ctx.data, ..._data } });

      }
    }

  }, [ctx.dateArray, ctx.users]);

  useEffect(() => {
    if (!ctx.modalResaOpen && ctx.modalInfos != null) {
      dispatch({ type: "setProperty", property: "modalInfos", value: null });
    }
  }, [ctx.modalResaOpen]);

  useEffect(() => {
    if (ctx.users) {
      let filteredUsers = {};

      Object.keys(ctx.users).forEach(uid => {
        const user = ctx.users[uid];

        if (ctx.filter.role === "senior" && ["senior", "seniorTotem"].includes(user.role)) {
          filteredUsers[uid] = user;
        } else if (ctx.filter.role === "employee" && ["owner", "employee"].includes(user.role)) {
          filteredUsers[uid] = user;
        } else if (ctx.filter.role === "guest" && user.role === "guest") {
          filteredUsers[uid] = user;
        }
      });

      dispatch({ type: "setProperty", property: "filteredUsers", value: filteredUsers });
    }
  }, [ctx.users, ctx.filter]);


  const updateMenu = (_resa, date, uid, repas) => {
    // date = "2022-07-15" options = {"midi" : 0, "soir" : 2} action = ["update", "remove"]
    //let docId = moment(date).format("YYYY-MM-DD");
    let map = {};

    let _date = date ?? ctx.modalInfos.date ?? null;
    let _uid = uid ?? ctx.modalInfos.uid ?? null;
    let _repas = repas ?? ctx.modalInfos.repas ?? null;

    const guestResa = Object.entries(ctx.data[_date].reservation ?? {})
        .filter(([idResa, resa]) =>
          resa?.[_repas]?.find(_resa => _resa.isGuest)
          && ctx?.users[idResa]?.linkedTo === _uid
        )
        .map(([idResa, resa]) => ({uid: idResa, ...resa[_repas][0]}));

    //let isMapEmpty = true;

    if (_resa === "delete") {

      if (ctx.data[_date].reservation[_uid][_repas][0].createdLocally) {
        //cancel
        map["reservation." + _uid + "." + _repas] = [{ status: "canceled", homeDelivery: false }];
      } else {
        map["reservation." + _uid + "." + _repas] = firestore.FieldValue.delete();
        guestResa.forEach(element => {
          map["reservation." + element.uid + "." + _repas] = firestore.FieldValue.delete();;
        })
      }
    } else {
      if (_resa[0].status === "canceled") delete _resa[0].status;
      if (_resa[0].createdLocally === true) delete _resa[0].createdLocally;

       // Gestion des invités

      // 1: Mise à jour des commandes
      _resa.forEach(element => {
        // Cas resa invité
        if(element.uid) {
          if(!map["reservation." + element.uid + "." + _repas]) map["reservation." + element.uid + "." + _repas] = [];
          map["reservation." + element.uid + "." + _repas].push(element)
        } 
        // Cas resa résident
        else {
          if(!map["reservation." + _uid + "." + _repas]) map["reservation." + _uid + "." + _repas] = [];
          map["reservation." + _uid + "." + _repas].push(element)
        }
      })

      // 2: Suppression de commande invités
      guestResa.forEach(element => {
        if(!map["reservation." + element.uid + "." + _repas]) {
          map["reservation." + element.uid + "." + _repas] = firestore.FieldValue.delete();;
        }
      })

    }

    firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("menu")
      .collection("menu")
      .doc(_date)
      .update(map);
  };

  const getData = async (start, end) => {
    const _rawData = await getMenusByPeriod({ ui, start, end });

    const _data = Object.fromEntries(_rawData.map(_d => [_d.uid, _d]));

    // apply subscriptions : 
    if (ctx.users) {
      // Gestion des abonnements :
      if (templateSubscription) {
        // on rajoute toutes les réservations nécessaires.

        Object.entries(_data).forEach(([_day, _menu]) => {
          const _defaultDay = getDefaultDay(template, _menu);
          if (!_menu.reservation) _menu.reservation = {};
          const _reservations = _menu.reservation;

          // on check tous les utilisateurs ayant un abonnement sur le jour en cours.
          Object.entries(ctx.users).forEach(([_uid, _user]) => {
            const subscription = findSubscriptionForDate(_user?.subscriptions, _day);
            const _subscription = subscription?.subscription;
            const _subscriptionHomeDelivery = subscription?.homeDelivery ?? {};
            const _subscriptionDays = subscription?.days ?? [1, 2, 3, 4, 5, 6, 7];


            // si l'utilisateur a des abonnements.
            if (_subscription && (!_user.isDeleted  || (_user.isDeleted && _user?.deletedAt?.toDate() > moment(_day).toDate()))) {
              // on vérifie s'il est abonné pour ce jour.
              if (_subscriptionDays.includes(moment(_day).isoWeekday())) {


                // on vérifie maintenant s'il a déjà un réservation pour les repas compris dans son abonnement. 
                // Si il a déjà une réservation on ne change rien. S'il n'en a pas on rajoute la réservation de base.


                if (templateSubscription[_subscription]) {
                  Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

                    // si il n'y a pas de resa pour ce repas on la rajoute.
                    if (!_reservations[_uid] || !_reservations[_uid][_repas]) {

                      if (!_reservations[_uid]) _reservations[_uid] = {};

                      const _newResa = {};

                      _repasInfos.forEach((_category) => {
                        _newResa[_category] = _defaultDay[_repas] && _defaultDay[_repas][_category] ? _defaultDay[_repas][_category] : null;
                      });

                      _reservations[_uid][_repas] = [{ ..._newResa, homeDelivery: _subscriptionHomeDelivery[_repas] ?? false, createdLocally: true }];
                    }

                  });
                }

              }

            }

          });
        });
      }
    }

    return _data;
  };

  ctx.getData = getData;
  ctx.updateMenu = updateMenu;

  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};
const useMenuReservation = () => {
  const contexte = useContext(Context);

  if (!contexte) throw new Error("Le contexte n'est pas défini, il n'est probablement pas dans un provider !");

  return contexte;
};
export default useMenuReservation;
export { Provider, Context };