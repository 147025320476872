import React from "react";
import {Col as BootstrapCol} from "reactstrap";

const Col = (props) => {
  return (
    <BootstrapCol {...props}>
      {props.children}
    </BootstrapCol>
  );
};

Col.propTypes = BootstrapCol.propTypes;

export default Col;