import React, {useState} from "react";
import styles from "assets/scss/pages/Gazette/gazettes.module.scss";
import { Plus, Trash } from "react-feather";
import {Button} from "lib/components";
import { useHistory } from "react-router-dom";
import { useFirebaseCollection } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";
import { toast } from "react-toastify";
import { VideoTuto } from "lib/components/YoutubeModal";
import sleep from "@/helpers/asyncWait";
import GazetteDemo from "./Demo";

const firestore = firebase.firestore;

const Gazette = () => {
  const history = useHistory();
  const [ui] = useUI();
  
  const [gazettesRef] = useState(firestore().collection("establishments").doc(ui.user.establishment).collection("gazettes"));
  const {data} = useFirebaseCollection(gazettesRef, []);
  
  const _delete = async (uid) => {
    try {
      if (window.confirm("Voulez-vous vraiment supprimer cette gazette ?")) {
        await gazettesRef.doc(uid).delete();
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };
  
  const _create = async () => {
    let res = await firestore().collection("establishments").doc(ui.user.establishment).collection("gazettes").add({
      title: "Gazette du " + moment().format("DD/MM/YYYY HH:mm"),
      publication: null,
      articles: [],
      pages: [JSON.stringify({uid: 0, slug: "summary", title: "Sommaire"})],
      editionDate:  new Date(),
      createdAt: new Date(),
      updatedAt: new Date(),
      color: "#D26F47",
    });
    history.replace(`/dashboard/gazette/${res.id}/edit`);
  }
  
  if(ui?.establishment?.demo === true)return <GazetteDemo></GazetteDemo>;
  
  return (
    <>
      <div className={styles.container} style={{paddingTop: 40}}>
        <div className={styles.createContainer} onClick={() => _create()}>
          <div></div>
          <Plus size={120} color="white" />
          <Button color="secondaryDark" onClick={() => {}}>Créer une gazette</Button>
        </div>
        {data.sort((a, b) => moment(b.createdAt.toDate()).format("YYYYMMDD") - moment(a.createdAt.toDate()).format("YYYYMMDD")).map(gazette => (
          <div className={styles.gazette} key={gazette.uid}>
            <div className={styles.header}>
              <h2>{gazette.title}</h2>
              <Trash color="red" size={26} style={{cursor: "pointer"}} onClick={() => _delete(gazette.uid)} />
            </div>
            <div style={{width: "100%", maxHeight: "70%", display: "flex", flexDirection: "row"}}>
              <div style={{ height: "100%", flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                {gazette.pages.map((pageStr, indx) => (
                  JSON.parse(pageStr).title ? 
                      indx < 5 ? 
                        JSON.parse(pageStr).slug !== "summary" ? 
                          <div key={indx} style={{display: "flex",width: "90%",maxWidth: 200, flexDirection: "row"}}>
                            <div>{JSON.parse(pageStr).title}</div>
                              <div style={{flex: 1,overflow: "hidden",marginLeft: 5,marginRight: 5}}>
                                {new Array(500).fill(".").join("")}
                              </div>
                              <div style={{color: "#D26F47"}}>{(indx+1)}</div>
                            </div>
                        : null
                      : 
                      indx === 5 ? 
                        <div key={indx} style={{width: "90%", flexDirection: "row"}}>
                          ...
                        </div>
                      : null
                  : null
              ))}

              </div>
              <div style={{flex: 1}}>
                  {gazette.pages.filter(pageStr => JSON.parse(pageStr)["1"] ? JSON.parse(JSON.parse(pageStr)["1"]).image !== undefined : false).length > 0 ?
                    <img style={{width: "100%", maxHeight: "100%", objectFit: "contain"}} src={JSON.parse(JSON.parse(gazette.pages.filter(pageStr => JSON.parse(pageStr)["1"] ? JSON.parse(JSON.parse(pageStr)["1"]).image !== undefined : false)[0])["1"]).image} />
                   : null}
                      
              </div>

              
            </div>
            <div className={styles.footer}>
              <span className={styles.createdAt}>Publié le {moment(gazette.createdAt.toDate()).format("L")}</span>
              <Button color="primary" size="sm" onClick={() => history.push(`/dashboard/gazette/${gazette.uid}/edit`)}>Editer la gazette</Button>
            </div>
          </div>
        ))}
      </div>
      <VideoTuto url="https://youtu.be/rgM06jOvick" />
    </>
  );
};

export default Gazette;