import React from "react";
import PropTypes from "prop-types";
import { Printer, Columns } from "react-feather";
import moment from "moment";

import { useMenu } from "../../../router_context";
import useUI from "hooks/ui.hook";
import { useHistory } from "react-router-dom";

const PrintDayMenu = ({data}) => {
  const [ctx, dispatch] = useMenu();
  const [ui] = useUI();
  const history = useHistory();
  
  const printDay = (day) => {
    history.push("/dashboard/menu/exportDay", {
      day: day,
      data: ctx.state[moment(day).format("YYYY-MM-DD")],
      logo: ui.establishment.profileImage,
    });
  };

  return (
    <div className={data.styles.printDay}>
      <Printer size={20} onClick={() => printDay(data.day)}/>
    </div>
  );
};

PrintDayMenu.propTypes = {
  data: PropTypes.object,
};
export default PrintDayMenu;