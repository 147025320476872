import React, { useMemo, useCallback, useState, useEffect } from "react";
import moment from "moment";
import styles from "../../../../../assets/scss/pages/User/subscriptionComponent.module.scss";
import firebase from "firebase/app";
import 'firebase/firestore';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Select, BetterCheckbox, DatePicker } from "lib/components";
import { isDayEmpty, getDefaultRepas, getDefaultDay, isRepasEmpty, areReservationsEqual, sortRepas } from "../../../Menu/helpers/operations";
import { Button, ModalFooter, Card } from "../../../../../lib/components";
import { usePrompt } from "@/lib/hooks/usePrompt";
import { ChevronDown, ChevronRight, ChevronsDown, Edit, Plus } from "react-feather";
import { findSubscriptionForDate } from "helpers/subscription"
import ReactTooltip from "react-tooltip";

const firestore = firebase.firestore;



const SubscriptionComponent = ({ user, establishment }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isSaved, setIsSaved] = useState(true);
  const [displayFuture, setDisplayFuture] = useState(false);
  const [displayPast, setDisplayPast] = useState(false);
  const [displayCurrent, setDisplayCurrent] = useState(true);

  const [subscriptionName, setSubscriptionName] = useState(null);
  const [subscriptionHomeDelivery, setSubscriptionHomeDelivery] = useState({});
  const [subscriptionDays, setSubscriptionDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [subscriptionStart, setSubscriptionStart] = useState(null);
  const [subscriptionEnd, setSubscriptionEnd] = useState(null);


  usePrompt(
    !isSaved,
    "Les modifications apportées aux abonnements ne sont pas enregistrées. Voulez vous quitter ?",
    true
  );

  // const months = moment.months();

  const template = establishment?.template;
  const templateSubscription = establishment?.templateSubscription2 ?? establishment?.templateSubscription;
  const currentSubscription = subscriptionName ? templateSubscription[subscriptionName] : null;
  const possibleRepas = currentSubscription?.repas ? sortRepas(template, Object.keys(currentSubscription?.repas)) : null;

  useEffect(() => {
    if (user?.subscriptions) {

      setSubscriptions(user?.subscriptions.sort((a, b) => b?.start.localeCompare(a?.start)));

      const _currentSubscription = findSubscriptionForDate(user?.subscriptions, moment());
      setSelectedSubscription(_currentSubscription);
    } else {
      setSubscriptions([]);
      setSelectedSubscription(null);
    }

  }, [user, establishment]);


  useEffect(() => {

    setSubscriptionName(selectedSubscription?.subscription ?? null);
    setSubscriptionDays(selectedSubscription?.days ?? [1, 2, 3, 4, 5, 6, 7]);
    setSubscriptionHomeDelivery(selectedSubscription?.homeDelivery ?? {});
    setSubscriptionStart(selectedSubscription?.start ?? null);
    setSubscriptionEnd(selectedSubscription?.end ?? null);

    return () => {

    };
  }, [selectedSubscription]);


  useEffect(() => {

    // cas où on crée un abonnement
    if (selectedSubscription === null) {

      // si c'est différent des paramètres par défaut
      if (subscriptionName !== null ||
        JSON.stringify(subscriptionDays) !== JSON.stringify([1, 2, 3, 4, 5, 6, 7]) ||
        JSON.stringify(subscriptionHomeDelivery) != JSON.stringify({}) ||
        subscriptionStart !== null ||
        subscriptionEnd !== null) {

        setIsSaved(false);

      } else {
        setIsSaved(true);
      }

      // si on modifie un abonnement existant :
    } else {
      if (subscriptionName !== selectedSubscription?.subscription ||
        JSON.stringify(subscriptionDays) !== JSON.stringify(selectedSubscription?.days) ||
        JSON.stringify(subscriptionHomeDelivery) !== JSON.stringify(selectedSubscription?.homeDelivery) ||
        subscriptionStart !== selectedSubscription?.start ||
        subscriptionEnd !== selectedSubscription?.end) {
        setIsSaved(false);

      } else {
        setIsSaved(true);
      }
    }


  }, [selectedSubscription, subscriptionName, subscriptionDays, subscriptionStart, subscriptionEnd, subscriptionHomeDelivery]);


  /*
    - Sélection automatique de l'abonnement actuel parmi ceux disponibles
    - Ajout de la possibilité de créer un abonnemnent
    - Ajout de la possibilité de sélectionner un abonnement
    - Ajout de la possibilité de modifier un abonnement
    - Ajout du système de sauvegarde, de vérification d'intégrité et de vérification de sauvegarde.
    - Ajout d'une vérification empéchant des abonnements de se chevaucher.
  
  */


  useEffect(() => {
    // On passe au nouveau système de gestion des portages d'abonnements
    if (subscriptionHomeDelivery === true && possibleRepas?.length > 0) {
      const _data = {};

      possibleRepas.forEach(element => {
        _data[element] = true;
      });
      setSubscriptionHomeDelivery(_data);
    }
  }, [subscriptionHomeDelivery, possibleRepas]);


  const onChangeDays = (_day) => {
    if (subscriptionDays.includes(_day)) {
      setSubscriptionDays(subscriptionDays.filter((a) => a !== _day));
    } else {
      setSubscriptionDays([...subscriptionDays, _day].sort((a, b) => a - b));
    }
  };


  const selectSubscription = (_sub) => {
    if (isSaved) {
      setSelectedSubscription(_sub);
    } else {
      if (confirm("Les modifications apportées aux abonnements ne sont pas enregistrées. Voulez vous quitter ?")) {
        setSelectedSubscription(_sub);
      }
    }
  }

  const isOverlapping = (subscription, existingSubscriptions) => {
    // Convertir les dates en objets Date pour pouvoir les comparer
    const newStart = new Date(subscription.start);
    const newEnd = new Date(subscription.end);

    for (let existingSub of existingSubscriptions) {
      const existingStart = new Date(existingSub.start);
      const existingEnd = new Date(existingSub.end);

      // Vérifier si les dates se chevauchent
      if (
        (newStart <= existingEnd && newEnd >= existingStart)
      ) {
        return true; // Il y a un chevauchement
      }
    }

    return false; // Pas de chevauchement
  }

  const save = () => {

    if (new Date(subscriptionStart) > new Date(subscriptionEnd)) {
      toast.error("La date de fin doit être après celle du début");
      return;
    }

    // check si il n'y a pas d'overlap avec les abonnements existants.
    const newSubscription = {
      subscription: subscriptionName,
      homeDelivery: subscriptionHomeDelivery,
      days: subscriptionDays,
      start: subscriptionStart,
      end: subscriptionEnd
    }


    // si c'est la création d'un nouvel abonnement
    if (selectedSubscription === null) {
      if (!isOverlapping(newSubscription, subscriptions)) {
        const _newSub = [...subscriptions, newSubscription];

        firestore().collection("users").doc(user.uid).update({
          subscriptions: _newSub
        });

        
        setSubscriptionName(selectedSubscription?.subscription ?? null);
        setSubscriptionDays(selectedSubscription?.days ?? [1, 2, 3, 4, 5, 6, 7]);
        setSubscriptionHomeDelivery(selectedSubscription?.homeDelivery ?? {});
        setSubscriptionStart(selectedSubscription?.start ?? null);
        setSubscriptionEnd(selectedSubscription?.end ?? null);

        toast.success("L'abonnement a été sauvegardé");
      } else {
        toast.error("2 abonnements ne peuvent pas être sur une même plage de dates");
      }
    } else {
      if (!isOverlapping(newSubscription, subscriptions.filter(_s => _s != selectedSubscription))) {
        const _newSub = [...subscriptions.filter(_s => _s != selectedSubscription), newSubscription];

        firestore().collection("users").doc(user.uid).update({
          subscriptions: _newSub
        });

        toast.success("L'abonnement a été sauvegardé");

      } else {
        toast.error("2 abonnements ne peuvent pas être sur une même plage de dates");
      }
    }


  };

  const remove = () => {
    if (confirm("Attention la suppression d'un abonnement peut affecter les données des exports de menus dans le passé ! Voulez vous vraiment supprimer l'abonnement ?")) {

      const _updatedSubscriptions = subscriptions.filter(_s => _s != selectedSubscription);

      firestore().collection("users").doc(user.uid).update({
        subscriptions: _updatedSubscriptions
      });

    }
  };

  const futur = useMemo(() => {
    return subscriptions.filter((_s => moment(_s.start).toDate() > new Date()))
  }, [subscriptions]);

  const current = useMemo(() => {
    return findSubscriptionForDate(subscriptions, moment())
  }, [subscriptions]);

  const past = useMemo(() => {
    return subscriptions.filter((_s => moment(_s.end).toDate() < new Date()))
  }, [subscriptions]);

  const creating = useMemo(() => {
    return { subscription: subscriptionName ?? "Non défini", start: subscriptionStart, end: subscriptionEnd }
  }, [subscriptionName, subscriptionStart, subscriptionEnd]);

  return (
    <Card>
      <div className={styles.subscriptionContainer}>
        <div className={styles.subscriptionListContainer}>
          {/* <h3>Historique : </h3>s */}
          <Button onClick={() => selectSubscription(null)} >Créer un abonnement &nbsp;&nbsp; <Plus></Plus></Button>

          <div className={styles.subscriptionList}>

            {
              selectedSubscription === null &&
              <CardItem subscription={creating} callback={() => null} selectedSubscription={creating} />
            }

            {current && <div className={styles.listHeader} onClick={() => setDisplayCurrent(!displayCurrent)} style={{ backgroundColor: "#49064d" }}>
              <div>Abonnement actuel</div>
              {displayCurrent ? <ChevronRight color="white" /> : <ChevronDown color="white" />}
            </div>}

            {displayCurrent && current &&
              <CardItem subscription={current} callback={selectSubscription} selectedSubscription={selectedSubscription} />
            }
            {futur && futur.length > 0 && <div className={styles.listHeader} onClick={() => setDisplayFuture(!displayFuture)} style={{ backgroundColor: "#008da7" }}>
              <div>Abonnements à venir </div>
              {displayFuture ? <ChevronRight color="white" /> : <ChevronDown color="white" />}
            </div>}

            {displayFuture && futur && futur.length > 0 && futur.map(_sub => (
              <CardItem subscription={_sub} callback={selectSubscription} selectedSubscription={selectedSubscription} />
            ))}



            {past && past.length > 0 && <div className={styles.listHeader} onClick={() => setDisplayPast(!displayPast)} style={{ backgroundColor: "#3fad90" }}>
              <div>Abonnements précédents </div>
              {displayPast ? <ChevronRight color="white" /> : <ChevronDown color="white" />}
            </div>}

            {displayPast && past && past.length > 0 && past.map(_sub => (
              <CardItem subscription={_sub} callback={selectSubscription} selectedSubscription={selectedSubscription} />
            ))}

          </div>
        </div>

        <div className={styles.subscriptionDetails}>

          <h3>Paramétrage de l'abonnement :</h3>

          <div className={styles.topPart}>

            <div className={styles.formInput}>
              <div className={styles.formLabel}>
                Type :
              </div>
              <div className={styles.inputDiv}>
                <Select
                  placeholder="Aucun abonnement"
                  options={Object.keys(templateSubscription).map(sub => ({ value: sub, label: sub }))}
                  value={subscriptionName ? { value: subscriptionName, label: subscriptionName } : null}
                  onChange={e => setSubscriptionName(e?.value ?? null)/*handleChange("subscription", e?.value ?? null)*/}
                  clearable
                />
              </div>
            </div>

            <div className={styles.formInput}>
              <div className={styles.formLabel} data-tip data-for={"start"}>
                Début :
              </div>
              <ReactTooltip id={"start"} place="bottom" effect="float" className={styles.tooltipBackground}>
                Début de l'abonnement (inclus)
              </ReactTooltip>
              <div className={styles.inputDiv}>
                <DatePicker value={subscriptionStart} onChange={(_date) => setSubscriptionStart(moment(_date).format("YYYY-MM-DD"))}/>
              </div>

            </div>

            <div className={styles.formInput}>
              <div className={styles.formLabel} data-tip data-for={"end"}>
                Fin :
              </div>
              <ReactTooltip id={"end"} place="bottom" effect="float" className={styles.tooltipBackground}>
                Fin de l'abonnement (inclus)
              </ReactTooltip>
              <div className={styles.inputDiv}>
                <DatePicker value={subscriptionEnd} onChange={(_date) => setSubscriptionEnd(moment(_date).format("YYYY-MM-DD"))} />
              </div>
            </div>

            <div className={styles.separator}></div>


            <div className={styles.formInput} style={{ width: "80%" }}>
              <div className={styles.formLabel} data-tip data-for={"homeDelivery"}>
                Portage :
              </div>
              <ReactTooltip id={"homeDelivery"} place="bottom" effect="float" className={styles.tooltipBackground}>
                Portage de repas (automatique)
              </ReactTooltip>
              <div className={styles.checkboxContainer}>
                {possibleRepas ? possibleRepas.map(_repas => (
                  <div key={_repas} className={styles.checkboxInput}>
                    <BetterCheckbox
                      checked={subscriptionHomeDelivery[_repas] ?? false}
                      onChange={() => setSubscriptionHomeDelivery({ ...subscriptionHomeDelivery, [_repas]: !subscriptionHomeDelivery[_repas] ?? true })}
                      className={styles.checkbox}
                    />
                    <div>
                      {_repas}
                    </div>
                  </div>

                )) :
                  <div className={styles.checkboxContainer}> Sélectionnez un type d'abonnement pour pouvoir accéder à ce paramètre.</div>
                }
              </div>
            </div>


            <div className={styles.formInput} style={{ width: "80%" }}>
              <div className={styles.formLabel} data-tip data-for={"days"}>
                Jours :
              </div>
              <ReactTooltip id={"days"} place="bottom" effect="float" className={styles.tooltipBackground}>
                Jours de la semaine qui sont inclus dans l'abonnement
              </ReactTooltip>
              <div className={styles.checkboxContainer}>

                {[1, 2, 3, 4, 5, 6, 7].map((_day) => (
                  <div className={styles.checkboxInput} key={_day}>

                    <BetterCheckbox
                      checked={subscriptionDays && subscriptionDays.includes(_day)}
                      onChange={() => onChangeDays(_day)}
                      className={styles.checkbox}
                    />
                    <div>
                      {moment().isoWeekday(_day).format("dddd")}
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div>




          <div className={styles.buttonBar}>
            <Button onClick={save} disabled={!subscriptionName || !subscriptionStart || !subscriptionEnd}>Enregistrer</Button>
            {selectedSubscription !== null &&
              <Button color="danger" onClick={remove} disabled={!selectedSubscription}>Supprimer l'abonnement</Button>
            }
          </div>


        </div>
      </div >
    </Card >
  );
};


const CardItem = ({ subscription, callback, selectedSubscription }) => {

  return (
    <div key={subscription.start + subscription.end} className={`${styles.subscriptionItem} ${subscription === selectedSubscription ? styles.selected : ""}`} onClick={() => callback(subscription)}>
      <div className={styles.subscriptionItemContent}>
        <div className={styles.subscriptionItemTitle}>
          {subscription.subscription ?? "Pas d'abonnement défini"}
        </div>
        <div className={styles.subscriptionItemDate}>
          {`${moment(subscription.start).format("DD/MM/YYYY")} ➜ ${moment(subscription.end).format("DD/MM/YYYY")}`}
        </div>
      </div>
      <div className={styles.subscriptionItemButton}>
        <Edit></Edit>
      </div>

    </div>)
};

SubscriptionComponent.propTypes = {
  user: PropTypes.object.isRequired,
  establishment: PropTypes.object.isRequired,
};


export default SubscriptionComponent;
