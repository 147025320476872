import React, { useMemo, useCallback, useState } from "react";
import useCtx, { Provider } from "./ctx";
import { Card, Input, MultiSelect, FormGroup, Tab, Button, Modal, ModalHeader, ModalBody, ModalFooter, Select, ColorPicker } from "lib/components";
import { useHistory, useParams } from "react-router-dom";
import { Plus, Trash, Edit } from "react-feather";
import deepClone from "helpers/deepClone";
import styles from "../../../assets/scss/pages/SuperAdmin/Groupement/infos.module.scss";

import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "@/hooks/ui.hook";
const firestore = firebase.firestore;

const Infos = () => {
  const [ui, dispatchUi] = useUI();
  const { id } = useParams();
  const history = useHistory();

  const [modalButton, setModalButton] = useState("Créer");

  const [isOpen, setIsOpen] = useState(false);
  const [regionName, setRegionName] = useState("");
  const [regionEstablishments, setRegionEstablishments] = useState([]);

  const [isOpenPresta, setIsOpenPresta] = useState(false);
  const [prestaName, setPrestaName] = useState("");
  const [currentPrestaName, setCurrentPrestaName] = useState(null);
  const [prestaEstablishments, setPrestaEstablishments] = useState([]);

  const { state, dispatch, save } = useCtx();
  const selectedEstablishmentsOptions = useMemo(() => state.establishments.filter(e => state?.groupement?.establishments?.includes(e.uid)).map(e => ({ label: e.name, value: e.uid })), [state.groupement]);
  const establishmentsOptions = useMemo(() => state.establishments.map(e => ({ label: e.name, value: e.uid })), [state.establishments]);
  const _handleChange = useCallback((key, value) => dispatch({ type: "setGroupement", groupement: { ...state.groupement, [key]: value } }), [state.groupement]);

  const openModal = () => {
    setRegionName("");
    setRegionEstablishments([]);
    setModalButton("Créer");
    setIsOpen(true);
  };

  const openModalPresta = () => {
    setPrestaName("");
    setCurrentPrestaName(null);
    setPrestaEstablishments([]);
    setModalButton("Créer");
    setIsOpenPresta(true);
  };

  const _selectEstablishments = (key) => {
    setRegionEstablishments(key);
  };

  const _selectEstablishmentsPresta = (key) => {
    setPrestaEstablishments(key);
  };

  const _create = () => {
    let _regions = deepClone(state.regions);

    // Ici on gère le fait qu'un établissement ne peut être que dans une seule région
    Object.entries(_regions).forEach(([_regionName, _etabs]) => {
      let newEtabs = [];
      _etabs.forEach(_id => {
        if (!regionEstablishments.includes(_id)) {
          newEtabs.push(_id);
        }
      });
      _regions[_regionName] = newEtabs;
    });
    _regions[regionName] = regionEstablishments;

    dispatch({ type: "setRegions", regions: _regions });
    setIsOpen(false);
  };

  const _createPresta = () => {
    let _presta = deepClone(state.presta);

    if(currentPrestaName) delete _presta[currentPrestaName];

    // Ici on gère le fait qu'un établissement ne peut être que dans une seule région
    Object.entries(_presta).forEach(([_prestaName, _etabs]) => {
      let newEtabs = [];
      _etabs.forEach(_id => {
        if (!prestaEstablishments.includes(_id)) {
          newEtabs.push(_id);
        }
      });
      _presta[_prestaName] = newEtabs;
    });

    _presta[prestaName] = prestaEstablishments;

    dispatch({ type: "setPresta", presta: _presta });
    setCurrentPrestaName(null);
    setIsOpenPresta(false);
  };

  const _edit = (_regionName) => {
    setRegionName(_regionName);
    setRegionEstablishments(deepClone(state.regions[_regionName]));
    setModalButton("Modifier");
    setIsOpen(true);
  };

  const _editPresta = (_prestaName) => {
    setPrestaName(_prestaName);
    setCurrentPrestaName(_prestaName);
    setPrestaEstablishments(deepClone(state.presta[_prestaName]));
    setModalButton("Modifier");
    setIsOpenPresta(true);
  };


  const _delete = (_regionName) => {
    if (confirm("Êtes vous sûr de supprimer cette région ?")) {
      let _regions = deepClone(state.regions);

      delete _regions[_regionName];
      dispatch({ type: "setRegions", regions: _regions });
    }
  };

  const _deletePresta = (_prestaName) => {
    if (confirm("Êtes vous sûr de supprimer ce prestataire ?")) {
      let _presta = deepClone(state.presta);

      delete _presta[_prestaName];
      dispatch({ type: "setPresta", presta: _presta });
    }
  };


  const _takeControl = async () => {
    try {
      let _user = {...ui.user}; 
      let groupement = null;
      // ajout du groupement
      if(id){
        let groupementDoc = await firestore().collection("groupements").doc(id).get();
        groupement = {uid: groupementDoc.id, ...groupementDoc.data()};
      }

      _user.role = "groupementAdmin";
      _user.groupement = id;
      _user.superadmin = true;

      dispatchUi({type: "takeControl", user: _user, groupement: groupement});
    } catch (e) {
      console.error(e);
    }
  };


  const specificOptions = [
    { value: "ovelia", label: "Ovelia" },
    { value: "heurus", label: "Heurus" },
    { value: "jasmins", label: "Jasmins" },
    { value: "happysenior", label: "Happy Senior" },
    { value: "villagebleu", label: "Vill'âge Bleu" },
    { value: "ekipage", label: "Ekip'Age" },
    { value: "arpavie", label: "Arpavie" },
    { value: "apiprovence", label: "API Provence" },
    { value: "acppa", label: "ACPPA" },
    { value: null, label: "Aucun" }
  ];

  if (state.isLoading) return <></>;
  return (
    <div style={{ width: "100%" }}>
      <Tab activeTab={0} onClick={console.log} render={[
        {
          title: "Informations", content:
            <>
              <Card style={{ flex: 1 }}>
                <FormGroup>
                  <Input
                    label="Nom"
                    value={state?.groupement?.name}
                    onChange={e => _handleChange("name", e.target.value)}
                    placeholder="Nom..."
                  />
                </FormGroup>
                <FormGroup>
                  <MultiSelect
                    label="Etablissements"
                    options={establishmentsOptions}
                    onChange={e => _handleChange("establishments", e.map(i => i.value))}
                    value={state?.groupement?.establishments?.map(i => establishmentsOptions.find(j => i === j.value) || null).filter(i => i) || []}
                  />
                </FormGroup>
                <FormGroup>
                  <span>Régions :</span><br />
                  <div className={styles.regionsContainer}>
                    <Button color="primary" onClick={openModal} className={styles.buttonAdd}><Plus color="white" /></Button>
                    {Object.entries(state?.regions).map(([_regionName, _etabs], indx) =>
                      <div key={indx} className={styles.region}>
                        <div className={styles.regionHeader}>
                          <div className={styles.extremity}></div>
                          <div className={styles.title}>{_regionName}</div>
                          <div className={styles.extremity}>
                            <Edit size={20} onClick={() => _edit(_regionName)} color="#300438" style={{ cursor: "pointer" }} />
                            <Trash size={20} style={{ color: "red", cursor: "pointer" }} onClick={() => _delete(_regionName)} />
                          </div>


                        </div>
                        <div className={styles.regionList}>
                          {_etabs.map(_e => (
                            <span key={_e}> - {state.establishments.find(_etabObject => _etabObject.uid === _e).name}</span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </FormGroup>
                {state.groupement.specific === "ovelia" ? <FormGroup>
                  <span>Prestataires :</span><br />
                  <div className={styles.regionsContainer}>
                    <Button color="primary" onClick={openModalPresta} className={styles.buttonAdd}><Plus color="white" /></Button>
                    {Object.entries(state?.presta).map(([_prestaName, _etabs], indx) =>
                      <div key={indx} className={styles.region}>
                        <div className={styles.regionHeader}>
                          <div className={styles.extremity}></div>
                          <div className={styles.title}>{_prestaName}</div>
                          <div className={styles.extremity}>
                            <Edit size={20} onClick={() => _editPresta(_prestaName)} color="#300438" style={{ cursor: "pointer" }} />
                            <Trash size={20} style={{ color: "red", cursor: "pointer" }} onClick={() => _deletePresta(_prestaName)} />
                          </div>
                        </div>

                        <div className={styles.regionList}>
                          {_etabs.map(_e => (
                            <span key={_e}> - {state.establishments.find(_etabObject => _etabObject.uid === _e).name}</span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </FormGroup> : null}
                <FormGroup>
                  <Select
                    options={specificOptions}
                    label="Spécifique"
                    value={specificOptions.filter(o => o.value === state?.groupement?.specific)[0]}
                    onChange={e => _handleChange("specific", e.value)}
                    placeholder="Spécifique"
                    clearable
                  />
                </FormGroup>
                <FormGroup>
                  <ColorPicker
                    value={ state?.groupement?.color}
                    onChange={e => _handleChange("color", e)}
                    label="Couleur du groupement"
                  ></ColorPicker>
                 
                </FormGroup>
              </Card>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, gap: 10 }}>
                <Button onClick={() => history.push("/dashboard/groupements")} color="secondary">Retour</Button>
                <Button onClick={save}>Enregistrer</Button>
                <Button color="danger" onClick={_takeControl}>Prendre le contrôle</Button>
              </div>
            </>
        },
        { title: "Etablissements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/establishments`) },
        // {title: "Abonnements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/abonnements`)},
        { title: "Statistiques", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/statistics`) },
        { title: "Menu", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/menu`) },
        { title: "Abonnement", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription`) },
        { title: "Abonnement V2", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription2`) },
      ]} />

      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <ModalHeader>Créer une région</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input type="text" label="Nom de la région" onChange={e => setRegionName(e.target.value)} value={regionName} placeholder="Nom de la région..." />
            <MultiSelect
              label="Etablissements"
              options={selectedEstablishmentsOptions}
              onChange={e => _selectEstablishments(e.map(i => i.value))}
              value={regionEstablishments.map(i => selectedEstablishmentsOptions.find(j => i === j.value) || null).filter(i => i) || []}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpen(false)}>Annuler</Button>
          <Button color="primary" onClick={_create}>{modalButton}</Button>
        </ModalFooter>
      </Modal>

      {state.groupement.specific === "ovelia" ?
        <Modal isOpen={isOpenPresta} toggle={() => setIsOpenPresta(false)} size="lg">
          <ModalHeader>Créer un prestataire</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input type="text" label="Nom du prestataire" onChange={e => setPrestaName(e.target.value)} value={prestaName} placeholder="Nom du prestataire ..." />
              <MultiSelect
                label="Etablissements"
                options={selectedEstablishmentsOptions}
                onChange={e => _selectEstablishmentsPresta(e.map(i => i.value))}
                value={prestaEstablishments.map(i => selectedEstablishmentsOptions.find(j => i === j.value) || null).filter(i => i) || []}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsOpenPresta(false)}>Annuler</Button>
            <Button color="primary" onClick={_createPresta}>{modalButton}</Button>
          </ModalFooter>
        </Modal> : null}
    </div>
  );
};

const GroupementHOC = () => {
  const { id } = useParams();

  return (
    <Provider id={id}>
      <Infos />
    </Provider>
  );
};

export default GroupementHOC;