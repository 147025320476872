import React, {useState, useEffect} from "react";
import { Input, Spinner } from "../../../lib/components";
import { Trash, Play, Save, PlusCircle, Settings } from "react-feather";
import styles from "../../../assets/scss/pages/CutlureQuizz/new.module.scss";
import AddModal from "./addModal";
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";
import Sidebar from "./Sidebar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//import * as QuestionsTypes from "./QuestionTypes";
import QuestionsTypes from "./QuestionTypes/system";
import useUI from "hooks/ui.hook";

const firestore = firebase.firestore;

const Edit = () => {
  const [ui] = useUI();
  const params = useParams();
  const history = useHistory();

  const [mode, setMode] = useState(null);

  const [quizzParams, setQuizzParams] = useState({});
  const [questions, setQuestions] = useState([]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {//initialize
    (async () => {
      if (mode === null) {
        setLoading(true);
        let uid = params.uid;
        
        if (!uid) {
          history.push("/dashboard/quizz");
          setMode(null);
        } else if (uid === "new") {
          setMode("new");
          setQuestions([]);
          setQuizzParams({title: "", establishment: "0"});
          setLoading(false);
        } else {
          setMode("edit");
          try {
            let _quizz = await firestore().collection("quizz").doc(uid).get();
            if (!_quizz.exists) {
              toast.error("Le quizz sélectionné n'existe plus");
              history.push("/dashboard/quizz");
              return;
            }
            let _questionsRes = await firestore().collection("quizz").doc(uid).collection("questions").get();
            let _questions = [];
            _questionsRes.forEach(doc => _questions.push({uid: doc.id, ...doc.data()}));

            setQuizzParams({uid: _quizz.id, ..._quizz.data()});
            setQuestions(_questions);
            setLoading(false);
          } catch (e) {
            console.error(e);
            toast.error(e.message);
            history.push("/dashboard/quizz");
          }
        }
      }
    })();
  }, [params]);

  const save = async () => {
    setLoading(true);
    try {
      if (mode === "new") {
        let _quizz = await firestore().collection("quizz").add({
          ...quizzParams
        });
        await Promise.all(questions.map(async (data, id) => {
          let d = {...data};
          delete d.uid;
          await firestore().collection("quizz").doc(_quizz.id).collection("questions").doc(`${id + 1}`).set(d);
        }));
      } else {
        let a = {...quizzParams};
        delete a.uid;
        await firestore().collection("quizz").doc(params.uid).set(a);
        //reset questions collection
        let _questions = [];
        let res = await firestore().collection("quizz").doc(params.uid).collection("questions").get();
        res.forEach(doc => _questions.push({uid: doc.id, ...doc.data()}));
        await Promise.all(_questions.map(async (data) => {
          await firestore().collection("quizz").doc(params.uid).collection("questions").doc(data.uid).delete();
        }));
        await Promise.all(questions.map(async (data, id) => {
          let _b = {...data};
          delete _b.uid;
          await firestore().collection("quizz").doc(params.uid).collection("questions").doc(`${id + 1}`).set(_b);
        }));
      }
      history.push("/dashboard/quizz");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };
  const play = async () => {
    toast.warning("coming soon");
  };
  const openSettings = async () => {
    toast.warning("coming soon");
  };
  const deleteQuizz = async () => {
    try {
      setLoading(true);
      await firestore().collection("quizz").doc(params.uid).delete();
      history.push("/dashboard/quizz");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };
  const onDeleteQuestion = (id) => {
    setCurrentQuestion(null);
    setSidebarOpen(false);
    setQuestions(questions.filter(q => q.uid !== id).map((i, id) => ({...i, uid: id + 1})));
  };
  const onChangeQuestion = (e) => {
    let _questions = [...questions];
    for (let i = 0; i < _questions.length; i++) {
      if (_questions[i].uid === currentQuestion.uid) {
        _questions[i] = {..._questions[i], ...e};
        setCurrentQuestion(_questions[i]);
      }
    }
    setQuestions(_questions);
  };
  const drop = (e) => {
    if (!e.source || !e.destination) return;
    let _questions = [...questions];
    
    _questions.splice(e.source.index, 1);
    _questions.splice(e.destination.index, 0, questions[e.source.index]);
    for (let i = 0; i < _questions.length; i++) _questions[i].uid = `${i + 1}`;

    setQuestions(_questions);
    setCurrentQuestion(_questions[e.destination.index]);
  };

  if (mode === null || loading) return <Spinner />;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.left}>
            {mode !== "new" && ui.user.role !== "superadmin" && <Play color="#6a0d7c" size={32} onClick={play} />}
            <Settings color="#6a0d7c" size={32} onClick={openSettings} />
            <Save color="#6a0d7c" size={32}     onClick={save} />
            {mode !== "new" && <Trash color="#D95550" size={32} onClick={deleteQuizz} />}
            <Input value={quizzParams.title} onChange={e => setQuizzParams({...quizzParams, title: e.target.value})} placeholder="Titre" />
          </div>
          <div className={styles.right}>
          </div>
        </div>
        <div className={styles.body}>


          {currentQuestion === null ? <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <span style={{fontSize: 32}}>Cliquez sur le bouton + en bas a gauche pour ajouter une nouvelle question</span>
          </div> : 
            <>
              {QuestionsTypes.map(questionType => (<>
                {questionType.slug === currentQuestion.type && 
                  <questionType.EditorDisplay question={currentQuestion} onDelete={onDeleteQuestion} />}
              </>))}
            </>
          }


        </div>
        <div className={`${styles.toolbar} ${sidebarOpen ? styles.sidebarOpen : ""}`}>
          <div className={`${styles.card} ${styles.add}`} onClick={() => setAddModalOpen(true)}>
            <PlusCircle color="#8b11a2" size={44} />
          </div>
          <DragDropContext onDragEnd={drop}>
            <Droppable droppableId="droppable" direction="horizontal" >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{display: "flex", gap: 10}}>
                  {questions.map((q, index) => (
                    <Draggable key={q.uid} draggableId={q.uid} index={index} >
                      {(provided) => (
                        <div className={`${styles.card} ${currentQuestion && q.uid === currentQuestion.uid ? styles.active : ""}`}
                          onClick={() => {setCurrentQuestion(q); setSidebarOpen(true);}}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span style={{textAlign: "center", fontWeight: 500}}>
                            {q.title ? q.title : "Question sans titre"}
                          </span>
                          {/*<Settings style={{cursor: "pointer"}} onClick={() => {setSidebarOpen(true); setCurrentQuestion(q)}}/>*/}
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Sidebar isOpen={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          onDelete={() => onDeleteQuestion(currentQuestion.uid)}
          question={currentQuestion}
          onChange={onChangeQuestion} />
      </div>
      <AddModal isOpen={addModalOpen}
        toggle={() => setAddModalOpen(false)}
        context={[questions, setQuestions]} />
    </>
  );
};

export default Edit;