import {
  Button,
  Card,
  Col,
  Container,
  DatePicker,
  FormGroup,
  Input,
  MultiSelect,
  Row,
  Select,
} from "../../lib/components";
import React, { useEffect, useState } from "react";

import { VideoTuto } from "lib/components/YoutubeModal";
import Topics from "./Notification/_Topics";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";
import { toast } from "react-toastify";
import useUI from "../../hooks/ui.hook";
import Banner from "./HomeWidgets/Banner";
import useFeature from "hooks/useFeature";
import useMicroService from "helpers/microService";

const firestore = firebase.firestore;
const functions = firebase.functions;

const Notifications = () => {
  const [ui] = useUI();

  return (
    <Container>
      {useFeature("banner") ? 
        <Row>
          <Col>
            <Card style={{position: "relative"}}>
              <div style={{display: "flex"}}>
                <h4>
                  Bandeau Dynamique
                </h4>
                <div>
                  <VideoTuto url="https://youtu.be/1sKFXq0NE5g" />
                </div>
              </div>
              <div style={{display: "flex", alignItems: "stretch", justifyContent: "center", gap: 10, width: "100%", flexWrap: "wrap", marginBottom: 30}}>
                <Banner/>
              </div>
            </Card>
          </Col>
        </Row>
      : null}
    </Container>
  );
};

export default Notifications;
