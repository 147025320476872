import React, { createContext, useReducer, useEffect, useState } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getGroupementById, updateGroupementById, updateAllGroupementEstablishmentsById } from "services/ressources/groupement";

const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  step: 0,
  repas: null,
  template: {},
  labels: {},
  establishments: [],
};

function Reducer(state, action) {
  switch (action.type) {
    case "setValue": return ({ ...state, [action.key]: action.value });
    case "setState": return ({ ...state, ...action.state });
    default: return ({ ...state });
  }
}

const Provider = ({ children }) => {
  const { id } = useParams();
  const [ctx, reducer] = useReducer(Reducer, Default);

  useEffect(() => {
    (async () => {
      const _data = await getGroupementById({ id });
      reducer({ type: "setValue", key: "template", value: _data?.template ?? {} });
      reducer({ type: "setValue", key: "labels", value: _data?.labels ?? {} });
      reducer({ type: "setValue", key: "establishments", value: _data?.establishments ?? [] });
    })();
  }, [id]);


  const save = async () => {
    try {
      //compute top level categories
      let _template = { ...ctx.template };
      Object.keys(_template).forEach((repas) => {
        const apparitionOrder = {};

        Object.keys(_template[repas].menus).forEach((menu) => {
          _template[repas].menus[menu].categories.forEach((_categ, indx) => {
            if(!apparitionOrder[_categ] || apparitionOrder[_categ] < indx)apparitionOrder[_categ] = indx;
          });
        });

        let _categories = [];

        Object.entries(apparitionOrder).sort((a, b) => a[1] - b[1]).forEach(([_categ, _maxIndex]) => {
          _categories.push(_categ);
        });

        _template[repas].categories = _categories;
      });

      await updateGroupementById({
        id, data: {
          template: _template,
          labels: ctx.labels
        }
      });
      toast.success("Modifications enregistrées");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const pushToAllEtab = async () => {

    await save();

    try {
      //compute top level categories
      let _template = { ...ctx.template };
      Object.keys(_template).forEach((repas) => {
        let _categories = [];
        Object.keys(_template[repas].menus).forEach((menu) => {
          _categories = [..._categories, ..._template[repas].menus[menu].categories];
        });
        _template[repas].categories = [...new Set(_categories)];
      });

      await updateAllGroupementEstablishmentsById({
        idList: ctx.establishments, data: {
          template: _template,
          labels: ctx.labels
        }
      });
      toast.success("Etablissements mis à jour");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (
    <Context.Provider value={[
      {
        ...ctx,
        save,
        pushToAllEtab
      },
      reducer
    ]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  establishmentId: PropTypes.string.isRequired
};

const useContext = () => React.useContext(Context);

export default useContext;
export { Provider };