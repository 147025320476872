import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";

const firestore = firebase.firestore;

const Context = createContext();


const Default = {
    availableServices: [], // la liste des types de prestataires possibles
    prestataires: [], // La liste des prestataires et leurs types de prestations
    planning: [], // la liste des prestations
};

function Reducer(state, action) {
    switch (action.type) {
        // case "setMenu": return ({ ...state, menu: action.menu });
        // case "nextWeek": return ({ ...state, week: state.week.map(day => moment(day).add(7, "day").toDate()) });
        case "setProperty": return ({ ...state, [action.property]: action.value });
        // case "setState": return { ...action.state };
        default: return { ...state };
    }
}

const Provider = ({ children }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useReducer(Reducer, Default);
    const [serviceList, setServiceList] = useState([]);


    useEffect(() => {

        // On récupère la liste des type de prestataires pris en compte dans major.
        firestore()
            .collection("services")
            .get()
            .then(res => {
                const _serviceList = [];
                res.forEach(doc => {
                    _serviceList.push(doc.data());
                });
                setServiceList(_serviceList);
            })
            .catch(error => {
                console.log("error", error);
            });


        // On récupère la liste des prestataires
        firestore()
            .collection("establishments")
            .doc(ui.user.establishment)
            .collection("blocks")
            .doc("services")
            .collection("prestataires")
            .onSnapshot(s => {
                const _prestataires = [];
                s.forEach(doc => {
                    _prestataires.push({uid: doc.id, ...doc.data()});
                });
                dispatch({ type: "setProperty", property: "prestataires", value: _prestataires });
            });


        // On récupère le planning des prestations
        firestore()
            .collection("establishments")
            .doc(ui.user.establishment)
            .collection("blocks")
            .doc("services")
            .collection("planning")
            .onSnapshot(s => {
                const _planning = [];
                s.forEach(doc => {
                    const _data = doc.data();
                    const _reservations = [];
                    if(_data.reservations){
                        Object.entries(_data.reservations).forEach(([_resaId, _resa]) => {
                            _reservations.push({..._resa, uid:_resaId,  start: _resa.start.toDate(), end: _resa.end.toDate()});
                        });
                    }
                    _planning.push({..._data, uid: doc.id, start: _data.start.toDate(), end: _data.end.toDate(), reservations: _reservations.sort((a,b)=> a.start < b.start)});

                });
                dispatch({ type: "setProperty", property: "planning", value: _planning });
            });

            firestore()
            .collection("establishments")
            .doc(ui.user.establishment)
            .collection("blocks")
            .doc("services")
            .collection("categories")
            .onSnapshot(s => {
                const _categories = [];
                s.forEach(doc => {
                    // console.log(dac.id);
                    _categories.push({uid: doc.id, ...doc.data()});
                });
                dispatch({ type: "setProperty", property: "availableServices", value: _categories });
            });
    }, []);

    // useEffect(() => {
   
    //         // on récupère les services disponibles dans cet établissement en particulier.
    //         // const _availableServices = [];
    //         // les données d'établissement override celles de groupement.
    //         // if (ui?.establishment?.availableServices && ui?.establishment?.availableServices.length > 0) {
    //         //     ui.establishment.availableServices.forEach(element => {
    //         //         _availableServices.push(serviceList.find(s => s.name === element));
    //         //     });
    //         // }
    //         // else if (ui?.groupement?.availableServices && ui?.groupement?.availableServices.length > 0) {
    //         //     ui.groupement.availableServices.forEach(element => {
    //         //         _availableServices.push(serviceList.find(s => s.name === element));
    //         //     });
    //         // }
           
    //         // dispatch({ type: "setProperty", property: "availableServices", value: _availableServices });

    //     }
    // }, []);



    return (
        <Context.Provider value={[ctx, dispatch]}>
            {children}
        </Context.Provider>
    );
};

Provider.propTypes = {
    children: PropTypes.node,
};


const useService = () => useContext(Context);
export default useService;
export { Provider, Context };