import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getQuizzById = async ({id}, err) => {
  try {
    const res = await firestore()
                        .collection("quizz")
                        .doc(id)
                        .get();
    if (!res.exists) return 0;
    return {uid: res.id, ...res.data()};
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const getQuestionsById = async ({id},err)=>{
  try{
    let questions = await firestore()
                              .collection("quizz")
                              .doc(id)
                              .collection("questions")
                              .get();
    questions = questions.docs.map(q => ({uid: q.id, ...q.data()}));
    return questions;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

//--------------------------  -------------------------------//

export const getQuizzesByEstablishment = async ({ui}, err) => {
  try {
    const res = await firestore()
                      .collection("quizz")
                      .where("establishment", "==", ui.establishment.uid)
                      .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

export const getQuizzesByRegion = async ({ui}, err) => {
  try {
    const res = await firestore()
                      .collection("quizz")
                      .where("region", "==", `${ui.groupement.uid}-${ui.user.region}`)
                      .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

export const getQuizzesByGroupement = async ({ui}, err) => {
  try {
    const res = await firestore()
                      .collection("quizz")
                      .where("groupement", "==", `${ui.groupement.uid}`)
                      .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

//--------------------------  -------------------------------//

export const getQuizzesShareWithEstablishment = async ({ui}, err) => {
  try {
    const res = await firestore()
                      .collection("quizz")
                      .where("shareEtab", "array-contains", ui.establishment.uid)
                      .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

export const getQuizzesShareWithRegion = async ({ui}, err) => {
  try {
    const regionId = ui?.user?.role === "regionAdmin" 
                        ? `${ui.establishment.groupement}-${ui.user.region}` 
                        : `${ui.establishment.groupement}-${ui.establishment.region}`;
    const res = await firestore()
                      .collection("quizz")
                      .where("shareEtab", "array-contains", regionId)
                      .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

export const getQuizzesShareWithGroupement = async ({ui, groupementId}, err) => {
  try {
    const res = await firestore()
                      .collection("quizz")
                      .where("shareGlobal", "array-contains", ui.establishment.groupement)
                      .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

//--------------------------  -------------------------------//

export const updateQuizz = async ({id, data}, err) => {
  try {
    const res = await firestore()
                      .collection("quizz")
                      .doc(id)
                      .update(data);
    return res;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

//--------------------------  -------------------------------//

export const createQuizz = async ({data}, err) => {
  try {
    const res = await firestore()
                      .collection("quizz")
                      .add(data);
    return res;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};