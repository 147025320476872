import React, { useState, useEffect, useMemo } from "react";
import { Table, Button, Input, SheetImport } from "lib/components";
import { Plus, Edit, X, Check, Trash } from "react-feather";
import { getAllEtabSupplements_rt, deleteEtabSupplement, getGroupementSupplements_rt, getGroupementSupplementCategories_rt, createEtabSupplement, updateEtabSupplement } from "@/services/ressources/supplement_restaurant";
import useUI from "@/hooks/ui.hook";
import { SupplementModalEditor, ValidationImportSupplementModal } from "../../router_components";
import useSearch from "@/hooks/useSearch";


const parameters = ["id", "name"];
const SupplementsEditor = (props) => {
  const [ui] = useUI();
  const [filter, setFilter] = useState("");
  const [supplements, setSupplements] = useState([]);
  const [groupementSupplements, setGroupementSupplements] = useState([]);
  const [selectedSupplement, setSelectedSupplement] = useState(null);
  const [groupementSupplementsCategories, setGroupementSupplementsCategories] = useState([]);
  const [isModalSupplementOpen, setIsModalSupplementOpen] = useState(false);
  const [isModalValidationSupplementOpen, setIsModalValidationSupplementOpen] = useState(false);
  const [validationSupplements, setValidationSupplements] = useState([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const groupedSupplements = useMemo(() => {
    return [...groupementSupplements, ...supplements]
  }, [supplements, groupementSupplements]);

  const { filtered: filteredSupplements } = useSearch(groupedSupplements, filter, parameters);

  const applyKeys = (categories) => {

    const applyLevelKeys = (categories, level) => {
      return categories.map((category, index) => {
        return {
          ...category,
          key: level + "-" + index,
          children: category.children ? applyLevelKeys(category.children, level + "-" + index) : []
        }
      })
    }

    return applyLevelKeys(categories, 0);
  }

  useEffect(() => {
    if (ui?.groupement?.uid) {
      const unsubscribe = getGroupementSupplementCategories_rt(
        { groupementId: ui?.groupement?.uid },
        (d) => setGroupementSupplementsCategories(applyKeys(d))
      );

      return unsubscribe;
    }
  }, [ui]);

  useEffect(() => {
    if (ui?.establishment?.uid) {

      const subscribe = getAllEtabSupplements_rt({ etabId: ui?.establishment?.uid }, (d) => setSupplements(d));
      return subscribe;
    }
  }, [ui]);

  useEffect(() => {
    if (ui?.groupement?.uid) {
      const subscribe = getGroupementSupplements_rt({ groupementId: ui?.groupement?.uid }, (d) => setGroupementSupplements(d), (e) => console.log(e));
      return subscribe;
    }
  }, [ui])

  const onDelete = (supplement) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
      deleteEtabSupplement({ etabId: ui.user.establishment, supplementId: supplement.uid }, () => { }, (e) => console.log(e))
    } else {
      console.log("Suppression annulée");
    }
  }

  const _submitSupplements = (data) => {
    data.forEach(supplement=> {
      // NOUVEAU SUPPLEMENT
      if(supplement.reason === "new"){
        createEtabSupplement({etabId: ui.user.establishment, supplement: {...supplement.imported, isAvailable: true}}, () => {},(e) => console.log(e))
      }

      // SUPPLEMENT MODIFIE
      if(supplement.reason === "modified" && supplement.keep) {
        console.log()
        updateEtabSupplement({etabId: ui.user.establishment, supplementId: supplement.original.uid, supplement: supplement.imported}, () => {},(e) => console.log(e))
      }
      // SUPPLEMENT SUPPRIME
      if(supplement.reason === "deleted" && !supplement.keep) {
        deleteEtabSupplement({etabId: ui.user.establishment, supplementId: supplement.original.uid}, () => {},(e) => console.log(e))
      }
    })
  }

  const submitImport = (data) => {

    setIsImportModalOpen(false);
    const _data = data.map(
      importedSupplement => { return { 
        id: importedSupplement["Code Produit"],
        idLink: importedSupplement["Identifiant logiciel"],  
        name: importedSupplement["Libellé"], 
        price: importedSupplement["Prix HT"], 
        category: importedSupplement["Categories"]?.split("/").map(c => c.trim()).join("|/|") ?? "", 
        isFileImported: true
      }}
    ).filter(importedSupplement => !groupementSupplements.find(origin => origin.name === importedSupplement.name));

    // Eléments déjà présents mais absents de l'import 
    const missingFromImport = supplements.filter(importedSupplement => !_data.find(origin => origin.name === importedSupplement.name));

    // Eléments modifiés
    const modified = _data.filter(importedSupplement => {
      const res = supplements.find(origin => origin.name === importedSupplement.name);
      if(res) {
        return !Object.keys(importedSupplement).filter(key => !["uid","isFileImported"].includes(importedSupplement[key])).every(key => importedSupplement[key] === res[key])
      } else return false
    });

    if(missingFromImport.length > 0) {
      setIsModalValidationSupplementOpen(true);
      setValidationSupplements(_data);
    } else {
      _submitSupplements(modified.map(
        importedSupplement => { 
          return { 
            imported: {...importedSupplement}, 
            original: {...supplements.find(origin => origin.name === importedSupplement.name)}, 
            reason: "modified", 
            keep: true,
          }
        }
      ));
      _submitSupplements(_data.filter(
        importedSupplement => !supplements.find(origin => origin.name === importedSupplement.name))
        .map(importedSupplement => { 
          return { imported: {...importedSupplement}, reason: "new" }}));
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <h4>Liste des suppléments</h4>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 20, marginBottom: 20 }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
          <div>Recherche :</div>
          <Input placeholder="Rechercher un supplément" value={filter} onChange={(e) => setFilter(e.target.value)} />
        </div>
        <Button color="primary" onClick={() => { setSelectedSupplement(null); setIsModalSupplementOpen(true) }}>
          <Plus /> Ajouter un supplément
        </Button>
        <Button style={{display: "flex", alignItems: "center", gap: 5}} color="primary" onClick={() => setIsImportModalOpen(true)}>
          <Plus /> Importer des suppléments
        </Button>
        <SheetImport isOpen={isImportModalOpen} toggle={() => setIsImportModalOpen(!isImportModalOpen)} wantedData={[{id: "Code Produit", isMandatory: true}, {id: "Libellé", isMandatory: true}, {id: "Identifiant logiciel"}, {id: "Prix HT"}, {id:"Categories"}]} onFileProcessed={(data) => submitImport(data)}/>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Code Produit</th>
            <th>Libellé</th>
            <th>Catégorie</th>
            {/* <th>Prix</th> */}
            <th>Origine</th>
            <th>Identifiant logiciel</th>
            <th>Disponibilité</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSupplements && filteredSupplements.map((s, index) => (

            <tr key={`supplement-${s.id}-${index}`} style={{ backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white" }}>
              <td>{s.id ?? ""}</td>
              <td>{s.name ?? ""}</td>
              <td>{s?.category?.replaceAll("|/|", " / ") ?? ""}</td>
              {/* <td>{s.price ? `${s.price}€` : ""}</td> */}
              <td>{s.isFromGroupement ? "Groupement" : "Etablissement"}</td>
              <td>{s.idLink ?? ""}</td>
              <td>{((s.isFromGroupement && !s.disabledIn?.includes(ui.user.establishment)) || s.isAvailable) ? <Check style={{ color: "green" }} /> : <X style={{ color: "red" }} />} </td>
              <td style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 10 }}>
                <Edit style={{ cursor: "pointer", color: "#51075e" }} onClick={() => { setSelectedSupplement(s); setIsModalSupplementOpen(true); }} />
                {!s.isFromGroupement && <Trash style={{ cursor: "pointer", color: "red" }} onClick={() => onDelete(s)} />}
              </td>
            </tr>

          ))}
        </tbody>
      </Table>
      <SupplementModalEditor isModalOpen={isModalSupplementOpen} onClose={() => setIsModalSupplementOpen(false)} supplement={selectedSupplement} supplementsCategories={groupementSupplementsCategories} supplements={[...groupementSupplements, ...supplements]}/>
      <ValidationImportSupplementModal isOpen={isModalValidationSupplementOpen} onSubmit={_submitSupplements} onClose={() => setIsModalValidationSupplementOpen(false)} supplementsOrigine={supplements} importedSupplements={validationSupplements} />
    </div>
  );
};

export default SupplementsEditor;