import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import useUI from "hooks/ui.hook";

import { useMenuReservation } from "../../../router_context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Edit, Home, PlusCircle, Info } from "react-feather";

import Table from "../../../../detailedTable";
import moment from "moment";
import { Button } from "lib/components";

import { ModalReservation, ModalWeek, SignatureIcon } from "../../../router_components";

import deepClone from "helpers/deepClone";

import ReactTooltip from "react-tooltip";
import {findSubscriptionForDate} from "helpers/subscription"

import styles from "../scss/reservationTable.module.scss";

const colorMap = {
  paid: "green",
  notPaid: "red",
  nothing: "#6d6d6d"
};

const ReservationTableGuest = (props) => {
  const {
    showTotal = false,
  } = props;
  const [ctx] = useMenuReservation();
  const [ui] = useUI();
  const [columns, setColumns] = useState([]);
  // const [showTotal, setShowTotal] = useState(false);
  const [organisedData, setOrganisedData] = useState();
  const [userId, setUserId] = useState(null);

  const template = ui.establishment.template;
  const templateSubscription = ui.establishment.templateSubscription2;

  
  const totalRepasDay = (_date, _repas) => {
    let retour = 0;

    if (ctx.dataGuestOrders && ctx.dataGuestOrders[_date]) {

      for (const key in ctx.dataGuestOrders[_date]) {
        retour += ctx.dataGuestOrders[_date][key][_repas]?.length ?? 0;
      }

      
    }
    if( ctx.data && ctx.data[_date] && ctx.data[_date]?.reservation) {
      for (const key in ctx.data[_date]?.reservation) {
        const reservation = ctx.data[_date]?.reservation[key]
        if(reservation && reservation[_repas] && reservation[_repas][0] && reservation[_repas][0].isGuest) {
          retour += reservation[_repas].length
        }
      }
    }

    if (retour === 0) retour = " ";
    return retour;
  };

  


  useEffect(() => {
    if (ctx.dateArray && ctx.filteredGuests) {
      let repas = Object.keys(ui.establishment.template).map(i => ({ ...ui.establishment.template[i], name: i })).sort((a, b) => a.heure - b.heure).map(i => i.name);

      let _columns = [];

      const _subColumns = [{
        Header: "Nom",
        Footer: "TOTAL",
        accessor: "name",
        filter: "fuzzyText",
        sticky: "left",
        width: 200,
      }];

      // if (ui.establishment.templateSubscription) {
      //   _subColumns.push({
      //     Header: "abonnement",
      //     Footer: " ",
      //     accessor: "subscription",
      //     width: 120,
      //   });
      // }
      _subColumns.push({
        Header: "détails",
        Footer: " ",
        accessor: "details",
        disableSortBy: true,
        width: 60,
        Cell: CellDetails
      });


      _columns.push({
        Header: "",
        Footer: "",
        accessor: "search",
        sticky: "left",
        disableSortBy: true,
        columns: _subColumns
      });


      // initialisation des utilisateurs
      let organisedDataDict = {};

      Object.entries(ctx.filteredGuests).forEach(([uid, _userData]) => {
        let name = `${_userData?.name ?? ""} ${_userData?.surname ?? ""}`;
        if (organisedDataDict[name] == undefined) {
          organisedDataDict[name] = {};
        }
        organisedDataDict[name].userId = uid;
        if (templateSubscription){

          const subArray = [];
          ctx.dateArray.forEach(_date => {
            const _subscription = findSubscriptionForDate( ctx.filteredGuests[uid]?.subscriptions, _date);
            if(_subscription){
              if(subArray.indexOf(_subscription?.subscription) === -1){
                subArray.push(_subscription?.subscription);
              }
            }
          });
          
          organisedDataDict[name]["subscription"] = subArray.join(", ");
        }
      });

      const sumTotaux = {
        "Total_repas": 0,
        "Total_portage": 0,
      };

      repas.forEach((repasKey) => {
        sumTotaux["Total_" + repasKey] = 0;
      });

      // on boucle sur les jours
      ctx.dateArray.forEach((_date, index) => {

        // on commence par ajouter les colonnes 
        let _underColumns = [];

        repas.forEach((repasId, indx) => {
          _underColumns.push(
            {
              Header: repasId,
              Footer: `${totalRepasDay(_date, repasId)}`,
              accessor: _date + "-" + repasId,
              filter: "fuzzyText",
              disableSortBy: false,
              width: 100,
              Cell: Cell,
              date: _date,
              repas: repasId,
              colorChange: index * repas.length + indx
            }
          );
        });

        if (!showTotal) {
          _columns.push({
            Header: moment(_date).format("ddd DD"),
            Footer: "",
            disableSortBy: true,
            columns: _underColumns
          });
        }




        //on s'occupe des lignes
        let day = ctx.data[_date];
        // New version
        if (day && day.reservation) {
          Object.entries(day.reservation).forEach(([resaUserId, resa]) => {
            if (ctx.filteredGuests[resaUserId]) {
              organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`].guestId = resaUserId;
             
              if (!organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_repas"]) organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_repas"] = 0;

            }
            
            repas.forEach((repasKey) => {
              if (ctx.filteredGuests[resaUserId] && resa[repasKey]) {

                organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`][`${_date}-${repasKey}`] = {
                  userId: resaUserId,
                  nbResas: resa[repasKey].length,
                  homeDelivery: resa[repasKey][0].homeDelivery,
                  status: resa[repasKey].length > 0 ? resa[repasKey][0].status ?? "valid" : null,
                };

                if (!(resa[repasKey][0] && resa[repasKey][0].status === "canceled")) {
                  organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_repas"] += resa[repasKey].length;
                  sumTotaux["Total_repas"] += resa[repasKey].length;

                  if (!organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_" + repasKey]) organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_" + repasKey] = 0;
                  organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_" + repasKey] += resa[repasKey].length;
                  sumTotaux["Total_" + repasKey] += resa[repasKey].length;

                  if (resa[repasKey][0].homeDelivery) {
                    if (!organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_portage"]) organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_portage"] = 0;
                    organisedDataDict[`${ctx.filteredGuests[resaUserId].name} ${ctx.filteredGuests[resaUserId].surname}`]["Total_portage"] += 1;
                    sumTotaux["Total_portage"] += 1;
                  }

                }


              }
            });


          });
        }
      });

      const _underColumnsTotal = [];

      _underColumnsTotal.push(
        {
          Header: "Repas",
          Footer: sumTotaux["Total_repas"] > 0 ? sumTotaux["Total_repas"] : " ",
          accessor: "Total_repas",
          sticky: "right",
          width: 60,
        }
      );

      repas.forEach((repasId, indx) => {
        _underColumnsTotal.push(
          {
            Header: repasId,
            Footer: sumTotaux["Total_" + repasId] > 0 ? sumTotaux["Total_" + repasId] : " ",
            accessor: "Total_" + repasId,
            disableSortBy: false,
            sticky: "right",
          }
        );
      });

      _underColumnsTotal.push(
        {
          Header: "Portage",
          Footer: sumTotaux["Total_portage"] > 0 ? sumTotaux["Total_portage"] : " ",
          accessor: "Total_portage",
          sticky: "right",
        }
      );
      
      if (showTotal) {
        _columns.push({
          Header: "Totaux",
          Footer: "",
          disableSortBy: true,
          sticky: "right",
          columns: _underColumnsTotal
        });
      }
      //format rows
      let _organisedData = [];
      Object.entries(organisedDataDict).sort((a, b) => a[0].localeCompare(b[0])).forEach(([key, value]) => {
        let obj = value;
        obj.name = key;
        _organisedData.push(obj);
      });
      setOrganisedData(_organisedData);
      setColumns(_columns);
    }
  }, [ctx.dateArray, ctx.data, ctx.dataGuestOrders, ctx.filteredGuests, showTotal]);

  return (
    <div>
      <ModalReservation type={ctx.filter.role === "guest" ? "guest" : "resident"} />
      <ModalWeek />
      <Table _columns={columns} _data={organisedData} colWidth={80} hasFooter={true} onClick={() => (null)} />
      {/* <UsersDetails uid={userId} retour={() => setUserId(null)} week={data ? Object.keys(data)[0] : null} onlyMenu={true}/> */}
    </div>
  );
};

ReservationTableGuest.propTypes = {
  showTotal: PropTypes.bool.isRequired,
};



const CellDetails = ({ cell }) => {
  const [ctx, dispatch] = useMenuReservation();

  const clickUser = () => {
    dispatch({ type: "setProperty", property: "modalWeekOpen", value: true });
    dispatch({
      type: "setProperty", property: "modalInfos", value: {
        uid: cell.row.original.userId,
        user: cell.row.original.name,
      }
    });
    //alert("coucou");
    //setUserId(uid);
  };

  return <div style={{ height: 91, display: "flex", justifyContent: "center", alignItems: "center" }}>
    <FontAwesomeIcon
      icon={faSearch}
      style={{ "cursor": "pointer" }}
      onClick={() => clickUser(cell.row.original.userId)}
    />
  </div>;
};
CellDetails.propTypes = {
  cell: PropTypes.object.isRequired,
};

const Cell = ({ cell }) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();
  const [homeDelivery, setHomeDelivery] = useState(false);
  const value = cell.value;
  const template = ui?.establishment?.template;

  useEffect(() => {
    if (value && value.homeDelivery == true) setHomeDelivery(true);
    if ((value && value.homeDelivery == false) || (!value && homeDelivery)) setHomeDelivery(false);
  }, [cell]);

  const clickCell = (cell) => {
    if (!ctx?.data[cell.column.date] || (!ctx?.data[cell.column.date][cell.column.repas])) return;
    dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
    dispatch({
      type: "setProperty", property: "modalInfos", value: {
        uid: cell.row.original.userId,
        date: cell.column.date,
        repas: cell.column.repas,
      }
    });
  };

  const status = useMemo(() => {
    if(ctx.dataGuestOrders && ctx.dataGuestOrders[cell.column.date] && ctx.dataGuestOrders[cell.column.date][cell.row.original.userId] && ctx.dataGuestOrders[cell.column.date][cell.row.original.userId][cell.column["Header"]]) {

      const guest = ctx.guests.find((_g) => _g.guestId === cell.row.original.userId);
      
      if(guest && guest.type === "guest") {
        if(guest.hasPaid) {
          return "paid";
        } else {
          return "notPaid";
        }
      }
    }
    return "nothing";


  }, [ctx.data, ctx.dataGuestOrders, value]);

  const isDisabled = !ctx?.data[cell.column.date] || !ctx?.data[cell.column.date][cell.column.repas];

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      width: 100, height: 91,
      padding: 10,
      position: "relative",
      cursor: isDisabled ? "not-allowed" : "pointer"
    }}
      onClick={() => clickCell(cell)}>
      <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
        <div style={{ fontSize: 22, color: colorMap[status ?? "nothing"] }}>
          {value && value.status === "valid" ? value.nbResas ?? 0 : 0}
        </div>
        {status && ["paid", "notPaid"].includes(status) ?
          <>
            <a data-tip data-for={cell.row.id + cell.column.id} className={styles.tooltip}>
              <Info
                size={12}
                style={{ width: 20, height: 20, cursor: "pointer" }}
                color="black"
              /> </a>

            <ReactTooltip id={cell.row.id + cell.column.id} place="bottom" effect="float" className={styles.tooltipBackground}>
              <div style={{maxWidth: 150, fontSize: 11}}>{status === "notPaid" ? "L'invité n'a pas encore payé son repas" : status === "paid" ? "L'invité a payé son repas" : "" }</div>
            </ReactTooltip>
          </>
          : null
        }

        <div style={{ position: "absolute", right: 0, top: 0, border: "1px solid #ccc", borderBottomLeftRadius: 5, padding: 3, }}>
          {value?.nbResas ?
            <Edit
              size={12}
              style={{ "cursor": isDisabled ? "not-allowed" : "pointer", width: 20, height: 20 }}
              color={colorMap[status ?? "nothing"]}
              onClick={() => clickCell(cell)}
            />
            :
            <PlusCircle
              size={12}
              style={{ "cursor": isDisabled ? "not-allowed" : "pointer", width: 20, height: 20 }}
              color={colorMap[status ?? "nothing"]}
              onClick={() => clickCell(cell)}
            />
          }
        </div>
      </div>
      {/*<Checkbox label="port." checked={homeDelivery} onChange={updateHomeDelivery}/>*/}
      <div style={{ width: 60 }}>
        {homeDelivery && <Home />}
      </div>
      <SignatureIcon cell={cell} orders={ctx.dataGuestOrders}/>
    </div >
  );
};

Cell.propTypes = {
  cell: PropTypes.object.isRequired,
};


export default ReservationTableGuest;
