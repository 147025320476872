import React, {useState} from "react";
import useCtx, { Provider } from "./ctx";
import {Tab, Button, Modal, ModalHeader, ModalFooter, ModalBody, Select} from "lib/components";
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase/app";
import 'firebase/firestore';

import SubscriptionComponent from "./SubscriptionComponent";
import SubscriptionDetails from "./SubscriptionDetails";


const firestore = firebase.firestore;

const Subscription = () => {
  const history = useHistory();
  const {id} = useParams();
  const [ctx] = useCtx();
  const [modalCopy, setModalCopy] = useState(false);
  const [establishments, setEstablishments] = useState(null);
  const [selectedEstablishment, setSelectedEstablishment] = useState("");

  const openCopyTemplate = async () => {
    let res = await firestore().collection("establishments").get();
    setEstablishments(res.docs.map(doc => ({uid: doc.id, ...doc.data()})));
    setModalCopy(true);
  };

  const handleChange = (e) => {
    setSelectedEstablishment(e);
  };

  const _handleDelete = () => {
    if(confirm("Attention, vous allez supprimer le template d'abonnement de cet établissement !")){
      ctx.remove();
    }
  };


  return (
    <div style={{width: "100%"}}>
      <Tab activeTab={2} render={[
        {title: "Paramètres", content: <div></div>, overrideOnClick: () => history.push(`/dashboard/establishments/${id}/settings`)},
        {title: "Menu",  overrideOnClick: () => history.push(`/dashboard/establishments/${id}/menu`), content:  <></>},
        {title: "Abonnement", content: <div>
            {ctx.step === 0 ? <SubscriptionComponent />
            :ctx.step === 1 ? <SubscriptionDetails />  
            : null}
        </div>, overrideOnClick: () => history.push(`/dashboard/establishments/${id}/subscription`)},
        {title: "Statistiques", content: <div></div>, overrideOnClick: () => history.push(`/dashboard/establishments/${id}/statistics`)}
      ]} />
      <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width:"50%", marginLeft: "25%", paddingBottom: 50}}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <Button onClick={openCopyTemplate}>Importer d'un autre établissement</Button>
        <Button onClick={ctx.save}>Enregistrer</Button>
        <Button color="danger" onClick={_handleDelete}>Supprimer</Button>

      </div>
      <Modal isOpen={modalCopy} toggle={() => setModalCopy(!modalCopy)} size="lg">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <ModalHeader>Sélectionnez la template d'un établissement</ModalHeader>
        <ModalBody>
          <Select
            label="Etablissement"
            value={selectedEstablishment}
            onChange={e => handleChange(e ?? null)}
            options={establishments?.map((e) => { return {label: e.name, value: e}; }) ?? null}
          />
        </ModalBody>
        <ModalFooter> 
          <Button color="secondary" onClick={() => setModalCopy(!modalCopy)}>Annuler</Button>
          <Button color="primary" onClick={() => {ctx.copyFromEtab(selectedEstablishment?.value);setModalCopy(!modalCopy);}}>Copier</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const MenuHoc = () => { 
  const {id} = useParams();

  return (
    <Provider establishmentId={id}>
      <Subscription />
    </Provider>
  );
};
export default MenuHoc;