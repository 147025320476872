import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

//--------------------------  -------------------------------//

export const getAllInterventionsByEtab_rt = ({id}, cb, err) => {
  const subscribe = firestore()
                      .collection("establishments")
                      .doc(id)
                      .collection("blocks")
                      .doc("interventions")
                      .collection("interventions")
                      .onSnapshot(snap => {
                        const data = snap.docs.map(doc => {
                          const _data = doc.data();

                          console.log(_data);
                          return {
                            uid: doc.id,
                            ..._data,
                            date : _data?.date?.toDate() ?? null
                          };
                          
                        });
                        cb(data);
                      }, err ?? (() => {}));
  return subscribe;
};

  //--------------------------  -------------------------------//

  export const updateInterventionByEtab = async({etabId, interventionId, data},err)=>{
    try{
        await firestore()
                .collection("establishments")
                .doc(etabId)
                .collection("blocks")
                .doc("interventions")
                .collection("interventions")
                .doc(interventionId)
                .update(data);
                 
    }catch (e) {
        console.error(e);
        if (err) err(e);
        else toast.error("Une erreur est survenue");
      }
  };

  //--------------------------  -------------------------------//

  export const createIntervention = async ({etabId,data},err) =>{
    try {
      const res = await firestore()
                          .collection("establishments")
                          .doc(etabId)
                          .collection("blocks")
                          .doc("interventions")
                          .collection("interventions")
                          .add({
                            ...data,
                          });
      return {uid: res.id, ...data};
    } catch (e) {
      console.error(e);
      if (err) err(e);
      else toast.error("Une erreur est survenue");
      return null;
    }
  };

    //--------------------------  -------------------------------//
 
  export const removeIntervention = async ({etabId,interventionId},err) => {
    try {
      await firestore()
            .collection("establishments").doc(etabId)
            .collection("blocks").doc("interventions")
            .collection("interventions").doc(interventionId)
            .delete();
    } catch (e) {
      console.error(e);
      if (err) err(e);
      else toast.error("Une erreur est survenue");
      return null;
    }
  };