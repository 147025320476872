import React from "react";
import {Modal as BootstrapModal,} from "reactstrap";
import Icon from "../Icon";
import PropTypes from "prop-types";
import {X} from "react-feather";

import styles from "../../assets/scss/component/modal.module.scss";

const Modal = (props) => {
  const {
    children,
    className,
    toggle,
    displayToggle = true
  } = props;

  return (
    <BootstrapModal {...props} className={`${styles.modal} ${className}`}>
      { toggle && displayToggle &&
        <Icon icon={X} size={24} color="dark" className={styles.close} onClick={toggle}/>}
      {children}
    </BootstrapModal>
  );
};

Modal.propTypes = {
  toggle: PropTypes.func,
  // isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.any,
  displayToggle: PropTypes.bool
  // size: PropTypes.oneOf(["sm", "lg", "xl"])
};

export default Modal;