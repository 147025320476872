import React, {useState, useEffect, useRef} from "react";
import Icon from "../Icon";
import PropTypes from "prop-types";
import {
  ChevronDown,
  ChevronRight
} from "react-feather";

import styles from "../../assets/scss/layout/dashboard/sidebarSubSubMenu.module.scss";

const SidebarSubSubMenu = (props) => {
  const {title, icon, children} = props;
  const [isOpen, setIsOpen] = useState(true);

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  },[isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={`${styles.sidebarLink} ${isOpen ? styles.selected : ""}`} onClick={handleClick}>
        {icon ?
          <Icon size={35} 
            icon={icon}
            className="f-c-light" />
          : null}
        <span className={styles.title}>{title ?? "aucun titre"}</span>
        <Icon size={30} 
          icon={isOpen ? ChevronDown : ChevronRight}
          className={styles.icon} />
      </div>
      <div style={{height :  height > 0 ? height + 10 : height, transition: "height 0.2s ease-in-out", overflow: "hidden", backgroundColor: "#b538cb1e", borderRadius: 15, borderTopLeftRadius: 0, borderTopRightRadius: 0, margin: 5, marginTop: 0}}>
        <div className={styles.sidebarSubLink} ref={ref}>
          {children}
        </div>
      </div>
      {/*isOpen ? children : null*/}
    </div>
  );
};

SidebarSubSubMenu.propTypes = {
  icon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default SidebarSubSubMenu;