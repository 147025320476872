import moment from "moment";

export const findSubscriptionForDate = (array, date) => {
    if (!array || !date) return null;

    let foundSubscription = null;
    const wantedDate = moment(date);
    array.forEach(element => {
      if (wantedDate.isBetween(moment(element.start), moment(element.end), "day", "[]")) {
        const _subscriptionDays = element?.days ?? [1, 2, 3, 4, 5, 6, 7];
        if(_subscriptionDays.includes(moment(date).isoWeekday())){
          foundSubscription = element;
        }
      }
    });
    return foundSubscription;
  };