import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import useUI from "hooks/ui.hook";

import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});


const styles = StyleSheet.create({
  mainContainer: {
    border: 6,
    borderColor: "#D26F47",
  },
  title: {
    fontSize: 35,
    fontFamily: "Roboto",
    fontWeight: "bold",
    color: "#D26F47",
  },
  titleContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  weekContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 6,
  },
  fromto: {
    fontFamily: "Roboto",
    fontSize: 15,
  },
  container: {
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: -20
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    right: -30,
    width: 160,
    height: 70,
  },
  logo: {
    width: "100%",
    objectFit: "contain",
  },
  day: {
    borderRadius: 20,
    backgroundColor: "#F6EAE5",
    margin: 8,
    width: "22%",
    height: 226,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 8,
    marginLeft: 5,
  },
  dayString: {
    margin: 5,
    color: "#D26F47",
    fontSize: 13,
    fontFamily: "Roboto",
  },
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  event: {
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  eventHour: {
    fontSize: 12,
  },
  eventTitleContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  eventTitle: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 12,
  },
  eventDescription: {
    fontFamily: "Roboto",
    fontSize: 11,
    marginLeft: 5,
    marginRight: 5,
    maxHeight: 20,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  eventImageContainer: {
    height: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  eventImage: {
    width: "100%",
    objectFit: "contain",
    marginBottom: 6,
  },
});

const WeekPlanningPdf = (props) => {
  const { ui, monday, events, logo, additionnalData } = props;
  const day = new Date(monday);

  const _getDays = () => {
    let _days = [];

    for (let i = 0; i < 7; i++) {
      let day = new Date(monday);
      day.setDate(day.getDate() + i);
      _days.push(day);
    }
    return _days;
  };
  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return events.filter((event) => event.start > start && event.end < end);
  };
  const _hourToString = (date) => {
    return moment(date).format("HH:mm");
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.mainContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>ANIMATIONS</Text>
          </View>
          <View style={styles.weekContainer}>
            <Text style={styles.fromto}>
              Semaine du {moment(day).startOf("week").format("ll")} au{" "}
              {moment(day).endOf("week").format("ll")}
            </Text>
          </View>
          <View style={styles.logoContainer}>
            {logo ? <Image src={logo} style={styles.logo} /> : <></>}
          </View>
          <View style={styles.container}>
            {_getDays().map((day) => (
              <View style={styles.day} key={day.toISOString()}>
                <View style={styles.dateContainer}>
                  <Text style={styles.dayString}>
                    {moment(day).format("dddd").toLocaleUpperCase()}{" "}
                    {moment(day).format("Do")}{" "}
                    {moment(day).format("MMM").toLocaleUpperCase()}
                  </Text>
                </View>
                <View style={styles.eventsWrapper}>
                  <View style={styles.eventTitleContainer}>
                    {_getEventByDay(day).map((event, index) => (
                      index < 3 ? 
                      <View style={styles.event} key={index}>
                        <Text style={styles.eventHour}>
                          {_hourToString(event.start)} -{" "}
                          {_hourToString(event.end)}
                        </Text>
                        <Text style={styles.eventTitle}>{event.title}</Text>
                      </View> : null
                    ))}
                  </View>
                  <View style={styles.eventImageContainer}>
                    {/* Si Jamais il y a plusieurs images le même jour, on récupère l'image de l'événement le plus long */}
                  
                    {_getEventByDay(day).filter(e => e?.extendedProps?.image !== null).sort((a,b) => (b.end - b.start) - (a.end - a.start))[0]?.extendedProps.image ? (
                      <Image
                        src={_getEventByDay(day).filter(e => e?.extendedProps?.image !== null).sort((a,b) => (b.end - b.start) - (a.end - a.start))[0].extendedProps.image}
                        style={styles.eventImage}
                      />
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

WeekPlanningPdf.propTypes = {
  monday: PropTypes.any.isRequired,
  events: PropTypes.array.isRequired,
  logo: PropTypes.string,
  additionnalData: PropTypes.string,
  ui: PropTypes.object.isRequired,
};
export default WeekPlanningPdf;
