import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Input} from "lib/components";

import {ImagePickerService} from "lib/components";
import { CheckCircle } from "react-feather";
import styles from "assets/scss/pages/CutlureQuizz/editorSidebar.module.scss";

const EditorSidebar = (props) => {
  const {question, onChange, setImageLoaderForm} = props;

  const [questionText, setQuestionText] = useState("");
  const [answers, setAnswers] = useState(["", "", "", ""]);
  const [explications, setExplications] = useState("");
  const [answer, setAnswer] = useState(null);
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    setAnswer(question.answer ? question.answer : 0);
    setAnswers(question.answers ? question.answers : ["", "", "", ""]);
    setExplications(question.explications ?? "");
    setQuestionText(question.title ? question.title : "");
    setMedia(question.media ? question.media : null);
    setMediaType(question.mediaType ? question.mediaType : null);
  }, [question]);

  useEffect(() => {
    onChange({
      ...question,
      title: questionText,
      answer: answer,
      answers,
      explications : explications ?? "",
      media: media,
      mediaType: media ? "image" : null
    });
  }, [answer, answers, questionText, media, mediaType, explications]);

  const _onChangeAnswers = (e, index) => {
    let _answers = [...answers];
    _answers[index] = e.target.value;
    setAnswers(_answers);
  };

  return (
    <div>
      <Input value={questionText}
        onChange={e => setQuestionText(e.target.value)}
        placeholder="Titre de la question"
        style={{marginBottom: 10}} />

      {/* multiple answers system */}
      <span>Réponses:</span>
      <br></br>
      <br></br>
      <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "center"}}>
        <Input placeholder="Réponse 1" style={{flex: 1}} value={answers[0]} onChange={e => _onChangeAnswers(e, 0)} />
        <CheckCircle size={28} color={answer === 0 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => setAnswer(0)} />
      </div>
      <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "center"}}>
        <Input placeholder="Réponse 2" style={{flex: 1}} value={answers[1]} onChange={e => _onChangeAnswers(e, 1)} />
        <CheckCircle size={28} color={answer === 1 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => setAnswer(1)} />
      </div>
      <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "center"}}>
        <Input placeholder="Réponse 3" style={{flex: 1}} value={answers[2]} onChange={e => _onChangeAnswers(e, 2)} />
        <CheckCircle size={28} color={answer === 2 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => setAnswer(2)} />
      </div>
      <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "center"}}>
        <Input placeholder="Réponse 4" style={{flex: 1}} value={answers[3]} onChange={e => _onChangeAnswers(e, 3)} />
        <CheckCircle size={28} color={answer === 3 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => setAnswer(3)} />
      </div>
      <br></br>

      <div style={{width: "100%",display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Input label="Explications de la réponse" type="textarea" placeholder="ex : Les premiers Jeux Olympiques modernes ont eu lieu à Athènes en 1896." className={styles.textArea} value={explications} onChange={e => setExplications(e.target.value)} />
      </div>

      <br></br>
      <br></br>

      {/*<Select value={answer === null  ? null :
                     answer === true  ? {label: "Vrai", value: true} :
                     answer === false ? {label: "Faux", value: false} : null}
                onChange={e => setAnswer(e ? e.value : null)}
                placeholder="réponse"
                options={[
                  {label: "Vrai", value: true},
                  {label: "Faux", value: false}
                ]}
      />*/}
        <ImagePickerService
            max={1}
            onChange={e => {
              if(typeof(e) === "string"){
                setMedia(e);
              }else if(typeof(e) === "object" && e && e.urls && e.urls.raw){
                setMedia(e.urls.raw);
              }
            }}
            value={media}
            theme="grey"
            setImageLoaderForm={setImageLoaderForm}
          />

    </div>
  );
};

EditorSidebar.propTypes = {
  question : PropTypes.object.isRequired,
  onChange : PropTypes.func.isRequired,
  setImageLoaderForm : PropTypes.func.isRequired
};

export default EditorSidebar;