import React, { useState } from "react";
import { Button } from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";

import { getUsersByGroupement } from "@/services/ressources/user";

const firestore = firebase.firestore;

export default function UsersSandBox() {

    const StatUsers = async () => {
        const users = await getUsersByGroupement({groupementId: "MT39Bqj5UKwRqbpgYitj"});

        const usersLastLoggedIn = users.filter(_u => _u.lastLogin);

        const nameMap = {
            "wzqfcK9SZnwciIzODpnw" : "Porte Neuve - API PROVENCE",
            "JhWff8iJvpsyLjyYhvFL" : "Le Cobalt - API PROVENCE",
            "StPtYAT4V6DlxB8UPGGs" : "Lou Paradou - API PROVENCE",
        };
        ["wzqfcK9SZnwciIzODpnw", "JhWff8iJvpsyLjyYhvFL", "StPtYAT4V6DlxB8UPGGs"].forEach(_etabId => {
            const _usersEtab = users.filter(_u => _u.establishment === _etabId);
            const _usersEtabLogged = usersLastLoggedIn.filter(_u => _u.establishment === _etabId);

            console.log("Etab : ", nameMap[_etabId]);
            console.log(" ");
            console.log("Total Users : ", _usersEtab);
            console.log("Users Logged In : ", _usersEtabLogged);
            console.log(" ");

            console.log("Senior : " + _usersEtabLogged.filter(_u => _u.role === "senior").length);
            console.log("Famille : " + _usersEtabLogged.filter(_u => _u.role === "family").length);
            console.log("Employé : " + _usersEtabLogged.filter(_u => _u.role === "employee").length);
            console.log("Administration : " + _usersEtabLogged.filter(_u => _u.role === "owner").length);

            console.log(" ");
            console.log("----------------");

        });
    }

    return (
        <div>
            <h2>Les Users</h2>
            <Button onClick={StatUsers}>Stats users</Button>
        </div>
    );
}
