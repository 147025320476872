import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
//import { matchSorter } from "match-sorter";
import { useSticky } from "react-table-sticky";
import "regenerator-runtime/runtime";
import { Download } from "react-feather";

import styles from "../../../assets/scss/pages/reservation/detailedTable.module.scss";

const colors = [styles.background1, styles.background2, styles.background3];

const Table = ({ _columns, _data, colWidth, hasFooter, onClick }) => {
  if (!colWidth) colWidth = 150;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const defaultColumn = React.useMemo(
    () => ({
      width: colWidth,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useBlockLayout,
    useSortBy,
    useSticky
  );


  useEffect(() => {
    if (_columns != undefined) setColumns(_columns);
    if (_data != undefined) {
      if (_data !== data)
        setData(_data);

      debounceSetFilter("");// Temporarily clear the filter
    }

  }, [_data, _columns, searchValue]);

  const debounceSetFilter = useAsyncDebounce(
    (_value) => setGlobalFilter(_value)
    , 300
  );

  useEffect(() => {
    debounceSetFilter(searchValue ?? "");
  }, [data, searchValue]);

  /*function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }*/

  const footerGroups = headerGroups.slice().reverse();

  return (
    <div style={{ userSelect: "none" }}>
      <div className={styles.research}>
        <p>Rechercher</p>
        <input
          value={searchValue || ""}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          placeholder={`${preGlobalFilteredRows.length} lignes...`}
          className={styles.input}
        />
      </div>
      <div
        {...getTableProps()}
        className={`${styles.table} ${styles.sticky}`}
      >

        <div className={styles.header}>
          {headerGroups.map((headerGroup, index) => {

            const { key, ...headerProps } = headerGroup.getHeaderGroupProps();

            return (
              <div key={key} {...headerProps} className={styles.tr} >
                {headerGroup.headers.map((column, index2) => {

                  const { key: key2, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());

                  
                  return (
                    <div key={key2} {...columnProps} className={`${styles.th} ${column.depth == 0 ? styles.firstHeader : styles.otherHeader}`}>
                      {column.time ? <div>{column.time}</div> : null}
                      <>
                        {column.render("Header")}
                        {column.export ? <div onClick={(e) => { e.stopPropagation(); column.export(column); }}><Download size={20} /></div> : null}
                        <span className={styles.sort}>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </>
                    </div>
                  )
                })}
              </div>);
          })}
        </div>
        <div {...getTableBodyProps()} className={styles.body}>
          {rows.map((row, index) => {
            prepareRow(row);

            const { key, ...rowProps } = row.getRowProps();

            return (
              <div key={key} {...rowProps} className={styles.tr}>
                {row.cells.map((cell, index2) => {

                  const { key: key2, ...cellProps } = cell.getCellProps();

                  return (
                    <div key={key2} {...cellProps} className={`${styles.td} ${cell.column.canUpdate ? styles.pointer : null} ${cell.column.colorChange != undefined ? colors[cell.column.colorChange % 3] : null}`} onClick={() => { if (cell.column.canUpdate) onClick(cell); }}>
                      {cell.render("Cell")}
                    </div>
                  )
                })}
              </div>
            );
          })}
        </div>
        {hasFooter ?
          <div className={styles.footer}>
            {footerGroups.map((footerGroup, index) => {

              const { key, ...footerGroupProps } = footerGroup.getHeaderGroupProps();

              return (

                <div key={key} {...footerGroupProps} className={styles.tr}>
                  {footerGroup.headers.map((column, index2) => {

                    const { key: key2, ...columnProps } = column.getHeaderProps();

                    return (
                      <Fragment key={key2}>
                        {column.Footer != undefined && column.Footer != "" ? (

                          <div {...columnProps} className={`${styles.td} ${styles.footerStyle}`}>
                            {column.render("Footer")}
                          </div>
                        ) : null}
                      </Fragment>
                    );
                  })}
                </div>
              )
            })}
          </div> : null}
      </div>
    </div>
  );
};

Table.propTypes = {
  _columns: PropTypes.array,
  _data: PropTypes.array,
  colWidth: PropTypes.number,
  hasFooter: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Table;
