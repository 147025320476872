import { Input } from "lib/components";
import PropTypes from "prop-types";
import { Edit3, Plus, Trash } from "react-feather";
import {
  Page,
  Text,
  View,
} from "@react-pdf/renderer";

import React from "react";
import contentTypes from "../contentTypes";
import styles from "assets/scss/pages/Gazette/pageTypes/page-2-slot.module.scss";

import "../fonts";

const Page2SlotMiniature = (props) => {
  const { onClick } = props;

  return (
    <div className={styles.miniature} onClick={onClick}>
      <div className={styles.field}></div>
      <div className={styles.field}></div>
    </div>
  );
};

const Page2Slot = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  return (
    <>
      <div className={styles.head}>
        <Input
          placeholder="Titre"
          value={page.title}
          onChange={(e) => {if (e.target.value.length <= 30) onEditPage("title", e.target.value);}}
          className={styles.input}
        />
      </div>
      <div className={styles.page}>
        <Input
            placeholder="Chapeau"
            value={page.standfirst}
            onChange={(e) => onEditPage("standfirst", e.target.value)}
            className={styles.input}
          />
        {page["1"] &&
        contentTypes.find(
          (type) => type.slug === JSON.parse(page["1"]).contentType
        ) ? (
            <div className={`${styles.content}`}>
              <Trash
                color="red"
                size={40}
                className={styles.trash}
                onClick={() => onRemoveContent("1")}
              />
              <Edit3
                color="blue"
                size={40}
                className={styles.edit}
                onClick={() => onEditContent({ format: "half", key: "1" })}
              />
              {contentTypes
                .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                .component({
                  ...JSON.parse(page["1"]),
                })}
            </div>
          ) : (
            <div
              className={`${styles.content} ${styles.add}`}
              onClick={() => onAddContent({ format: "half", key: "1" })}
            >
              <Plus size={120} />
            </div>
          )}
        {page["2"] &&
        contentTypes.find(
          (type) => type.slug === JSON.parse(page["2"]).contentType
        ) ? (
            <div className={`${styles.content}`}>
              <Trash
                color="red"
                size={40}
                className={styles.trash}
                onClick={() => onRemoveContent("2")}
              />
              <Edit3
                color="blue"
                size={40}
                className={styles.edit}
                onClick={() => onEditContent({ format: "half", key: "2" })}
              />
              {contentTypes
                .find((type) => type.slug === JSON.parse(page["2"]).contentType)
                .component({
                  ...JSON.parse(page["2"]),
                })}
            </div>
          ) : (
            <div
              className={`${styles.content} ${styles.add}`}
              onClick={() => onAddContent({ format: "half", key: "2" })}
            >
              <Plus size={120} />
            </div>
          )}
        <Trash
          color="red"
          size={40}
          className={styles.trash}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const Page2SlotPdf = (props) => {
  const { page, ctx, index, ui } = props;

  let pdfStyles = {
    container: {
      backgroundColor: ctx.color ?? "#D26F47",
      margin: 5,
      padding: 5,
      width: 585,
      height: 830,
    },
    content: {
      backgroundColor: "white",
      width: "100%",
      height: "100%",
      borderRadius: 25,
    },
    titleContainer: {
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: ctx.color ?? "#D26F47",
    },
    title: {
      fontFamily: ctx?.settings?.fontFamily ?? "Roboto",
      fontWeight: "bold",
      fontSize: ctx?.settings?.fontSize1 ?? 42,
    },
    standfirst: {
      fontFamily: ctx?.settings?.fontFamily ?? "Roboto",
      fontSize: ctx?.settings?.fontSize3 ?? 14,
      color: "black",
      marginTop: 8,
      width: "97%",
      maxHeight: 75,
    },
    field1: {
      height: 360,
      overflow: "hidden",
      padding: 15,
      marginBottom: 10,
    },
    field2: {
      height: 360,
      overflow: "hidden",
      padding: 15,
    },
    footerContainer: {
      marginTop: 5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 20,
      paddingRight: 20,
    },
    footerTitle: {
      color: ctx.color ?? "#D26F47",
    },
    pageCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: ctx.color ?? "#D26F47",
      borderRadius: 25,
      width: 30,
      height: 30,
    },
    pageCountText: {
      color: "white",
      fontSize: 24,
      fontWeight: 600,
    },
  };

  if(ui?.groupement?.specific === "heurus") {
    pdfStyles = {
      ...pdfStyles,
      container: {
        ...pdfStyles.container,
        margin: 0,
        padding: 0,
        width: 596,
        height: 840,
        backgroundColor: "white",
      },
      pageCountText: {
        ...pdfStyles.pageCountText,
        color: "black",
      },
      content: {
        ...pdfStyles.content,
        backgroundColor: "white",
        width: "100%",
        height: "100%",
      },
      titleContainer: {
        ...pdfStyles.titleContainer,
        backgroundColor: "#1c2140",
        height: 70
      },
      title: {
        ...pdfStyles.title,
        fontFamily: "Brandon",
        fontWeight: "bold",
        color: "white",
        backgroundColor: "#ec871c",
        paddingHorizontal: 20,
        fontSize: 24,
      },
      field: {
        ...pdfStyles.field,
        margin: 5,
        padding: 5
      },
      pageCountContainer: {
        ...pdfStyles.pageCountContainer,
        backgroundColor: "white",
      },
      standfirst: {
        ...pdfStyles.standfirst,
        fontFamily: "Brandon",
        color: "white"
      }
    };
  }
  return (
    <Page size="A4" orientation="portrait" key={index}>
      <View style={pdfStyles.container}>
        <View style={pdfStyles.content}>
          <View style={[pdfStyles.titleContainer, page.standfirst ? {height: 85} : {}]}>
            <Text style={pdfStyles.title}>{page.title}</Text>
            <Text style={pdfStyles.standfirst}>{page.standfirst}</Text>
          </View>
          <View style={[pdfStyles.field1, page.standfirst ? {height: 342} : {}]}>
            {page["1"] &&
            contentTypes.find(
              (type) => type.slug === JSON.parse(page["1"]).contentType
            )
              ? contentTypes
                .find(
                  (type) => type.slug === JSON.parse(page["1"]).contentType
                )
                .pdf({settings: ctx.settings, ui: ui, ...JSON.parse(page["1"]) })
              : null}
          </View>
          <View style={[pdfStyles.field2, page.standfirst ? {height: 342} : {}]}>
            {page["2"] &&
            contentTypes.find(
              (type) => type.slug === JSON.parse(page["2"]).contentType
            )
              ? contentTypes
                .find(
                  (type) => type.slug === JSON.parse(page["2"]).contentType
                )
                .pdf({settings: ctx.settings, ui: ui, ...JSON.parse(page["2"]) })
              : null}
          </View>
          <View style={pdfStyles.footerContainer}>
            <View style={{ width: 30, height: 30 }}></View>
            <View>
              <Text style={pdfStyles.footerTitle}>{ctx.title}</Text>
            </View>
            <View style={pdfStyles.pageCountContainer}>
              <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};



//TODO Definir les proptypes 

Page2Slot.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};

Page2SlotMiniature.propTypes = {
  onClick : PropTypes.any,
};

Page2SlotPdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  ui: PropTypes.any,
};

export { Page2Slot, Page2SlotPdf, Page2SlotMiniature };
