import React, {useState, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Select, Separator } from "../../../lib/components";
import { Trash } from "react-feather";
import useUI from "hooks/ui.hook";
import firebase from "firebase/app";
import 'firebase/firestore';

import {READ} from "hooks/useCultureQuizz";

const firestore = firebase.firestore;

const ShareModal = ({isOpen, toggle, quiz, cultureQuiz}) => {
  const [ui] = useUI();
  const {share, unshare} = cultureQuiz;

  const regionsOptions = useMemo(() => ui.groupement?.regions?.map(e => ({value: e, label: e})) ?? [], [ui.groupement]);
  const [establishmentsOptions, setEstablishmentsOptions] = useState([]);

  const [shareType, setShareType] = useState(null);
  const [shareRegion, setShareRegion] = useState(null);
  const [shareEstablishment, setShareEstablishment] = useState(null);
  
  useEffect(() => {
    (async () => {
      console.log(ui?.groupement);
      if (!ui?.groupement?.establishments) return;
      const res = await Promise.all((ui?.groupement?.establishments ?? []).map(async e => {
        const _e = await firestore().collection("establishments").doc(e).get();
        return {value: e, label: _e.data().name};
      }));
      setEstablishmentsOptions(res.filter(e => e.value !== ui?.establishment?.uid));
    })();
  }, [ui?.groupement?.establishments]);

  useEffect(() => {
    setShareRegion(null);
    setShareEstablishment(null);
  }, [shareType]);

  const _share = async () => {
    try {
      await share(
        quiz.uid,
        shareType,
        //shareTo
          shareType === "etab" ? shareEstablishment :
          shareType === "region" ? `${ui.groupement.uid}-${shareRegion}` :
          shareType === "global" ? ui.groupement.uid : null,
        READ
      );
      setShareEstablishment(null);
      setShareRegion(null);
      setShareType(null);
    } catch (e) {
      console.log(e);
    }
  };

  const shareTypeOptions = [
    {value: "global", label: "Tout le monde"},
    ...(ui.groupement && ui.groupement.regions?.length > 1 ? [{value: "region", label: "Région"}] : []),
    ...(ui.groupement ? [{value: "etab",   label: "Etablissement"}] : [])
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>Partager le quizz</ModalHeader>
      <ModalBody>
        <div style={{display: "flex", gap: 15}}>
          <div style={{flex: 1}}>
            <h5>Partager à</h5>
            <Select options={shareTypeOptions} onChange={e => setShareType(e.value)} value={shareTypeOptions.find(e => e?.value === shareType)} />
            <div style={{height: 15}}></div>
            {shareType === "region"? 
              <Select 
                options={regionsOptions.filter(i => quiz.shareRegion ? !quiz.shareRegion.includes(`${ui?.establishment?.groupement}-${i.value}`) : true)} /* check if already shared with */
                onChange={e => setShareRegion(e.value)}
                value={regionsOptions.find(e => e?.value === shareRegion) ?? null}
              />
            :shareType === "etab" ? 
              <Select
                options={establishmentsOptions.filter(i => quiz.shareEtab ? !quiz.shareEtab.includes(i.value): true)} /* check if already shared */
                onChange={e => setShareEstablishment(e.value)}
                value={establishmentsOptions.find(e => e?.value === shareEstablishment) ?? null}
              />
            : <></>}
            <Separator />
            <div style={{display: "flex", gap: 10, justifyContent: "center"}}>
              <Button color="primary" onClick={_share}>Partager</Button>
            </div>
          </div>
          <div style={{width: 1, backgroundColor: "#AAA"}}></div>
          <div style={{flex: 1}}>
            <h5>Partagé à</h5>
            {quiz?.shareGlobal?.length === 2 ? 
              <p style={{display: "flex", alignItems: "center"}}>
                Tout le monde ({
                  [...(quiz.shareGlobal[1] & READ ? ["Lecture"] : []),].join(", ")
                })
                <Trash size={18} style={{cursor: "pointer"}} color="red" onClick={() => unshare(quiz.uid, ui.groupement.uid)}/>
              </p> : null}
            {quiz?.shareRegion?.reduce((acc, val, i, arr) => [...acc, ...(i % 2 === 0 ? [[val, arr[i+1]]] : [])], [])?.map(e =>
              <p style={{display: "flex", alignItems: "center"}} key={e[0]}>
                Région {e[0].split("-")[1]} ({
                  [...(e[1] & READ ? ["Lecture"] : []),].join(", ")
                })
                <Trash size={18} style={{cursor: "pointer"}} color="red" onClick={() => unshare(quiz.uid, e[0])}/>
              </p>
            )}
            {quiz?.shareEtab?.reduce((acc, val, i, arr) => [...acc, ...(i % 2 === 0 ? [[val, arr[i+1]]] : [])], [])?.map(e =>
              <p style={{display: "flex", alignItems: "center"}} key={e[0]}>
                Etablissement {establishmentsOptions.find(i => i.value === e[0])?.label} ({
                  [...(e[1] & READ ? ["Lecture"] : []),].join(", ")
                })
                <Trash size={18} style={{cursor: "pointer"}} color="red" onClick={() => unshare(quiz.uid, e[0])}/>
              </p>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>

      </ModalFooter>
    </Modal>
  );
};

ShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  quiz: PropTypes.object,
  cultureQuiz: PropTypes.object.isRequired //instance of useCultureQuizz
};

export default ShareModal;