import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./truefalse.module.scss";
import QuestionResult from "../questionResult";
import { Circle, Triangle, Info } from "react-feather";
import { Modal, ModalHeader, ModalBody } from "lib/components";
import * as c from "../colors";

const Answering = ({question}) => {
  const [modalInfo, setModalInfo] = useState(false);

  return (
    <div className={styles.displayAnswer}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      {question.explications && question.explications != "" ?
      <div className={styles.info} onClick={() => setModalInfo(true)}>
        <Info width={50} height={50}/>
        {/* <span>{question.explications}</span> */}
      </div>
      : null}
      <div className={styles.mediaDiv}>
        <QuestionResult question={question}/>
      </div>
      <div className={styles.answers}>
        <div className={`${styles.answer}`} style={question.answer ? {backgroundColor : c.COLOR_PALETTE_RESULT[0]} : {}}>
          <Circle stroke={c.COLOR_PALETTE_1[0]} width={70} height={70} fill={c.COLOR_PALETTE_1[0]}/>
          <span>VRAI</span></div>
        <div className={`${styles.answer}`} style={!question.answer ? {backgroundColor : c.COLOR_PALETTE_RESULT[0]} : {}}>
          <Triangle stroke={ c.COLOR_PALETTE_1[1]} width={70} height={70} fill={ c.COLOR_PALETTE_1[1]}/>
          <span>FAUX</span></div>
      </div>
      <Modal isOpen={modalInfo} toggle={()=> setModalInfo(false)} size="xl" className={styles.modalInfo}>
          <ModalHeader>
            Informations supplémentaires
          </ModalHeader>
          <ModalBody>
            {question.explications && question.explications != "" ?
              <div className={styles.explications}>
                {question.explications}
              </div>
              : null
            }
          </ModalBody>
      </Modal>
    </div>
  );
};

Answering.propTypes = {
  question : PropTypes.object.isRequired,
};

export default Answering;