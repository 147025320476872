import React, { useEffect, useMemo, useState } from "react";
import {Container, Row, Col} from "reactstrap";

import YoutubePlayer from "./YoutubePlayer";
import image1 from "../../../assets/images/TutoMinias/1.png"
import image2 from "../../../assets/images/TutoMinias/2.png"
import image3 from "../../../assets/images/TutoMinias/3.png"
import image4 from "../../../assets/images/TutoMinias/4.png"
import image5 from "../../../assets/images/TutoMinias/5.png"
import image6 from "../../../assets/images/TutoMinias/6.png"
import image7 from "../../../assets/images/TutoMinias/7.png"
import image8 from "../../../assets/images/TutoMinias/8.png"
import image9 from "../../../assets/images/TutoMinias/9.png"
import image10 from "../../../assets/images/TutoMinias/10.png"
import image11 from "../../../assets/images/TutoMinias/11.png"
import styles from "../../../assets/scss/pages/Tutoriels/videoplayer.module.scss";
import { PlayCircle } from "react-feather";
import useUI from "@/hooks/ui.hook";

const VideoLibrary = () => {
  const [ui] = useUI();

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO" || ui?.establishment?.uid === "A1LKT1bK4Aj4TAaqPsel";

  const videos = [
    {
      id: 1,
      src: "https://youtu.be/3_i0XoifFxU",
      title: "Vidéo de présentation des actualités.",
      shortTitle: "Actualités",
      duration: "1mn43",
      minia: image1
    },
    {
      id: 2,
      src: "https://youtu.be/7MZ1mRH2BV4",
      title: `Vidéo de présentation des animations${isAutourDeLHumain ? " (ateliers)" : ""}.`,
      shortTitle: "Planning d'animations",
      duration: "2mn35",
      minia: image2
    },
    {
      id: 3,
      src: "https://youtu.be/Xo7PSK9K4lQ",
      title: "Vidéo de présentation des menus.",
      shortTitle: "Menus",
      duration: "2mn29",
      minia: image3
    },
    {
      id: 4,
      src: "https://youtu.be/1sKFXq0NE5g",
      title: "Vidéo de présentation du bandeau dynamique.",
      shortTitle: "Bandeau dynamique",
      duration: "0mn52",
      minia: image4
    },
    {
      id: 5,
      src: "https://youtu.be/YQABjrtFQeQ",
      title: "Vidéo de présentation de culture quiz.",
      shortTitle: "Culture quiz",
      duration: "2mn11",
      minia: image5
    },
    {
      id: 6,
      src: "https://youtu.be/W18_85zuhZ8",
      title: "Vidéo de présentation des ateliers stimulants",
      shortTitle: "Ateliers stimulants",
      duration: "2mn09",
      minia: image6
    },
    {
      id: 7,
      src: "https://youtu.be/RvfDYtMWM3g",
      title: "Vidéo de présentation des données d'utilisation",
      shortTitle: "Données d'utilisation",
      duration: "1mn16",
      minia: image7
    },
    {
      id: 8,
      src: "https://youtu.be/1P61Re4pRx4",
      title: "Vidéo de présentation des réservations",
      shortTitle: "Réservations",
      duration: "2mn01",
      minia: image8
    },
    {
      id: 9,
      src: "https://youtu.be/u8OsB67dubY",
      title: "Vidéo de présentation de la gestion utilisateur",
      shortTitle: "Gestion utilisateur",
      duration: "1mn29",
      minia: image9
    },
    {
      id: 10,
      src: "https://youtu.be/2SVFf0RpaGc",
      title: "Vidéo de présentation des notifications",
      shortTitle: "Notifications",
      duration: "1mn14",
      minia: image10
    },
    {
      id: 11,
      src: "https://youtu.be/rgM06jOvick",
      title: "Vidéo de présentation de la Gazette",
      shortTitle: "Gazette",
      duration: "2mn52",
      minia: image11
    }
  ];

  const [selectedVideoId, setSelectedVideoId] = useState(1);
  const selectedVideo = useMemo(() => videos.find(video => video.id === selectedVideoId), [selectedVideoId]);

  return (
    <Container><Row><Col lg={12}>
      <div className={styles.libContainer}>
        <div className={styles.videoPlayerContainer}>
          <YoutubePlayer src={selectedVideo?.src.split("/")[3]} light={true} />
          <p className={styles.videoTitle}>{selectedVideo?.title} </p>
        </div>
        <div className={styles.videoLibrary}>
          <p className={styles.txtOtherVideo}>Découvrez également : </p>
          {videos.map((video) => (
            <div
              key={video.id}
              className={styles.videoPreview}
              onClick={() => {
                setSelectedVideoId(video.id);                
              }}
            >
              <img src={video.minia} alt={video.shortTitle} className={`${styles.videoMinia} ${(video.id === selectedVideoId) ? styles.selected : ""}`} />
            </div>
          ))}
        </div>
      </div>
    </Col></Row></Container>
  );
};

export default VideoLibrary;
