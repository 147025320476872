import React from "react";
import {Badge} from "reactstrap";
import PropTypes from "prop-types";

import styles from "../assets/scss/component/tag.module.scss";

const Tag = (props) => {
  const {
    children,
    color="primary",
    rounded=false,
    className
  } = props;

  const colorClass = color === "primary"   ? styles.primary   :
    color === "secondary" ? styles.secondary :
      color === "success"   ? styles.success   :
        color === "warning"   ? styles.warning   :
          color === "info"      ? styles.info      :
            color === "danger"    ? styles.danger    : styles.primary;

  return (
    <Badge className={`${styles.tag} ${className ?? ""} ${colorClass} ${rounded ? styles.rounded : ""}`}
      {...props}>
      {children}
    </Badge>
  );
};

Tag.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "success", "warning", "info", "danger"]),
  rounded: PropTypes.bool,
  className: PropTypes.string,
  children  : PropTypes.node,
};

export default Tag;