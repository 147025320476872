import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getPatchnoteById = async ({ id }, err) => {
    console.log("dd",id);
    try {
        const res = await firestore()
            .collection("patchnotes")
            .doc(id)
            .get();
        if (!res.exists) return 0;
        return { uid: res.id, ...res.data(), date: res.data().date ? res.data().date.toDate() : new Date()};
    } catch (e) {
        console.error(e);
        if (err) err(e);
        else toast.error("Une erreur est survenue");
        return null;
    }
};

export const updatePatchnoteById = async ({ id, data }, err) => {
    try {
        await firestore()
            .collection("patchnotes")
            .doc(id)
            .update(data);
    } catch (e) {
        console.error(e);
        if (err) err(e);
        else toast.error("Une erreur est survenue");
    }
};

export const getAllPatchnotes_rt = (_, cb, err) => (
    firestore()
      .collection("patchnotes")
      .onSnapshot(snap => {
        const data = snap.docs.map(doc => ({
          uid: doc.id,
          ...doc.data(),
          date: doc.data().date ? doc.data().date.toDate() : new Date()
        }));
        cb(data);
      }, err ?? (() => {}))
  );

export const createPatchnote = async ( data , err) => {
    try {
        const res = await firestore()
            .collection("patchnotes")
            .add({
                ...data,
            });
        return { uid: res.id, ...data };
    } catch (e) {
        console.error(e);
        if (err) err(e);
        else toast.error("Une erreur est survenue");
        return null;
    }
};