import { getAllEtabSupplements_rt, getGroupementSupplements_rt } from '@/services/ressources/supplement_restaurant';
import {create} from 'zustand';

const useSupplements = create((set) => ({
    supplements: [],
    initSupplements: (etabId, groupementId) => {
        if (!etabId || !groupementId) return;

        let etabSupplements = [];
        let groupementSupplements = [];

        let unsubscribeEtabSupplements = () => {};
        let unsubscribeGroupementSupplements = () => {};

        if(etabId){
            unsubscribeEtabSupplements = getAllEtabSupplements_rt({ etabId: etabId }, (d) => {
               etabSupplements = d;
               set({ supplements: [...etabSupplements, ...groupementSupplements] });
            }, (e) => console.error(e));
        }
        if(groupementId) {
            unsubscribeGroupementSupplements = getGroupementSupplements_rt({ groupementId: groupementId }, (d) => {
                groupementSupplements = d;
                set({ supplements: [...etabSupplements, ...groupementSupplements] });
            }, (e) => console.error(e));
        }                    

        return () => {
            unsubscribeEtabSupplements();
            unsubscribeGroupementSupplements();
        };
    }
}));

export default useSupplements;