import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

// style with css : color and fontSize
const BetterCheckbox = (props) => {
    const {
        checked=false,
        className="",
        disabled=false,
        onChange
    } = props;    

    return (
        <FontAwesomeIcon
            onClick={!disabled ? onChange : null}
            icon={checked ? faCheckSquare : faSquare}
            style={{ "cursor": "pointer" }}
            className={`${className}`}
        />
    );
};

BetterCheckbox.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default BetterCheckbox;
