import React from "react";
import PropTypes from "prop-types";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import HeaderImage from "./assets/WeeklyHeaderImage.png"

import MontserratBlack from "fonts/montserrat/Montserrat-Black.ttf";
import moment from "moment";

Font.register({
  family: "Montserrat",
  fontWeight: "heavy",
  src: MontserratBlack,
})

const styles = StyleSheet.create({
  page: {
    height: "100%",
    width: "100%",
    paddingLeft: 36,
    paddingTop: 14,
    paddingRight: 36,
  },
  globalContainer: {
    width: "100%",
    height: "100%",
  },
  header: {
    backgroundColor: "#1AB3AA",
    width: "100%",
    height: 70,
    position: "relative",
    display: "flex",
    justifyContent: "center"
  },
  headerText: {
    fontSize: 18,
    fontFamily: "Montserrat",
    fontWeight: "heavy",
    color: "#FFF",
    width: 745,
    paddingHorizontal: 10,
  },
  headerImage: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
  },
  daysHeader: {
    backgroundColor: "#FBAF2B",
    width: "100%",
    height: 45,
    display: "flex",
    flexDirection: "row",
  },
  dayContainer: {
    width: "14.28%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8
  },
  dayText: {
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: "heavy",
    color: "#FFF",
  },
  menuContainer: {
    width: "100%"
  },
  menuHeader: {
    backgroundColor: "#F2F2F2",
    width: "100%",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1.2px solid black",
    borderTop: "1.2px solid black",
    borderRight: "1.2px solid black",
  },
  menuHeaderText: {
    fontSize: 13,
    fontFamily: "Montserrat",
    fontWeight: "heavy",
    color: "#1AB3AA",
  },
  menuColumnsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  menuColumn: {
    width: "14.28%",
  },
  menuCell: {
    width: "100%",
    height: 29,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
    border: "0.6px solid #8B8B8B",
  },
  menuText: {
    fontSize: 10,
    textAlign: "center",
  },
  footer: {
    width: "100%",
    height: 55,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1AB3AA",
  },
  footerText: {
    fontFamily: "Montserrat",
    fontWeight: "heavy",
    color: "#FFF",
    fontSize: 32,
  },
});

const ExportWeek = (props) => {
  const { week, menuState, ui } = props;
  const template = ui.establishment.template;

  const categoryGroups = [
    [
      "Potage",
      "Entrée",
      "Plat",
      "Accompagnement",
    ],
    [
      "Fromage",
      "Dessert",
      "Boisson chaude",
    ],
  ];

  // Repas triés par heure : ["Déjeuner", "Dîner"]
  const sortedRepas = Object.keys(template).sort((a,b) => template[a].heure - template[b].heure);
  
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.globalContainer}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Cette semaine,</Text>
            <Text style={styles.headerText}>le Chef vous propose</Text>
            <Image style={styles.headerImage} src={HeaderImage}></Image>
          </View>
          <View style={styles.daysHeader}>
            {week.map((_day, index) => {
              const localeDay = _day.toLocaleDateString("fr-FR", { weekday: "long" });              
              
              return (
                <View key={index} style={styles.dayContainer}>
                  <Text style={styles.dayText}>{localeDay.charAt(0).toUpperCase() + localeDay.slice(1)}</Text>
                  <Text style={styles.dayText}>{moment(_day).format("DD/MM/YYYY")}</Text>
                </View>
              );
            })}
          </View>
          {sortedRepas.map(_repas => (
            <View style={styles.menuContainer} key={_repas}>
              <View style={styles.menuHeader}>
                <Text style={styles.menuHeaderText}>{_repas}</Text>
              </View>
              {Object.entries(template[_repas].menus).sort(([key1, value1], [key2, value2]) => value1.weight - value2.weight).slice(0, 1).map(([_menuName, _menuContent]) => (
                <View style={styles.menuColumnsContainer} key={_menuName}>
                  {Object.values(menuState).map(_menuDayState => (
                    <View key={_menuDayState.uid} style={styles.menuColumn}>
                      {_menuContent.categories.map((_category, _categoryIndex) => {
                        const [_dish, index] = Object.values(_menuDayState[_repas][_category]).filter(dish => dish.origin === _menuName).slice(0, 1);
                        
                        if(_categoryIndex > 0 && categoryGroups[0].includes(_menuContent.categories[_categoryIndex - 1]) && categoryGroups[1].includes(_category)){                          
                          return (
                            <>
                              <View key={index} style={styles.menuCell}>
                                <Text style={styles.menuText} key={index} />
                              </View>
                              <View key={index} style={styles.menuCell}>
                                <Text style={styles.menuText} key={index}>{_dish?.name ?? ""}</Text>
                              </View>
                            </>
                          );

                        }

                        return (
                          <View key={index} style={styles.menuCell}>
                            <Text style={styles.menuText} key={index}>{_dish?.name ?? ""}</Text>
                          </View>
                        );
                      })}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          ))}
          <View style={styles.footer}>
            <Text style={styles.footerText}>Bon Appétit !</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};


ExportWeek.propTypes = {
  week: PropTypes.array.isRequired,
  menuState: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

export default ExportWeek;