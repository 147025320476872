import firebase from "firebase/app";
import 'firebase/firestore';
const firestore = firebase.firestore;

export const getSignatureById = async ({etabId, orderId}) => {
  const res = await firestore()
    .collection("establishments")
    .doc(etabId)
    .collection("blocks")
    .doc("restaurant")
    .collection("orders")
    .doc(orderId)
    .get();
  return new Uint8Array(Object.values(res.data().signature));
} 