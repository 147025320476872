import useUI from "@/hooks/ui.hook";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input } from "@/lib/components";
import TreeSelect from "@/lib/components/Form/TreeSelect";
import { createGroupementSupplement, updateGroupementSupplement } from "@/services/ressources/supplement_restaurant";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const SupplementModalEditor = (props) => {
  const {
    isModalOpen,
    onClose,
    supplement = null,
    supplementsCategories = [],
    supplements = []
  } = props;
  const [ui] = useUI();
  const [localSupplement, setLocalSupplement] = useState(null);
  const [checkedKeys, setCheckedKeys] = useState({});

  useEffect(() => {
    setLocalSupplement(supplement ?? {})

    if (supplement?.category) {
      const _path = findKeyByPath(supplementsCategories, supplement?.category);
      setCheckedKeys({ checked: _path ? [_path] : [] })
    } else {
      setCheckedKeys({});
    }
  }, [isModalOpen, supplement]);

  const deepSortTree = (treeData) => {
    treeData.sort((a, b) => a.title.localeCompare(b.title));
    treeData.forEach((node) => {
      if (node.children) {
        deepSortTree(node.children);
      }
    });
    return treeData;
  };

  const onSubmit = () => {
    // CREATE
    if (!localSupplement.uid) {
      // check if there is already a supplement with the same name
      if (supplements.find((_sup) => _sup.name === localSupplement?.name)) {
        toast.warn("2 suppléments ne peuvent pas avoir le même nom");
        return;
      }

      createGroupementSupplement({ groupementId: ui.user.groupement, supplement: { ...localSupplement, price: parseFloat(localSupplement.price), isFromGroupement: true } }, () => { }, (e) => console.log(e))
    }
    // UPDATE
    else {
      // check if there is already a supplement with the same name (if the name changed)
      if (supplement?.name !== localSupplement?.name && supplements.find((_sup) => _sup.name === localSupplement?.name)) {
        toast.warn("2 suppléments ne peuvent pas avoir le même nom");
        return;
      }

      updateGroupementSupplement({ groupementId: ui.user.groupement, supplementId: localSupplement.uid, supplement: { ...localSupplement, price: parseFloat(localSupplement.price), isFromGroupement: true } }, () => { }, (e) => console.log(e))
    }
    onClose();
  };

  function findPathByKey(nodes, key) {
    // Helper function to recursively search for the path in the tree
    function search(node, key) {
      // Base case: if the current node's key matches the target key, return its title
      if (node.key === key) {
        return node.title;
      }

      // If the node has children, iterate over each child
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          // Recursively search for the key in the child nodes
          const path = search(child, key);
          // If the path is found in the subtree, prepend the current node's title
          if (path) {
            return node.title + '|/|' + path;
          }
        }
      }

      // If the key is not found in the subtree, return null
      return null;
    }

    // Iterate over each top-level node in the array
    for (let node of nodes) {
      const path = search(node, key);
      if (path) {
        return path;
      }
    }

    // If the key is not found in any of the top-level nodes, return null
    return null;
  }

  function findKeyByPath(nodes, path) {
    // Split the path into an array of titles
    const titles = path.split('|/|');

    // Helper function to recursively search for the key in the tree
    function search(node, titles, index) {
      // Base case: if the current title matches and is the last in the path, return the node's key
      if (node.title === titles[index]) {
        if (index === titles.length - 1) {
          return node.key;
        }

        // If the node has children, continue searching
        if (node.children && node.children.length > 0) {
          for (let child of node.children) {
            // Recursively search for the next title in the path
            const key = search(child, titles, index + 1);
            // If the key is found in the subtree, return it
            if (key) {
              return key;
            }
          }
        }
      }

      // If the title is not found or the key is not found in the subtree, return null
      return null;
    }

    // Iterate over each top-level node in the array
    for (let node of nodes) {
      const key = search(node, titles, 0);
      if (key) {
        return key;
      }
    }

    // If the key is not found in any of the top-level nodes, return null
    return null;
  }

  const onCheckStrictly = (_checkedKeys, e) => {
    const _checked = _checkedKeys.checked || _checkedKeys;
    const filtered = _checked.filter(_c => !checkedKeys?.checked?.includes(_c))
    const cks = {
      checked: filtered,
    };

    const _path = findPathByKey(supplementsCategories, filtered[0]);

    setLocalSupplement({ ...localSupplement, category: _path ?? "" })
    setCheckedKeys(cks);
  };

  const checkPrice = (price) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    if (regex.test(price)) return true;
    return false;
  }

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} size="xl">
      <ModalHeader>
        {supplement ? "Modifier le supplément" : "Ajouter un supplément"}
      </ModalHeader>
      <ModalBody>
        <FormGroup style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <div style={{ display: "flex", flexDirection: "row", gap: 15, justifyContent: "flex-stard" }}>
            <Input
              placeholder="ex: KFE01"
              label="Code produit (obligatoire)"
              value={localSupplement?.id ?? ""}
              onChange={e => setLocalSupplement({ ...localSupplement, id: e.target.value })}
            />
            <Input
              placeholder="Café"
              label="Libellé (obligatoire)"
              value={localSupplement?.name ?? ""}
              onChange={e => setLocalSupplement({ ...localSupplement, name: e.target.value })} />

            <Input
              disabled={localSupplement?.isFromGroupement ?? false}
              placeholder="ex: 4395093405"
              label="Identifiant logiciel"
              value={localSupplement?.idLink ?? ""}
              onChange={e => setLocalSupplement({ ...localSupplement, idLink: e.target.value ?? "" })}
            />
            <div>
              <Input
                placeholder="10"
                label="Prix"
                value={localSupplement?.price ?? ""}
                onChange={e => setLocalSupplement({ ...localSupplement, price: e.target.value ?? "" })} />
              {!checkPrice(localSupplement?.price) && <p style={{ color: "red" }}>Vous devez écrire un nombre valide</p>}
            </div>
          </div>
          <div>

          </div>
          <p>Catégories :</p>
          <div style={{ height: 250, overflow: "auto", backgroundColor: "#f5f5f5", margin: 0, border: "1px solid #ced4da" }}>
            <TreeSelect
              treeData={supplementsCategories}
              checkable={true}
              checkStrictly={true}
              selectable={false}
              checkedKeys={checkedKeys}
              onCheck={onCheckStrictly}
            />
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Annuler</Button>
        <Button disabled={!localSupplement?.id || !localSupplement?.name || !localSupplement?.price || !checkPrice(localSupplement.price)} onClick={() => checkPrice(localSupplement?.price) && onSubmit()}>Enregistrer</Button>
      </ModalFooter>
    </Modal>
  );
};

export default SupplementModalEditor;