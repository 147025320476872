import React, { createContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import axios from "axios";
import { updateUserById } from "services/ressources/user";
import moment from "moment";
import MODE from "@/env";

const UIContext = createContext();

function UIReducer(state, action) {
  switch (action.type) {
    case "endLoad":
      return {
        ...state,
        appLoaded: true
      };
    case "login":
      updateUserById({ id: firebase.auth().currentUser.uid, data: { lastLogin: firebase.firestore.FieldValue.serverTimestamp() } });
      return {
        appLoaded: true,
        connected: true,
        user: { ...firebase.auth().currentUser, ...action.user },
        establishment: action.establishment,
        groupement: action.groupement,
        region: action.region,
        refSenior: action.refSenior,
        backendMode: "api",
      };
    case "updateUser":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        }
      };
    case "logout":
      axios.defaults.headers.common["Authorization"] = null;
      firebase.auth().signOut();
      return {
        user: null,
        establishment: null,
        connected: false,
        appLoaded: true
      };
    case "takeControl":
      if(action.establishment)
        axios.defaults.headers.common["Establishment"] = action.establishment.uid;


      return ({
        ...state,
        user: action.user,
        ...(action.establishment ? {establishment: action.establishment} : {}),
        groupement: action.groupement,
      });
    case "updateEstablishment":
      return ({
        ...state,
        establishment: action.establishment,
      });
    case "updateEstablishmentKey":
      return ({
        ...state,
        establishment: {
          ...state.establishment,
          [action.key]: action.value,
        },
      });
    case "updateGroupement":
      return ({
        ...state,
        groupement: action.groupement
      });
    case "updateGroupementKey":
      return ({
        ...state,
        groupement: {
          ...state.groupement,
          [action.key]: action.value,
        },
      });
    case "setBackendMode":
      return ({
        ...state,
        backendMode: action.mode,
      });
    default:
      return { ...state };
  }
}

const UIDefault = {
  user: null,
  establishment: null,
  groupement: null,
  connected: false,
  appLoaded: false,
  backendMode: null, // "firebase" or "api"
};

const Provider = ({ children }) => {
  const [ui, dispatch] = useReducer(UIReducer, UIDefault);

  useEffect(() => {
    if (ui.user && ui.user.isDeleted) {
      dispatch({ type: "logout" });
    }

    // envoie de notif pour la connexion des comptes push.
    if (MODE === "PROD" && ui.user && !ui?.user?.superadmin && !ui.user.isDeleted && ui?.establishment?.demo === true) {
      axios.post("/notifications/discordActivityMonitor", {
        data: `${ui?.user?.surname} ${ui?.user?.name} s'est connecté.
                                - établissement : ${ui?.establishment?.name}\n
                                - jours restants : ${moment(ui?.establishment?.endDate?.toDate()).diff(moment(), "days")}\n
                                - features découvertes : ${ui?.establishment?.demoData ? Object.entries(ui?.establishment?.demoData).map(([key, value]) => key != "onboarding" ? value === true ? `${key},` : "": "").join("") : ""}`});
    }
  }, [ui.user]);

  return (
    <UIContext.Provider value={[ui, dispatch]}>
      {children}
    </UIContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default UIContext;
export { Provider };