
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
    Card,
    Separator,
    Modal, ModalHeader, ModalBody,
    Icon,
    Button
} from "lib/components";
// import { Switch, FormControlLabel, styled } from "@material-ui/core";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "hooks/ui.hook";
import { ModalFooter } from "reactstrap";
import { Gift, Tool, Calendar } from "react-feather";
import { colorByCateLessClear } from "../Scss/colors";
import ConciergeBellIcon from "../../../../lib/assets/svg/ConciergeBell.svg";
import Utensils from "../../../../lib/assets/svg/Utensils.svg";
import { getUserById, updateUserById } from "services/ressources/user";
import { toast } from "react-toastify";
import Switch from "react-switch";
import notificationsStore from "store/notificationsStore";

const ControlNotifModal = (props) => {
    const {
        isOpen,
        onClose
    } = props;
    const [userData,setUserData] = useState({});
    const [notifsHandleCurrent, setnotifsHandleCurrent] = useState({
        all: true,
        animation: {add: true,delete: true,update: true,},
        service: {add: true,delete: true,update: true,},
        menu: {add: true,delete: true,update: true,},
        intervention: {add: true,delete: true,update: true,},
        birthday: true
    });

    const [ui] = useUI();
    const setNotificationRequired = notificationsStore((state) => state.setNotificationRequired);
    const notificationRequired = notificationsStore((state) => state.notificationRequired);


useEffect(()=>{
 const fetchUserInfo = async () => {
            const user = await getUserById({ id: ui.user.uid });
            setUserData(user);
           
        };
        fetchUserInfo();
},[]);
    useEffect(() => {
        if (Object.keys(notificationRequired).length > 0) {
            setnotifsHandleCurrent(notificationRequired);
        }
       
    }, [notificationRequired]);


    const handleChangeNotif = (category, action) => {
        setnotifsHandleCurrent((prev) => {
            if (category === "all") {
                const updatednotifsHandleCurrent = { all: !prev.all };
                Object.keys(prev).forEach((key) => {
                    if (key !== "all") {
                        if (typeof prev[key] === "object") {
                            updatednotifsHandleCurrent[key] = Object.keys(prev[key]).reduce((acc, innerKey) => {
                                acc[innerKey] = !prev.all;
                                return acc;
                            }, {});
                        } else {
                            updatednotifsHandleCurrent[key] = !prev.all;
                        }
                    }
                });
    
                return updatednotifsHandleCurrent;
            }
            if (action) {
                return {
                    ...prev,
                    [category]: {
                        ...prev[category],
                        [action]: !prev[category][action],
                    },
                };
            }
            return {
                ...prev,
                [category]: !prev[category],
            };
        });
    };

    const _submit = async (e) => {
        try {
            const updatedData = {
                ...userData,
                notifSaving: notifsHandleCurrent
            };
            await updateUserById({ id: userData.uid, data: updatedData });
            setNotificationRequired(notifsHandleCurrent);
            toast.success("Préférences Enregistrés");
        } catch (error) {
            toast.warning("Préférences Enregistrés");

            console.error("Error updating user:", error);
        }
        onClose();
    };
    const onReturn = ()=>{
        onClose();
        if (Object.keys(notificationRequired).length > 0) {
            setnotifsHandleCurrent(notificationRequired);
        }
    }

    return (
        <Modal size="md" isOpen={isOpen} toggle={onReturn} >
            <ModalHeader>Gérer les notifications</ModalHeader>
            <ModalBody>
                <h5>Recevoir les notifications pour: </h5>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <h6>{"Toutes les categories ("}</h6>
                        {ui.establishment.features.includes("reservationPlanning") || ui.establishment.features.includes("animationPlanning")  ? <>
                            <Calendar size={16} style={{ marginBottom: "2px", color: "#300438" }}></Calendar>
                            <h6>{","}</h6></>
                        :null}
                        {ui.establishment.features.includes("services") ? <> 
                            <img src ={ConciergeBellIcon} style={{ width: "17px", height: "17px", stroke: "#300438" }} ></img>
                            <h6>{","}</h6></>
                        :null}

                        {ui.establishment.features.includes("menuPlanning") || ui.establishment.features.includes("reservationMenu") ? <>
                            <img src ={Utensils} style={{ width: "17px", height: "17px", stroke: "#300438" }} ></img>
                            <h6>{","}</h6></>
                        :null}
                        <Icon icon={Gift} size={15}></Icon>
                        {ui.establishment.features.includes("interventionsRequest") ?<><h6>{","}</h6>
                            <Icon icon={Tool} size={16}></Icon></>:null}
                        <h6>{")"}</h6>
                    </div>
                    <Switch checked={notifsHandleCurrent.all} onChange={()=>{handleChangeNotif("all");}} onHandleColor="#6f0582" offHandleColor="#6f0582" onColor="#a133b5" width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>
                </div>


                {ui.establishment.features.includes("reservationPlanning") || ui.establishment.features.includes("animationPlanning")  ? <Card style={{ padding: "5px", paddingLeft: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ padding: "2px", paddingInline: "5px", borderRadius: "5px", justifyContent: "center", display: "flex", alignItems: "center" }}>
                            <Calendar size={16} style={{ marginBottom: "2px", color: "#300438" }}></Calendar>
                            <span style={{ marginLeft: "10px" }}>Animation</span>
                        </div>
                    </div>
                    <Separator style={{ margin: "0px" }}></Separator>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: "20%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Nouvelle participation</span>
                            <Switch checked={notifsHandleCurrent.animation.add} onChange={() => { handleChangeNotif("animation","add"); }} onHandleColor={colorByCateLessClear.animation.primary} offHandleColor={colorByCateLessClear.animation.primary} onColor={colorByCateLessClear.animation.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.animation.add} onChange={() => { handleChangeNotif("animation","add"); }} colortrack={colorByCateLessClear.animation.secondary} colorthumb={colorByCateLessClear.animation.primary} /> */}
                        </div>
                        <Separator style={{ margin: "0px", width: "90%", alignSelf: "center" }}></Separator>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Annulation</span>
                            <Switch checked={notifsHandleCurrent.animation.delete} onChange={() => { handleChangeNotif("animation","delete"); }} onHandleColor={colorByCateLessClear.animation.primary} offHandleColor={colorByCateLessClear.animation.primary} onColor={colorByCateLessClear.animation.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.animation.delete} onChange={() => { handleChangeNotif("animation","delete"); }} colortrack={colorByCateLessClear.animation.secondary} colorthumb={colorByCateLessClear.animation.primary} /> */}
                        </div>                       
                    </div>
                </Card>:null}
                {ui.establishment.features.includes("services") ?<Card style={{ padding: "5px", paddingLeft: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ padding: "2px", paddingInline: "5px", borderRadius: "5px", justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
                            {/* <ConciergeBellIcon style={{ width: "17px", height: "17px", color: "#300438" }} /> */}
                            <img src ={ConciergeBellIcon} stroke="blue" style={{ width: "17px", height: "17px", stroke: "#300438" }} ></img>

                            <span style={{ marginLeft: "10px" }}>Service</span>

                        </div>
                    </div>
                    <Separator style={{ margin: "0px" }}></Separator>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: "20%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Nouvelle réservation</span>
                            <Switch checked={notifsHandleCurrent.service.add} onChange={() => { handleChangeNotif("service","add");}} onHandleColor={colorByCateLessClear.service.primary} offHandleColor={colorByCateLessClear.service.primary} onColor={colorByCateLessClear.service.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.service.add} onChange={() => { handleChangeNotif("service","add");}} colortrack={colorByCateLessClear.service.secondary} colorthumb={colorByCateLessClear.service.primary} /> */}
                        </div>
                        <Separator style={{ margin: "0px", width: "90%", alignSelf: "center" }}></Separator>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" ,marginBlock:"5px"}}>
                            <span style={{ fontSize: "15px" }}>Suppression</span>
                            <Switch checked={notifsHandleCurrent.service.delete} onChange={() => { handleChangeNotif("service","delete"); }} onHandleColor={colorByCateLessClear.service.primary} offHandleColor={colorByCateLessClear.service.primary} onColor={colorByCateLessClear.service.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.service.delete} onChange={() => { handleChangeNotif("service","delete");}} colortrack={colorByCateLessClear.service.secondary} colorthumb={colorByCateLessClear.service.primary} /> */}
                        </div>
                        <Separator style={{ margin: "0px", width: "90%", alignSelf: "center" }}></Separator>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Modification</span>
                            <Switch checked={notifsHandleCurrent.service.update} onChange={() => { handleChangeNotif("service","update"); }} onHandleColor={colorByCateLessClear.service.primary} offHandleColor={colorByCateLessClear.service.primary} onColor={colorByCateLessClear.service.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.service.update} onChange={() => { handleChangeNotif("service","update");}} colortrack={colorByCateLessClear.service.secondary} colorthumb={colorByCateLessClear.service.primary} /> */}
                        </div>
                    </div>
                </Card>:null}
                {ui.establishment.features.includes("menuPlanning") || ui.establishment.features.includes("reservationMenu") ? <Card style={{ padding: "5px", paddingLeft: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ padding: "2px", paddingInline: "5px", borderRadius: "5px", justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
                            <img src ={Utensils}  style={{ width: "17px", height: "17px", color: "#300438" }} ></img>

                            <span style={{ marginLeft: "10px" }}>Menu</span>

                        </div>
                    </div>
                    <Separator style={{ margin: "0px" }}></Separator>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: "20%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Nouvelle réservation</span>
                            <Switch checked={notifsHandleCurrent.menu.add} onChange={() => { handleChangeNotif("menu","add"); }} onHandleColor={colorByCateLessClear.menu.primary} offHandleColor={colorByCateLessClear.menu.primary} onColor={colorByCateLessClear.menu.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.menu.add} onChange={() => { handleChangeNotif("menu","add");}} colortrack={colorByCateLessClear.menu.secondary} colorthumb={colorByCateLessClear.menu.primary} /> */}
                        </div>
                        <Separator style={{ margin: "0px", width: "90%", alignSelf: "center" }}></Separator>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Suppression</span>
                            <Switch checked={notifsHandleCurrent.menu.delete} onChange={() => { handleChangeNotif("menu","delete"); }} onHandleColor={colorByCateLessClear.menu.primary} offHandleColor={colorByCateLessClear.menu.primary} onColor={colorByCateLessClear.menu.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>
                            {/* <MaterialUISwitch checked={notifsHandleCurrent.menu.delete} onChange={() => { handleChangeNotif("menu","delete");}} colortrack={colorByCateLessClear.menu.secondary} colorthumb={colorByCateLessClear.menu.primary} /> */}
                        </div>
                        <Separator style={{ margin: "0px", width: "90%", alignSelf: "center" }}></Separator>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Modification</span>
                            <Switch checked={notifsHandleCurrent.menu.update} onChange={() => { handleChangeNotif("menu","update"); }} onHandleColor={colorByCateLessClear.menu.primary} offHandleColor={colorByCateLessClear.menu.primary} onColor={colorByCateLessClear.menu.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.menu.update} onChange={() => { handleChangeNotif("menu","update");}} colortrack={colorByCateLessClear.menu.secondary} colorthumb={colorByCateLessClear.menu.primary} /> */}
                        </div>
                    </div>
                </Card>:null}
                <Card style={{ padding: "5px", paddingLeft: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ padding: "2px", paddingInline: "5px", borderRadius: "5px", justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
                            <Icon icon={Gift} size={15}></Icon>
                            <span style={{ marginLeft: "10px" }}>Anniversaire</span>

                        </div>
                        <Switch checked={notifsHandleCurrent.birthday} onChange={() => { handleChangeNotif("birthday"); }} onHandleColor={colorByCateLessClear.birthday.primary} offHandleColor={colorByCateLessClear.birthday.primary} onColor={colorByCateLessClear.birthday.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                        {/* <MaterialUISwitch checked={notifsHandleCurrent.birthday} onChange={() => { handleChangeNotif("birthday");}} colortrack={colorByCateLessClear.birthday.secondary} colorthumb={colorByCateLessClear.birthday.primary} /> */}
                    </div>
                </Card>
                {ui.establishment.features.includes("interventionsRequest") ? <Card style={{ padding: "5px", paddingLeft: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ padding: "2px", paddingInline: "5px", borderRadius: "5px", justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
                            <Icon icon={Tool} size={16}></Icon>
                            <span style={{ marginLeft: "10px" }}>Intervention</span>

                        </div>
                    </div>
                    <Separator style={{ margin: "0px" }}></Separator>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: "20%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Nouvelle demande</span>
                            <Switch checked={notifsHandleCurrent.intervention.add} onChange={() => { handleChangeNotif("intervention","add"); }} onHandleColor={colorByCateLessClear.intervention.primary} offHandleColor={colorByCateLessClear.intervention.primary} onColor={colorByCateLessClear.intervention.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>

                            {/* <MaterialUISwitch checked={notifsHandleCurrent.intervention.add} onChange={() => { handleChangeNotif("intervention","add");}} colortrack={colorByCateLessClear.intervention.secondary} colorthumb={colorByCateLessClear.intervention.primary} /> */}
                        </div>
                        <Separator style={{ margin: "0px", width: "90%", alignSelf: "center" }}></Separator>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Suppression</span>
                            <Switch checked={notifsHandleCurrent.intervention.delete} onChange={() => { handleChangeNotif("intervention","delete"); }} onHandleColor={colorByCateLessClear.intervention.primary} offHandleColor={colorByCateLessClear.intervention.primary} onColor={colorByCateLessClear.intervention.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>
                            {/* <MaterialUISwitch checked={notifsHandleCurrent.intervention.delete} onChange={() => { handleChangeNotif("intervention","delete");}} colortrack={colorByCateLessClear.intervention.secondary} colorthumb={colorByCateLessClear.intervention.primary} /> */}
                        </div>
                        <Separator style={{ margin: "0px", width: "90%", alignSelf: "center" }}></Separator>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",marginBlock:"5px" }}>
                            <span style={{ fontSize: "15px" }}>Modification</span>
                            <Switch checked={notifsHandleCurrent.intervention.update} onChange={() => { handleChangeNotif("intervention","update"); }} onHandleColor={colorByCateLessClear.intervention.primary} offHandleColor={colorByCateLessClear.intervention.primary} onColor={colorByCateLessClear.intervention.secondary} width={30} height={15} uncheckedIcon={false} checkedIcon={false} handleDiameter={20}></Switch>
                            {/* <MaterialUISwitch checked={notifsHandleCurrent.intervention.update} onChange={() => { handleChangeNotif("intervention","update");}} colortrack={colorByCateLessClear.intervention.secondary} colorthumb={colorByCateLessClear.intervention.primary} /> */}
                        </div>
                    </div>
                </Card>:null}


            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} color="secondary">Annuler</Button>
                <Button onClick={_submit}>Enregistrer</Button>
            </ModalFooter>
        </Modal>
    );
};
ControlNotifModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,

};


const MaterialUISwitch = <></>;
// styled(Switch)(({ colortrack, colorthumb }) => ({
//     "& .MuiSwitch-switchBase": {

//         "&.Mui-checked": {

//             "& + .MuiSwitch-track": {
//                 backgroundColor: colortrack ? colortrack : "#ad3cba",
//             },
//         },
//     },
//     "& .MuiSwitch-thumb": {
//         backgroundColor: colorthumb ? colorthumb : "#841494",
//         position: "relative",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         // "&::before": {
//         //     content: "''",
//         //     position: "absolute",
//         //     width: "100%",
//         //     height: "100%",
//         //     left: 0,
//         //     top: 0,
//         //     backgroundRepeat: "no-repeat",
//         //     backgroundPosition: "center",
//         //     display: "flex",
//         //     alignItems: "center",
//         //     justifyContent: "center",
//         //     color: "#fff",
//         //     fontSize: "20px",
//         // },
//     },
//     "& .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: "#b3b3b3",
//         borderRadius: 20 / 2,
//         height: 14,
//     },

// }));

export default ControlNotifModal;