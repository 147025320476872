import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import styles from "../assets/scss/component/demoModal.module.scss"

import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "@/hooks/ui.hook";

import MajorLogo from "assets/images/jabiLogo/Logo_MajorByJabi.png";

import ImageAnimation from "assets/images/demo/animation.png";
import ImageAteliers from "assets/images/demo/ateliers.png";
import ImageMenu from "assets/images/demo/menu.png";
import ImageReservationAnimation from "assets/images/demo/reservationanimation.png";
import ImageStatistiques from "assets/images/demo/statistics.png";
import ImageUtilisateurs from "assets/images/demo/users.png";
import ImageNews from "assets/images/demo/news.png";
import ImageGazette from "assets/images/demo/gazette.png";

const firestore = firebase.firestore;

const demoData = {
    "animation": {
        title: "Présentation : Animations",
        text: `L'onglet planning d'animation permet de planifier vos activités et de les partager sur l'application MajorConnect. Avec une vue semaine ou mois, créez vos catégories, ajoutez les événements et cliquez sur "Impression semaine" pour obtenir un PDF avec une mise en page automatique.`,
        image: ImageAnimation,
    },
    "menu": {
        title: "Présentation : Menus",
        text: `Remplissez les champs pour saisir vos menus alimentaires de la semaine. Publiez-les et téléchargez le menu hebdomadaire format papier avec le bouton “Imprimer” ou le menu du jour via le pictogramme imprimante.`,
        image: ImageMenu,
    },
    "news": {
        title: "Présentation : Actualités",
        text: `L'onglet actualités centralise tous les évènements que vous souhaitez partager sur l'application MajorConnect. Créez une actualité à tout moment pour informer vos résidents et leurs familles. Vos articles sont consultables sur l'application, mais aussi réutilisables pour la gazette.`,
        image: ImageNews,
    },
    "gazette": {
        title: "Présentation : Gazettes",
        text: `L'onglet gazette vous permet de créer simplement et rapidement le journal de votre résidence. Ce journal composé de vos précédentes actualités ou tout autre contenu sera consultable sur tous vos supports (les applications, la borne interactive ou le support papier).`,
        image: ImageGazette,
    },
    "reservationanimation": {
        title: "Présentation : Réservations d'animations",
        text: `L'onglet de réservations d’animations récapitule les choix d'animation des résidents sur l'application MajorConnect ou la borne interactive. Il offre ainsi une vue détaillée des animations réservées. Les exports des réservations par semaine, par animations, ou par résidents sont disponibles pour faciliter votre suivi.`,
        image: ImageReservationAnimation,
    },
    "users": {
        title: "Présentation : Utilisateurs",
        text: `Dans l'onglet utilisateurs, vous pouvez créer ou supprimer un compte pour vos résidents, collaborateurs, familles ou simples invités. C'est également ici que vous pouvez consulter toutes les informations concernant un compte associé à votre établissement.`,
        image: ImageUtilisateurs,
    },
    "statistics": {
        title: "Présentation : Statistiques",
        text: `Les statistiques sont des indicateurs très précis pour le personnel de l’établissement. Retrouvez des informations clés pour votre gestion des services sur vos animations, vos menus ou encore vos réservations. Les graphiques et taux d’évolution vous permettront de mieux les comprendre.`,
        image: ImageStatistiques,
    },
    "ateliers": {
        title: "Présentation: Ateliers",
        text: `Les ateliers proposés sont des ateliers stimulants développés par des experts de santé dans le but de favoriser l'autonomie des seniors. Classés selon les fonctions cognitives visées, chaque atelier dispose d'un mode présentation à projeter sur grand écran lors de vos animations ainsi qu'un livret d'instructions à destination de l'animateur, à imprimer.`,
        image: ImageAteliers,
    },
}

const DemoModal = (props) => {
    const {
        type,
    } = props;
    const [ui] = useUI();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (type) {
            firestore().collection("establishments").doc(ui?.user?.establishment).get().then(_doc => {

                const _data = _doc.data();

                const demoData = _data?.demoData;

                if (!demoData || !demoData[type]) {
                    setIsOpen(true);
                }
            });
        }
    }, [type]);

    const onClose = () => {
        setIsOpen(false);
        firestore().collection("establishments").doc(ui?.user?.establishment).update({ [`demoData.${type}`]: true });
    };
    
    const {title, text, image} = demoData[type];
    
    return (
        <Modal isOpen={isOpen} toggle={onclose} size="xl" className={styles.modal}>
            <div className={styles.modalContainer}>
                <img src={MajorLogo} className={styles.logo}></img>


                <div className={styles.content}>

                    <div className={styles.leftContainer}>

                        <div className={styles.textContainer}>
                            <h5 className={styles.title}>{title}</h5>

                            <p className={styles.text}>
                                {text}
                            </p>

                            <Button onClick={onClose}>
                                Commencer à utiliser cette fonctionnalité
                            </Button>
                        </div>

                    </div>

                    <div className={styles.rightContainer}>
                        <img src={image} className={styles.image}></img>
                    </div>
                </div>
            </div>

        </Modal>
    );
};

DemoModal.propTypes = {
    type: PropTypes.string.isRequired,
};
export default DemoModal;