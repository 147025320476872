import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import { useFirebaseDocumentOnce } from "lib/hooks/firebase";
import { useParams } from "react-router-dom";
import {
  Card,
  FileUpload,
  Container,
  Row,
  Col,
  Input,
  MultiSelect,
  Button,
  FormGroup,
  Spinner,
  Select
} from "lib/components";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import featuresOptions from "enums/features";
import useUI from "hooks/ui.hook";
import { toast } from "react-toastify";
import { getEstablishmentById, updateEstablishmentById } from "services/ressources/establishment";

const selectOptions = [{ value: true, label: "Ouvert" }, { value: false, label: "Fermé" }];

const firestore = firebase.firestore;

const Establishment = () => {
  const [ui, dispatch] = useUI();
  const params = useParams();

  const [establishment, setEstablishment] = useState({});
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const [isRestaurantOpen, setIsRestaurantOpen] = useState(true);

  useEffect(() => {
    (async () => {
      setEstablishment(await getEstablishmentById({ id: params.id }));
    })();
  }, []);

  useEffect(() => {
    if (!establishment) return;
    if (establishment.id) setId(establishment.id);
    if (establishment.name) setName(establishment.name);
    if (establishment.isRestaurantOpen !== undefined) setIsRestaurantOpen(establishment.isRestaurantOpen);

    setLoading(false);
  }, [establishment]);

  const _save = async () => {
    try {
      setLoading(true);

      const object = {};
      if (ui.groupement.specific === "ovelia" && isRestaurantOpen !== null) object.isRestaurantOpen = isRestaurantOpen;

      if(Object.keys(object).length > 0)
        await updateEstablishmentById({ id: params.id, data: object });

      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };
  const _takeControl = async () => {
    try {
      let _est = await firestore().collection("establishments").doc(params.id).get();
      let _user = { ...ui.user };

      _est = { uid: _est.id, ..._est.data() };

      _user.role = "owner";
      _user.establishment = params.id;
      _user.groupementAdmin = true;
      dispatch({ type: "takeControl", user: _user, establishment: _est, groupement: ui.user.groupement });
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) return <Spinner />;
  return (
    <Container>
      <Row>
        <Col>
          <Link to="/dashboard/establishments">
            <ArrowLeft color="#300438" size={42} />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <Card>
            <FormGroup>
              <Input
                type="text"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                label="id"
                value={id}
                onChange={(e) => setId(e.target.value)}
                disabled
              />
            </FormGroup>
            {ui?.groupement?.specific === "ovelia" ?
              <FormGroup>
                
                  label="Ouverture du restaurant le soir"
                  value={selectOptions.find(e => e.value === isRestaurantOpen)}
                  options={selectOptions}
                  onChange={(e) => setIsRestaurantOpen(e.value)}
                />
              </FormGroup>
              : null}

            <FormGroup style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button color="primary" onClick={_save}>
                Enregistrer
              </Button>
            </FormGroup>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Button color="danger" onClick={_takeControl}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Accéder à la vue de l'établissement
            </Button>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Establishment;
