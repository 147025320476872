import React, {} from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styles from "../../assets/scss/component/fileUpload.module.scss";
import {Download, Trash, Upload} from "react-feather";
import firebase from "firebase/app";
import 'firebase/storage';
import useUI from "hooks/ui.hook";

const storage = firebase.storage;

const FileUpload = (props) => {
  const {
    className,
    style,
    onChange,
    value,
    accept,
    label,
    firebaseParentRef,
    disabled
  } = props;
  const [ui] = useUI();

  const onAdd = async (e) => {
    e.preventDefault();
    if (disabled) return;
    try {
      let _parentRef = firebaseParentRef ? firebaseParentRef : `/uploads/${ui.user.establishment}`;
      await storage().ref(`${_parentRef}/${e.target.files[0].name}`).put(e.target.files[0]);
      let url = await storage().ref(_parentRef).child(e.target.files[0].name).getDownloadURL();
      onChange(url);
    } catch (e) {
      console.error(e.message);
      toast.error("Une erreur est survenue");
    }
  };

  const onRemove = async () => {
    if (disabled) return;
    onChange(null);
  };

  return (
    <div className={`${styles.fileUpload} ${disabled ? styles.disabled : ""}`}>
      {label ?
        <label>
          {label}:
        </label>
        : ""}
      <div className={`${styles.container} ${value ? styles.filled : ""}`}>
        <label className={styles.label} htmlFor="file">{value ?? "Choisir un fichier"}</label>
        <label className={styles.uploadIcon} htmlFor="file">
          <Upload size={24} />
        </label>
        {value && <>
          <a href={value} target="_blank" className={styles.downloadIcon} rel="noreferrer">
            <Download size={24} />
          </a>
          <div className={styles.trashIcon} onClick={onRemove}>
            <Trash color="#ff5370" size={24} />
          </div>
        </>}
      </div>
      {!disabled ? <input 
        id="file"
        type="file"
        placeholder="Sélectionner votre image"
        onChange={onAdd}
        style={{visibility: "hidden"}}
        accept={accept}
      /> : null}
    </div>
  );
};


FileUpload.propTypes = {
  className : PropTypes.any,
  style : PropTypes.any,
  onChange : PropTypes.func,
  value : PropTypes.string,
  accept : PropTypes.any,
  label : PropTypes.string,
  firebaseParentRef : PropTypes.any,
  disabled : PropTypes.bool,
};


export default FileUpload;