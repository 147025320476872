import React from "react";
import {ModalBody as BootstrapModalBody} from "reactstrap";

const ModalBody = (props) => {
  const {children} = props;

  return (
    <BootstrapModalBody {...props}>
      {children}
    </BootstrapModalBody>
  );
};

ModalBody.propTypes = BootstrapModalBody.propTypes;

export default ModalBody;