import React from "react";
import PropTypes from "prop-types";

import styles from "../../assets/scss/component/card.module.scss";

const CardHeader = (props) => {
  return (
    <div {...props} className={`${styles.cardHeader}
        ${props.className ?? ""}
        ${props.border !== false && styles.border}`}>
      {props.children}
    </div>
  );
};

CardHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  border : PropTypes.bool
};

export default CardHeader;