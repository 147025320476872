import React, { useState } from "react";

import { useMenuReservation } from "../../../../router_context";
import useUI from "@/hooks/ui.hook";
import { sortRepas } from "@/pages/Major/Menu/helpers/operations";
import moment from "moment";
import ExportDay from "../ExportDay";
import { findSubscriptionForDate } from "@/helpers/subscription";

// Excel Base Styles :
const baseBorder = {
  top: { style: "thin", color: { rgb: "000000" } },
  bottom: { style: "thin", color: { rgb: "000000" } },
  left: { style: "thin", color: { rgb: "000000" } },
  right: { style: "thin", color: { rgb: "000000" } },
};

const ExportReservations = ({ modalExportOpen, setModalExportOpen }) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();

  const template = ui?.establishment?.template;

  const sortedRepas = sortRepas(template, Object.keys(template));

  const isGuest = (userId) => {
    return ctx.usersUnfiltered[userId].role === "guest"
      ? ctx.usersUnfiltered[userId]
      : null;
  };

  const generateExportData = async (date) => {
    const start = moment(date).startOf("day").clone().toDate();
    const end = moment(date).clone().endOf("day").toDate();
    const periodData = await ctx.getData(start, end);

    const formated = moment(date).format("YYYY-MM-DD");

    const _obj = {};

    if (periodData.data[formated] && periodData.data[formated].reservation) {
      Object.entries(periodData.data[formated].reservation).forEach(
        ([userId, values]) => {
          if (values) {
            Object.entries(values).forEach(([repas, reservations]) => {
              if (
                reservations &&
                reservations[0] &&
                reservations[0].status !== "canceled"
              ) {
                if (!_obj[repas]) _obj[repas] = {};

                if (!_obj[repas][userId]) _obj[repas][userId] = [];

                // maintenant on ne prends que le premier élément :
                const _r = reservations[0];

                _obj[repas][userId] = _r ?? {};
              }
            });
          }
        }
      );
    }

    const data = {};

    // LES TOTAUX

    const totalHeaders = [
      {
        v: "Total Réservations",
        s: {
          font: { bold: true },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          border: { bottom: { style: "thin", color: "000000" } },
        },
      },
    ];
    sortRepas(template, Object.keys(_obj)).forEach((_repas) => {
      totalHeaders.push({
        v: _repas,
        s: {
          font: { bold: true },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          border: { bottom: { style: "thin", color: "000000" } },
        },
      });
    });
    if (!data["Totaux"]) data["Totaux"] = [totalHeaders];

    const totalData = {
      Restaurant: {
        Déjeuner: {
          total: 0,
          duo: 0,
          trio: 0,
        },
        Dîner: {
          total: 0,
          duo: 0,
          tri: 0,
        },
      },
      Portage: {
        Déjeuner: {
          total: 0,
          duo: 0,
          trio: 0,
        },
        Dîner: {
          total: 0,
          duo: 0,
          trio: 0,
        },
      },
    };

    // fill with init data.
    Object.keys(totalData).forEach((element) => {
      sortRepas(template, Object.keys(template)).forEach((repas) => {
        totalData[element][repas] = {
          total: 0,
          duo: 0,
          trio: 0,
        };
      });
    });

    sortRepas(template, Object.keys(_obj)).forEach((_repas) => {
      Object.keys(periodData.data[formated].reservation).forEach((resa) => {
        const _resa = periodData.data[formated].reservation[resa];
        if (
          _resa[_repas] &&
          _resa[_repas].length > 0 &&
          _resa[_repas][0].status !== "canceled"
        ) {
          _resa[_repas].forEach((_r) => {
            let mode = null;

            if (_r.homeDelivery) {
              totalData["Portage"][_repas]["total"] += 1;
              mode = "Portage";
            } else {
              totalData["Restaurant"][_repas]["total"] += 1;
              mode = "Restaurant";
            }

            // Si réservation vide
            if (
              Object.keys(_r).filter(
                (element) =>
                  template[_repas].categories.includes(element) && _r[element]
              ).length === 0
            ) {
              // alors on fait en fonction des abonnements :
              const subscription = findSubscriptionForDate(
                ctx.users[resa]?.subscriptions,
                date
              );

              const _subscription = subscription?.subscription.split(" ")[1];

              if (_subscription && _subscription.includes("Duo"))
                totalData[mode][_repas]["duo"] += 1;
              if (_subscription && _subscription.includes("Trio"))
                totalData[mode][_repas]["trio"] += 1;
            } else {
              let res = Object.keys(_r).filter(
                (value) =>
                  ["entrée", "plat", "dessert"].includes(value) && _r[value]
              ).length;
              if (res === 2) totalData[mode][_repas]["duo"] += 1;
              if (res === 3) totalData[mode][_repas]["trio"] += 1;
            }
          });
        }
      });
    });

    Object.keys(totalData).forEach((mode) => {
      data["Totaux"].push(["", "", ""]);
      data["Totaux"].push([
        {
          v: `Total ${mode}`,
          s: { border: { bottom: { style: "thin", color: "000000" } } },
        },
        ...Object.keys(totalData[mode]).map((_repas) => ({
          v: totalData[mode][_repas]["total"],
          s: {
            font: { bold: true },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
            border: { bottom: { style: "thin", color: "000000" } },
          },
        })),
      ]);

      data["Totaux"].push([
        "Duo",
        ...Object.keys(totalData[mode]).map((_repas) => ({
          v: totalData[mode][_repas]["duo"],
          s: {
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        })),
      ]);

      data["Totaux"].push([
        "Trio",
        ...Object.keys(totalData[mode]).map((_repas) => ({
          v: totalData[mode][_repas]["trio"],
          s: {
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        })),
      ]);
    });

    // DETAILS PORTAGE

    sortRepas(template, Object.keys(_obj)).forEach((_repas) => {
      let _repasData = [];
      if (!data[_repas])
        data[_repas] = [
          [
            {
              v: "Nom",
              s: {
                font: { bold: true },
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                  wrapText: true,
                },
              },
            },
            {
              v: "Prénom",
              s: {
                font: { bold: true },
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                  wrapText: true,
                },
              },
            },
            {
              v: "Appartement",
              s: {
                font: { bold: true },
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                  wrapText: true,
                },
              },
            },
            {
              v: "Abonnement",
              s: {
                font: { bold: true },
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                  wrapText: true,
                },
              },
            },
            {
              v: "Formule",
              s: {
                font: { bold: true },
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                  wrapText: true,
                },
              },
            },
            ...[...template[_repas].categories, "Suppléments"].map((_categ) => {
              return {
                v: _categ,
                s: {
                  font: { bold: true },
                  alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true,
                  },
                },
              };
            }),
          ],
        ];

      Object.entries(_obj[_repas]).forEach(([userId, _resa], idx) => {
        const _localData = [];
        const _guest = isGuest(userId);
        const subscription = findSubscriptionForDate(
          ctx.users[userId]?.subscriptions,
          date
        );
        const _subscription =
          subscription?.subscription.split(" ")[1] ?? "Unité";

        //start
        let _line = [
          {
            v: ctx.users[userId].name ?? "",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
              },
            },
          },
          {
            v: ctx.users[userId].surname ?? "",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
              },
            },
          },
          {
            v:
              _guest && _guest.linkedTo
                ? ctx.users[_guest.linkedTo]?.room ?? ""
                : ctx.users[userId].room ?? "",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
              },
            },
          },
          {
            v: _subscription ?? "Unité",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
              },
            },
          },
          {
            v: "",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
              },
            },
          },
        ];

        if (Object.entries(_resa).length === 0) {
          // Cas de réservation sans désigner des plats spécifiques = Plat du jour
          template[_repas].categories.forEach((_categ) => {
            if (_categ === "dessert" && _subscription === "Duo") {
              _line.push({
                v: "",
                s: {
                  alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true,
                  },
                },
              });
            } else {
              _line.push({
                v:
                  Object.values(
                    periodData.data[formated][_repas][_categ] ?? {}
                  ).find(
                    (_plate) => _plate.origin.toLowerCase() === "menu du jour"
                  )?.name ?? "",
                s: {
                  alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true,
                  },
                },
              });
            }
          });
        } else {
          // Cas où on spécifie des plats
          template[_repas].categories.forEach((_categ) => {
            const _plateResa = _resa[_categ];

            if (_plateResa) {
              if (Array.isArray(_plateResa)) {
                const plateNames = _plateResa.map(
                  (_plate) =>
                    periodData.data[formated][_repas][_categ][_plate]?.name ??
                    ""
                );

                _line.push({
                  v: plateNames.join(", "),
                  s: {
                    alignment: {
                      vertical: "center",
                      horizontal: "center",
                      wrapText: true,
                    },
                  },
                });
              } else {
                const plate =
                  periodData.data[formated][_repas][_categ][_plateResa] ?? "";
                _line.push({
                  v: plate.name,
                  s: {
                    alignment: {
                      vertical: "center",
                      horizontal: "center",
                      wrapText: true,
                    },
                  },
                });
              }
            } else {
              _line.push({
                v: "",
                s: {
                  alignment: {
                    vertical: "center",
                    horizontal: "center",
                    wrapText: true,
                  },
                },
              });
            }
          });
        }

        // ajout des suppléments :

        const _supplements = _resa["suppléments restaurant"];

        if (_supplements) {
          _line.push({
            v: _supplements.map((_s) => _s.name + " (x " + _s.number + ")").join(", ") ?? "",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
              },
            },
          });
        }

        // Calcul de la formule choisie
        let formule = 0;
        _line.forEach((element, idx) => {
          if ([0, 1, 2, 3, 4, 7, 9].includes(idx)) return;
          if (element.v.length > 0) formule += 1;
        });

        _line[4].v = formule === 2 ? "Duo" : formule === 3 ? "Trio" : "";
        _localData.push(_line);

        data[_repas] = [...data[_repas]];
        _repasData.push(_localData);
      });

      // Tri des lignes
      _repasData.sort((a, b) => {
        const vANom = a[0][0].v ?? "";
        const vAPrenom = a[0][1].v ?? "";
        const vBNom = b[0][0].v ?? "";
        const vBPrenom = b[0][1].v ?? "";

        return `${vANom} ${vAPrenom}`.localeCompare(`${vBNom} ${vBPrenom}`);
      });

      // Coloriser une ligne sur deux
      _repasData.forEach((lines, linesIndex) => {
        lines.forEach((line, lineIndex) => {
          line.forEach((cell, cellIndex) => {
            if (linesIndex % 2 === 0) {
              _repasData[linesIndex][lineIndex][cellIndex] = {
                ...cell,
                s: {
                  ...cell.s,
                  fill: {
                    fgColor: {
                      rgb: "e6e6e6",
                    },
                  },
                },
              };
            }
          });
        });
        return lines;
      });
      data[_repas] = [...data[_repas], ..._repasData.flat()];
    });

    return data;
  };

  return (
    <>
      {modalExportOpen ? (
        <ExportDay
          onClose={() => setModalExportOpen(false)}
          dataGeneration={generateExportData}
          infos={{
            title: "Export des réservations",
            type: "portage",
            date: ctx?.selectedWeek,
          }}
        />
      ) : null}
    </>
  );
};

export default ExportReservations;
