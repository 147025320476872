import React, { useState } from "react";

import { useMenuReservation } from "../../../../router_context";
import useUI from "@/hooks/ui.hook";
import { sortRepas } from "@/pages/Major/Menu/helpers/operations";
import ExportDay from "../ExportDay";
import moment from "moment";
import { findSubscriptionForDate } from "@/helpers/subscription";
import { toast } from "react-toastify";

const ExportDaily = ({ modalExportOpen, setModalExportOpen }) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();

  const template = ui?.establishment?.template;

  const sortedRepas = sortRepas(template, Object.keys(template));

  const isGuest = (userId) => {
    return ctx?.usersUnfiltered[userId]?.role === "guest"
      ? ctx.usersUnfiltered[userId]
      : null;
  };


  const generateExportData = async (date) => {
    const start = moment(date).startOf("day").clone().toDate();
    const end = moment(date).clone().endOf("day").toDate();
    const periodData = await ctx.getData(start, end);

    const formated = moment(date).format("YYYY-MM-DD");

    const _obj = {};
    const _obj2 = {};

    if (periodData.data[formated] && periodData.data[formated].reservation) {
      Object.entries(periodData.data[formated].reservation).forEach(([userId, values]) => {
        if (values) {
          Object.entries(values).forEach(([repas, reservations]) => {
            // avec portage
            if (reservations && reservations[0] && reservations[0].status !== "canceled" && reservations[0].homeDelivery === true) {
              const _categories = template[repas].categories;

              if (!_obj[repas]) _obj[repas] = {};

              if (!_obj[repas][userId]) _obj[repas][userId] = [];

              _obj[repas][userId] = reservations.map(_r => {
                const _array = [];
                _categories.forEach(_categ => {
                  if (_r[_categ]) _array.push(periodData.data[formated][repas][_categ][_r[_categ]].name);
                });
                if (_r["suppléments restaurant"]) _array.push({ "suppléments restaurant": _r["suppléments restaurant"] });
                return _array;
              });

            }

            // sans portage
            if (reservations && reservations[0] && reservations[0].status !== "canceled" && !reservations[0].homeDelivery) {
              const _categories = template[repas].categories;

              if (!_obj2[repas]) _obj2[repas] = {};

              if (!_obj2[repas][userId]) _obj2[repas][userId] = [];

              _obj2[repas][userId] = reservations.map(_r => {
                const _array = [];
                _categories.forEach(_categ => {
                  if (_r[_categ] && periodData?.data[formated][repas][_categ][_r[_categ]]) {
                    _array.push(periodData.data[formated][repas][_categ][_r[_categ]].name);
                  }
                });
                if (_r["suppléments restaurant"]) _array.push({ "suppléments restaurant": _r["suppléments restaurant"] });
                return _array;
              });
            }
          });
        }

      });
    }


    const data = {};

    // LES TOTAUX
    const totalHeaders = [{ v: moment(date).format("dddd DD MMMM YYYY"), s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } } }];
    const totalSubHeaders = [{ v: "", s: {} }];

    sortRepas(template, Object.keys(template)).forEach(_repas => {
      totalHeaders.push({ v: _repas, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } } });
      totalHeaders.push({ v: "", s: {} });
      totalHeaders.push({ v: "", s: {} });
      totalHeaders.push({ v: "", s: {} });
      totalHeaders.push({ v: "", s: {} });

      totalSubHeaders.push({ v: "total", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "résident", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "collaborateur", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "", s: {} });


    });
    if (!data["Totaux"]) data["Totaux"] = [totalHeaders];
    data["Totaux"].push(totalSubHeaders);

    const totalData = {
      "Total": {
        "Déjeuner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        },
        "Dîner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "allégé": [0, 0, 0, 0],
          "potage": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        }
      },
      "Total Restaurant": {
        "Déjeuner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        },
        "Dîner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "allégé": [0, 0, 0, 0],
          "potage": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        }
      },
      "Total Portage": {
        "Déjeuner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        },
        "Dîner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "allégé": [0, 0, 0, 0],
          "potage": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        }
      },
    };

    // [total, résident, invité, collaborateur]
    const updateTotalData = (totalArray, array, uid, change) => {
      array[0] += change;
      totalArray[0] += change;

      if (ctx?.users[uid] && (!ctx?.users[uid].isDeleted  || (ctx?.users[uid].isDeleted && ctx?.users[uid]?.deletedAt?.toDate() > start))) {

        if (ctx?.users[uid].role === "guest") {
          array[2] += change;
          totalArray[2] += change;
        }

        if (ctx?.users[uid].role === "senior" || ctx?.users[uid].role === "seniorTotem") {
          array[1] += change;
          totalArray[1] += change;
        }

        if (ctx?.users[uid].role === "employee" || ctx?.users[uid].role === "owner") {

          array[3] += change;
          totalArray[3] += change;
        }
      }


    }


    sortedRepas.forEach(_repas => {

      if (periodData?.data[formated] && periodData.data[formated]?.reservation) {
        Object.keys(periodData.data[formated].reservation).forEach(resa => {

          const _resa = periodData.data[formated].reservation[resa];
          if (_resa[_repas] && _resa[_repas].length > 0 && _resa[_repas][0].status !== "canceled") {
            _resa[_repas].forEach((_r, index) => {
              let mode = null;

              if (_r.homeDelivery) {
                updateTotalData(totalData["Total"][_repas]["total"], totalData["Total Portage"][_repas]["total"], resa, 1);
                // totalData["Portage"][_repas]["total"] += 1;
                mode = "Total Portage";

              } else {
                // totalData["Restaurant"][_repas]["total"] += 1;
                updateTotalData(totalData["Total"][_repas]["total"], totalData["Total Restaurant"][_repas]["total"], resa, 1);
                mode = "Total Restaurant";
              }


              if (sortedRepas.indexOf(_repas) === 0) {

                //si c'est un employé et qu'il n'y a seulement un plat de commandé :
                if ((_r["Plat"] || _r["Accompagnement"]) && !_r["Potage"] && !_r["Entrée"] && !_r["Dessert"] /* && !_r["Boisson chaude"] */) {
                  if (ctx?.users[resa]?.role === "employee" || ctx?.users[resa]?.role === "owner") {
                    updateTotalData(totalData["Total"][_repas]["plat seul"], totalData[mode][_repas]["plat seul"], resa, 1);
                  } else {
                    updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1);
                  }
                } else {
                  updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1);
                }

                // totalData[mode][_repas]["complet"] += 1;
              } else {

                // Si réservation vide
                if (Object.keys(_r).filter(element =>
                  template[_repas].categories.includes(element) && _r[element]).length === 0) {

                  // alors on fait en fonction des abonnements : 

                  const subscription = findSubscriptionForDate(ctx.usersUnfiltered[resa]?.subscriptions, date);

                  const _subscription = subscription?.subscription;

                  if (_subscription && _subscription.includes("Dîner")) {
                    if (_subscription.includes("allégé")) {
                      updateTotalData(totalData["Total"][_repas]["allégé"], totalData[mode][_repas]["allégé"], resa, 1);
                      // totalData[mode][_repas]["allégé"] += 1;
                    } else if (_subscription.includes("potage")) {
                      updateTotalData(totalData["Total"][_repas]["potage"], totalData[mode][_repas]["potage"], resa, 1);
                      // totalData[mode][_repas]["potage"] += 1;
                    } else {
                      updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1);
                      // totalData[mode][_repas]["complet"] += 1;
                    }
                  } else
                    updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1);
                  // totalData[mode][_repas]["complet"] += 1;
                }
                // Si plat ou accompagnement
                else if (_r["Plat"] || _r["Accompagnement"]) {

                  //si c'est un employé et qu'il n'y a seulement un plat de commandé :
                  if ((_r["Plat"] || _r["Accompagnement"]) && !_r["Potage"] && !_r["Entrée"] && !_r["Dessert"] /* && !_r["Boisson chaude"] */) {
                    if (ctx?.users[resa].role === "employee" || ctx?.users[resa].role === "owner") {
                      updateTotalData(totalData["Total"][_repas]["plat seul"], totalData[mode][_repas]["plat seul"], resa, 1);
                    } else {
                      updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1);
                    }
                  } else {
                    updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1);
                  }
                  // totalData[mode][_repas]["complet"] += 1;s
                }
                // Si uniquement potage
                else if (_r["Potage"] &&
                  Object.entries(_r).filter(([key, value]) =>
                    key !== "Potage" && template[_repas].categories.includes(key) && value).length === 0) {
                  updateTotalData(totalData["Total"][_repas]["potage"], totalData[mode][_repas]["potage"], resa, 1);
                  // totalData[mode][_repas]["potage"] += 1;
                }
                // Si plusieurs catégories
                else {
                  updateTotalData(totalData["Total"][_repas]["allégé"], totalData[mode][_repas]["allégé"], resa, 1);
                  // totalData[mode][_repas]["allégé"] += 1;s
                }
              }
            });
          }
        });
      }

    });

    Object.keys(totalData).forEach(mode => {
      data["Totaux"].push(["", "", ""]);

      ["total", "complet", "allégé", "potage", "plat seul"].forEach((_type, index) => {
        const line = [];

        let style = {};
        if (index === 0) style = { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } };
        else style = { alignment: { vertical: "center", horizontal: "center", wrapText: true } };

        line.push({ v: `${index === 0 ? mode : _type}`, s: style });

        Object.keys(totalData[mode]).forEach(_repas => {
          if (totalData[mode][_repas][_type]) {
            totalData[mode][_repas][_type].forEach(_count => {
              line.push({ v: _count, s: style });
            });
          } else {
            line.push({ v: "", s: {} }); line.push({ v: "", s: {} }); line.push({ v: "", s: {} }); line.push({ v: "", s: {} });
          }
          line.push({ v: "", s: {} });
        });

        data["Totaux"].push(line);
      });
    });



    // DETAILS PORTAGE

    sortedRepas.forEach(_repas => {
      const sheetName = "Portage " + _repas;
      let _repasData = [];
      if (!data[sheetName]) data[sheetName] = [
        [{ v: moment(date).format("dddd DD MMMM YYYY"), s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: sheetName, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
        ],
        [
          { v: "Nom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Prénom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Appartement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Abonnement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Régime/Allergie", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          ...[...template[_repas].categories, "suppléments restaurant"].map(_categ => {
            return { v: _categ, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } };
          })
        ]];

      if (_obj && _obj[_repas]) {


        Object.entries(_obj[_repas]).forEach(([userId, _resas], idx) => {

          const _localData = [];

          _resas.forEach((_resa, _indx) => {
            const subscription = findSubscriptionForDate(ctx.usersUnfiltered[userId]?.subscriptions, date);

            const _subscription = subscription?.subscription;
            const _restoInfos = ctx.usersUnfiltered[userId]?.restoInfos;
            const _guest = isGuest(userId);

            if (!ctx.usersUnfiltered[userId]) console.log(userId);
            let _line = [
              { v: ctx.usersUnfiltered[userId]?.name ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: ctx.usersUnfiltered[userId]?.surname ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _guest && _guest.linkedTo ? ctx.usersUnfiltered[_guest.linkedTo]?.room ?? "" : ctx.usersUnfiltered[userId]?.room ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _subscription ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _restoInfos ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
            ];

            if (_resa.length === 0) {
              // alors on fait en fonction des abonnements : 

              if (_subscription && _repas === "Dîner" && _subscription.includes("Dîner")) {

                if (_subscription.includes("allégé")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: _categ !== "Plat" && _categ !== "Accompagnement" ? Object.values(periodData.data[formated][_repas][_categ])
                        .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour" : "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else if (_subscription.includes("potage")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: _categ === "Potage" ? Object.values(periodData.data[formated][_repas][_categ])
                        .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour" : "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: Object.values(periodData.data[formated][_repas][_categ])
                        .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                }
              } else {
                // Cas de réservation sans désigner des plats spécifiques = Plat du jour
                template[_repas].categories.forEach(_categ => {
                  _line.push({
                    v: Object.values(periodData.data[formated][_repas][_categ])
                      .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour",
                    s: {
                      alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    }
                  }
                  );
                });

              }

            } else {
              // Si on spécifie des plats
              template[_repas].categories.forEach(_categ => {
                let plate = Object.values(periodData.data[formated][_repas][_categ])
                  .find(_plate => _resa.includes(_plate.name));
                if (plate) {
                  // Plat du jour donc on affiche le nom
                  if (plate.origin === "Menu du jour") {
                    _line.push({
                      v: plate.name,
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  } else {
                    // Sinon on affiche le plat avec un style différent
                    _line.push({
                      v: plate.name,
                      s: {
                        font: { color: { rgb: "000000" }, bold: true },
                        // fill: { fgColor: { rgb: "e39da7" } },
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  }
                } else {
                  _line.push({ v: "" });
                }
              });
            }

            // Ajout des suppléments restaurant
            const supplements = _resa.find(e => typeof e === "object")
            if (supplements) {
              _line.push({
                v: supplements["suppléments restaurant"].map(e => `x${e.number} ${e.name}`).join("\n"),
                s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } },
              });
            } else {
              _line.push({ v: "" });
            }

            _localData.push(_line);
          });

          data[sheetName] = [...data[sheetName]];
          _repasData.push(_localData);
        });
      }
      // Tri des lignes
      // _repasData.sort((a, b) => {
      //   const vANom = a[0][0].v ?? "";
      //   const vAPrenom = a[0][1].v ?? "";
      //   const vBNom = b[0][0].v ?? "";
      //   const vBPrenom = b[0][1].v ?? "";

      //   return `${vANom} ${vAPrenom}`.localeCompare(`${vBNom} ${vBPrenom}`);
      // });
      _repasData.sort((a, b) => {
        const vARoom = a[0][2].v ?? "";
        const vBRoom = b[0][2].v ?? "";

        return vARoom.localeCompare(vBRoom);
      });

      // Coloriser une ligne sur deux
      _repasData.forEach((lines, linesIndex) => {
        lines.forEach((line, lineIndex) => {
          line.forEach((cell, cellIndex) => {
            if (linesIndex % 2 === 0) {
              _repasData[linesIndex][lineIndex][cellIndex] = {
                ...cell,
                s: {
                  ...cell.s,
                  fill: {
                    fgColor: {
                      rgb: "b7dacf"
                    }
                  },
                }
              };
            }
          });
        });
        return lines;
      });
      data[sheetName] = [...data[sheetName], ..._repasData.flat()];
    });


    // DETAILS RESERVATIONS

    sortedRepas.forEach(_repas => {
      const sheetName = "Restaurant " + _repas;
      let _repasData = [];
      if (!data[sheetName]) data[sheetName] = [
        [{ v: moment(date).format("dddd DD MMMM YYYY"), s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: sheetName, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
        ],
        [
          { v: "Nom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Prénom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Appartement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Abonnement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Régime/Allergie", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          ...[...template[_repas].categories, "suppléments restaurant"].map(_categ => {
            return { v: _categ, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } };
          })
        ]];

      if (_obj2 && _obj2[_repas]) {

        Object.entries(_obj2[_repas]).forEach(([userId, _resas], idx) => {

          const _localData = [];

          _resas.forEach((_resa, _indx) => {
            const subscription = findSubscriptionForDate(ctx.usersUnfiltered[userId]?.subscriptions, date);

            const _subscription = subscription?.subscription;
            const _restoInfos = ctx.usersUnfiltered[userId]?.restoInfos;
            const _guest = isGuest(userId);

            let _line = [
              { v: ctx.usersUnfiltered[userId]?.name ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: ctx.usersUnfiltered[userId]?.surname ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _guest && _guest.linkedTo ? ctx.usersUnfiltered[_guest.linkedTo]?.room ?? "" : ctx.usersUnfiltered[userId]?.room ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _subscription ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _restoInfos ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
            ];

            if (_resa.length === 0) {
              // alors on fait en fonction des abonnements : 

              if (_subscription && _repas === "Dîner" && _subscription.includes("Dîner")) {

                if (_subscription.includes("allégé")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else if (_subscription.includes("potage")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                }
              } else {
                // Cas de réservation sans désigner des plats spécifiques = Plat du jour
                template[_repas].categories.forEach(_categ => {
                  _line.push({
                    v: "",
                    s: {
                      alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    }
                  }
                  );
                });

              }

            } else {
              // Si on spécifie des plats
              template[_repas].categories.forEach(_categ => {
                let plate = Object.values(periodData.data[formated][_repas][_categ])
                  .find(_plate => _resa.includes(_plate.name));
                if (plate) {
                  // Plat du jour donc on affiche le nom
                  if (plate.origin === "Menu du jour") {
                    _line.push({
                      v: plate.name,
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  } else {
                    // Sinon on affiche le plat avec un style différent
                    _line.push({
                      v: plate.name,
                      s: {
                        font: { color: { rgb: "000000" }, bold: true },
                        // fill: { fgColor: { rgb: "e39da7" } },
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  }
                } else {
                  _line.push({
                    v: "",
                    s: {
                      alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    }
                  });
                }
              });
            }

            // Ajout des suppléments restaurant
            const supplements = _resa.find(e => typeof e === "object")
            if (supplements) {
              _line.push({
                v: supplements["suppléments restaurant"].map(e => `x${e.number} ${e.name}`).join("\n"),
                s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } },
              });
            } else {
              _line.push({ v: "" });
            }

            _localData.push(_line);
          });

          // console.log("localData", _localData);
          data[sheetName] = [...data[sheetName]];
          _repasData.push(_localData);
        });
      }
      // Tri des lignes
      // _repasData.sort((a, b) => {
      //   const vANom = a[0][0].v ?? "";
      //   const vAPrenom = a[0][1].v ?? "";
      //   const vBNom = b[0][0].v ?? "";
      //   const vBPrenom = b[0][1].v ?? "";

      //   return `${vANom} ${vAPrenom}`.localeCompare(`${vBNom} ${vBPrenom}`);
      // });
      _repasData.sort((a, b) => {
        const vARoom = a[0][2].v ?? "";
        const vBRoom = b[0][2].v ?? "";

        return vARoom.localeCompare(vBRoom);
      });

      // Coloriser une ligne sur deux
      _repasData.forEach((lines, linesIndex) => {
        lines.forEach((line, lineIndex) => {
          line.forEach((cell, cellIndex) => {
            if (linesIndex % 2 === 0) {
              _repasData[linesIndex][lineIndex][cellIndex] = {
                ...cell,
                s: {
                  ...cell.s,
                  fill: {
                    fgColor: {
                      rgb: "b7dacf"
                    }
                  },
                }
              };
            }
          });
        });
        return lines;
      });
      data[sheetName] = [...data[sheetName], ..._repasData.flat()];
    });

    console.log("cc", data);
    return data;
  };


  return (
    <>
      {modalExportOpen ? (
        <ExportDay
          onClose={() => setModalExportOpen(false)}
          dataGeneration={generateExportData}
          infos={{
            title: "Export du jour",
            type: "portage",
            date: ctx?.selectedWeek,
          }}
        />
      ) : null}
    </>
  );
};

export default ExportDaily;
