import React from "react";
import {Header, LeftSidebar} from "../components/dashboard";
import {SidebarContextProvider} from "../context/dashboard/sidebar.context";
import PropTypes from "prop-types";

import styles from "../assets/scss/layout/dashboard/dashboard.module.scss";

const DashboardLayout = ({children, menu, header}) => {
  return (
    <SidebarContextProvider defaultOpen={true}>
      {(sidebarIsOpen) => (
        <div className={styles.dashboard}>
          <LeftSidebar>{menu}</LeftSidebar>
          <div className={styles.bodyWrapper}>
            <Header>
              {header}
            </Header>
            <div className={`${styles.body} ${sidebarIsOpen ? styles.bodyOpen: ""}`}>
              <div className={`${styles.content} ${sidebarIsOpen ? styles.contentOpen : ""}`}>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </SidebarContextProvider>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  menu: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default DashboardLayout;