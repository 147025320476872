import React from "react";
import {ModalFooter as BootstrapModalFooter} from "reactstrap";

const ModalFooter = (props) => {
  const {children} = props;

  return (
    <BootstrapModalFooter {...props}>
      {children}
    </BootstrapModalFooter>
  );
};

ModalFooter.propTypes = BootstrapModalFooter.propTypes;

export default ModalFooter;