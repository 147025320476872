import React, {useEffect, useState} from "react";
import useGazette, {Provider} from "../../context";
import { useParams } from "react-router-dom";
import { Tab, Input, DatePicker } from "lib/components";
import useUI from "hooks/ui.hook";
import firebase from "firebase/app";
import 'firebase/firestore';
import ArticlesSelector from "../../edit/ArticlesSelector";
import PagesEditorHeurus from "../heurus/edit/PageEditorHeurus";
import { toast } from "react-toastify";
import moment from "moment";
import {getPageTypeFromSlug} from "./pageTypes";
import ContentEditorHeurus from "./edit/ContentEditorHeurus";
import PrevisualisationHeurus from "./edit/PrevisualisationHeurus";
import { useFirebaseCollection } from "lib/hooks/firebase";
import { getAllAnimationByEtab } from "@/services/ressources/animations";

const firestore = firebase.firestore;

const GazetteEditHeurus = () => {
  const {uid} = useParams();
  const [ui] = useUI();
  const [ctx, dispatch] = useGazette();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    (async () => {
      const _events = (await getAllAnimationByEtab({id: ui.user.establishment, start: moment(ctx.editionDate ?? new Date()).startOf("month").toDate(), end: moment(ctx.editionDate ?? new Date()).endOf("month").add(1, "months").toDate()}, (e) => {console.log(e)}))
        .map(e => ({...e, start: e.start.toDate(), end: e.end.toDate()}));
      setEvents(_events ?? []);
      dispatch({type: "setProperty", property: "events", value: _events ?? []});
    })();
  }, [ctx.editionDate]);

  const [categoriesEventRef] = useState(firestore().collection("establishments").doc(ui.user.establishment).collection("blocks").doc("planning").collection("categories"));

  const {data: categoriesData} = useFirebaseCollection(categoriesEventRef, []);
  const [isHidden, setIsHidden] = useState(false);

  const categoriesEvent = categoriesData.map(i => ({...i}));

  useEffect(() => {
    if(!ctx.categoriesEvent){
      dispatch({type: "setProperty", property: "categoriesEvent", value: categoriesEvent});
    }
  }, [categoriesEvent]);
  
  useEffect(() => {
    (async () => {
      try {
        if (uid) {
          let _gazette = await firestore().collection("establishments").doc(ui.user.establishment)
            .collection("gazettes").doc(uid).get();
          let pages = _gazette.data().pages.map(JSON.parse);
          let articles = _gazette.data().articles.map(JSON.parse).map(i => ({...i, date: moment().utc(i.date).toDate(), updatedAt: moment().utc(i.date).toDate()}));
          let title = _gazette.data().title && _gazette.data()?.title?.length > 0 ? _gazette.data().title : "Gazette du " + moment().format("DD/MM/YYYY HH:mm");

          dispatch({type: "clear"});
          dispatch({type: "setTitle", title});
          dispatch({type: "setPages", pages});
          dispatch({type: "setArticles", articles});
          dispatch({type: "setReady", ready: true});
          dispatch({type: "setProperty", property: "uid", value: uid});
          dispatch({type: "setProperty", property: "createdAt", value: _gazette.data().createdAt.toDate()});
          dispatch({type: "setProperty", property: "editionDate", value: _gazette.data()?.editionDate ? _gazette.data().editionDate.toDate() : new Date()});
          dispatch({type: "setProperty", property: "publication", value: _gazette.data().publication ?? null});
          dispatch({type: "setProperty", property: "events", value: events});
        } else {
          dispatch({type: "clear"});
          dispatch({type: "setReady", ready: true});
          dispatch({type: "setProperty", property: "createdAt", value: new Date()});
          dispatch({type: "setProperty", property: "title", value: "Gazette du " + moment().format("DD/MM/YYYY HH:mm")});
          dispatch({type: "addPage", pageType : getPageTypeFromSlug("summary")});
        }
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue");
      }
    })();
  }, [uid]);

  useEffect(() => {
    if(ctx.step === 2) {
      setIsHidden(true);
    }
    else{
      setIsHidden(false);
    }
  }, [ctx.step]);

  if (!ctx.ready) return <>loading...</>;
  return (
    <div style={{width: "100%"}}>
      <div style={{display: "flex", alignItems: "center", gap: 10, marginBottom: 15, display: isHidden ? "none" : "flex"}}>
        <span>Titre : </span><Input placeholder="Titre de la gazette..." value={ctx.title} onChange={e => dispatch({type: "setTitle", title: e.target.value.slice(0, 29)})}/>
        <span>Date d'édition : </span><DatePicker value={ctx.editionDate} onChange={date => dispatch({type: "setProperty", property: "editionDate", value: date})}/>
      </div>

      <Tab activeTab={ctx.step}
        onClick={(step) => dispatch({type: "setStep", step})}
        render={[
          {title: "Sélection d'actualité",         content: <ArticlesSelector />, hide: isHidden},
          {title: "Création de la gazette",            content: <PagesEditorHeurus />, hide: isHidden},
          {title: "Contenu",          content: <ContentEditorHeurus />, hide: true},
          {title: "Finalisation", content: <PrevisualisationHeurus />, hide: isHidden},
        ]} 
      />
    </div>
  );
};

export default GazetteEditHeurus;