import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Tab, Select, Container, FormGroup, Checkbox
} from "../../../lib/components";
import moment from "moment";

import XLSX from "xlsx-js-style";
import useUI from "hooks/ui.hook";

const startDate = 2021;

// excel Styles
const baseBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedTopBorder = { top: { style: "thick", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedLeftBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thick", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const baseFont = { sz: 12 };
const headerFont = { sz: 15, bold: true };
const baseAlignment = { vertical: "center", horizontal: "center", wrapText: true };
const baseFill = { fgColor: { rgb: "B3FFBF" } };


const ExportTable = ({ onClose, dataGeneration, infos }) => {
  const [ui] = useUI();
  const periods = ["week", "month", "year"];
  //const typeOptions = [{value : ".xlsx", label : "excel (.xlsx)"},{value : ".csv", label : "csv"},{value : ".pdf", label : "pdf"}];
  const [activeTab, setActiveTab] = useState(0);
  const [date, setDate] = useState(null);
  const [exportType, setExportType] = useState(".xlsx");
  const [identifiedBySurname, setIdentifiedBySurname] = useState(true);
  const [identifiedByName, setIdentifiedByName] = useState(true);
  const [identifiedByNumero, setIdentifiedByNumero] = useState(true);
  const [multipleSheets, setMultipleSheets] = useState(false);
  const [total, setTotal] = useState(true);
  const [signature, setSignature] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO" || ui?.establishment?.uid === "A1LKT1bK4Aj4TAaqPsel";

  let options = [];

  // génération des années pouvant être choisies
  for (let i = 0; i < moment().year() - startDate + 1; i++) {
    options.push({ value: startDate + i, label: startDate + i });
  }

  useEffect(() => {
    if (infos != undefined) {
      if (infos.date != undefined) setSelectedDate(infos.date);
    }
  }, [infos]);

  useEffect(() => {
    _onTabChange(0);
  }, []);

  // Fonctions Utiles

  // Permet de convertir les input en date (moment())
  const convertDate = (selectDate) => {
    let _date = null;
    switch (periods[activeTab]) {
      case "week":
        {
          const split = selectDate.split("-W");
          _date = moment().year(split[0]).week(parseInt(split[1])).startOf("week");
        }
        break;
      case "month":
        _date = moment(selectDate).startOf("month");
        break;
      case "year":
        _date = moment(selectDate.value.toString()).startOf("year");
        break;

      default:
        _date = moment();
        break;
    }

    return _date;
  };

  const getDateForName = () => {
    let retour = "";
    switch (periods[activeTab]) {
      case "week":
        {
          retour += "semaine" + selectedDate.week() + "_" + selectedDate.year();
        }
        break;
      case "month":
        retour += selectedDate.format("MMMM") + "_" + selectedDate.year();
        break;
      case "year":
        retour += selectedDate.format("YYYY");

        break;

      default:
        retour = "";
        break;
    }
    return retour;
  };


  const createSheet = (data) => {
    if(infos.type === "fullControl"){
      return fullControl(data);
    }

    let rows = [];
    let merge = [];
    let columnsSize = [];
    let rowSize = [];


    // On merge les cellules du début de header (en hauteur), (Prénom, nom, etc.)
    // for (let index = 0; index < data.colStart; index++) {
    //   merge.push({ s: { c: index , r: 0 }, e: { c: index, r: data.depth-1 } });
    // }


    //const baseStyle = {border : {}, alignment: {}, font: {}, fill: {}};
    let headerStyle = { border: baseBorder, alignment: baseAlignment, font: headerFont };

    for (let i = 0; i < data.depth; i++) {
      rows.push([]);
    }

    const findMergeValue = (array) => {
      if (array.length > 0) {
        let somme = 0;
        for (let t = 0; t < array.length; t++) {
          somme += findMergeValue(Object.values(array[t]));
        }
        return somme;
      } else {
        return 1;
      }
    };

    // Fonction récursive permettant de générer les header, avec des fusions de cellules.
    const XLSXHeaderGenerator = (array, row) => {
      Object.entries(array).forEach(([key, value]) => {
        rows[row].push({ v: key, s: headerStyle });
        if (value.length > 0) {


          merge.push({ s: { c: rows[row].length - 1, r: row }, e: { c: rows[row].length - 1 + findMergeValue(value) - 1, r: row } });
          value.forEach(element => {
            for (let i = 0; i <= row; i++) {
              rows[i].push({ v: "", s: headerStyle });
            }
            XLSXHeaderGenerator(element, row + 1);
          });
          for (let i = 0; i <= row; i++) {
            rows[i].pop();
          }
        } else {
          for (let i = row + 1; i < data.depth; i++) {
            rows[i].push({ v: "", s: headerStyle });
          }
        }
      });
    };


    XLSXHeaderGenerator(data.header, 0);

    for (let index = 0; index < rows[0].length; index++) {
      if (data.header[rows[0][index].v] && data.header[rows[0][index].v].length === 0) {
        merge.push({ s: { c: index, r: 0 }, e: { c: index, r: data.depth - 1 } });
      }
    }

    // on transforme les données au format excel
    let _data = [];
    let countRow = 0;


    let dataSetLength = data.data.length;
    data.data.forEach(array => {
      let arr = [];
      let countLine = 0;
      array.forEach(element => {
        let dataStyle = { border: {}, alignment: baseAlignment, font: baseFont };
        let type = "s";

        countRow == 0 ? dataStyle.border = enhancedTopBorder : dataStyle.border = baseBorder;

        if (total && (countRow >= dataSetLength - (data.bottomLength ?? 1) || countLine == rows[0].length - data.colEnd) && (ui?.groupement?.specific === "heurus") && infos?.type === "menu") {
          dataStyle["font"] = headerFont;
          if (countRow === dataSetLength - (data.bottomLength ?? 1)) {
            if (!(countRow === dataSetLength - (data.bottomLength ?? 1) && countLine == rows[0].length - data.colEnd))
              dataStyle["border"] = countRow === dataSetLength - (data.bottomLength ?? 1) ? enhancedTopBorder : enhancedLeftBorder;
            else dataStyle["border"] = { top: { style: "thick", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thick", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
          }
        }
        else {
          if (data.styles && data.styles.length > 0) {
            // {x : 0, y: 0, style : {}}
            const _x = countLine - data.colStart;
            const _y = countRow;



            const _cell = data.styles.find(_e => _e.x === _x && _e.y === _y);

            dataStyle["font"] = {
              bold: true,
              sz: "13"
            };

            if (_cell) {
              // console.log(_x, _y, countLine, countRow, data);
              Object.assign(dataStyle, _cell.style ?? {});
            }
          } else {
            if (infos.type != "menuDishes" && element != "" && countLine >= data.colStart && countLine <= rows[0].length - data.colEnd) {
              dataStyle["fill"] = baseFill;
            } else {
    
              dataStyle["fill"] = null;
            }
          }

          if(infos.type !== "menuDishes" && countLine >= data.colStart && countLine <= rows[0].length - data.colEnd)type = "n";
        }
        arr.push({ v: element, s: dataStyle, t:type });
        countLine++;
      });
      countRow++;
      _data.push(arr);
    });

    // On merge les cellules de fin de Menu (en hauteur), (total, signature, etc.)
    for (let index = rows[0].length; index <= rows[0].length + data.colEnd; index++) {
      merge.push({ s: { c: index, r: 0 }, e: { c: index, r: data.depth - 1 } });
    }
    // génération des la largeur des colonnes
    rows[0].forEach(() => {
      columnsSize.push({ width: 21 });
    });
    // génération de la hauteur des lignes
    for (let i = 0; i < data.depth + _data.length; i++) {
      if (infos.type == "event" && i == 1) {
        rowSize.push({ "hpt": 58 });
      } else if (signature) {
        rowSize.push({ "hpt": 30 });
      } else {
        rowSize.push({});
      }
    }

    console.log(data);
    if ((ui?.groupement?.specific === "ovelia") && infos?.type === "menu") {
      _data.push([]);
      _data.push([]);

      console.log("hello world");

      // légende :
      const styleUnite = {
        font: {
          color: { rgb: "e33119" },
          bold: true,
          sz: "13"
        }
      };
      const stylePortage = { fill: { fgColor: { rgb: "f2ea00" } } };
      const styleSupplement = {
        font: {
          color: { rgb: "be00f2" },
          bold: true,
          sz: "13"
        },
      };
      const styleSupplementPortage = {
        font: {
          color: { rgb: "be00f2" },
          bold: true,
          sz: "13"
        },
        fill: { fgColor: { rgb: "f2ce00" } }
      };


      _data.push([
        { v: "1", s: styleUnite }, { v: "Repas à l'unité", s: null }
      ]);
      _data.push([
        { v: "1", s: stylePortage }, { v: "Portage de repas", s: null }
      ]);
      _data.push([
        { v: "1", s: styleSupplement }, { v: "Supplément par rapport à l'abonnement", s: null }
      ]);
      _data.push([
        { v: "1", s: styleSupplementPortage }, { v: "Supplément et Portage de repas", s: null }
      ]);
    }


    let sheet = XLSX.utils.aoa_to_sheet([...rows, ..._data]);
    sheet["!merges"] = merge;
    sheet["!cols"] = columnsSize;
    sheet["!rows"] = rowSize;

    return sheet;
  };

  const fullControl = (data) => {
    if(Array.isArray(data)){
      const sheets = [];

      data.forEach(_s => {
        let columnsSize = _s.data.columnsSize ?? [];
        let rowsSize = _s.data.rowsSize ?? [];
        columnsSize = columnsSize.map(_c => {return {width: _c};});
        rowsSize = rowsSize.map(_r => {return {hpt: _r};});
         
        let sheet = XLSX.utils.aoa_to_sheet(_s.data.data);
        sheet["!cols"] = columnsSize;
        sheet["!rows"] = rowsSize;

        sheets.push({name: _s.name, data: sheet})
      });      
  
      return sheets;
    }else{
      let columnsSize = data.columnsSize ?? [];
      let rowsSize = data.rowsSize ?? [];
      columnsSize = columnsSize.map(_c => {return {width: _c};});
      rowsSize = rowsSize.map(_r => {return {hpt: _r};});
       
      let sheet = XLSX.utils.aoa_to_sheet(data.data);
      sheet["!cols"] = columnsSize;
      sheet["!rows"] = rowsSize;
  
      return sheet;
    }
   
  };

  const save = async () => {
    const wb = XLSX.utils.book_new();

    let localPeriod = periods[activeTab];
    let _date = selectedDate;

    let dateArray = [];
    let sheetNames = [];
    if (multipleSheets && activeTab > 0) {

      localPeriod = periods[activeTab - 1];


      if (activeTab == 1) {
        let localDate = _date.clone();
        for (let i = 1; i <= _date.daysInMonth(); i++) {
          let locWeek = localDate.clone();
          if (startOfWeek) {
            if (startOfWeek.clone().format("YYYY/MM/DD") != locWeek.clone().format("YYYY/MM/DD")) {
              startOfWeek = locWeek;
              dateArray.push(startOfWeek);
              sheetNames.push("Week " + startOfWeek.clone().week());
            }
          } else {
            startOfWeek = locWeek;
            dateArray.push(startOfWeek);
            sheetNames.push("Week " + startOfWeek.clone().week());
          }

          localDate = _date.clone().add(i, "days");
        }
      }
      if (activeTab == 2) {
        let localDate = _date.clone();
        for (let i = 1; i <= 12; i++) {
          dateArray.push(localDate);
          sheetNames.push(localDate.clone().format("MMMM"));
          localDate = _date.clone().add(i, "months");
        }
      }
    } else {
      dateArray.push(_date);
      sheetNames.push("Feuille 1");
    }

    for (let i = 0; i < dateArray.length; i++) {
      let data = await dataGeneration(localPeriod, dateArray[i], identifiedBySurname, identifiedByName, identifiedByNumero, total, signature);

      let sheets = createSheet(data);
      if(Array.isArray(sheets)){
        sheets.forEach(sheet => {
          XLSX.utils.book_append_sheet(wb, sheet.data, sheet.name);
        });
      }else{
        XLSX.utils.book_append_sheet(wb, sheets, sheetNames[i]);
      }
    }
    console.log("wb", wb);

    const _type = infos.type === "menu" || infos.type === "menuDishes" ? "menus" : infos.type === "restaurant" ? "restaurant" : infos.type === "event" ? (isAutourDeLHumain ? "ateliers" : "animations") : "";
    const filename = _type + "_" + getDateForName();
    XLSX.writeFile(wb, filename + ".xlsx");

    setLoading(true);
    onClose();
  };


  // event Handler 

  const handleDatePickerChange = (e) => { // week and month
    if (e.value) {
      setDate(e.value);
      setSelectedDate(convertDate(e.value));
    }
    else {
      setDate(e.target.value);
      setSelectedDate(convertDate(e.target.value));
    }

  };

  //changement du type de période
  const _onTabChange = (e) => {
    let _date = null;
    if (selectedDate == null) {
      if (infos.date == null) {
        _date = moment();
      } else {
        _date = infos.date;
      }
    } else {
      _date = selectedDate;
    }

    let value = "";
    if (e == 0) {
      value = _date.clone().startOf("week").format("YYYY") + "-W" + _date.format("WW");
      setSelectedDate(_date.clone().startOf("week"));
    }
    if (e == 1) {
      value = _date.format("YYYY") + "-" + _date.format("MM");
      setSelectedDate(_date.clone().startOf("month"));
    }
    if (e == 2) {
      value = { value: _date.year(), label: _date.year() };
      setSelectedDate(_date.clone().startOf("year"));
    }

    setDate(value);
    setActiveTab(e);
  };
  return (
    <Modal isOpen={true} size="lg" toggle={onClose}>
      <ModalHeader>
        {infos.title}
      </ModalHeader>
      <ModalBody>

        {infos.type === "menu" || infos.type === "event" ?
          <>

            <h5>Identification des résidents</h5>

            <FormGroup>
              <Checkbox
                label={"Prénom"}
                onChange={() => setIdentifiedBySurname(!identifiedByNumero && !identifiedByName && identifiedBySurname ? identifiedBySurname : !identifiedBySurname)}
                checked={identifiedBySurname} />
              <Checkbox
                label={"Nom"}
                onChange={() => setIdentifiedByName(!identifiedByNumero && !identifiedBySurname && identifiedByName ? identifiedByName : !identifiedByName)}
                checked={identifiedByName} />
              <Checkbox
                label={isAutourDeLHumain ? "Service" : "Numéro d'appartement"}
                onChange={() => setIdentifiedByNumero(!identifiedByName && !identifiedBySurname && identifiedByNumero ? identifiedByNumero : !identifiedByNumero)}
                checked={identifiedByNumero} />
            </FormGroup>
          </>
          : null}




        <h5>Choix de la période</h5>
        <Tab activeTab={activeTab}
          onClick={_onTabChange}
          render={[{
            title: "Semaine",
            content: <Container>
              <input type="week" value={date ? date : ""} className="form-control" id="Week" onChange={handleDatePickerChange} />
            </Container>
          }, {
            title: "Mois",
            content: <Container>
              <input type="month" value={date ? date : ""} className="form-control" id="Month" onChange={handleDatePickerChange} />
              {/* {exportType == ".xlsx" ? <Checkbox
                label={"Répartition des semaines dans plusieurs feuilles"}
                onChange={() => setMultipleSheets(!multipleSheets)}
                checked={multipleSheets} />
                : null} */}
            </Container>
          }, {
            title: "Année",
            content: <Container>
              <Select
                theme="grey"
                options={options.map(o => ({ value: o.value.toString(), label: o.label.toString() }) )}
                value={date && activeTab == 2 ? { value: date.value.toString(), label: date.label.toString() } : { value: "", label: ""}}
                onChange={e => { setDate(e); setSelectedDate(convertDate(e)); }}
              />
              {exportType == ".xlsx" ? <Checkbox
                label={"Répartition des mois dans plusieurs feuilles"}
                onChange={() => setMultipleSheets(!multipleSheets)}
                checked={multipleSheets} />
                : null}
            </Container>
          }]} />
        {/*
            <h5>Choix du format</h5>

            <FormGroup>
                <Select
                    theme="grey"
                    options={typeOptions}
                    value={exportType}
                    onChange={e => setExportType(e)}
                    />
            </FormGroup>
            */}
        {infos.type == "menu" || infos.type == "event" ?
          <>
            <h5>Options supplémentaires</h5>

            <FormGroup>
              <Checkbox
                label={"Ajouter les totaux"}
                onChange={() => setTotal(!total)}
                checked={total} />
              <Checkbox
                label={"Ajouter une colonne : Signature"}
                onChange={() => setSignature(!signature)}
                checked={signature} />
            </FormGroup>
          </>
          : null}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Annuler</Button>
        <Button color="primary" onClick={() => {setLoading(true); save();}} loading={loading}>Télécharger</Button>
      </ModalFooter>
    </Modal>
  );
};
ExportTable.propTypes = {
  onClose: PropTypes.func.isRequired,
  dataGeneration: PropTypes.func.isRequired,
  infos: PropTypes.object.isRequired,
};

export default ExportTable;