
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Separator,
  Modal, ModalHeader, ModalBody,
  Icon, Button
} from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "hooks/ui.hook";
import { ModalFooter } from "reactstrap";
import { getUserById } from "services/ressources/user";
import { colorByCate } from "../Scss/colors";
import { updateNotificationById } from "services/ressources/notification";
import { Gift, MoreVertical, Tool, Calendar, Settings } from "react-feather";
import notificationsStore from "store/notificationsStore";
import ConciergeBellIcon from "../../../../lib/assets/svg/ConciergeBellGreen.svg";
import  Utensils from "../../../../lib/assets/svg/UtensilsBlue.svg";
const InformationNotifModal = (props) => {
  const {
    isOpen,
    onClose,
    eventOpen
  } = props;
  const [ui] = useUI();
  const [userInfo, setUserInfo] = useState([]);
  const [isAlreadyRead,setIsAlreadyRead] =useState(false);
  const [notifToShow,setNotifToShow] =useState(true);
  const notificationRequired = notificationsStore((state) => state.notificationRequired);

  const _markRead = async (uid) => {
    await updateNotificationById({ ui, id: uid, data: { seenBy: firebase.firestore.FieldValue.arrayUnion(ui.user.uid) } });
  };

  useEffect(() => {
    //récupération des info du User par ID
    (async () => {
      if (eventOpen?.data) setUserInfo(await getUserById({ id: eventOpen.data.userId }));
      else setUserInfo(undefined);
    })();
    if (eventOpen) {
      //recup info pour savoir si la notif peut être marqué comme lu
      const isNotifShowable = eventOpen.type === "birthday" ? notificationRequired.birthday : notificationRequired[eventOpen.type]?.[eventOpen.action];
      console.log(isNotifShowable);
      setNotifToShow(isNotifShowable);
      //récup des infos si la notif est déja Marqué comme lu ou pas
      if (eventOpen.seenBy && isNotifShowable) {
        if (eventOpen.seenBy.includes(ui.user.uid)) setIsAlreadyRead(true);
        else setIsAlreadyRead(false);
      }
    }
  }, [eventOpen]);


  const generateBody = () => {
    // Html pour le début du message et la fin du message
    const getSalutation = () => "Bonjour,<br/><br/>";
    const getClosing = () => "<br/><br/>Cordialement,<br/>- L'équipe Major-";

    //récuperation html du nom/prénom du senior
    let userName = "";
    if (eventOpen?.data) {
      userName = `<strong>${userInfo?.name} ${userInfo?.surname}</strong>`;
    } else {
      userName = "<strong>Utilisateur inconnu</strong>";
    }

    //récuperation sous String de la date et l'heure de DEPART
    let date = "";
    const dateObject = new Date(eventOpen?.data?.date?.seconds * 1000);
    const dateDaymonth = dateObject.toLocaleDateString("fr-FR", { day: "2-digit", month: "short" });
    date = dateDaymonth;
    const time = `${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}`;

     //récuperation sous String de la date et l'heure de FIN
     let dateEND = "";
     const dateObjectEND = new Date(eventOpen?.data?.end?.seconds * 1000);
     const dateDaymonthEND = dateObjectEND.toLocaleDateString("fr-FR", { day: "2-digit", month: "short" });
     dateEND = dateDaymonthEND;
     const timeEND = `${dateObjectEND.getHours().toString().padStart(2, "0")}:${dateObjectEND.getMinutes().toString().padStart(2, "0")}`;

    //MENU -> Quantité et Portage
    const quantityText = eventOpen?.data?.quantity > 1
      ? ` Sur la réservation on retrouve <strong>${eventOpen?.data?.quantity}</strong> personnes.`
      : ` Pour <strong>${eventOpen?.data?.quantity}</strong> personne.`;
    const homeDeliveryText = eventOpen?.data?.homeDelivery
      ? "Le senior souhaite que sa réservation soit en <strong>portage</strong>."
      : "Pas de portage.";

    //INTERVENTION -> Detail de l'intervention
    const interventionDetails = eventOpen?.data?.interventionDetails 
    ? `Le détail de cette intervention est : ''${eventOpen?.data?.interventionDetails }''.`
    :"";

    //SERVICE -> les prestations choisies
    const prestations = eventOpen?.data?.prestation 
    ? eventOpen.action ==="delete"
      ? `Le senior avait choisi : <strong>${eventOpen?.data?.prestation}</strong> avant d'annuler.`
      : `Le senior a choisi de réserver pour : <strong>${eventOpen?.data?.prestation}</strong>.`
    :"";

    const categoryService = eventOpen?.data?.category 
    ? `Ce prestataire est compris dans votre catégorie de service nommée : <strong>${eventOpen?.data?.category}</strong>.`
    :"";

    const messageBody = () => {
      switch (eventOpen.type) {
        case "animation":
          switch (eventOpen.action) {
            case "delete":
              return `${userName} a annulé sa réservation à l'évènement <strong>${eventOpen?.data?.name}</strong> qui a lieu le <strong>${date}</strong> de ${time} à ${timeEND}.`;
            case "update":
              return `${userName} a modifié sa réservation à l'évènement <strong>${eventOpen?.data?.name}</strong> qui a lieu le <strong>${date}</strong> de ${time} à ${timeEND}.`;
            case "add":
              return `${userName} s'est inscrit à l'évènement <strong>${eventOpen?.data?.name}</strong> qui a lieu le <strong>${date}</strong> de ${time} à ${timeEND}.`;
            default:
              return "Action inconnue.";
          }
        case "service":
          switch (eventOpen.action) {
            case "delete":
              return `${userName} a annulé sa réservation avec le prestataire <strong>${eventOpen?.data?.name}</strong> qui a lieu le <strong>${date}</strong> de ${time} à ${timeEND}.</br>${prestations}</br>${categoryService}`;
            case "update":
              return `${userName} a modifié sa réservation avec le prestataire <strong>${eventOpen?.data?.name}</strong> qui a lieu le <strong>${date}</strong> de ${time} à ${timeEND}.</br>${prestations}</br>${categoryService}`;
            case "add":
              return `${userName} a réservé un créneau avec le prestataire <strong>${eventOpen?.data?.name}</strong> qui a lieu le <strong>${date}</strong> de ${time} à ${timeEND}.</br>${prestations}</br>${categoryService}`;
            default:
              return "Action inconnue.";
          }
        case "menu":
          switch (eventOpen.action) {
            case "delete":
              return `${userName} a annulé sa réservation pour le <strong>${eventOpen?.data?.name}</strong> du ${date}.`;
            case "update":
              return `${userName} a modifié sa réservation pour le <strong>${eventOpen?.data?.name}</strong> du ${date}.<br/>${quantityText} ${homeDeliveryText}`;
            case "add":
              return `${userName} a effectué une réservation pour le <strong>${eventOpen?.data?.name}</strong> du ${date}.<br/>${quantityText} ${homeDeliveryText}`;
            default:
              return "Action inconnue.";
          }
        case "intervention":
          switch (eventOpen.action) {
            case "delete":
              return `${userName} a annulé sa demande d'intervention de type <strong>${eventOpen?.data?.category}</strong>.</br>${interventionDetails}`;
            case "update":
              return `${userName} a modifié sa demande d'intervention de type <strong>${eventOpen?.data?.category}</strong>.</br>${interventionDetails}`;
            case "add":
              return `${userName} a fait une demande d'intervention de type <strong>${eventOpen?.data?.category}</strong>.</br>${interventionDetails}`;
            default:
              return "Action inconnue.";
          }
        case "birthday":
          
          return `Nous souhaitons l'anniversaire de ${userName} aujourd'hui!`;
           
          
        default:
          return "Type de notification inconnu.";
      }
    };

    return `${getSalutation()}${messageBody()}${getClosing()}`;
  };
  
  return (
    (eventOpen) ?
      <Modal size="lg" isOpen={isOpen} toggle={onClose} >
        <ModalHeader>{"Informations supplémentaires"}</ModalHeader>
        <ModalBody>
          <div style={{ backgroundColor: colorByCate[eventOpen.type].secondary, borderRadius: "10px", gap: "35px", padding: "10px", display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {eventOpen.type === "animation" && (<Calendar size={50} color={colorByCate.animation.primary} />)}
              {eventOpen.type === "service" && (
                <img src={ConciergeBellIcon} color="#99E36E" style={{ width: "50px", height: "50px", stroke: "#99E36E" }}></img>
              // <ConciergeBellIcon style={{ width: "50px", height: "50px", stroke: "#99E36E" }} />
              )}
              {eventOpen.type === "menu" && (
                <img src={Utensils} color="#5CADDB" style={{ width: "50px", height: "50px", stroke: "#5CADDB" }} ></img>
              // <Utensils style={{ width: "50px", height: "50px", stroke: "#5CADDB" }} />
              )}
              {eventOpen.type === "intervention" && (<Tool size={50} color={colorByCate.intervention.primary} />)}
              {eventOpen.type === "birthday" && (<Gift size={50} color="#DF7189"></Gift>)}
            </div>
            <div dangerouslySetInnerHTML={{ __html: generateBody() }}></div>
          </div>
        </ModalBody>
        <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
          <Button color="secondary" onClick={onClose}>Retour</Button>
          {notifToShow?<Button disabled={isAlreadyRead} onClick={() => { _markRead(eventOpen.uid); onClose(); }} style={{ marginRight: 0 }}>{!isAlreadyRead? "Marquer comme lu": "Déjà marqué comme lu"}
          </Button>:null}        
        </ModalFooter>
      </Modal> : <></>
  );

};
InformationNotifModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventOpen: PropTypes.any

};



export default InformationNotifModal;