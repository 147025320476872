import React from "react";
import PropTypes from "prop-types";
import {TabContent as BootstrapTabContent,
  TabPane} from "reactstrap";

const TabContent = (props) => {
  let {
    children,
    activeTab
  } = props;

  return (
    <BootstrapTabContent activeTab={activeTab} >
      {children && children.map((i, id) => (
        <TabPane key={id} tabId={id}>{i}</TabPane>
      ))}
    </BootstrapTabContent>
  );
};

TabContent.propTypes = {
  activeTab : PropTypes.number,
  children  : PropTypes.node,
};

export default TabContent;