import {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "../../../hooks/ui.hook";
import {Plus, RotateCcw, RotateCw, ZoomIn, ZoomOut} from "react-feather";
import Cropper from "react-easy-crop";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "lib/components";
import styles from "../../assets/scss/component/imagePickerCrop.module.scss";
import {v4 as uuidv4} from "uuid";
import {Trash, Upload, Scissors} from "react-feather";
import useUUid from "lib/hooks/useUid";
import { toast } from "react-toastify";
import { uploadDataUrl } from "./imagePickerUtils";

const storage = firebase.storage;

const ImagePickerCrop = (props) => {
  const {
    value,
    onChange,
    ratio,
  } = props;

  const [ui] = useUI();
  const inputId = useUUid();
  const [step, setStep] = useState("upload");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewSize, setPreviewSize] = useState({width: 150, height: 150});
  const [loading, setLoading] = useState(false);
  const [ratioWarning, setRatioWarning] = useState(false);

  const [file, setFile] = useState(null);
  const [localUrl, setLocalUrl] = useState(null);

  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(null);
  const typesAutorises = ["png", "jpg", "jpeg", "gif", "bmp", "webp", "ico", "svg"];

  const _onCropComplete = useCallback((_, croppedAreaPixels) => setCropArea(croppedAreaPixels), []);

  useEffect(() => {
    //compute preview ratio
    if (ratio < 1) {//vertical
      setPreviewSize({
        height: 200,
        width: 200 * ratio
      });
    } else {//horizontal
      setPreviewSize({
        width: 200,
        height: 200 / ratio
      });
    }
  }, [ratio]);
  useEffect(() => {
    if (value === null) {setRatioWarning(false); return;}
    const img = new Image();
    img.addEventListener("load", function() {
      let _ratio = this.naturalWidth / this.naturalHeight;
      //check if ratio is near of 10%
      if (Math.abs(_ratio - ratio) > 0.1) {
        setRatioWarning(true);
      } else {
        setRatioWarning(false);
      }
    });
    img.src = value;
  }, [ratio, value]);

  const _clear = () => {
    setCrop({x: 0, y: 0});
    setZoom(1);
    setIsModalOpen(false);
    setStep("upload");
    setLocalUrl(null);
    setFile(null);
    setLoading(false);
  };
  const _onRotateLeft = () => {
    let imageFile = file;
    var img = document.createElement("img");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    img.onload = function () {
      canvas.width = img.height;
      canvas.height = img.width;

      var x = canvas.width / 2;
      var y = canvas.height / 2;
      var width = img.width;
      var height = img.height;

      ctx.translate(x, y);
      ctx.rotate(-Math.PI / 2);
      ctx.drawImage(img, -width / 2, -height / 2, width, height);
      ctx.rotate(Math.PI / 2);
      ctx.translate(-x, -y);

      let dataUrl = canvas.toDataURL(imageFile.type);
      setLocalUrl(dataUrl);
    };
    img.src = localUrl;
  };
  const _onRotateRight = () => {
    let imageFile = file;
    var img = document.createElement("img");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    img.onload = function () {
      canvas.width = img.height;
      canvas.height = img.width;

      var x = canvas.width / 2;
      var y = canvas.height / 2;
      var width = img.width;
      var height = img.height;

      ctx.translate(x, y);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(img, -width / 2, -height / 2, width, height);
      ctx.rotate(-Math.PI / 2);
      ctx.translate(-x, -y);

      let dataUrl = canvas.toDataURL(imageFile.type);
      setLocalUrl(dataUrl);
    };
    img.src = localUrl;
  };
  const _submitUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      let extension = e.target.files[0].name.split(".").pop().toLowerCase();
      if(!typesAutorises.includes(extension)) {toast.warning("Le format ''"+extension+"'' n'est pas autorisé. Seuls les fichiers PNG, JPG, JPEG, GIF, BMP, WEBP, ICO et SVG sont autorisés."); return;}  
      let _localUrl = URL.createObjectURL(e.target.files[0]);
      setLocalUrl(_localUrl);
      setStep("crop");
      setFile(e.target.files[0]);
    }
  };
  const _submitCrop = () => {
    setLoading(true);
    let imageFile = file;
    var reader = new FileReader();
    reader.onload = function (e) {
      var img = document.createElement("img");
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      ctx.canvas.width = cropArea.width;
      ctx.canvas.height = cropArea.height;
      ctx.fillStyle = "white"; // Set fill color to white
      ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with color
      img.onload = function () {
        ctx.drawImage(img, cropArea.x, cropArea.y, cropArea.width, cropArea.height, 0, 0, cropArea.width, cropArea.height);
        let dataUrl = canvas.toDataURL(imageFile.type);
        
        //cropping done, now upload
        (async () => {
          try {
            onChange(await uploadDataUrl(ui, dataUrl, e.name, 1200, 800, 0.7));
            _clear();
          } catch (e) {
            console.error(e);
            toast.error(e.message);
            setLoading(false);
          }
        })();
      };
      img.src = localUrl;
    };
    reader.readAsDataURL(imageFile);
  };

  const cropCurrentImage = async () => {
    const blob = await fetch(value).then(r => r.blob());
    let _localUrl = URL.createObjectURL(blob);
    setIsModalOpen(true);
    setStep("crop");

    setLocalUrl(_localUrl);
    setFile(blob);
  };

  return (
    <div className={styles.container} style={{width: previewSize.width}}>
      {value ? (
        <div className={styles.imageContainer} style={previewSize}>
          <img src={value} alt="image" className={styles.image} style={previewSize} />
          {ratioWarning ? 
            <Scissors color="black" onClick={cropCurrentImage} className={styles.scissors}/>
          : null}
          <Trash color="red" onClick={() => onChange(null)} 
            className={styles.trash} />
        </div>
      ) : (
        <div className={styles.upload} style={previewSize} onClick={() => setIsModalOpen(true)}>
          <Plus size={48} color="white" />
        </div>
      )}
      {ratioWarning && (
        // eslint-disable-next-line react/no-unescaped-entities
        <p style={{textAlign: "center", color: "orange", fontWeight: 600}}>Cliquez sur les ciseaux pour recadrer l'image et ainsi obtenir un format d'image idéal.</p>
      )}
      {/*<Button color="primary" onClick={() => setIsModalOpen(true)}>click here</Button>*/}
      <Modal isOpen={isModalOpen} toggle={_clear} size="lg">
        <ModalHeader><h4></h4></ModalHeader>
        {step === "upload" ? (
          <ModalBody className={styles.modalBody} style={{height: 400}}>
            <label className={styles.dropZone} htmlFor={inputId} style={{display: "flex", flexDirection: "column", gap: 10}}>
              <Upload size={80} color="#AAA" />
              <span style={{fontSize: 20, color: "#aaaaaa"}}>Cliquez ici pour ajouter une image</span>
              <input type="file" onChange={_submitUpload} id={inputId} accept=".png,.jpg,.jpeg,.gif,.bmp,.webp,.ico,.svg" />
            </label>
          </ModalBody>
        ) : (
          <ModalBody className={styles.modalBody} style={{height: 400}}>
            <Cropper
              image={localUrl}
              aspect={ratio}
              crop={crop}
              zoom={zoom}
              restrictPosition={false}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={_onCropComplete}
              maxZoom={5}
            />
          </ModalBody>
        )}
        <ModalFooter className={styles.modalFooter}>
          {step === "crop" ? 
            <>
              <ZoomOut style={{cursor: "pointer"}} onClick={() => setZoom(zoom * 0.8)} />
              <ZoomIn  style={{cursor: "pointer"}} onClick={() => setZoom(zoom < 4.5 ? zoom * 1.2 : zoom)} />
              <Button  color="primary" onClick={_submitCrop} loading={loading}>Valider</Button> 
              <RotateCw style={{cursor: "pointer"}} onClick={() => _onRotateRight()} />
              <RotateCcw style={{cursor: "pointer"}} onClick={() => _onRotateLeft()} />
            </>
            : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

ImagePickerCrop.propTypes = {
  value : PropTypes.any,
  onChange : PropTypes.func,
  ratio : PropTypes.number,
};


export default ImagePickerCrop;