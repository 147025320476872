import React from "react";
import {Nav, NavItem, NavLink,
  TabContent, TabPane} from "reactstrap";
import PropTypes from "prop-types";

import styles from "../assets/scss/component/tab.module.scss";

const Tab = ({render, activeTab, onClick}) => {

  return (
    <>
      <Nav tabs>
        {render && render.map(({title, hide, overrideOnClick}, id) => (
          <NavItem key={id} style={hide ? {display: "none"} : {}}>
            <NavLink className={`${activeTab === id ? "active" : ""} ${styles.navlink}`}
              onClick={() => overrideOnClick ? overrideOnClick(id) : onClick(id)}>
              {title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className={styles.tabContentWrapper}>
        <TabContent activeTab={activeTab}>
          {render && render.map(({content}, id) => (
            <TabPane className={styles.pane} key={id} tabId={id}>{content}</TabPane>
          ))}
        </TabContent>
      </div>
    </>
  );
};

Tab.propTypes = {
  activeTab: PropTypes.number.isRequired,
  render: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      content: PropTypes.node,
      hide: PropTypes.bool
    })
  ).isRequired,
  onClick: PropTypes.func
};

export default Tab;