
import React, { useState } from "react";
import { Button } from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";

const firestore = firebase.firestore;

export default function SupplementSandBox() {

    const handleSupplements = async () => {
        alert("désactivé par sécurité");
        return;

        const groupementId = "SmZIV27oywvUZR2Z2vih";
        ///groupements/vubElEcqFad0ZysMwbHN/blocks/restaurant/supplements/0sKr7ExuBpwCWsKTiYJh
        const docs = await firestore()
            .collection("groupements")
            .doc(groupementId)
            .collection("blocks")
            .doc("restaurant")
            .collection("supplements")
            .get();

        const _supplements = [];

        docs.forEach(doc => {
            _supplements.push({...doc.data(), uid: doc.id});
        });

        _supplements.forEach(_sup => {
            const _supToUpdateData = suppToUpdate.find(_s => _s.SKU === _sup.id);
            if (_supToUpdateData) {
                _sup.idLink = _supToUpdateData.id;
            }
        });

        const batch = firestore().batch();

        _supplements.forEach((doc) => {
            const ref = firestore()
                .collection("groupements")
                .doc(groupementId)
                .collection("blocks")
                .doc("restaurant")
                .collection("supplements")
                .doc(doc.uid);

            batch.update(ref, doc);
        });

        // Commit the batch
        batch.commit().then(() => {
            console.log("Batch committed successfully.");
        }).catch((error) => {
            console.error("Error committing batch:", error);
        });
    };

    return (
        <div>
            <h2>Les Suppléments</h2>
            <Button onClick={handleSupplements}>Appliquer l'update pour Heurus</Button>

        </div>
    );
}

const suppToUpdate = [
    {
        "id": 1511130828,
        "SKU": "RBANR"
    },
    {
        "id": 1511129925,
        "SKU": "RAPER"
    },
    {
        "id": 1511130829,
        "SKU": "RBBCB"
    },
    {
        "id": 1511130826,
        "SKU": "RBAAL"
    },
    {
        "id": 1511130827,
        "SKU": "RBLAN"
    },
    {
        "id": 1511130859,
        "SKU": "RBSAL"
    },
    {
        "id": 1511130866,
        "SKU": "RBSIR"
    },
    {
        "id": 1511130830,
        "SKU": "RBBOR"
    },
    {
        "id": 1511130914,
        "SKU": "RVBOR"
    },
    {
        "id": 1511130869,
        "SKU": "RBVIN"
    },
    {
        "id": 1511130831,
        "SKU": "RBCAB"
    },
    {
        "id": 1511130874,
        "SKU": "RCAFE"
    },
    {
        "id": 1511130875,
        "SKU": "RCASO"
    },
    {
        "id": 1511130877,
        "SKU": "RCAOR"
    },
    {
        "id": 1511130873,
        "SKU": "RCAEG"
    },
    {
        "id": 1511130871,
        "SKU": "RCHAB"
    },
    {
        "id": 1511130916,
        "SKU": "RVCHB"
    },
    {
        "id": 1511130833,
        "SKU": "RBCHA"
    },
    {
        "id": 1511130918,
        "SKU": "RGASP"
    },
    {
        "id": 1511130870,
        "SKU": "RCHAR"
    },
    {
        "id": 1511130834,
        "SKU": "RBCHR"
    },
    {
        "id": 1511130836,
        "SKU": "RBCIB"
    },
    {
        "id": 1511130835,
        "SKU": "RBCID"
    },
    {
        "id": 1511130823,
        "SKU": "RCOGN"
    },
    {
        "id": 1511130832,
        "SKU": "RBCDP"
    },
    {
        "id": 1511130846,
        "SKU": "RBCOB"
    },
    {
        "id": 1511130849,
        "SKU": "RBCOT"
    },
    {
        "id": 1511130912,
        "SKU": "RVRHO"
    },
    {
        "id": 1511130851,
        "SKU": "RBCSB"
    },
    {
        "id": 1511130852,
        "SKU": "RBCSV"
    },
    {
        "id": 1511130837,
        "SKU": "RBCLA"
    },
    {
        "id": 1511130842,
        "SKU": "RBCLV"
    },
    {
        "id": 1511130841,
        "SKU": "RBCLO"
    },
    {
        "id": 1511130880,
        "SKU": "RDEJI"
    },
    {
        "id": 1511130879,
        "SKU": "RDEJE"
    },
    {
        "id": 1509827679,
        "SKU": "RDEJR"
    },
    {
        "id": 1511130881,
        "SKU": "RDEJP"
    },
    {
        "id": 1511130883,
        "SKU": "RDESS"
    },
    {
        "id": 1509828179,
        "SKU": "RDIGE"
    },
    {
        "id": 1511130887,
        "SKU": "RDINX"
    },
    {
        "id": 1511130885,
        "SKU": "RDINI"
    },
    {
        "id": 1511130896,
        "SKU": "RPOFD"
    },
    {
        "id": 1511130886,
        "SKU": "RDINR"
    },
    {
        "id": 1511130888,
        "SKU": "REAGA"
    },
    {
        "id": 1511130890,
        "SKU": "REAUG"
    },
    {
        "id": 1511130891,
        "SKU": "REAUM"
    },
    {
        "id": 1516465856,
        "SKU": "RDPEN"
    },
    {
        "id": 1516465857,
        "SKU": "RFDIN"
    },
    {
        "id": 1516465853,
        "SKU": "RFPDJ"
    },
    {
        "id": 1516465859,
        "SKU": "RFDED"
    },
    {
        "id": 1516465860,
        "SKU": "RFDDL"
    },
    {
        "id": 1511130893,
        "SKU": "RFRIT"
    },
    {
        "id": 1511130825,
        "SKU": "RGET2"
    },
    {
        "id": 1511130878,
        "SKU": "RCGOU"
    },
    {
        "id": 1511130822,
        "SKU": "RKIR"
    },
    {
        "id": 1511130819,
        "SKU": "RCOIN"
    },
    {
        "id": 1510646802,
        "SKU": "XLIVR"
    },
    {
        "id": 1511129926,
        "SKU": "RMART"
    },
    {
        "id": 1511130853,
        "SKU": "RBMER"
    },
    {
        "id": 1511130913,
        "SKU": "RVMER"
    },
    {
        "id": 1511130855,
        "SKU": "RBMUS"
    },
    {
        "id": 1511130820,
        "SKU": "RPAST"
    },
    {
        "id": 1516465852,
        "SKU": "RPDJE"
    },
    {
        "id": 1511130894,
        "SKU": "RPDEJ"
    },
    {
        "id": 1511130900,
        "SKU": "RPVIP"
    },
    {
        "id": 1511130899,
        "SKU": "RPVIG"
    },
    {
        "id": 1511130895,
        "SKU": "RPLAP"
    },
    {
        "id": 1511130821,
        "SKU": "RPORT"
    },
    {
        "id": 1511130897,
        "SKU": "RPOTA"
    },
    {
        "id": 1511130898,
        "SKU": "RPOTD"
    },
    {
        "id": 1511130901,
        "SKU": "RREMI"
    },
    {
        "id": 1511130903,
        "SKU": "RREPF"
    },
    {
        "id": 1511130856,
        "SKU": "RBRDL"
    },
    {
        "id": 1511130864,
        "SKU": "RBSEB"
    },
    {
        "id": 1511130915,
        "SKU": "RVSEM"
    },
    {
        "id": 1511130863,
        "SKU": "RBSCH"
    },
    {
        "id": 1511130858,
        "SKU": "RBSAC"
    },
    {
        "id": 1511130860,
        "SKU": "RBSAU"
    },
    {
        "id": 1511130867,
        "SKU": "RBSNB"
    },
    {
        "id": 1511130868,
        "SKU": "RBSNV"
    },
    {
        "id": 1511130905,
        "SKU": "RSUPA"
    },
    {
        "id": 1511130910,
        "SKU": "RVJDF"
    },
    {
        "id": 1511130911,
        "SKU": "RVVIN"
    },
    {
        "id": 1511130908,
        "SKU": "RVIEN"
    },
    {
        "id": 1511130854,
        "SKU": "RBMRP"
    },
    {
        "id": 1511130857,
        "SKU": "RBRVB"
    },
    {
        "id": 1511130904,
        "SKU": "RROCO"
    },
    {
        "id": 1511130824,
        "SKU": "RWISK"
    }
];