import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Input, FormGroup, Select,
} from "../../../lib/components";
import roles from "enums/roles";
import useGroupement from "hooks/useGroupement";
import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";
import useMicroService from "helpers/microService";
import useUI from "hooks/ui.hook";

const functions = firebase.functions;

const AddModal = (props) => {
  const [ui] = useUI();
  const execMicroService = useMicroService();
  const { isOpen, toggle } = props;
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { groupement, establishments, loading: loadingEstablishments } = useGroupement();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [role, setRole] = useState(null);
  const [establishment, setEstablishment] = useState(null);
  const [region, setRegion] = useState(null);

  useEffect(() => {
    if (role != "regionAdmin") {
      setRegion(null);
    }
  }, [role]);

  const _add = async () => {
    setLoading(true);
    try {
      let _error = {};
      if (!name) _error.name = "Ce champ est obligatoire";
      if (!surname) _error.surname = "Ce champ est obligatoire";
      if (!mail) _error.mail = "Ce champ est obligatoire";
      if (mail && !mail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) _error.mail = "L'adresse mail n'est pas valide";
      if (!role) _error.role = "Ce champ est obligatoire";
      if (!establishment) _error.establishment = "Ce champ est obligatoire";

      setError(_error);
      if (Object.keys(_error).length > 0) return;
      let res = await execMicroService("createUserFromSuperadmin", {
        name,
        surname,
        mail,
        role: role.value,
        establishment: establishment.value,
        groupement: region ? ui.user.groupement ?? null : null,
        region: region ?? null,
      });
      if (res.status === 200) {
        setName("");
        setSurname("");
        setMail("");
        setRole(null);
        toast.success("Utilisateur ajouté, il recevra un mail avec ses codes pour se connecter");
      } else {
        console.error(res.data);
        toast.error("Erreur lors de la création de l'utilisateur");
        toast.error("Cette adresse mail est peut-être déjà prise ou est incorrecte");
      }
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Ajouter un administrateur</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Select label="Role"
            invalid={error.role}
            placeholder="Role..."
            value={role}
            onChange={e => setRole(e)}
            options={roles.filter(i => i.value !== "superadmin")
              .filter(i => i.value !== "groupementAdmin")
              .filter(i => i.value !== "seniorTotem")
              .filter(i => groupement?.regions && groupement?.regions.length > 0 ? true : i.value === "regionAdmin" ? false : true)}
          />
          <FormGroup><Input invalid={error.name} label="Nom" placeholder="Nom..." value={name} onChange={e => setName(e.target.value)} /></FormGroup>
          <FormGroup><Input invalid={error.surname} label="Prénom" placeholder="Prénom..." value={surname} onChange={e => setSurname(e.target.value)} /></FormGroup>
          <FormGroup><Input invalid={error.mail} label="Email" placeholder="@" value={mail} onChange={e => setMail(e.target.value)} /></FormGroup>

        </FormGroup>
        {role && role.value === "regionAdmin" ?
          <FormGroup>
            <Select label="Région" value={{ value: region, label: region }} onChange={e => setRegion(e?.value ?? null)} options={groupement?.regions?.map(r => ({ value: r, label: r })) ?? []} />
          </FormGroup>
          :
          <FormGroup>
            <Select label="Établissement" invalid={error.establishment} value={establishment} onChange={e => setEstablishment(e)} options={establishments.map(i => ({ label: i.name, value: i.uid }))} />
          </FormGroup>
        }

      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>Retour</Button>
        <Button color='primary' onClick={_add} loading={loading}>Ajouter</Button>
      </ModalFooter>
    </Modal>
  );
};

AddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};


export default AddModal;