import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;


export const setMenuBatchGroupement = async ({ idGroupement, presta, menus }, err) => {
  try {
    const batch = firestore().batch();
    Object.entries(menus).forEach(([uid, data]) => {
      const _data = { ...data, created: true };
      batch.set(
        firestore()
          .collection("groupements").doc(idGroupement)
          .collection("menu")
          .doc(presta)
          .collection("menu")
          .doc(uid),
        _data,
      );
    });
    await batch.commit();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

//--------------------------  -------------------------------//

export const getMenusByPeriodGroupement = async ({ idGroupement, presta, start, end }, err) => {
  try {
    const res = await firestore()
      .collection("groupements")
      .doc(idGroupement)
      .collection("menu")
      .doc(presta)
      .collection("menu")
      .where("day", ">=", start)
      .where("day", "<=", end)
      .get();
    let _data = [];
    res.forEach(doc => _data.push({ uid: doc.id, ...doc.data() }));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

//--------------------------  -------------------------------//

export const getMenusByPeriodGroupement_rt = ({ idGroupement, presta, start, end }, cb, err) => {
  const subscribe = firestore().collection("groupements").doc(idGroupement)
    .collection("menu")
    .doc(presta)
    .collection("menu")
    .where("day", ">=", start)
    .where("day", "<=", end)
    .onSnapshot(snap => {
      let _data = snap.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
      cb(_data);
    }, err ?? (() => { }));
  return subscribe;
};