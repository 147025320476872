import React, {useState} from "react";
import {Modal, ModalHeader, ModalBody, Input} from "lib/components";
import { Plus } from "react-feather";
import useGazette from "../../../context";

import pageTypes from "../pageTypes";
import contentTypes from "../contentTypesHeurus/";
import styles from "assets/scss/pages/Gazette/PageEditor.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const PageEditorHeurus = () => {
  const [ctx, dispatch] = useGazette();
  const [pageTypeModalOpen, setPageTypeModalOpen] = useState(false);
  const [contentTypeModalOpen, setContentTypeModalOpen] = useState(false);

  const _addPage = (pageType) => {dispatch({type: "addPage", pageType}); setPageTypeModalOpen(false);};
  const _onRemovePage = (page) => dispatch({type: "removePage", uid: page.uid});
  const _onEditPage = (page, key, value) => dispatch({type: "setPage", page: {...page, [key]: value}});
  const _onCloseContentTypeModal = () => {
    dispatch({type: "setSelectedPage", page: null});
    dispatch({type: "setSelectedField", field: null});
    setContentTypeModalOpen(false);
  };
  const _onAddContent = (page, field) => {
    dispatch({type: "setSelectedPage", page});
    dispatch({type: "setSelectedField", field});
    setContentTypeModalOpen(true);
  };
  const _onRemoveContent = (page, field) => {
    let _page = {...page};
    _page[field] = null;
    dispatch({type: "setPage", page: _page});
  };
  const _onEditContent = (page, field) => {
    dispatch({type: "setSelectedPage", page});
    dispatch({type: "setSelectedField", field});
    dispatch({type: "setSelectedContent", contentType: contentTypes.find(i => i.slug === JSON.parse(page[field.key]).contentType)});
    dispatch({type: "setStep", step: 2});
  };
  const _onSubmitContentModal = (contentType) => {
    dispatch({type: "setSelectedContent", contentType});
    // ctx.step + 1 implique que <PagesEditor /> doit toujours être suivi par <ContentEditor />
    dispatch({type: "setStep", step: ctx.step + 1});
    setContentTypeModalOpen(false);
  };

  const _onMovePage = (oldIndex, newIndex) => {
    const _pages = ctx.pages;
    if (oldIndex < 0 || oldIndex >= _pages.length || newIndex < 0 || newIndex >= _pages.length) {
      console.error("Index invalide.");
      return;
  }

    const element = _pages.splice(oldIndex, 1)[0];
    _pages.splice(newIndex, 0, element);

    dispatch({type: "setPages", pages: _pages});
  };


  return (
    <div className={styles.container}>
      <div className={styles.pagesContainer}>
        {ctx.pages.map((page, index) => (
          <div key={index}>
            {pageTypes.find(type => type.slug === page.slug) ? 
              pageTypes.find(type => type.slug === page.slug).component({
                page,
                onEditPage: (key, value) => _onEditPage(page, key, value),
                onRemove: () => _onRemovePage(page),
                onAddContent: (field) => _onAddContent(page, field),
                onRemoveContent: (field) => _onRemoveContent(page, field),
                onEditContent: (field) => _onEditContent(page, field),
                contentTypes: contentTypes 
              })
              : null}
              <div style={{display: "flex", width: "100%", justifyContent: "center", gap: 10, alignItems :"center", marginTop: 25}}>
                <FontAwesomeIcon
                  className={styles.pageArrow}
                  icon={faArrowLeft}
                  onClick={() => {
                    _onMovePage(index, index - 1);
                  }}
                />
                <p>Page N°</p>
                <Input
                  style={{width: 100}}
                  onBlur={(e) => {e.target.value !== "" && _onMovePage(index, parseInt(e.target.value) - 1); e.target.value = "";}}
                  onEnterPressed={(e) => { e.target.value !== "" && _onMovePage(index, parseInt(e.target.value) - 1); e.target.value = "";}}
                  type="number" 
                  min="1" 
                  max={ctx.pages.length} 
                  placeholder={index + 1}
                />
                <FontAwesomeIcon
                  className={styles.pageArrow}
                  icon={faArrowRight}
                  onClick={() => {
                    _onMovePage(index, index + 1);
                  }}
                />
              </div>
          </div>
        ))}
        <div className={`${styles.addPage}`} onClick={() => setPageTypeModalOpen(true)}><Plus size={120} /></div>
      </div>

      <Modal isOpen={pageTypeModalOpen} toggle={() => setPageTypeModalOpen(false)} size="lg">
        <ModalHeader>Ajouter un type de page</ModalHeader>
        <ModalBody>
          <div className={styles.typeContainer}>
            {pageTypes.map(pageType => (
              <React.Fragment key={pageType.slug}>
                <pageType.miniature onClick={() => _addPage(pageType)}/>
              </React.Fragment>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={contentTypeModalOpen} toggle={_onCloseContentTypeModal} size="lg">
        <ModalHeader>Ajouter un type de contenu</ModalHeader>
        <ModalBody>
          <div className={styles.typeContainer}>
            {ctx.selectedField ? contentTypes.filter(i => i.formatCompatibility.includes(ctx.selectedField.format) && i.slug !== "text").map(contentType => (
              <div className={styles.typeItem} key={contentType.slug} onClick={() => _onSubmitContentModal(contentType)}>
                <p>Bloc de type &quot;{contentType.name}&quot;</p>
              </div>
            )) : null}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PageEditorHeurus;