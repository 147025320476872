import React, {useState} from "react";
import JabiLogo from "assets/images/jabiLogo/LOGO_JABI.png";
import { Button } from "lib/components";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "hooks/ui.hook";

const firestore = firebase.firestore;

const RenderError = (props) => {
  const {
    error,
    resetErrorBoundary
  } = props;
  const [displayStack, setDisplayStack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [ui] = useUI();

  const _handleSubmit = async () => {
    setLoading(true);
    try {
      await firestore().collection("errorReports").add({
        user: ui?.user?.uid,
        establishment: ui?.user?.establishment,
        message: error.message,
        stack: error.stack,
        url: window.location.href,
        status: "reviewing",
        createdAt: new Date(),
      });
      setMessage({color: "green", text: "Le rapport d'erreur a été envoyé avec succès"});
    } catch (e) {
      console.error(e);
      setMessage({color: "red", text: "Une erreur est survenue, vérifiez votre connection internet"});
    }
    setLoading(false);
  };

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", width: "100vw"}}>
      <img src={JabiLogo} style={{maxWidth: 300}} />
      <h2>Oops, une erreur est survenue</h2>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <span style={{marginBottom: 25}}>N'hésitez pas à nous contacter si cette erreur persiste.</span>
      {displayStack && (
        <div style={{marginTop: 25, marginLeft: 10, marginRight: 10, border: "1px solid grey", borderRadius: 10, padding: 20}}>
          <h3>{error.message}</h3>
          <span>{error.stack}</span>
          {message && 
            <p style={{color: message.color, textAlign: "center", marginTop: 15, marginBottom: 15}}>{message.text}</p>}
          <div style={{marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center"}}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Button loading={loading} onClick={_handleSubmit} color="secondary">Envoyer le rapport d'erreur</Button>
          </div>
        </div>
      )}
      <Button onClick={() =>{window.location="/";}} style={{marginTop: 15}}>Retour</Button>
      <div style={{position: "absolute", top: 5, left: 5}}>
        <span style={{color: "#AAA", cursor: "pointer"}} onClick={() => setDisplayStack(true)}>Afficher le code erreur</span>
      </div>
    </div>
  );
};

RenderError.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.any,
};

export default RenderError;