import React from "react";
import PropTypes from "prop-types";
import styles from "../assets/scss/layout/public.module.scss";

const PublicLayout = ({children, header}) => (
  <div className={styles.publicLayout}>
    <div className={styles.header}>
      {header}
    </div>
    <div className={styles.body}>
      {children}
    </div>
  </div>
);

PublicLayout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
};

export default PublicLayout;