import React from "react";
import {Container as BootstrapContainer} from "reactstrap";

const Container = (props) => {
  return (
    <BootstrapContainer {...props}>
      {props.children}
    </BootstrapContainer>
  );
};

Container.propTypes = BootstrapContainer.propTypes;

export default Container;