import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {ImagePickerService, Input} from "lib/components";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";
import { CheckCircle } from "react-feather";

import styles from "assets/scss/cultureQuizzQuestionTypes/trueFalse.module.scss";

const MultipleChoice = (props) => {
  const {question, onChange, setImageLoaderForm} = props;

  const _onChange = (key, value) => onChange({...question, [key]: value});
  const _batchChange = (obj) => onChange({...question, ...obj});
  const _onChangeAnswers = (e, index) => {
    let _answers = [...(question?.answers ?? [])];
    _answers[index] = e.target.value;
    _onChange("answers", _answers);
  };

  useEffect(() => {
    if (question?.answers) return;
    _batchChange({
      answer: 0,
      answers: ["", "", "", ""],
      explications: "",
      media: null,
      mediaType: null,
      title: "",
    });
  }, [question]);

  if (!question?.answers) return <div className={styles.container}></div>;

  return (
    <div className={styles.container}>
      <Input value={question?.title ?? ""}
        onChange={e => _onChange("title", e.target.value)}
        placeholder="Titre de la question"
        className={styles.questionTitle}
        />
      
      <ImagePickerService
        max={1}
        onChange={e => {
          console.log(e);
          if (typeof(e) === "string"){
            _batchChange({media: e, mediaType: "image"});
          } else if (typeof(e) === "object" && e && e.urls && e.urls.raw){
            _batchChange({media: e.urls.raw, mediaType: "image"});
          }
          else {
            _batchChange({media: "", mediaType: ""});
          }
        }}
        value={question?.media ?? null}
        theme="grey"
      />

      <div className={styles.answers}>
        <div className={styles.answerMultiple}>
          <Input placeholder="Réponse 1" style={{flex: 1}} value={question?.answers[0]} onChange={e => _onChangeAnswers(e, 0)} />
          <CheckCircle size={28} color={question?.answer === 0 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => _onChange("answer", 0)} />
        </div>
        <div className={styles.answerMultiple}>
          <Input placeholder="Réponse 2" style={{flex: 1}} value={question?.answers[1]} onChange={e => _onChangeAnswers(e, 1)} />
          <CheckCircle size={28} color={question?.answer === 1 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => _onChange("answer", 1)} />
        </div>
      </div>
      <div className={styles.answers}>
        <div className={styles.answerMultiple}>
          <Input placeholder="Réponse 3" style={{flex: 1}} value={question?.answers[2]} onChange={e => _onChangeAnswers(e, 2)} />
          <CheckCircle size={28} color={question?.answer === 2 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => _onChange("answer", 2)} />
        </div>
        <div className={styles.answerMultiple}>
          <Input placeholder="Réponse 4" style={{flex: 1}} value={question?.answers[3]} onChange={e => _onChangeAnswers(e, 3)} />
          <CheckCircle size={28} color={question?.answer === 3 ? "#0F0" : "#CCC"} style={{cursor: "pointer"}} onClick={() => _onChange("answer", 3)} />
        </div>
      </div>

      {/*<div className={styles.answers}>
        {question.answers &&
          question.answers
            .filter((i) => i)
            .map((answer, id) => (
              <span
                key={id}
                className={`${styles.answer} ${question.answer === id ? styles.isCorrect : ""}`}
              >
                {answer}
              </span>
            ))}
        </div>*/}

      <Input value={question?.explications ?? ""}
        onChange={e => _onChange("explications", e.target.value)}
        placeholder="Explications"
        className={styles.explanation}
        />
    </div>
  );
};

MultipleChoice.propTypes = {
  question : PropTypes.object.isRequired,
  onChange : PropTypes.func.isRequired,
  setImageLoaderForm : PropTypes.func.isRequired
};

export default MultipleChoice;
