/* eslint-disable react/prop-types */
import {useMemo, useState} from "react";
import { Container, Row, Col, Table, Input } from "lib/components";
import useBornes from "./useBornes";
import { Info } from "react-feather";
import {useDebounce} from "@uidotdev/usehooks";
import BorneModal from "./BorneModal";
import Paginate from "react-paginate";

const BorneItem = ({item}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <tr>
      <td>{item.name} ({item.address}) <Info onClick={() => setIsOpen(!isOpen)} style={{cursor: "pointer"}} /></td>
      <td>{item?.groupement?.name}</td>
      <td>{item?.establishment?.name}</td>
      <td>{item.computedLastHandshake}</td>
      <td style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {/* check if item.latestHandshakeAt is inforior at 10min*/ }
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
        <div style={{width: 25, height: 25, backgroundColor: item.vpnState?.color, borderRadius: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div style={{width: 15, height: 15, backgroundColor: item.appState?.color, borderRadius: 50}}>
          </div>
        </div>
        <span style={{textAlign: "center", marginTop: 3, fontSize: 12}}>{item.vpnState.text}</span>
        <span style={{textAlign: "center", marginTop: 3, fontSize: 12}}>{item.appState.text}</span>
      </div>
      </td>
      <td>
        launcher: {item.launcherVersion ?? "N/A"}<br />
        app: {item.version ?? "N/A"}
      </td>
      {isOpen ? <BorneModal machineId={item.name} item={item} isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
    </tr>
  )
}

const Bornes = () => {
  const [search, setSearch] = useState("");
  const filter = useMemo(() => ({filter: {search}}), [search]);
  const debouncedSearch = useDebounce(filter, 500);

  const bornes = useBornes(debouncedSearch);

  const bornesCutInPages = useMemo(() => {
    let pages = []; 

    //cut results in pages of 30
    for (let i = 0; i < bornes.length; i += 30) {
      pages.push(bornes.slice(i, i + 30));
    }

    return pages;
  }, [bornes]);
  const [page, setPage] = useState(0);
  const pageCount = useMemo(() => bornesCutInPages.length, [bornesCutInPages]);

  return (
    <div style={{width: "100%"}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <h1>Bornes</h1>
        <div>
          <Input placeholder="Search..." value={search} onChange={e => {setSearch(e.target.value); setPage(0);}} />
        </div>
      </div>

      <Container><Row><Col lg={12}>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Groupement</th>
              <th>Etablissement</th>
              <th>Latest Handshake At</th>
              <th>Connected</th>
              <th>Version</th>
            </tr>
          </thead>
          <tbody>
            {(bornesCutInPages[page] ?? []).map(i => <BorneItem item={i} key={i.address} />)}
          </tbody>
        </Table>
      </Col></Row></Container>
      <Paginate
        className="react-paginate"
        breakLabel={"..."}
        nextLabel="next"
        onPageChange={e => {
          setPage(e.selected);
          window.scrollTo(0, 0);
        }}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default Bornes;