import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import moment from "moment";
import PropTypes from "prop-types";
import { useMenuReservation } from "../../../router_context";
import styles from "../../../Basic/Assets/scss/modalInformations.module.scss";
import { Tag } from "lib/components";
import {findSubscriptionForDate} from "helpers/subscription"

const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const fixe = ["assiette de fromage", "faisselle"];


const ModalInformations = ({ reservation, page }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenuReservation();

    const [supplements, setSupplements] = useState([]);

    // const dataDay = ctx?.data[ctx?.modalInfos?.date];
    const currentUser = ctx?.users[ctx?.modalInfos?.uid] ?? (ctx.guests && ctx.guests.find(guest => guest.guestId === ctx.modalInfos.uid)) ?? {};    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription2;
    const subscriptionObject = findSubscriptionForDate(currentUser?.subscriptions, ctx?.modalInfos?.date);
    const subscription = subscriptionObject?.subscription;
    const dataDay = ctx?.data[ctx?.modalInfos?.date];
    const isCocotte = moment(ctx.modalInfos.date).weekday() === 6 && ctx.modalInfos.repas === "Déjeuner";

    // const supplements = useMemo(() => {
    //     const _supp = [];

    //     if (template && templateSubscription && subscription && templateSubscription[subscription].repas[ctx?.modalInfos?.repas]) {
    //         // s'il y a un abonnement pour ce repas.

    //         // maintenant on check tous les plats qui sont réservés alors qu'ils ne sont pas dans l'abonnement.
    //         template[ctx.modalInfos.repas].categories.forEach(_plat => {
    //             if (reservation[0][_plat] && !templateSubscription[subscription].repas[ctx?.modalInfos?.repas].includes(_plat)) _supp.push(_plat);
    //         });
    //     }
    //     return _supp;
    // }, [ctx, reservation]);

    const _reservation = useMemo(() => {
        return reservation.map(r => r.plates ? r.plates : r);
    }, [reservation]);

    useEffect(() => {
        if (
            currentUser
            && !currentUser.guestId
            && ctx.dataOrdersSupplements
            && ctx.dataOrdersSupplements[ctx.modalInfos.date]
            && ctx.dataOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid]
            && ctx.dataOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas]){
            setSupplements(ctx.dataOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas]);
        }
        else if (
            currentUser
            && currentUser.guestId
            && ctx.dataGuestOrdersSupplements
            && ctx.dataGuestOrdersSupplements[ctx.modalInfos.date]
            && ctx.dataGuestOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid]
            && ctx.dataGuestOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas]){
            setSupplements(ctx.dataGuestOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas]);
        }
        else setSupplements([]);
    }, [ctx]);


    const selectedFormula = useMemo(() => {
        if (ctx.modalInfos.repas === "Déjeuner") {
            let count = 0;
            if (_reservation[page]) {

                if (_reservation[page]["entrée"]) count++;
                if (_reservation[page]["plat"]) count++;
                if (_reservation[page]["dessert"] && reservation[page]["dessert"]?.length > 0) count++;
            }

            if (count === 2) return "DUO";
            if (count === 3) return "TRIO";

            return null;
        }
        if (ctx.modalInfos.repas === "Dîner") {
            let count = 0;
            if (_reservation[page]) {

                if (_reservation[page]["entrée"]) count++;
                if (_reservation[page]["plat"]) count++;
                if (_reservation[page]["dessert"] && reservation[page]["dessert"]?.length > 0) count++;
            }

            if (count >= 2) return "DUO";
            return null;
        }
        return null;
    }, [dataDay, reservation, ctx, page]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}> {currentUser?.surname} {currentUser?.name} {page > 0 ? `(Invité ${page})` : ""}</div>
            </div>
            {page === 0 && templateSubscription ?
                <div className={styles.content}>
                    <div className={styles.title}> Abonnement : </div>
                    {subscription ?
                        subscription === "1/2p Duo" ?
                            <div>
                                <Tag className={styles.tag} style={{ backgroundColor: colors[0] }}><div>Demi Pension</div></Tag>
                                <Tag className={styles.tag} style={{ backgroundColor: colors[1] }}><div>DUO</div></Tag>
                            </div>
                            :
                            subscription === "1/2p Trio" ?
                                <div>
                                    <Tag className={styles.tag} style={{ backgroundColor: colors[0] }}><div>Demi Pension</div></Tag>
                                    <Tag className={styles.tag} style={{ backgroundColor: colors[2] }}><div>TRIO</div></Tag>
                                </div>
                                : null
                        :
                        <Tag className={styles.tag} style={{ backgroundColor: colorUnite }}><div>Unité</div></Tag>
                    }
                </div>
                : null}

            <div className={styles.content}>
                <div className={styles.title}> Formule sélectionnée : </div>
                {selectedFormula ? <div>
                    <Tag className={styles.tag} style={{ backgroundColor: colors[selectedFormula === "DUO" ? 1 : 2] }}><div>{selectedFormula}</div></Tag>
                </div> : null}
                {
                    template[ctx.modalInfos.repas].categories.map((_categ, indx) => (
                        _reservation[page] && _reservation[page][_categ] && _categ !== "garniture" && (_categ === "dessert" ? reservation[page]["dessert"]?.length > 0 : true) ? <div key={indx}>{_categ} </div> : null
                    ))
                }
            </div>
            {_reservation[page] && _reservation[page]["supplément"] ?
                <div className={styles.content}>
                    <div className={styles.title}> Supplément <span className={styles.priceUp}>(€)</span> : </div>
                    {
                        <div>{fixe.includes(_reservation[page]["supplément"]) ? _reservation[page]["supplément"] : "dessert"} </div>
                    }
                </div>
                : null
            }
            {(page === 0 || currentUser?.guestId) && supplements.length > 0 ?
                <div className={styles.content}>
                    <div className={styles.title}> Suppléments sélectionnés : </div>
                    {
                        supplements.map((i, indx) => (
                            <div key={i.id} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <span style={{textAlign: "center"}}>{i.name}  <span style={{ fontSize: 16, fontStyle: "italic" }}>({i.id}) </span> <span style={{ fontWeight: "bold" }}>x{i.number}</span></span>
                        </div>
                        ))
                    }
                </div>
                : null}

            {templateSubscription && subscription && page === 0 && templateSubscription[subscription].repas[ctx?.modalInfos?.repas] && subscription.toUpperCase().includes("DUO") && selectedFormula === "TRIO" ?
                <div className={styles.content}>
                    <div className={styles.title}> Surclassement <span className={styles.priceUp}>(€)</span> : </div>
                    <Tag className={styles.tag} style={{ backgroundColor: colors[selectedFormula === "DUO" ? 1 : 2] }}><div>{selectedFormula}</div></Tag>
                </div>
                : null}

            {templateSubscription && subscription ?
                page === 0 ?
                <div className={styles.content}>
                    {subscription && templateSubscription[subscription].repas[ctx?.modalInfos?.repas] ?
                        <div className={styles.text}> Le {ctx?.modalInfos?.repas} est compris dans votre abonnement.</div>
                        :
                        <div className={styles.text}> Le {ctx?.modalInfos?.repas} n&apos;est pas compris dans votre abonnement. </div>
                    }
                </div>
                : <div></div>
                : <div></div>}

        </div>
    );
};


ModalInformations.propTypes = {
    reservation: PropTypes.array,
    page: PropTypes.number,
};

export default ModalInformations;
