import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getGroupementById = async ({id}, err) => {
  try {
    const res = await firestore()
                        .collection("groupements")
                        .doc(id)
                        .get();
    if (!res.exists) return 0;
    return {uid: res.id, ...res.data()};
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const updateGroupementById = async ({id, data}, err) => {
  try {
    await firestore()
          .collection("groupements")
          .doc(id)
          .update(data); 
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};


export const updateAllGroupementEstablishmentsById = async ({idList, data}, err) => {
  try {
    const batch = firestore().batch();
    idList.forEach((idEtab) => {
      batch.update(
        firestore().collection("establishments").doc(idEtab),
        data
      );
    });
    await batch.commit();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

export const getAllGroupements = async (_, err) => {
  try{
    let res = await firestore()
                              .collection("groupements")
                              .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

export const getAllGroupements_rt = (_, cb, err) => (
  firestore()
    .collection("groupements")
    .onSnapshot(snap => {
      const data = snap.docs.map(doc => ({
        uid: doc.id,
        ...doc.data()
      }));
      cb(data);
    }, err ?? (() => {}))
);

export const createGroupement = async ({data}, err) => {
  try {
    const res = await firestore()
                        .collection("groupements")
                        .add({
                          ...data,
                        });
    return {uid: res.id, ...data};
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};