import React, {useState, useEffect} from "react";
import { Card, Table, Input, Button } from "../../../lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "../../../hooks/ui.hook";
import styles from "../../../assets/scss/pages/notifications.module.scss";
import {Plus, Trash} from "react-feather";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

const Topics = () => {
  const [ui] = useUI();
  const [topics, setTopics] = useState([]);

  const [title, setTitle] = useState("");

  useEffect(() => {
    const subscribe = firestore().collection("establishments").doc(ui.user.establishment)
      .collection("topics").onSnapshot(snapshot => {
        let _topics = [];
        snapshot.forEach(doc => {
          _topics.push({...doc.data(), uid: doc.id});
        });
        setTopics(_topics);
      });
    return subscribe;
  }, []);

  const _onAdd = async () => {
    try {
      if (!title) return;
      await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("topics").add({
          title,
          origin: "manual"
        });
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const _onDelete = async (uid) => {
    try {
      await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("topics").doc(uid).delete();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };


  if (!ui.user.superadmin) return <></>;

  return (
    <Card className={styles.topics}>
      <h4>Listes de diffusion</h4>
      <div className={styles.header}>
        <Input className={styles.input} type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="Titre de la liste de..." 
          onEnterPressed={_onAdd}/>{" "}
        <Button color="primary" onClick={_onAdd}><Plus color="white" /></Button>
      </div>
      <div className={styles.topicsContainer}>
        <Table>
          <thead>
            <tr>
              <th>Titre</th>
              <th>Origine</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {topics.map(t => (
              <tr key={t.uid}>
                <td>{t.title}</td>
                <td>{t.origin === "manual" ? "manuelle" : 
                  t.origin === "animationCategory" ? "catégorie planning d'animation" : ""}</td>
                <td>
                  {/*<Edit color="#300438" style={{cursor: "pointer"}} />{" "}*/}
                  <Trash color="#e3707f" style={{cursor: "pointer"}} onClick={() => _onDelete(t.uid)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default Topics;