import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import HeaderImage from "./assets/DailyHeaderImage.png"
import FooterImage from "./assets/DailyFooterImage.png"

import MontserratBlack from "fonts/montserrat/Montserrat-Black.ttf";

Font.register({
  family: "Montserrat",
  fontWeight: "heavy",
  src: MontserratBlack,
})

const styles = StyleSheet.create({
  page: {
    height: "100%",
    width: "100%",
    paddingLeft: 25,
    paddingTop: 29,
    paddingRight: 59,
  },
  globalContainer: {
    width: "100%",
    height: "100%",
  },
  header: {
    backgroundColor: "#1BB8AD",
    width: "100%",
    height: 72,
  },
  headerText: {
    fontSize: 42,
    fontFamily: "Montserrat",
    fontWeight: "heavy",
    color: "#FFF",
    width: 745,
    paddingHorizontal: 10,
    position: "relative",
    top: 10
  },
  headerImage: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
  },
  menu: {

  },
  menuHeader: {
    backgroundColor: "#FBAF2B",
    width: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: 32
  },
  menuHeaderText: {
    color: "#FFF",
    fontSize: 20,
    position: "relative",
    top: 4,
    fontFamily: "Montserrat",
    fontWeight: "heavy",
  },
  menuContent: {
    display: "flex",
    gap: 30,
    paddingVertical: 10
  },
  menuCategory: {
    display: "flex",
    gap: 5
  },
  menuText: {
    textAlign: "center",
    fontSize: 18
  },
  footer: {
    backgroundColor: "#1BB8AD",
    width: "100%",
    height: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footerImage: {
    marginVertical: 10,
    marginLeft: 10,
    objectFit: "contain"
  },
  footerText: {
    fontSize: 32,
    fontFamily: "Montserrat",
    fontWeight: "heavy",
    color: "#FFF",
    marginRight: 120,
    position: "relative",
    top: 4,
  },
});

const ExportDay = (props) => {
  const { day, data, ui } = props;
  const template = ui.establishment.template;

  const categoryGroups = [
    [
      "Potage",
      "Entrée",
      "Plat",
      "Accompagnement",
    ],
    [
      "Fromage",
      "Dessert",
      "Boisson chaude",
    ],
  ];

  // Repas triés par heure : ["Déjeuner", "Dîner"]
  const sortedRepas = Object.keys(template).sort((a,b) => template[a].heure - template[b].heure);

  return (
    <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={styles.globalContainer}>
            <View style={styles.header}>
              <Text style={styles.headerText}>Ce {day.toLocaleDateString("fr-FR", { weekday: "long" })},</Text>
              <Image style={styles.headerImage} src={HeaderImage}></Image>
            </View>
            {sortedRepas.map(_repas => (
              <View style={styles.menu} key={_repas}>
                <View style={styles.menuHeader}>
                  <Text style={styles.menuHeaderText}>{_repas}</Text>
                </View>
                {Object.entries(template[_repas].menus).sort(([key1, value1], [key2, value2]) => value1.weight - value2.weight).slice(0, 1).map(([_menuName, _menuContent]) => (
                  <View style={styles.menuContent} key={_menuName}>
                    {categoryGroups.map((_categoryGroup, index) => (
                      <View style={styles.menuCategory} key={index}>
                        {
                          _menuContent.categories.filter(_category => _categoryGroup.includes(_category)).map(_category => {
                            const [_dish, index] = Object.values(data[_repas][_category]).filter(dish => dish.origin === _menuName).slice(0, 1);
                            
                            return <Text style={styles.menuText} key={index}>{_dish?.name}</Text>;
                          })
                        }
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            ))}
            <View style={styles.footer}>
              <Image style={styles.footerImage} src={FooterImage}></Image>
              <Text style={styles.footerText}>Bon Appétit !</Text>
            </View>
          </View>
        </Page>
    </Document>
  );
};
ExportDay.propTypes = {
  day: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

export default ExportDay;
