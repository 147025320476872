import React from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import useGroupement from "hooks/useGroupement";
import { Spinner, Table, Card, CardHeader, CardBody, Container, Row, Col, Button } from "lib/components";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import useUI from "hooks/ui.hook";
import { getEstablishmentById } from "services/ressources/establishment";

const Establishments = () => {
  const [ui, dispatch] = useUI();
  const {groupement, loading, establishments } = useGroupement();

  const _takeControl = async (_id) => {
    try {
      let _est = await getEstablishmentById({id: _id});
      let _user = { ...ui.user };

      _user.role = "owner";
      _user.establishment = _id;
      _user.groupementAdmin = true;
      dispatch({ type: "takeControl", user: _user, establishment: _est, groupement: groupement });
    } catch (e) {
      console.error(e);
    }
  };


  if (loading) return <Spinner />;
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>Etablissements</h5>
              </CardHeader>
              <CardBody>
                <Table loading={loading}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th style={{textAlign: "center"}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupement?.regions &&  groupement?.regions.length > 0 ?
                      <>
                       {groupement?.regions.map((region) => (
                        <>
                          <tr key={region} style={{backgroundColor: "#4a0956"}}>
                            <th scope="row" colSpan={2} style={{fontSize: "x-large", color: "white"}}>{region}</th>
                          </tr>
                          {establishments.filter(e => e.region === region).map((i) => (
                            <tr key={i.uid}>
                              <th scope="row">{i.name}</th>
                              <td>
                                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 30}}>                                
                                <Link to={`/dashboard/establishments/${i.uid}`}>
                                  <Edit color="#4A0956" size={22} style={{ cursor: "pointer" }} />
                                </Link>
                                {" "}
                                <Button color="danger" onClick={() => _takeControl(i.uid)}>
                                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                                  Accéder à la vue de l'établissement
                                </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                        ))}    
                      </>
                     
                    : <>
                    {establishments &&
                      establishments.map((i) => (
                        <tr key={i.uid}>
                          <th scope="row">{i.name}</th>
                          <td>
                            <Link to={`/dashboard/establishments/${i.uid}`}>
                              <Edit color="#4A0956" size={22} style={{ cursor: "pointer" }} />
                            </Link>
                            {" "}
                            <Button color="danger" onClick={() => _takeControl(i.uid)}>
                              {/* eslint-disable-next-line react/no-unescaped-entities */}
                              Accéder à la vue de l'établissement
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>}
                    
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Establishments;
