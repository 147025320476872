import React from "react";
import {Label as BootstrapLabel} from "reactstrap";
import PropTypes from "prop-types";

const Label = (props) => {
  const {
    children,
    className=""
  } = props;
  return (
    <BootstrapLabel className={className} >{children}</BootstrapLabel>
  );
};

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string
};

export default Label;