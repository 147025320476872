import React from "react";
import useUI from "hooks/ui.hook";

import Basic_UserInfos from "./Basic/Pages/User/infos";
import Basic_AddModal from "./Basic/Components/AddModal";
import Basic_SubscriptionComponent from "./Basic/Components/SubscriptionComponent";


import Heurus_UserInfos from "./Specific/Heurus/Components/infos";
import Heurus_AddModal from "./Specific/Heurus/Components/AddModal";

import AutourDeLHumain_AddModal from "./Specific/autourDeLHumain/Components/AddModal";

const UserInfos = (props) => {
  const [ui] = useUI();
  if(ui?.groupement?.specific === "heurus") return <Heurus_UserInfos {...props} />;
  return <Basic_UserInfos {...props} />;
};


const AddUserModal = (props) => {
  const [ui] = useUI();

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO" || ui?.establishment?.uid === "A1LKT1bK4Aj4TAaqPsel";

  if(isAutourDeLHumain) return <AutourDeLHumain_AddModal {...props} />;
  if(ui?.groupement?.specific === "heurus") return <Heurus_AddModal {...props} />;
  return <Basic_AddModal {...props} />;
};

const SubscriptionComponent = (props) => {
  const [ui] = useUI();

  return <Basic_SubscriptionComponent {...props} />;
};




export {UserInfos, AddUserModal, SubscriptionComponent};