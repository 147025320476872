import React from "react";
import PropTypes from "prop-types";
const ErrorPage = ({code, children}) => (
  <>
    <h1>{code}</h1>
    {children ?? ""}
  </>
);

ErrorPage.propTypes = {
  children  : PropTypes.node,
  code  : PropTypes.string,
};

export default ErrorPage;