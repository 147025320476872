import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {createContext, useReducer, useContext} from "react";
import { getAllGroupements_rt } from "services/ressources/groupement";
import { getAllEstablishments_rt } from "services/ressources/establishment";

const Context = createContext();

const Default = {
  groupementOptions: null,
  establishmentOptions: null,
};

function Reducer(state, action) {
  switch(action.type) {
  case "setProperty": return ({...state, [action.property]: action.value});
  default: return {...state};
  }
}

const Provider = ({presta ,children}) => {
  const [ctx, dispatch] = useReducer(Reducer, Default);

  const setGroupements = (_data) => {
    dispatch({ type: "setProperty", property : "groupementOptions", value: _data.map(_g => {return {value: _g.uid, label: _g.name};})});
    };


  const setEstablishments = (_data) => {
    dispatch({ type: "setProperty", property : "establishmentOptions", value: _data.map(_g => {return {value: _g.uid, label: _g.name};})});
  };

  useEffect(()=> {
    getAllGroupements_rt(null, setGroupements);
    getAllEstablishments_rt(null, setEstablishments);
  }, []);


  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  presta: PropTypes.string.isRequired,
  children: PropTypes.node,
};
const usePatchnote = () => useContext(Context);
export default usePatchnote;
export {Provider, Context};