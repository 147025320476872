import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import useService from "../../../serviceCtx";
import { createPrestataire,updatePrestataireBDD,servicesInit, deletePrestataire, addAvailabiliyPlanning,updateAvailabiliyPlanning, removeAvailabiliyPlanning } from "services/ressources/service";
import md5 from "md5";


const firestore = firebase.firestore;

const Context = createContext();


const Default = {
    availableServices: [], // la liste des types de prestataires possibles
    prestataires: [], // La liste des prestataires et leurs types de prestations
    planning: [], // la liste des prestations
    currentPrestataire: {}, // le prestataire en cours de création / modification
    page:0, //la page ou l'on est
    startPage:0, //la dernière page de départ pour revenir sur la bonne page a la fin de la création de prestataire
    chosenService:"", //le service choisi lors de la création 
};

function Reducer(state, action) {
    switch (action.type) {
        // case "setMenu": return ({ ...state, menu: action.menu });
        // case "nextWeek": return ({ ...state, week: state.week.map(day => moment(day).add(7, "day").toDate()) });
        case "setProperty": return ({ ...state, [action.property]: action.value });
        // case "setState": return { ...action.state };
        default: return { ...state };
    }
}

const Provider = ({ children }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useReducer(Reducer, Default);
    const [ctxService] = useService();

    useEffect(() => {
        (async ()  => {
          try{
            await servicesInit({ui});
          }catch(e) {
            console.log(e);
          }
        })();
        
      }, []);
    
    useEffect(() => {
        dispatch({ type: "setProperty", property: "availableServices", value: ctxService.availableServices });
    }, [ctxService.availableServices]);

    useEffect(() => {
        dispatch({ type: "setProperty", property: "prestataires", value: ctxService.prestataires });
    }, [ctxService.prestataires]);

    useEffect(() => {
        dispatch({ type: "setProperty", property: "planning", value: ctxService.planning });
    }, [ctxService.planning]);

    const updatePage = (newpage)=>{
        if(ctx.page<2){dispatch({ type: "setProperty", property: "startPage", value: ctx.page });}
        dispatch({ type: "setProperty", property: "page", value: newpage });
    };
    const returnStartPage = (newpage)=>{
        dispatch({ type: "setProperty", property: "page", value: newpage });
    };
    const updateChosenService = (newChosenService)=>{
        dispatch({ type: "setProperty", property: "chosenService", value: newChosenService });
    };
    /**
     * Ajoute une nouvelle prestation aux planning.
     *
     * @param {String} prestataireId - Id du prestataire.
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {Array} prestationsAvailable - Liste des prestations non disponibles pendant cette période.
     * @param {string} place - Lieu de la prestation.
     */
    const addAvailabiliy = (prestataireId, start, end, personnel, prestationsAvailable, place) => {

        if(prestataireId && start && end){

            const _data = {
                prestataireId,
                start,
                end,
                personnel : personnel ?? [],
                prestationsAvailable : prestationsAvailable ?? [],
                place : place ?? "",
            };
            addAvailabiliyPlanning({ui:ui,data:_data});
           

        }else{
            console.error("informations non conformes");
        }
    };

    /**
     * Modifie une prestation existante.
     *
     * @param {String} prestationId - Id de la prestation.
     * @param {String} prestataireId - Id du prestataire.
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {Array} prestationsAvailable - Liste des prestations non disponibles pendant cette période.
     * @param {string} place - Lieu de la prestation.
     */
    const updateAvailabiliy = (prestationId, prestataireId, start, end, personnel, prestationsAvailable, place) => {

        if(prestationId && prestataireId && start && end){

            const _data = {
                prestataireId,
                start,
                end,
                personnel : personnel ?? [],
                prestationsAvailable : prestationsAvailable ?? [],
                place : place ?? "",
            };
            updateAvailabiliyPlanning({ui:ui,data:_data,prestationId:prestationId});
    
        }else{
            console.error("informations non conformes");
        }
    };

    /**
     * Supprime une prestation existante.
     *
     * @param {String} prestationId - Id de la prestation.=
     */
    const removeAvailabiliy = (prestationId) => {
        if(prestationId){
            removeAvailabiliyPlanning({ui:ui,prestationId:prestationId});

        }else{
            console.error("informations non conformes");
        }
    };

    /**
     * Initialise localement un nouveau prestataire
     *
     * @param {String} name - Nom du prestataire.
     * @param {String} contact - Contact du prestataire.
     * @param {String} description - Informations supplémentaire sur le prestataire.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {String} img - Url d'une image représentant le prestataire.
     * @param {string} serviceRef - Nom de la reference du type de service (ex : coiffeur)
     */
    const initCurrentPrestataire = (name, contact, description, personnel, img, serviceRef) => {
        if(name && serviceRef){
            const _currentPrestataire = {
                name,
                contact : contact ?? "",
                description  : description ?? "",
                personnel  : personnel ?? [],
                img  : img ?? "",
                serviceRef,
                prestations: []
            };
            dispatch({ type: "setProperty", property: "currentPrestataire", value: _currentPrestataire });
        }else{
            console.error("informations non conformes pour initialiser le presta");
        }

    };
     /**
     * Modifie un prestataire existant
     *
     * @param {String} name - Nom du prestataire.
     * @param {String} contact - Contact du prestataire.
     * @param {String} description - Informations supplémentaire sur le prestataire.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {String} img - Url d'une image représentant le prestataire.
     * @param {string} serviceRef - Nom de la reference du type de service (ex : coiffeur)
     */
     const updateCurrentPrestataire = (name, contact, description, personnel, img, serviceRef,prestations) => {
        if(name && serviceRef){
            const _currentPrestataire = {
                name,
                contact : contact ?? "",
                description  : description ?? "",
                personnel  : personnel ?? [],
                img  : img ?? "",
                serviceRef,
                prestations: prestations ?? []
            };

            dispatch({ type: "setProperty", property: "currentPrestataire", value: {...ctx.currentPrestataire, ..._currentPrestataire}});
            
        }else{
            console.error("informations non conformes pour update le presta");
        }
     };
     const deleteCurrentPrestataire = () => {
        if(ctx.currentPrestataire){
            const _currentPrestataire = {};

            dispatch({ type: "setProperty", property: "currentPrestataire", value: _currentPrestataire});
            
        }else{
            console.error("informations non conformes pour delete le presta");
        }
     };

    /**
     * Ajoute une prestation au prestataire courant (pas directement dans la bdd)
     *
     * @param {String} title - Nom de la prestation.
     * @param {String} description - details de la prestation.
     * @param {Number} duration - Durée de la prestation (multiple de 15 min)
     * @param {Number} price - prix de la prestation en euros.
     */
    const addPrestation = (title, description, duration, price) => {
        if(title && duration && price){
            const prestaId = md5( title + duration.toString() + price.toString());

            dispatch({ type: "setProperty", property: "currentPrestataire", value: {...ctx.currentPrestataire, prestations : {...ctx.currentPrestataire.prestations, [prestaId]: {
                title,
                description : description ?? "",
                duration,
                price,
                createdAt: new Date()
            }}}}); 
        }else{
            console.error("informations non conformes pour add une prestation");
        }
    };


     /**
     * Modifie un prestation du prestataire courant (pas directement dans la bdd)
     *
     * @param {String} prestationId - Id de la prestation.
     * @param {String} title - Nom de la prestation.
     * @param {String} description - details de la prestation.
     * @param {Number} duration - Durée de la prestation (multiple de 15 min)
     * @param {Number} price - prix de la prestation en euros.
     */
     const updatePrestation = (prestationId, title, description, duration, price) => {
        if(prestationId && title && duration && price){
            dispatch({ type: "setProperty", property: "currentPrestataire", value: {...ctx.currentPrestataire, prestations : {...ctx.currentPrestataire.prestations, [prestationId]: {
                title,
                description : description ?? "",
                duration,
                price,
            }}}});
        }else{
            console.error("informations non conformes pour update une presta");
        }
       
     };

    /**
     * Supprime une prestation du prestataire courant
     * 
     * @param {String} prestationId - Id de la prestation.
     */
    const removePrestation = (prestationId) => {
        const _prestations = deepClone(ctx.currentPrestataire.prestations);

        if(prestationId){
            delete _prestations[prestationId];
        }
            

        dispatch({ type: "setProperty", property: "currentPrestataire", value: {...ctx.currentPrestataire, prestations : _prestations}});
    };

    /**
     * Ajoute le prestataire courant dans la base de données
     */
    const addPrestataire = () => {
        createPrestataire({ui:ui,data:ctx.currentPrestataire});
    };
    
     /**
     * Update le prestataire dans la base de données à partir de son id
     */
    const updatePrestataire =  (prestataireId) =>{
       if(prestataireId){updatePrestataireBDD({ui:ui,id:prestataireId,data:ctx.currentPrestataire});}
       else{
        console.error("prestataireId non conforme");
    }
    };
     

     /**
     * Supprime un prestataire existant de la base de données.
     *
     * @param {String} prestataireId - Id du prestataire à supprimer.
     */
    const removePrestataire = (prestataireId) => {
        if(prestataireId){deletePrestataire({ui:ui,id:prestataireId});
        }else{
            console.error("prestataireId non conforme");
        }
    };


    return (
        <Context.Provider value={[ctx,{addAvailabiliy, updateAvailabiliy, removeAvailabiliy, initCurrentPrestataire, updateCurrentPrestataire, deleteCurrentPrestataire, addPrestation, updatePrestation, removePrestation, addPrestataire, removePrestataire, updatePrestataire, updatePage, returnStartPage, updateChosenService}]}>
            {children}
        </Context.Provider>
    );
};

Provider.propTypes = {
    children: PropTypes.node,
};


const useServiceManagement = () => useContext(Context);
export default useServiceManagement;
export { Provider, Context };