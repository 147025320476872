import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import { Card } from "../../../lib/components";
import { Edit3, Play, Plus, Trash, Share2 } from "react-feather";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import sleep from "lib/helpers/asyncWait";
import { VideoTuto } from "lib/components/YoutubeModal";
import QuizzShareModal from "./QuizzList_ShareModal";
import {READ, WRITE, OWNER} from "hooks/useCultureQuizz";

import useUI from "../../../hooks/ui.hook";
import useCultureQuizz from "../../../hooks/useCultureQuizz";

const firestore = firebase.firestore;
const rtd = firebase.database;

const QuizzList = () => {
  const [ui] = useUI();
  const history = useHistory();
  const {checkRights} = useCultureQuizz();

  const [activeQuizzes, setActiveQuizzes] = useState([]);
  const cultureQuiz = useCultureQuizz();
  const { quizzes } = cultureQuiz;

  const [quizToShare, setQuizToShare] = useState(null);

  //fetch active quizzes in rtd
  useEffect(() => {
    const ref = rtd()
      .ref("quizz")
      .on("value", (snapshot) => {
        let _instances = snapshot.val() ?? {};
        let _myInstances = {};
        let _instancesKeys = Object.keys(_instances);

        for (let i = 0; i < _instancesKeys.length; i++) {
          if (_instances[_instancesKeys[i]].establishment === ui.user.establishment)
            _myInstances[_instancesKeys[i]] = { ..._instances[_instancesKeys[i]] };
        }
        setActiveQuizzes(_myInstances);
      });
    return () => {
      rtd().ref("quizz").off("value", ref);
    };
  }, []);

  //update quizToShare when quizzes change
  useEffect(() => {
    if (!quizzes) return;
    if (!quizToShare) return;
    setQuizToShare(quizzes.find((q) => q.uid === quizToShare.uid));
  }, [quizzes, quizToShare]);

  const _createInstance = async (quizz/*, quizzType*/) => {
    try {
      let _quizzRtdInstance = uuidv4();
      const _quizz = quizzes.find((q) => q.uid === quizz);
      const _questions = await firestore()
        .collection("quizz")
        .doc(quizz)
        .collection("questions")
        .get();
      if (!_quizz) {
        toast.error("Une erreur est survenue");
        return;
      }

      await rtd().ref(`quizz/${_quizzRtdInstance}`).set({
        createdAt: new Date().toISOString(),
        createdBy: ui.user.uid,
        establishment: ui.user.establishment,
        title: _quizz.title,
        quizzId: _quizz.uid,
        participants: [],
        status: "waiting_participants",
        questionsCount: _questions.size,
      });
      history.push(`/dashboard/cultureQuizz/${_quizzRtdInstance}/start`);
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
    }
  };
  const _deleteInstance = async (uid) => {
    if (!window.confirm("Etes vous sur de vouloir supprimer cette partie ?")) return;

    sleep(3000);
    rtd().ref(`quizz/${uid}`).remove();
  };

  const _renderItem = (q) => (
    <Card
      key={q.uid}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 200,
        width: 220,
      }}
    >
      <h4 style={{ marginRight: 10, textAlign: "center", fontSize: 22 }}>
        {q.title ?? "Quizz sans nom"}
      </h4>
      <div style={{ display: "flex", justifyContent: "center", gap: 15 }}>
        <Play
          style={{ cursor: "pointer" }}
          onClick={() => _createInstance(q.uid, "superadmin")}
          color="#4a0956"
        />
        {checkRights(q.uid, WRITE) ? (
          <Edit3
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/dashboard/cultureQuizz/edit/${q.uid}`)}
            color="#4a0956"
          />
        ) : null}
        {ui.user.role === "owner" && !ui.groupement ? null : //si pas de groupement, pas de partage
         ui.user.role === "owner" && ui.groupement && ui?.groupement?.establishments?.length === 1 ? null : //si un seul établissement dans le groupement, pas de partage
          <>
            {checkRights(q.uid, WRITE) ? (
              <Share2
                style={{ cursor: "pointer" }}
                onClick={() => setQuizToShare(q)}
                color="#4a0956"
              />
            ) : null}
          </>
        }
      </div>
      {!checkRights(q.uid, OWNER) ? <div style={{marginTop: 8}}>
        <span style={{fontSize: 12, backgroundColor: "#5f0d6d", padding: "5px 10px", color: "white", borderRadius: 7}}>par {q.sharedBy}</span>
      </div> : null}
    </Card>
  );

  return (
    <>
      <VideoTuto url="https://youtu.be/YQABjrtFQeQ" />
      <div
        style={{ borderBottom: "1px solid grey", width: "100%", marginTop: 15, paddingBottom: 15 }}
      >
        <h3>Parties en cours:</h3>
        <div style={{ display: "flex", gap: 10 }}>
          {Object.keys(activeQuizzes).length === 0 ? (
            <h6>Aucune partie en cours</h6>
          ) : (
            Object.keys(activeQuizzes).map((uid) => (
              <Card key={uid}>
                <span style={{ marginRight: 10 }}>
                  {activeQuizzes[uid].title ?? "Quizz sans nom"}
                </span>
                <Play
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(
                      `/dashboard/cultureQuizz/${uid}/${
                        activeQuizzes[uid].status === "waiting_participants" ? "start" : "play"
                      }`
                    )
                  }
                />
                <Trash
                  style={{ cursor: "pointer" }}
                  onClick={() => _deleteInstance(uid)}
                  color="red"
                />
              </Card>
            ))
          )}
        </div>
      </div>
      <div style={{ width: "100%", marginTop: 15, paddingBottom: 15 }}>
        <h3>Mes quiz:</h3>
        <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: 220,
              height: 200,
              cursor: "pointer",
            }}
            onClick={() => history.push("/dashboard/cultureQuizz/edit/new")}
          >
            <Plus size={76} />
          </Card>
          {quizzes.filter(q => checkRights(q.uid, OWNER)).map(_renderItem)}
        </div>
        <h3 style={{marginTop: 20}}>Quiz partagés:</h3>
        <div style={{display: "flex", gap: 20, flexWrap: "wrap"}}>
          {quizzes.filter(q => !checkRights(q.uid, OWNER)).map(_renderItem)}
        </div>
        {quizToShare &&
          <QuizzShareModal 
          quiz={quizToShare} 
          toggle={() => setQuizToShare(null)} 
          isOpen={!!(quizToShare)}
          cultureQuiz={cultureQuiz}
          />
        }
      </div>
    </>
  );
};

export default QuizzList;
