import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, ImagePicker } from "../../../../lib/components";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "../../../../hooks/ui.hook";
import useGoogleAnalytics from "../../../../hooks/useGoogleAnalytics";
import { updateActu, createActu, removeActu } from "services/ressources/actus";
import useMicroService from "helpers/microService";
import { getUsersByEtab } from "services/ressources/user";
import WaitingModal from "../../WaitingList/WaitingModal";
import { createWaitingEvent, deleteWaitingEvent, getWaitingDataByEventId, updateWaitingEvent } from "../../../../services/ressources/waitingList";
import { Info } from "react-feather";
import useFeature from "hooks/useFeature";
import CompressorImagePicker from "@/lib/components/Form/CompressorImagePicker";

const firestore = firebase.firestore;

const CreateEditNews = (props) => {
  const {
    isOpen,
    toggle,
    item,
    preFilledData,
  } = props;

  const ga = useGoogleAnalytics();
  const ms = useMicroService();
  const [ui] = useUI();
  const isSavePublish = useFeature("savePublishNews");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [galery, setGalery] = useState([]);
  const [imageLoaderForm, setImageLoaderForm] = useState(false);
  const [editing, setEditing] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalProgrammation, setModalProgrammation] = useState(false);
  const [dateProg, setDateProg] = useState(new Date());
  const [startTimeProg, setStartTimeProg] = useState({ value: "", label: "" });
  const [isPublished, setIsPublished] = useState(false);

  // const [sendNotif, setSendNotif] = useState(false);

  useEffect(() => {
    if (editing === null) return;
    setEditing(true);
  }, [title, description, image, galery, imageLoaderForm]);

  useEffect(() => {
    if (!item && !preFilledData) {
      setTitle("");
      setDescription("");
      setImage([]);
      setError({});
      setGalery([]);
    } else if (preFilledData) {
      setTitle(preFilledData.title ?? "");
      setDescription(preFilledData.description ?? "");
      setImage([]);
      setGalery([]);
    } else {
      setTitle(item.title);
      setDescription(item.description);
      setImage(item.image ? [item.image] : []);
      setGalery(item.galery ?? []);
      setIsPublished(item.isPublished ?? true);
      setError({});
    }
    // setSendNotif(false);
    //initialized, waiting for changes, 1s timeout for avoid useEffect preshot
    setTimeout(() => setEditing(false), 1000);
  }, [item, isOpen]);

  const onSubmit = async (_isPublished) => {
    setLoading(true);
    let _error = {};
    if (!title) { _error.title = "Ce champ est obligatoire"; }
    setError(_error);
    if (Object.keys(_error).length > 0) {
      toast.warning("Il vous manque un titre");
      setLoading(false);
      return;
    }


    //notif pour les users
    let users = await getUsersByEtab({ etabId: ui.user.establishment }, () => { throw new Error("Une erreur est survenue"); });
    users = users.filter(u => u.role === "senior");
    users = users.map(u => u.uid);

    try {
      // nouvelle actu
      if (!item) {
        if (modalProgrammation) {
          try {
            const newActuId = await createActu({
              ui, data: {
                title,
                description,
                image: image.length ? image[0] : null,
                galery,
                date: new Date(),
                updatedAt: new Date(),
                isProgrammed: true,
                isPublished: isSavePublish ? false : null,
              }
            });
            const [scheduleHours, scheduleMinutes] = startTimeProg?.value.split('h').map(Number);
            let newDate = new Date(dateProg);
            newDate.setHours(scheduleHours, scheduleMinutes);
            const newActu = { title: title, timing: newDate, date: new Date(), eventLinkedId: newActuId.uid };
            await createWaitingEvent({ ui: ui, type: "Actualité", data: newActu });
            toast.success("L'actualité sera publiée à l'heure");
            setModalProgrammation(false);
          } catch (e) {
            console.log(e);
            toast.error("Une erreur est survenue");
            return;
          }
        }
        else {
          const newActu = await createActu({
            ui, data: {
              title,
              description,
              image: image.length ? image[0] : null,
              galery,
              date: new Date(),
              updatedAt: new Date(),
              isProgrammed: false,
              isPublished: isSavePublish ? _isPublished ?? false : null,
            }
          });


          // envoyer une notif uniquement si l'actu est publiée
          if (newActu.isPublished) {
            await ms("sendPushToUserById", {
              userId: users,
              title: "Nouvelle actualité publiée",
              body: `${title}. Rendez-vous sur la page actualité  !`,
              type: "news",
              etabId: ui.establishment.uid,
              info: newActu.uid,
            });
          }
        }

        // modification d'actu
      } else {
        if (item.isProgrammed) {
          const waitingNews = await getWaitingDataByEventId({ eventLinkedId: item.uid });
          // si l'actu va etre publié on doit enlever de la liste programmation l'event
          if (_isPublished) {
            await deleteWaitingEvent({ eventId: waitingNews.uid });
            await updateActu({
              ui, id: item.uid, data: {
                title,
                description,
                image: image.length ? image[0] : null,
                galery,
                updatedAt: new Date(),
                isProgrammed: false,
                date: new Date(),
                isPublished: _isPublished
              }
            });

            // on peut donc envoyer une notification car l'actualité se fait publier
            await ms("sendPushToUserById", {
              userId: users,
              title: "Nouvelle actualité publiée",
              body: `${title}. Rendez-vous sur la page actualité  !`,
              type: "news",
              etabId: ui.establishment.uid,
              info: item.uid,
            });
          }
          // autrement on update l'actu dans la liste de programmation
          else {
            await updateActu({
              ui, id: item.uid, data: {
                title,
                description,
                image: image.length ? image[0] : null,
                galery,
                updatedAt: new Date(),
                isProgrammed: true,
                isPublished: _isPublished
              }
            });
            const { uid, ...waitingEventWithoutUid } = waitingNews;
            const newAnim = { ...waitingEventWithoutUid, title: title };
            await updateWaitingEvent({ waitingEventId: waitingNews.uid, data: newAnim });
          }
        }
        else {
          await updateActu({
            ui, id: item.uid, data: {
              title,
              description,
              image: image.length ? image[0] : null,
              galery,
              updatedAt: new Date(),
              isProgrammed: false,
              isPublished: _isPublished
            }
          });

          if(item.isPublished === false && _isPublished === true){
            // on peut donc envoyer une notification car l'actualité se fait publier
            await ms("sendPushToUserById", {
              userId: users,
              title: "Nouvelle actualité publiée",
              body: `${title}. Rendez-vous sur la page actualité  !`,
              type: "news",
              etabId: ui.user.establishment,
              info: item.uid,
            });
          }
        }

      }
      ga("news_edit");
      setLoading(false);
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const remove = async () => {
    try {
      if (!window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) return;
      await removeActu({ ui, id: item.uid });
      if (item.isProgrammed) {
        const waitingEvent = await getWaitingDataByEventId({ eventLinkedId: item.uid });
        await deleteWaitingEvent({ eventId: waitingEvent.uid });
      }
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const close = () => {
    if (!editing) return toggle();
    if (window.confirm("Êtes-vous sûr de vouloir quitter sans sauvegarder ?")) {
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={close} size="lg">
      <ModalHeader>{item ? item.title : "Nouvel article"}</ModalHeader>
      <ModalBody>
        <Input type="text" label="Titre" value={title} invalid={error.title} onChange={e => setTitle(e.target.value)} disabled={item?.type != undefined} />
        <Input type="textarea" label="Contenu" value={description} onChange={e => setDescription(e.target.value)} disabled={item?.type != undefined} />
        <ImagePicker value={image} onChange={setImage} label="Image mise en avant" max={1} setImageLoaderForm={setImageLoaderForm} disabled={item?.type != undefined} />
        {/*<CompressorImagePicker value={image} onChange={setImage} label="Image mise en avant" disabled={item?.type != undefined} />*/}
        <ImagePicker value={galery} onChange={setGalery} label="Galerie" max={15} setImageLoaderForm={setImageLoaderForm} disabled={item?.type != undefined} />
        {/* {!item ? <Checkbox label="Envoyer une notification" checked={sendNotif} onChange={() => setSendNotif(!sendNotif)} disabled={item?.type!= undefined}/> : null} */}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: 10 }}>
          {!item ?

            <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between" }}>
              <Button color="secondary" onClick={close}>Fermer</Button>
              <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                {isSavePublish && <Button color="secondary" onClick={() => onSubmit(false)} disabled={imageLoaderForm || loading} loading={loading}>{isSavePublish ? "Enregistrer en tant que brouillon" : "Enregistrer"}</Button>}
                {isSavePublish && <Button color="primary" onClick={() => { setModalProgrammation(true); }} disabled={imageLoaderForm || loading} loading={loading}>Programmer l'envoi</Button>}
                <Button color={isSavePublish ? "warning" : "primary"} onClick={() => onSubmit(true)} disabled={imageLoaderForm || loading} loading={loading}>Publier</Button>
              </div>
            </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              {item && item.isProgrammed ?
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", color: "blue", alignItems: "center" }}>
                  <Info size={15} />
                  Cette actualité est programmée pour la publication
                  <dvi style={{ marginRight: "40px" }}></dvi>
                </div>
                : null} <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                  <Button color="secondary" onClick={close}>Fermer</Button>

                </div>
                {!item.type ?
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    {!item.type ?
                      <Button color="danger" onClick={remove}>Supprimer</Button>
                      : null}
                    {isSavePublish ? <>
                      <Button color="warning" onClick={() => onSubmit(!isPublished)} disabled={imageLoaderForm || loading} loading={loading}> {isPublished ? "Dépublier" : "Publier"}</Button>
                      <Button color="primary" onClick={() => onSubmit(isPublished)} disabled={imageLoaderForm || loading} loading={loading}>Mettre à jour</Button>
                    </>
                      :
                      <>
                        <Button color="primary" onClick={() => onSubmit(true)} disabled={imageLoaderForm || loading} loading={loading}>Mettre à jour</Button>
                      </>}

                  </div>
                  : null}
              </div>
            </div>
          }
        </div>
      </ModalFooter>
      <WaitingModal isOpen={modalProgrammation} toggle={() => { setModalProgrammation(false); }} setInfo={(startTime, date) => { setDateProg(date); setStartTimeProg(startTime); }} onValidate={() => onSubmit(isPublished)}></WaitingModal>
    </Modal>
  );
};

CreateEditNews.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  item: PropTypes.object,
  preFilledData: PropTypes.object,
};

export default CreateEditNews;