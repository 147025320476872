import * as PropTypes from "prop-types";

import { Col, Input, Row } from "../../../../../../lib/components";
import React, { useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";
import firebase from "firebase/app";
import 'firebase/firestore';
import { useFirebaseDocumentOnce } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";

const firestore = firebase.firestore();

const VillageBleuBanneer = ({ onChange, value }) => {
  const [ui, dispatch] = useUI();
  const [banniere, setBanniere] = useState("");

  const [estRef] = useState(
    firestore.collection("establishments").doc(ui.establishment.uid)
  );
  const { data: est, update } = useFirebaseDocumentOnce(estRef, {});

  const deboucedFirebaseSave = useCallback(
    debounce((formData) => {
      if (formData.banniere === ui.establishment.banniere) return;
      if(formData != undefined && formData.banniere != undefined)
        update(formData);
    }, 2000),
    [est, ui.establishment]
  );

  useEffect(() => {
    if (!est) return;
    setBanniere(est.banniere);
    onChange({ ...value, banniere: est.banniere });
  }, [est]);

  useEffect(() => {
    if (banniere === ui.establishment.banniere) return;
    deboucedFirebaseSave({ banniere });
    onChange({ ...value, banniere });
  }, [banniere]);

  return (
    <Row>
      <Col lg={12}>
        <div style={{ marginBottom: 30 }}>
          <Input
            type="textarea"
            placeholder="Ex : Contact : Mme X, du lundi au vendredi"
            onChange={(e) => setBanniere(e.target.value)}
            value={banniere}
          />
        </div>
      </Col>
    </Row>
  );
};

VillageBleuBanneer.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default VillageBleuBanneer;
