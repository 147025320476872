import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {createContext, useReducer, useContext} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import {toast} from "react-toastify"; 
import {isDayEmpty, getDefaultRepas, getDefaultDay, isRepasEmpty, areReservationsEqual, deepMenuEqual} from "../../helpers/operations";
import {getMenusByPeriod_rt, menusInit, setMenuBatch} from "services/ressources/menu";

const firestore = firebase.firestore;

const Context = createContext();

const exceptions = ["reservations", "day", "created", "day", "published"];

const Default = {
  page: 0,
  meal: null,
  menu: null,
  users: null,
  menuType: null,
  week: null,
  data: null,
  state: null,
  isSaved: null,
  save: null,
  loading: true,
  modalBrokenOpen: false,
  brokenList: [],
};

function Reducer(state, action) {
  switch(action.type) {
  case "setMeal": return ({...state, meal: action.meal}); 
  case "setMenu": return ({...state, menu: action.menu});
  case "changePage": return ({...state, page: action.page});
  case "clear": return ({...Default});
  case "save": return ({...Default});
  case "nextWeek": return ({...state, week: state.week.map(day => moment(day).add(7, "day").toDate())});
  case "previousWeek": return ({...state, week: state.week.map(day => moment(day).subtract(7, "day").toDate())});
  case "setProperty": return ({...state, [action.property]: action.value});
  case "setState": return {...action.state};
  default: return {...state};
  }
}

const Provider = ({children}) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);
  //init

  const template = ui?.establishment?.template;

  useEffect(() => {
    let _monday = moment().startOf("week");
    let _week = [];
    _week.push(_monday.toDate());
    for (let i = 0; i < 6; i++) {
      _monday.add(1, "day");
      _week.push(_monday.toDate());
    }
    dispatch({type: "setProperty", property: "week", value: _week});

    if(template && Object.keys(template).length) {
      let meal = Object.keys(template)[0];
      dispatch({type: "setProperty", property: "meal", value: meal});
      let menu = Object.keys(template[meal].menus).sort((a,b) => template[meal].menus[a].weight - template[meal].menus[b].weight)[0];
      dispatch({ type: "setMenu", menu: menu });
    }

    firestore()
    .collection("users")
    .where("establishment", "==", ui.user.establishment)
    .where("role", "in", ["senior","seniorTotem", "owner", "employee"])
    .onSnapshot(res => {
      let _dataUsers = {};
      res.forEach((doc) => {
        _dataUsers[doc.id] = {...doc.data(), uid: doc.id};
      });
      dispatch({ type: "setProperty", property : "users", value: _dataUsers });
    });
    
  }, []);

  // auto change the menu when we change the meal.
  useEffect(()=> {
    if(template && ctx.meal) {
      let menu = Object.keys(template[ctx.meal].menus).sort((a,b) => template[ctx.meal].menus[a].weight - template[ctx.meal].menus[b].weight)[0];
      if(menu !== ctx.menu)
        dispatch({ type: "setMenu", menu: menu });
    }
  }, [ctx.meal]);

  // auto change the menu type 
  useEffect(()=> {
    if(template && ctx.menu) {
      let menuType = template[ctx.meal].menus[ctx.menu].type;
      if(menuType !== ctx.menuType)
        dispatch({type: "setProperty", property: "menuType", value: menuType});
    }
  }, [ctx.menu]);

  useEffect(()=> {
    if(ctx.brokenList.length > 0){
      changeBrokenReservations();
    }
  }, [ctx.brokenList]);


  //auto load menu when week change
  useEffect(() => {
    if (ctx.week === null) return;
    (async () => {
      dispatch({type: "setProperty", property: "loading", value: true});


      try {
        await menusInit({ui});
        let monday = ctx.week[0];
        let sunday = ctx.week[6];

        const unsubscribe = getMenusByPeriod_rt({ui, start: monday, end: sunday}, (data) => {        
            const events = {};
            data.forEach(doc => events[doc.uid] = doc);
            dispatch({type: "setProperty", property: "loading", value: false});
            dispatch({type: "setProperty", property: "data", value: events});
          }
        );
          

        return () => {
          unsubscribe();
        };
      } catch (e) {
        console.error(e);
        dispatch({type: "setProperty", property: "data", value: {}});
        dispatch({type: "setProperty", property: "loading", value: false});
      }
    })();
  }, [ctx.week]);

  //fill state with data
  useEffect(() => {
    if (!ctx.week) return;
    if (!template) return;

    const _defaultDay = {};

    Object.keys(template).forEach(element => {
      let _content = {};
      template[element].categories.forEach(category => {
        _content[category] = {};
      });
      _defaultDay[element] = _content;
    });
  
    if (ctx.data === null || Object.keys(ctx.data).length === 0) {
      let newData = {};
      ctx.week.forEach(day => {
        let docId = moment(day).format("YYYY-MM-DD");  
        newData[docId] = {
          day,
          ...deepClone(_defaultDay)
        };
      });
     
      dispatch({type: "setProperty", property: "data", value: newData});
      dispatch({type: "setProperty", property: "state", value: newData});

    } else {
      dispatch({type: "setProperty", property: "state", value: deepClone(ctx.data)});
    }

  }, [ctx.data, ctx.week]);

  const isSaved = () => {
    return deepMenuEqual(ctx.data, ctx.state);
  };

  const isBreakingReservations = (published) => {
    let broken = false;
    let _brokenList = [];

    Object.entries(ctx.state).forEach(([_date, _dataDay]) => {
      if(_dataDay.reservation && Object.values(_dataDay.reservation).length > 0){
        Object.entries(_dataDay.reservation).forEach(([_uid, _resaDay]) => {
          Object.entries(_resaDay).forEach(([_repas, _resaRepasArray]) => {
            if(_resaRepasArray && _resaRepasArray.length > 0){
              _resaRepasArray.forEach((_resa, _indx) => {
                Object.entries(_resa).forEach(([_categ, _value]) => {
                  if(template[_repas].categories.includes(_categ)){
                      if(Object.keys(ctx.data[_date][_repas][_categ]).includes(_value) && ctx.state[_date][_repas][_categ][_value] === undefined){

                        broken = true;
                        _brokenList.push({
                          date: _date,
                          uid: _uid,
                          index: _indx,
                          repas: _repas,
                          category: _categ,
                        });
                      }

                      // un catégorie inexistante a été créée
                      

                      if(_value === null && Object.keys(ctx.data[_date][_repas][_categ]).length === 0 && Object.keys(ctx.state[_date][_repas][_categ]).length > 0){
                        broken = true;
                        _brokenList.push({
                          date: _date,
                          uid: _uid,
                          index: _indx,
                          repas: _repas,
                          category: _categ,
                        });
                      }
                  }
                 
                });
              });
            }
          });
        });
      }
    });

    //console.log("bkll",_brokenList);
    dispatch({type: "setProperty", property: "brokenList", value: _brokenList});
    dispatch({type: "setProperty", property: "brokenPublish", value: published});

    return broken;
  };

  const getDefaultDish = (data, date, repas, category) => {
    if (!data || !date || !ui?.establishment?.template || !template[repas].menus) return null;

    const dailyRepas = data[date][repas];
    const _defaultMenus = Object.entries(template[repas].menus).sort((a, b) => a[1].weight - b[1].weight).map(i => i[0]);

    const defaultResa = Object.entries(dailyRepas).map(([repas, value]) => {
      const defaultMenu = _defaultMenus.find((defaultMenu) => {
        const _defaultPlat = Object.entries(value).filter(([, value]) => value.origin === defaultMenu)
                                                  .sort(([, a], [, b]) => a.order - b.order)
                                                  .map(([key, value]) => ({ key, ...value }));
        return _defaultPlat.length > 0;
      });
      return defaultMenu ? {
        repas,
        ...(Object.entries(value).filter(([, value]) => value.origin === defaultMenu).sort(([, a], [, b]) => a.order - b.order).map(([key, value]) => ({ key, ...value }))[0])
      } : { repas };
    });

    return defaultResa.reduce((acc, { repas, ...rest }) => ({ ...acc, [repas]: rest?.key ?? null }), {})[category];
  };

  const changeBrokenReservations = () => {
    let _state = deepClone(ctx.state);
    ctx.brokenList.forEach(element => {
      const _newDish = getDefaultDish(ctx.state, element.date, element.repas, element.category);
      _state[element.date].reservation[element.uid][element.repas][element.index][element.category] = _newDish;
    });

    console.log("broken", _state);
    dispatch({type: "setProperty", property: "state", value: _state});
  };



  const save = async (published , force) => {
    if(!isSaved() || published !== Object.values(ctx.data)[0].published){
      if (ctx.state === null) return;
      try {
        if(!isBreakingReservations(published) || force){

          let batch = {};
        
          Object.entries(ctx.state).forEach(([docId,document]) => {
            //let docId = moment(document.day).format("YYYY-MM-DD");

            
            const _data = {...document, published : published};
            
            batch[docId] = _data;
          });
          setMenuBatch({ui, menus: batch});

          dispatch({type: "setProperty", property: "data", value: deepClone(ctx.state)});
          toast.success(`Votre menu a bien été ${published ? "publié" : "sauvegardé"}`);
          if (!published) toast.warn("Attention: votre menu n'a pas été publié");
        }else{
          dispatch({type: "setProperty", property: "modalBrokenOpen", value: true});
        }
      } catch (e) {
        console.error(e);
        toast.error(`Une erreur est survenue lors de la ${published ? "publication" : "sauvegarde"} de votre menu`);
      }
    }
  };

  ctx.isSaved = isSaved;
  ctx.save = save;

  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};
const useMenu = () => useContext(Context);
export default useMenu;
export {Provider, Context};