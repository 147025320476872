import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup,
    Button,
    Select,
    Icon, Row, Card, Input
} from "lib/components";
import "react-tippy/dist/tippy.css";
import useUI from "hooks/ui.hook";
import moment from "moment";
import styles from "./scss/waitingModal.module.scss";
import { Checkbox, DatePicker, TimePicker } from "../../../lib/components";

const WaitingModal = (props) => {
    const {
        isOpen,
        setInfo,
        toggle,
        eventOpen,
        onValidate,
    } = props;
    const [ui] = useUI();
    const [startTime,setStartTime] = useState({value:"",label:""});
    const [date,setDate] = useState(new Date());
    const [now,setNow] = useState(new Date());
    const [scheduleHours, scheduleMinutes] = startTime?.value.split('h').map(Number);
    const scheduleInMinutes = 60 * scheduleHours + scheduleMinutes;
    const dateNow = new Date();
    const nowInMinutes = 60 * dateNow.getHours() + dateNow.getMinutes() + 15;
    const isBeforeOrEqualTime = scheduleInMinutes < nowInMinutes;

    useEffect(()=>{
        if(eventOpen){
            if(eventOpen.timing){ 
                const date = new Date(eventOpen?.timing.seconds * 1000);            
                setDate(date);
                setStartTime({value:moment(date).format("HH[h]mm"),label:moment(date).format("HH[h]mm")})
            }
        }
    },[eventOpen])


    useEffect(()=>{
        setInfo(startTime,date);
        setNow(new Date());        
    },[startTime,date])


    return (
        <Modal isOpen={isOpen} size="lg" toggle={()=>toggle()}>
            <ModalHeader> {eventOpen?"Modifier la programmation":"Définir la programmation"}
            </ModalHeader>
            <ModalBody>
                <div className={styles.container}>
                    <FormGroup className={styles.chooseTiming}>
                        <div >Jour: </div>
                        <DatePicker
                            value={date}
                            onChange={setDate}
                            theme="white"
                        />
                        <div>Heure: </div>
                        <TimePicker
                            value={startTime}
                            onChange={setStartTime}
                            theme="white"
                        />
                    </FormGroup> 
                   { startTime?.value === "" ?<div className={styles.errorText}>{"Vous devez remplir les 2 champs avant de Valider"}</div>
                   :(date.getFullYear() < now.getFullYear()) || (date.getFullYear() === now.getFullYear() && date.getMonth() < now.getMonth()) ||
                    (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() < now.getDate())?
                    <div className={styles.errorText}>{"La date de publication ne doit pas être inférieure à la date du jour"}</div>
                    :(now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && now.getDate() === date.getDate() && isBeforeOrEqualTime)?
                    <div className={styles.errorText}>{"L'horaire doit être supérieur à l'horaire actuel de 15 minutes minimum"}</div>
                    :null
                 }
                </div>
            </ModalBody>

            <ModalFooter className={styles.footer}>
             <Button color='secondary' onClick={()=>toggle()} >Annuler</Button>
                <Button color='primary' onClick={()=>onValidate()} disabled={startTime?.value === "" || (date.getFullYear() < now.getFullYear()) || (date.getFullYear() === now.getFullYear() && date.getMonth() < now.getMonth()) ||
                    (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() < now.getDate()) || (now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && now.getDate() === date.getDate() && isBeforeOrEqualTime)} >{eventOpen?"Modifier":"Valider"}</Button>
            </ModalFooter>
        </Modal>
    );

};

WaitingModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    setInfo: PropTypes.func,
    onValidate:PropTypes.func.isRequired,
    eventOpen: PropTypes.object
};

export default WaitingModal;