import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Table, Checkbox } from "@/lib/components";
import React, {useState, useEffect} from "react";
import styles from "../assets/validationImportSupplementModal.module.scss"

const ValidationImportSupplementModal = ({isOpen, onSubmit, onClose, supplementsOrigine, importedSupplements}) => {
  
  const [decisionList, setDecisionList] = useState([]);
  
  useEffect(() => {
    setDecisionList([
      // NOUVEAU SUPPLEMENTS - Considéré comme nouveau supplément si c'est un nom unique parmi les suppléments déjà existants.
      ...importedSupplements.filter(importedSupplement => !supplementsOrigine.find(origin => origin.name === importedSupplement.name)).map(importedSupplement => {
        return ({
          imported: importedSupplement,
          reason: "new",
          keep: true,
        })
      }),
      // SUPPLEMENTS MODIFIES - Considéré comme modifié si le nom est le même qu'un autre dans la liste mais qui a des propertiés differentes.
      ...importedSupplements.filter(importedSupplement => {
        const res = supplementsOrigine.find(origin => origin.name === importedSupplement.name);
        if(res) {
          return Object.keys(importedSupplement).filter(key => !["uid","isFileImported"].includes(importedSupplement[key])).some(key => importedSupplement[key] !== res[key])
        } else return false
      })
        .map(importedSupplement => {
        return ({
          original: supplementsOrigine.find(origin => origin.name === importedSupplement.name),
          imported: importedSupplement,
          reason: "modified",
          keep: true, 
        })
      }),
      // SUPPLEMENTS SUPPRIMES - Considéré comme supprimé si le nom n'est plus présent dans l'import. On peut tout de même en garder certains
      ...supplementsOrigine.filter(s => !importedSupplements.find(d => d.name === s.name)).map(s => {
        return ({
          original: s,
          isFileImported: s.isFileImported ?? false,
          reason: "deleted",
          keep: true,
        })
      })
    ]);
  }, [importedSupplements])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalHeader>
        <h2>Validation des suppléments importés</h2>
      </ModalHeader>
      <ModalBody>

        <div style={{maxHeight: 500, overflowY: "scroll"}}>
          <p>La liste des suppléments à importer contient des suppléments modifiés par rapport à la liste d'origine</p>
          
          {decisionList.filter(d => d.reason === "deleted" && d.isFileImported).length > 0 && 
            <>
              <h4>Suppléments précédement importés</h4>
              <p>Séléctionnez les suppléments que vous souhaitez supprimer :</p>
              <Checkbox label="Tout supprimer" checked={decisionList.filter(d => d.reason === "deleted" && d.isFileImported).every(d => d.keep === false)} onChange={() => setDecisionList(decisionList.map(d => {return d.reason === "deleted" && d.isFileImported ? {...d, keep: !d.keep} : d}))} />

              <div style={{display:"flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", backgroundColor: "#f5f5f5", padding: 5, marginBottom: 10}}>
                <Table>
                  <thead>
                    <tr>
                      <th>Identifiant</th>
                      <th>Identifiant logiciel</th>
                      <th>Libellé</th>
                      <th>Catégories</th>
                      <th>Prix</th>
                    </tr>
                  </thead>
                  <tbody>
                    {decisionList.filter(d => d.reason === "deleted" && d.isFileImported).map(supplement => {
                      return (
                        <tr className={styles.tableRowDelete} onClick={() => {setDecisionList(decisionList.map(d => d.reason === "deleted"  && d.original === supplement.original ? {...d, keep: d.keep ? !d.keep : true} : d))}} style={!supplement.keep ? {background: "lightcoral"} : {}}>
                          <td>{supplement.original?.id}</td>
                          <td>{supplement.original?.idLink}</td>
                          <td>{supplement.original?.name}</td>
                          <td>{supplement.original?.category?.replaceAll("|/|", " / ") ?? ""}</td>
                          <td>{supplement.original?.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

              </div>
            </>
          }
          {decisionList.filter(d => d.reason === "deleted" && !d.isFileImported).length > 0 && (
            <>
              <h4>Suppléments précédement créés à la main</h4>
              <p>Séléctionnez les suppléments que vous souhaitez supprimer :</p>
              <Checkbox label="Tout supprimer" checked={decisionList.filter(d => d.reason === "deleted" && !d.isFileImported).every(d => d.keep === false)} onChange={() => setDecisionList(decisionList.map(d => {return d.reason === "deleted" && !d.isFileImported ? {...d, keep: !d.keep} : d}))} />

              <div style={{display:"flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", backgroundColor: "#f5f5f5", padding: 5}}>

                <Table>
                  <thead>
                    <tr>
                      <th>Identifiant</th>
                      <th>Identifiant logiciel</th>
                      <th>Libellé</th>
                      <th>Catégories</th>
                      <th>Prix</th>
                    </tr>
                  </thead>
                  <tbody>
                    {decisionList.filter(d => d.reason === "deleted" && !d.isFileImported).map(supplement => {
                      return (
                        <tr className={styles.tableRowDelete} onClick={() => {setDecisionList(decisionList.map(d => d.reason === "deleted"  && d.original === supplement.original ? {...d, keep: d.keep ? !d.keep : true} : d))}} style={!supplement.keep ? {background: "lightcoral"} : {}}>
                          <td>{supplement.original?.id}</td>
                          <td>{supplement.original?.idLink}</td>
                          <td>{supplement.original?.name}</td>
                          <td>{supplement.original?.categories?.join(" / ")}</td>
                          <td>{supplement.original?.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
          
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => {onSubmit(decisionList); onClose()}}>Confirmer</Button>
        <Button onClick={onClose}>Annuler</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ValidationImportSupplementModal;
