import React, {useState, useEffect} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import {Container, Row, Col, Tab, Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Table, Card, CardHeader, CardBody, Input} from "lib/components";
import { Link } from "react-router-dom";
import { Edit, Plus } from "react-feather";
import useUI from "hooks/ui.hook";
import { toast } from "react-toastify";
import useCtx, {Provider} from "./ctx";
import {useHistory, useParams} from "react-router-dom";

const firestore = firebase.firestore;

const Establishments = () => {
  const [ui, dispatch] = useUI();
  const history = useHistory();
  const {state, save} = useCtx();
  const [loading, setLoading] = useState(true);
  const {id} = useParams();

  const _takeControl = async (_id) => {
    try {
      let _est = await firestore().collection("establishments").doc(_id).get();
      let _user = {...ui.user}; 

      _est = ({uid: _est.id, ..._est.data()});
      
      let _owner = _est.owner;
      if(_owner){
        let res = await firestore().collection("users").doc(_owner).get();
        _owner = res.data();
        _est.owner = _owner;
      }

      let groupement = null;
      // ajout du groupement
      if(_est.uid){
        let groupementDoc = await firestore().collection("groupements").where("establishments", "array-contains", _est.uid).get();
        if(groupementDoc.docs.length > 0){
          groupement = {uid: groupementDoc.docs[0].id, ...groupementDoc.docs[0].data()};

          if(groupementDoc.docs.length > 1)console.warn("this etab is in more than 1 groupement");
        }
      }
      
      _user.role = "owner";
      _user.establishment = _id;
      _user.superadmin = true;
      dispatch({type: "takeControl", user: _user, establishment: _est, groupement: groupement});
    } catch (e) {
      console.error(e);
    }
  };
  

  return (
    <div style={{width: "100%"}}>
      <Tab activeTab={1} onClick={console.log} render={[
        {title: "Informations", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/infos`)},
        {title: "Etablissements", content: <>
          <Container><Row><Col xs={12}>
            {state?.groupement && state?.establishments ? 
                <Card>
                <CardHeader style={{display: "flex", justifyContent: "space-between"}}>
                  <h5>Etablissements</h5>
                </CardHeader>
                <CardBody>
                  <Table loading={loading}>
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Propriétaire</th>
                        {/* <th>Features</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.establishments &&state?.establishments.filter((e) => state?.groupement?.establishments.includes(e.uid)).map(i => 
                        <tr key={i.uid}>
                          <th scope="row">{i.name}</th>
                          <td>{i.owner?.surname ?? ""} {i.owner?.name ?? ""}</td>
                          {/* <td>{i.features && i.features.map(j => <p key={j}>{j}</p>)}</td> */}
                          <td>
                          <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: 30}}>
                            <Link to={`/dashboard/establishments/${i.uid}`}>
                              <Edit color="#4A0956" style={{cursor: "pointer"}}/>
                            </Link>
                            <Button color="danger" onClick={() => _takeControl(i.uid)}>Prise de controle</Button>{" "}
                          </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            : null}
        </Col></Row></Container></>},
      // {title: "Abonnements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/abonnements`)},
      {title: "Statistiques", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/statistics`)},
      {title: "Menu", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/menu`)},
      {title: "Abonnement", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription`)},
      { title: "Abonnement V2", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription2`) },
      ]} />
    </div>
  );
};


const GroupementEstablishments = () => {
  const {id} = useParams();

  return (
    <Provider id={id}>
      <Establishments />
    </Provider>
  );
};

export default GroupementEstablishments;