import firebase from "firebase/app";
import 'firebase/firestore';
const firestore = firebase.firestore;

export const getGroupementSupplementCategories_rt = ({ groupementId }, cb, err) => {
  const subscribe = firestore()
    .collection("groupements")
    .doc(groupementId)
    .collection("blocks")
    .doc("restaurant")
    .onSnapshot(snap => {
      cb(snap.data()?.supplementsCategories ?? []);
    }, err ?? (() => {}));

  return subscribe;
}

export const updateSupplementsCategories= ({ etabId, supplementsCategories }, cb = () => {}) => {
  const subscribe = firestore()
    .collection("establishments")
    .doc(etabId)
    .collection("blocks")
    .doc("restaurant")
    .update({supplementsCategories: supplementsCategories})
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });

  return subscribe;
}

export const updateGroupementSupplementsCategories= ({ groupementId, supplementsCategories }, cb = () => {}, err = () => {}) => {
  const subscribe = firestore()
    .collection("groupements")
    .doc(groupementId)
    .collection("blocks")
    .doc("restaurant")
    .set({supplementsCategories: supplementsCategories})
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });

  return subscribe;
}


export const getAllEtabSupplements_rt = ({etabId}, cb, err) => {
    const subscribe  = firestore()
      .collection("establishments")
      .doc(etabId)
      .collection("blocks")
      .doc("restaurant")
      .collection("supplements")
      .onSnapshot(snap => {
        const data = snap.docs.map(doc => ({
          uid: doc.id,
          ...doc.data()
        }));
        cb(data);
      }, err ?? (() => {}));

    return subscribe;
};

export const getGroupementSupplements_rt = ({ groupementId }, cb, err) => {
  const subscribe  = firestore()
  .collection("groupements")
  .doc(groupementId)
  .collection("blocks")
  .doc("restaurant")
  .collection("supplements")
  // .orderBy("date", "desc")
  // .limit(1)
  .onSnapshot(snap => {
    const data = snap.docs.map(doc => ({
      uid: doc.id,
      ...doc.data()
    }));
    cb(data);
  }, err ?? (() => {}));

  return subscribe;
}

export const createGroupementSupplement = ({ groupementId, supplement }, cb = () => {}, err = () => {}) => {
  const subscribe = firestore()
    .collection("groupements")
    .doc(groupementId)
    .collection("blocks")
    .doc("restaurant")
    .collection("supplements")
    .add(supplement)
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });
  return subscribe;
};

export const createEtabSupplement = ({ etabId, supplement }, cb = () => {}, err = () => {}) => {

  const subscribe = firestore()
    .collection("establishments")
    .doc(etabId)
    .collection("blocks")
    .doc("restaurant")
    .collection("supplements")
    .add(supplement)
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });
  return subscribe;
};

export const updateGroupementSupplement = ({ groupementId, supplementId, supplement }, cb = () => {}, err = () => {}) => {
  
  const subscribe = firestore()
    .collection("groupements")
    .doc(groupementId)
    .collection("blocks")
    .doc("restaurant")
    .collection("supplements")
    .doc(supplementId)
    .update(supplement)
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });

  return subscribe;
}

export const updateEtabSupplement = ({ etabId, supplementId, supplement }, cb = () => {}, err = () => {}) => {
  const subscribe = firestore()
    .collection("establishments")
    .doc(etabId)
    .collection("blocks")
    .doc("restaurant")
    .collection("supplements")
    .doc(supplementId)
    .update(supplement)
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });

  return subscribe;
}

export const deleteGroupementSupplement = ({ groupementId, supplementId }, cb = () => {}, err = () => {}) => {
  const subscribe = firestore()
    .collection("groupements")
    .doc(groupementId)
    .collection("blocks")
    .doc("restaurant")
    .collection("supplements")
    .doc(supplementId)
    .delete()
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });

  return subscribe;
}

export const deleteEtabSupplement = ({ etabId, supplementId }, cb = () => {}, err = () => {}) => {
  const subscribe = firestore()
    .collection("establishments")
    .doc(etabId)
    .collection("blocks")
    .doc("restaurant")
    .collection("supplements")
    .doc(supplementId)
    .delete()
    .then(() => {
      cb();
    })
    .catch(e => {
      err(e);
    });

  return subscribe;
}