import React, { useState } from "react";

import { useMenuReservation } from "../../../../router_context";
import useUI from "@/hooks/ui.hook";
import { sortRepas } from "@/pages/Major/Menu/helpers/operations";
import ExportTable from "@/pages/Major/Reservation/ExportTable";
import moment from "moment";
import { log } from "react-zlib-js";

const sku = [
    {
        "id": "1/2FORFD",
        "name": "Forfait 1/2 pension DUO",
        "category": "Déjeuner"
    },
    {
        "id": "1/2FORFT",
        "name": "Forfait 1/2 pension TRIO",
        "category": "Déjeuner"
    },
    {
        "id": "DEJDUO",
        "name": "Déjeuner Duo",
        "category": "Déjeuner"
    },
    {
        "id": "DEJTRIO",
        "name": "Dejeuner Trio",
        "category": "Déjeuner"
    },
    {
        "id": "DEJBIEN",
        "name": "Déjeuner Bienvenue",
        "category": "Déjeuner"
    },
    {
        "id": "ARRIVAGE",
        "name": "Arrivage du mois",
        "category": "Déjeuner"
    },
    {
        "id": "DEJASTRE",
        "name": "Dejeuner astreinte",
        "category": "Déjeuner"
    },
    {
        "id": "DEJENFANT",
        "name": "Déjeuner enfant",
        "category": "Déjeuner"
    },
    {
        "id": "DEJSALAR",
        "name": "Déjeuner salarié",
        "category": "Déjeuner"
    },
    {
        "id": "INVSALAST",
        "name": "Invité salarié astreinte",
        "category": "Déjeuner"
    },
    {
        "id": "INVSALDEJ",
        "name": "Déjeuner invité salarié",
        "category": "Déjeuner"
    },
    {
        "id": "DUORES",
        "name": "Dîner duo",
        "category": "Diner"
    },
    {
        "id": "DINTHEME",
        "name": "Diner à thème",
        "category": "Diner"
    },
    {
        "id": "DINBIEN",
        "name": "Diner Bienvenue",
        "category": "Diner"
    },
    {
        "id": "DINASTRE",
        "name": "Diner Astreinte",
        "category": "Diner"
    },
    {
        "id": "DINENF",
        "name": "Diner Enfant",
        "category": "Diner"
    },
    {
        "id": "DINSAL",
        "name": "Diner salarié",
        "category": "Diner"
    },
    {
        "id": "SUPDESSERT",
        "name": "Supplément dessert",
        "category": "Supplément"
    },
    {
        "id": "SUPFROM",
        "name": "Supplément fromage",
        "category": "Supplément"
    },
];

// Excel Base Styles :
const baseBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedTopBorder = { top: { style: "thick", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedLeftBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thick", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const baseFont = { sz: 12 };
const headerFont = { sz: 15, bold: true };
const baseAlignment = { vertical: "center", horizontal: "center", wrapText: true };
const baseFill = { fgColor: { rgb: "B3FFBF" } };

const ExportRestaurant = ({ modalExportOpen, setModalExportOpen }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenuReservation();

    const template = ui?.establishment?.template;

    const sortedRepas = sortRepas(template, Object.keys(template));


    const getFormula = (_repas, _reservation) => {
        if (_repas === "Déjeuner") {
            let count = 0;
            if (_reservation) {

                if (_reservation["entrée"]) count++;
                if (_reservation["plat"]) count++;
                if (_reservation["dessert"]) count++;
            }

            if (count <= 2) return "DUO";
            if (count >= 3) return "TRIO";

            return null;
        }
        if (_repas === "Dîner") {
            let count = 0;
            if (_reservation) {

                if (_reservation["entrée"]) count++;
                if (_reservation["plat"]) count++;
                if (_reservation["dessert"]) count++;
            }

            if (count <= 2) return "DUO";
            else return "TRIO";
            // return null;
        }
        return null;
    };

    const getSupplements = (_array, _userId, _repas) => {
        if (_array && _array[_userId] && _array[_userId][_repas] && _array[_userId][_repas].length > 0) {
            return _array[_userId][_repas];
        } else {
            return [];
        }
    };

    const getSKURepas = (repas, formula, userId) => {

        const _role = userId && ctx.users && ctx.users[userId] && ctx.users[userId].role ? ["senior", "seniorTotem", "guest"].includes(ctx.users[userId].role) ? "senior" : ["employee", "owner"].includes(ctx.users[userId].role) ? "employee" : "senior" : "senior";
        if (sortedRepas.indexOf(repas) === 0) {
            if (_role === "senior") {
                if (formula === "DUO") {
                    return sku.find((_c) => _c.id === "DEJDUO");
                }
                if (formula === "TRIO") {
                    return sku.find((_c) => _c.id === "DEJTRIO");
                }
            } else {
                return sku.find((_c) => _c.id === "DEJSALAR");
            }
        } else {
            if (_role === "senior") {
                return sku.find((_c) => _c.id === "DUORES");
            } else {
                return sku.find((_c) => _c.id === "DINSAL");
            }
        }

    };

    const getSupplementsDessertFromage = (dataRepas, _reservation) => {
        const _retour = {
            suppFromage: 0,
            suppDessert: 0,
        };

        if (_reservation["dessert"] && Array.isArray(_reservation["dessert"])) {

            if (_reservation["dessert"].length > 1) {

                // on ne compte pas le premier, car il est compris dans les formules, par contre les suivants, on détermine si c'est des suppléments dessert ou fromage.

                for (let index = 1; index < _reservation["dessert"].length; index++) {
                    const element = _reservation["dessert"][index];

                    const _name = dataRepas["dessert"][element] ? dataRepas["dessert"][element]?.name ?? "" : element;

                    if (_name.toLocaleLowerCase().includes("fromage") || _name.toLocaleLowerCase().includes("faisselle")) {
                        // supplément fromage
                        _retour.suppFromage += 1;
                    } else {
                        // supplément dessert
                        _retour.suppDessert += 1;
                    }

                }


            }
        }

        return _retour;
    }


    const generateExportDataDateToDate = async (period, date) => {
        const start = date.clone().toDate();
        const end = date.clone().add(1, period).toDate();
        const periodData = await ctx.getData(start, end);

        const contentStyle = { border: baseBorder, font: { sz: 11 } };

        const _data = [
            [{ v: "DATE", s: { font: { sz: 14, bold: true } }, t: "s" }, { v: `du ${moment(start).format("DD/MM/YY")} au ${moment(end).add(-1, "day").format("DD/MM/YY")}`, s: { font: { sz: 11, bold: true } }, t: "s" }],
            [{ v: "code_article", s: contentStyle, t: "s" }, { v: "libelle", s: contentStyle, t: "s" }, { v: "Catégorie", s: contentStyle, t: "s" }, { v: "Quantités", s: contentStyle, t: "s" }],
        ];

        let numDays = moment(end).diff(moment(start), "days");

        const _obj = {};


        for (let i = 1; i <= numDays; i++) {
            let formated = moment(start).add(i, "days").format("YYYY-MM-DD");
            // on remplit l'objet avec les données nécessaires pour remplir le tableau :

            //réservations classiques (portage)
            if (periodData?.data[formated]?.reservation) {
                Object.entries(periodData?.data[formated]?.reservation).forEach(([_userId, _content]) => {
                    Object.entries(_content).forEach(([_r, _list]) => {
                        if (_list.length > 0 && _list[0].homeDelivery === true) {
                            // ajout de la résa du résident
                            const _sku = getSKURepas(_r, getFormula(_r, _list[0]), _userId);

                            if (!_obj[_sku.id]) _obj[_sku.id] = 0;

                            _obj[_sku.id] += 1;

                            // gestion des suppléments spécifiques (fromage / dessert)
                            const _suppDessert = getSupplementsDessertFromage(periodData?.data[formated][_r], _list[0]);
                            if (_suppDessert.suppDessert > 0) {
                                if (!_obj["SUPDESSERT"]) _obj["SUPDESSERT"] = 0;
                                _obj["SUPDESSERT"] += _suppDessert.suppDessert;
                            }
                            if (_suppDessert.suppFromage > 0) {
                                if (!_obj["SUPFROM"]) _obj["SUPFROM"] = 0;
                                _obj["SUPFROM"] += _suppDessert.suppFromage;
                            }


                            // gestion des suppléments classiques
                            const _supplements = _list[0]["suppléments restaurant"];

                            if (_supplements) {
                                _supplements.forEach(_sup => {
                                    if (!_obj[_sup.id]) _obj[_sup.id] = {
                                        name: _sup.name,
                                        number: 0,
                                        category: "",
                                    };
    
                                    _obj[_sup.id].number += _sup.number;
                                });
                            }

                        }
                    });
                });
            }

            //commandes en restaurant
            if (periodData?.dataOrders[formated]) {
                Object.entries(periodData?.dataOrders[formated]).forEach(([_userId, _content]) => {
                    Object.entries(_content).forEach(([_r, _list]) => {
                        if (_list.length > 0) {
                            const _sku = getSKURepas(_r, getFormula(_r, _list[0].plates ? _list[0].plates : _list[0]), _userId);

                            if (!_obj[_sku.id]) _obj[_sku.id] = 0;

                            _obj[_sku.id] += 1;

                            // gestion des suppléments spécifiques (fromage / dessert)

                            const _suppDessert = getSupplementsDessertFromage(periodData?.data[formated][_r], _list[0].plates ? _list[0].plates : _list[0]);
                            if (_suppDessert.suppDessert > 0) {
                                if (!_obj["SUPDESSERT"]) _obj["SUPDESSERT"] = 0;
                                _obj["SUPDESSERT"] += _suppDessert.suppDessert;
                            }
                            if (_suppDessert.suppFromage > 0) {
                                if (!_obj["SUPFROM"]) _obj["SUPFROM"] = 0;
                                _obj["SUPFROM"] += _suppDessert.suppFromage;
                            }

                            // gestion des suppléments classiques
                            const _supplements = getSupplements(periodData?.dataOrdersSupplements[formated], _userId, _r);

                            _supplements.forEach(_sup => {
                                if (!_obj[_sup.id]) _obj[_sup.id] = {
                                    name: _sup.name,
                                    number: 0,
                                    category: "",
                                };

                                _obj[_sup.id].number += _sup.number;

                            });
                        }
                    });
                });
            }
        }

        ["Déjeuner", "Diner", "Supplément"].forEach((_categ, _indx) => {

            _data.push([{ v: _categ, s: { font: { sz: 14, bold: true } } }]);

            sku.filter(_s => _s.category === _categ).forEach(element => {
                _data.push([
                    { v: element.id, s: contentStyle, t: "s" },
                    { v: element.name, s: contentStyle, t: "s" },
                    { v: element.category, s: contentStyle, t: "s" },
                    { v: _obj[element.id] ?? 0, s: contentStyle, t: "n" },
                ]);
            });

        });

        // on ajoute les autres suppléments (Boissons / Glaces) :

        _data.push([{ v: "Boissons / Glaces", s: { font: { sz: 14, bold: true } } }]);


        // On rajoute les suppléments customs :

        const others = Object.keys(_obj).filter(_s => !sku.map(_sku => _sku.id).includes(_s));
        if (others.length > 0) {

            others.forEach(element => {
                _data.push([
                    { v: element, s: contentStyle, t: "s" },
                    { v: _obj[element].name, s: contentStyle, t: "s" },
                    { v: _obj[element].category?.split("/").map(c => c.trim()).join("|/|") ?? "", s: contentStyle, t: "s" },
                    { v: _obj[element].number ?? 0, s: contentStyle, t: "n" }
                ]);
            });
        }


        let generatedData = {
            columnsSize: [25, 25, 18, 10],
            data: _data,
        };

        return generatedData;
    };

    return (
        <>
            {modalExportOpen ? (
                <ExportTable onClose={() => setModalExportOpen(false)} dataGeneration={generateExportDataDateToDate} infos={{ title: "Export restaurant", type: "fullControl", date: ctx?.selectedDate }} />
            ) : null}
        </>
    )
};



export default ExportRestaurant;