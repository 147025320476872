import React, { useState, useEffect } from "react";
import useUI from "hooks/ui.hook";

import firebase from "firebase/app";
import 'firebase/firestore';

import styles from "../../../Basic/Assets/scss/menu.module.scss";

import moment from "moment";

import { Resume, ReservationTable } from "../../../router_components";
import { useMenuReservation } from "../../../router_context";

import { VideoTuto } from "lib/components/YoutubeModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Download } from "react-feather";
import { Button } from "lib/components";
import DropDown from "lib/components/DropDown";
import { ExportTable, GuestSelectorModal, GuestSelectorModalStore } from "../../../router_components";
import ExportDay from "../Components/ExportDay";
import { findSubscriptionForDate } from "helpers/subscription"

import { filterRepas, sortRepas, sortCategories } from "pages/Major/Menu/helpers/operations";
import ReservationTableGuest from "../Components/ReservationTableGuest";
import useSupplements from "../../../Basic/Stores/useSupplements";

import ExportRestaurant from "../Components/Exports/ExportRestaurant";
import ExportDaily from "../Components/Exports/ExportDaily";



// Soir : Potage, plat, accompagnement, produit laitier, dessert.

const ReservationMenus = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [showTotal, setShowTotal] = useState(false);
  const [modalExportOpen2, setModalExportOpen2] = useState(false);
  const [modalExportOpen3, setModalExportOpen3] = useState(false);

  const { 
    isOpen: isGuestSelectorModalOpen, 
    setIsOpen: setIsGuestSelectorModalOpen,
    props: guestSelectorModalProps,
    setProps: setGuestSelectorModalProps
  } = GuestSelectorModalStore((state) => ({ 
    isOpen: state.isOpen, 
    setIsOpen: state.setIsOpen,
    props: state.props,
    setProps: state.setProps
  }));

  const template = ui?.establishment?.template;

  const sortedRepas = sortRepas(template, Object.keys(template));

  const initSupplements = useSupplements(state => state.initSupplements);

  useEffect(() => {    
    const unsubscribe = initSupplements(ui?.establishment?.uid, ui?.groupement?.uid);
    return unsubscribe;
  }, [ui?.establishment?.uid, ui?.groupement?.uid, initSupplements])

  // update data ! 
  // useEffect(() => {

  // Attention, ne pas réactiver si on ne sait pas à quoi ça sert ! (rewrite toutes les données des orders pour rajouter la date en timestamp manquante)
  //   firestore()
  //     .collection("establishments")
  //     .doc(ui.user.establishment)
  //     .collection("blocks")
  //     .doc("restaurant")
  //     .collection("orders")
  //     .get().then((s) => {

  //       const newDocs = s.docs.map(_d => ({ ..._d.data() }));

  //       const docsToUpdate = newDocs.filter(_d => !_d.day);

  //       const updatedDocs = docsToUpdate.map(_d => ({..._d, day: moment(_d.date).toDate()}));
  //       console.log(docsToUpdate.length, docsToUpdate);

  //       // Diviser les nouveaux documents en lots de 500
  //       const batchSize = 500;
  //       for (let i = 0; i < updatedDocs.length; i += batchSize) {
  //         const batch = firestore().batch();
  //         const batchDocs = updatedDocs.slice(i, i + batchSize);

  //         batchDocs.forEach((doc) => {
  //           const ref = firestore()
  //             .collection("establishments")
  //             .doc(ui.user.establishment)
  //             .collection("blocks")
  //             .doc("restaurant")
  //             .collection("orders")
  //             .doc(doc.uid);

  //           batch.update(ref, doc);
  //         });

  //         // Commit the batch
  //         batch.commit().then(() => {
  //           console.log("Batch committed successfully.");
  //         }).catch((error) => {
  //           console.error("Error committing batch:", error);
  //         });
  //       }


  //     });
  //   return () => {

  //   };
  // }, []);


  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx.data, ctx.users]);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx?.selectedWeek, ctx.daysToDisplay]);

  const selectDate = () => {

    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      let _dateArray = [];

      for (let i = 0; i < 7; i++) {
        if (ctx.daysToDisplay.includes(i + 1)) {
          _dateArray.push(ctx?.selectedWeek?.clone().add(i, "days").format("YYYY-MM-DD"));
        }
      }

      dispatch({ type: "setProperty", property: "dateArray", value: _dateArray });
    }
  };


  const changeWeek = (direction) => {
    // direction = -1 || 1
    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      dispatch({ type: "setProperty", property: "selectedWeek", value: ctx?.selectedWeek?.clone().add(direction, "week") });
    }
  };

  const generateResumeRepas = (data, repas) => {

    let retour = "";

    if (data[repas]) {
      const _categories = sortCategories(ui.establishment.template, repas, Object.keys(data[repas]));

      _categories.forEach(_categ => {
        const dishes = Object.keys(data[repas][_categ]);
        if (dishes.length > 0) {

          dishes.forEach(_dishId => {
            let count = 0;

            Object.keys(ctx.users).forEach(_uid => {
              if (data.reservation != undefined) {
                if (data.reservation[_uid] && data.reservation[_uid][repas] && data.reservation[_uid][repas].length > 0) {
                  data.reservation[_uid][repas].forEach(element => {
                    if (element[_categ] === _dishId) {
                      count++;
                    }
                  });
                }
              }
            });

            retour += data[repas][_categ][_dishId].name + " : " + count + "\n";

          });

        }
      });
    }


    return retour;
  };

  const generateExportData2 = async (period, date) => {
    const start = date.clone().toDate();
    const end = date.clone().add(1, period).add(-1, "day").toDate();
    const periodData = await ctx.getData(start, end);

    let generatedData = null;
    let header = {};

    header["Repas"] = [];

    let _data = [];

    sortRepas(ui.establishment.template, filterRepas(ui.establishment.template, Object.keys(ui.establishment.template))).forEach(_repas => {
      let line = [];
      line.push(_repas);

      let localDate = date.clone();
      let numDays = - localDate.diff(end.clone(), "days");
      for (let i = 1; i <= numDays; i++) {
        let formated = localDate.format("YYYY-MM-DD");
        header[localDate.format("ddd DD/MM")] = [];
        if (periodData.data[formated] != undefined) {
          const dataDay = periodData.data[formated];

          let content = generateResumeRepas(dataDay, _repas);

          line.push(content);

        } else { // aucun événement ce jour
          line.push("");
        }
        localDate = moment(localDate).add(1, "day");
      }
      // line.push("");
      _data.push(line);
    });
    _data.push([]);
    header[""] = [];

    generatedData = {
      header: header,
      data: _data,
      depth: 1,
      colStart: 1,
      colEnd: 0
    };

    return generatedData;
  };



 
  if (!ctx || !ctx.users || !ctx.data) return (<div></div>);
  return (
    <>
      <VideoTuto url="https://youtu.be/1P61Re4pRx4" />
      <div className={styles.menu}>
        <div className={styles.weekSelector}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowLeft}
            onClick={() => {
              changeWeek(-1);
            }}
          />
          <div className={styles.weekText}>
            {ctx?.selectedWeek?.clone().format("DD MMMM YYYY")} - {ctx?.selectedWeek?.clone().endOf("week").format("DD MMMM YYYY")}
          </div>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowRight}
            onClick={() => {
              changeWeek(1);
            }}
          />
        </div>

        <div className={styles.buttonContainer} style={{ marginBottom: -52, marginLeft: "15%" }}>
          <Button onClick={() => setIsDropDownOpen(!isDropDownOpen)} style={{ position: "relative", display: "flex", gap: 10 }}>
            Exporter
            <FontAwesomeIcon
              className={styles.angle}
              icon={isDropDownOpen ? faAngleDown : faAngleRight}
            />
            <DropDown isOpen={isDropDownOpen} style={{ top: 38, paddingLeft: 30, paddingRight: 30 }} toggle={() => setIsDropDownOpen(false)}>

              <Button className={styles.exportButton} color="primary" onClick={() => setModalExportOpen3(true)}>
                <span>
                  Export restauration
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>

              <Button className={styles.exportButton} style={{ marginTop: 10 }} color="primary" onClick={() => setModalExportOpen(true)}>
                <span>
                  Export du jour
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>


            </DropDown>
          </Button>


          <div className={styles.filterContainer}>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "senior" })} className={`${styles.filterElement} ${ctx?.filter?.role === "senior" ? styles.active : ""}`}>
              Résidents
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "employee" })} className={`${styles.filterElement} ${ctx?.filter?.role === "employee" ? styles.active : ""}`}>
              Collaborateurs
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "guest" })} className={`${styles.filterElement} ${ctx?.filter?.role === "guest" ? styles.active : ""}`}>
              Invités
            </div>


          </div>

          <Button onClick={() => setShowTotal(!showTotal)}>{!showTotal ? "Afficher les totaux" : "Cacher les totaux"}</Button>

          <Button onClick={() => {
            setGuestSelectorModalProps({
              ...guestSelectorModalProps, 
              linkedTo: null, 
              date: null, 
              service: null, 
              guestTypeFilter: [],
              onSubmit: (guest, date, service) => {
                // dispatch({ type: "setProperty", property: "filter", value: {...ctx.filter, role: "guest"} });
                dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
                dispatch({
                  type: "setProperty", property: "modalInfos", value: {
                    uid: guest.uid,
                    date: moment(date).format("YYYY-MM-DD"),
                    repas: service,
                  }
                });
              }
            }); 
            setIsGuestSelectorModalOpen(true); 

          }}>Ajouter invité</Button>
          <GuestSelectorModal 
            {...guestSelectorModalProps}
            isOpen={isGuestSelectorModalOpen} 
            onClose={() => setIsGuestSelectorModalOpen(false)}  
          />
        </div>





        {ctx.filter?.role === "guest" ? <ReservationTableGuest showTotal={showTotal} /> : <ReservationTable showTotal={showTotal} />}
        {/* On enlève le deuxième tableau pour ovélia. */}
        
        {/* <div className={styles.buttonContainer} style={{ marginLeft: 6, marginTop: 50, marginBottom: -100 }}>
          <Button className={styles.exportButton} style={{ display: "flex", gap: 10, padding: 8 }} color="primary" onClick={() => setModalExportOpen2(true)}>
            <span>
              Exporter ce tableau
            </span>
            <Download
              color="white"
              size={30}
            />
          </Button>
        </div>

        <Resume /> */}

        <ExportDaily modalExportOpen={modalExportOpen} setModalExportOpen={setModalExportOpen}/>

        {/* {modalExportOpen2 ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportData2} infos={{ title: "Export des Menus", type: "menuDishes", date: ctx?.selectedWeek }} />
        ) : null} */}
        <ExportRestaurant modalExportOpen={modalExportOpen3} setModalExportOpen={setModalExportOpen3}></ExportRestaurant>
       
        {/*<Tab render={render} activeTab={page} onClick={pressHeaderHandler}/>*/}
        {/* <Details data={dataSelected} users={etabUsers} allData={dataMenus} updateData={updateData} selectedDate={selectedWeek}/> */}
      </div>
    </>

  );
};
export default ReservationMenus;
