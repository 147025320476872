import React, {useState, useMemo, useCallback, useEffect} from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Avatar, Spinner, Table, Input, Select, FormGroup, Button } from "../../../lib/components";
import { BookOpen, Edit } from "react-feather";
import useCtx, {Provider} from "./ctx";
import useMicroService from "helpers/microService";
import {toast} from "react-toastify";
import firebase from "firebase/app";
import 'firebase/firestore';

import roles from "enums/roles";
import CognitiveModal from "./_CognitiveModal";

const firestore = firebase.firestore();

const User = () => {
  const execMicroService = useMicroService();
  const history = useHistory();
  const {state, dispatch, save, deletUser} = useCtx();
  const {user, cognitives, establishments, isLoading, groupement} = (state ?? {});

  const [deleteAccountLoader, setDeleteAccountLoader] = useState(false);
  const [cognitive, setCogitive] = useState(null);
  const [groupements, setGroupements] = useState([]);

  const handleChange = useCallback((key, value) => dispatch({type: "setUser", user: {...user, [key]: value}}), [user]);
  
  const establishmentsOptions = useMemo(() => establishments?.map(e => ({value: e.uid, label: e.name}) ?? []), [establishments]);
  const groupementsOptions = useMemo(() => groupements?.map(e => ({value: e.id, label: e.name}) ?? []), [groupements, user]);
  const regionsOptions = useMemo(() =>  groupements?.find(i => i.id === user?.groupement)?.regions?.map(r=> ({label: r, value: r})) ?? [], [groupements, user]);
  // const abonnementOptions = useMemo(() => groupement?.abonnements ? Object.entries(groupement.abonnements).map(([k, v]) => ({label: v.name, value: k})) : [], [groupement]);
  const establishment = useMemo(() => establishments?.find(e => e.uid === user.establishment) ?? null, [user, establishments]);

  useEffect(()=> {
    firestore.collection("groupements").onSnapshot(res => {
      let _groupements = [];
      res.forEach((doc) => {
        _groupements.push({id: doc.id, ...doc.data()});
        });
      setGroupements(_groupements);
    });

  },[]);
  const handleChangePassword = async () => {
    try {
      await execMicroService("superadminForceResetPassword", {uid: user.uid});
      toast.success("Mail envoyé");
    } catch (e) {
      toast.error("Une erreur est survenue");
      console.error(e);
    }
  };

  const handleSuppression = async () => {
    if(confirm("Voulez vous vraiment supprimer cet utilisateur ?")){
      setDeleteAccountLoader(true);
      await deletUser(user.uid);
      setDeleteAccountLoader(false);
      history.push("/dashboard/users");
    }
  };

  const handleRestore = async () => {
    if (!window.confirm("Souhaitez vous vraiment restaurer cet utilisateur ?")) return;

    try {
      await firestore.collection("users").doc(user.uid).update({ isDeleted: null, deletedAt: null });
      toast.success("Utilisateur restauré");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  if (isLoading) return (<Spinner />);
  return (
    <>
      <Container>
        <Row>
          <Col lg={7}>
            <Card>
              <CardHeader><h5>Utilisateur</h5></CardHeader>
              <CardBody style={{display: "flex", flexDirection: "column"}}>
                <FormGroup>
                  <Input label="Name" value={user?.name} onChange={e => handleChange("name", e.target.value)} placeholder="Name..." />
                </FormGroup>
                <FormGroup>
                  <Input label="Surname" value={user?.surname} onChange={e => handleChange("surname", e.target.value)} placeholder="Surname..." />
                </FormGroup>
                <FormGroup>
                  <Select
                    label="Role"
                    value={roles.find(i => i.value === user?.role) ?? null}
                    onChange={e => handleChange("role", e?.value ?? null)}
                    options={roles}
                    placeholder="Role..."
                  />
                </FormGroup>
                {["groupementAdmin", "regionAdmin"].includes(user?.role) ? 
                  <FormGroup>
                    <Select
                      label="Groupement"
                      value={groupementsOptions?.find(i => i.value === user?.groupement) || null}
                      onChange={e => handleChange("groupement", e?.value ?? null)}
                      options={groupementsOptions}
                      placeholder="Groupement..."
                    />
                  </FormGroup>
                : null}
                {user?.role === "regionAdmin" ? 
                  <FormGroup>
                    <Select
                      label="Région"
                      value={regionsOptions?.find(i => i.value === user?.region) || null}
                      onChange={e => handleChange("region", e?.value ?? null)}
                      options={regionsOptions}
                      placeholder="Région..."
                    />
                  </FormGroup>
                : null}
                {["owner", "family", "senior", "seniorTotem", "totem", "employee", "restaurant"].includes(user?.role) ? 
                  <FormGroup>
                    <Select
                      label="Etablissement"
                      value={establishmentsOptions?.find(i => i.value === user?.establishment) || null}
                      onChange={e => handleChange("establishment", e?.value ?? null)}
                      options={establishmentsOptions}
                      placeholder="Etablissement..."
                    />
                  </FormGroup>
                : null}
                <div style={{display: "flex", justifyContent: "center"}}>
                  <Button color="primary" onClick={save}>Enregistrer</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={5}>
            <Card>
              <CardHeader><h5>Etablissement</h5></CardHeader>
              <CardBody style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {establishment ? <>
                  <Avatar img={establishment.profileImage} firstname={establishment.name} size="xl"/>
                  <span>{establishment.name}</span>
                  <Edit size={26} onClick={() => history.push(`/dashboard/establishments/${establishment.uid}`)} color="#300438" style={{cursor: "pointer"}} />
                </> : <></>}
              </CardBody>
            </Card>
            {/* <Card>
              <CardHeader><h5>Menu</h5></CardHeader>
              <CardBody>
                <Select 
                  placeholder="Abonnement..."
                  options={abonnementOptions}
                  value={abonnementOptions?.find(i => i.value === user?.subscription) ?? null}
                  onChange={e => handleChange("subscription", e?.value ?? null)}
                  clearable
                />
              </CardBody>
            </Card> */}
            <Card>
              <CardHeader><h5>Gestion</h5></CardHeader>
              <CardBody style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 20}}>
                <Button color="warning" onClick={handleChangePassword}>Envoyer un mail de rénitialisation de mot de passe</Button>
                {user.isDeleted && <Button color="success" onClick={handleRestore} loading={deleteAccountLoader}>Restaurer le compte</Button>}
                <Button color="danger" onClick={handleSuppression} loading={deleteAccountLoader}>SUPPRIMER DEFINITIVEMENT</Button>
              </CardBody>
            </Card>
            <Card>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader><h5>Données cognitives</h5></CardHeader>
              <CardBody>
                {cognitives && cognitives.length > 0 ? 
                  <Table>
                    <thead>
                      <tr>
                        <th>Jeu</th>
                        <th>Niveau</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cognitives.map(i => (
                        <tr key={i.uid}>
                          <td>{i.game}</td>
                          <td>{i.level}</td>
                          <td>
                            <BookOpen onClick={() => setCogitive(i)} size={26} color="#300438" style={{cursor: "pointer"}} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  :
                  <p>Pas de données cognitives</p>}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CognitiveModal isOpen={cognitive !== null} item={cognitive} toggle={() => setCogitive(null)} />
      </Container>
    </>
  );
};

const UserHOC = () => {
  const {uid} = useParams();
  return (
    <Provider id={uid}>
      <User />
    </Provider>
  );
};

export default UserHOC;