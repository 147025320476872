import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../../lib/components";
import { Printer } from "react-feather";
import useUI from "@/hooks/ui.hook";
import firebase from "firebase/app";
import 'firebase/firestore';

const firestore = firebase.firestore;


const WeekExportButton = ({ callback }) => {
    const [ui] = useUI();
    //demo clignotement 

    const [demoData, setDemoData] = useState({});

    useEffect(() => {

        if (ui?.establishment?.demo === true) {
          const stop = firestore().collection("establishments").doc(ui?.user?.establishment).onSnapshot(_doc => {
            const _data = _doc.data();
            const demoData = _data?.demoData;
            setDemoData(demoData);
          });
    
          return () => {
            stop();
          };
        }
      }, [ui.establishment]);

    const handleClick = () => {
        callback();

        if (ui?.establishment?.demo === true && !demoData?.printPlanning) {
            firestore().collection("establishments").doc(ui?.user?.establishment).update({ [`demoData.printPlanning`]: true });
        }
    };


    return (
        <Button
            onClick={handleClick}
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                margin: "0 5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
            }}
            color="primary"
            blinking={ui?.establishment?.demo === true && !demoData?.printPlanning ? true : false}
        >
            <Printer size={20} />
            Impression semaine
        </Button>
    );
};

WeekExportButton.propTypes = {
    callback: PropTypes.func.isRequired,
};

export default WeekExportButton;