import React, {useContext} from "react";
import {Link} from "react-router-dom";
import Icon from "../Icon";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import {SidebarContext} from "../../context/dashboard/sidebar.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../assets/scss/layout/dashboard/sidebarLink.module.scss";

const SidebarLinkWrapper = (props) => props.to ? <SidebarLinkIntra {...props} /> : <SidebarLinkAction {...props} />;

const SidebarLinkAction = (props) => {
  const {onClick, icon, iconAfter, children} = props;
  const [,setSidebarOpen] = useContext(SidebarContext);

  return (
    <div className={`${styles.sidebarLink}`} onClick={() => {onClick(); setSidebarOpen(false);}}>
      {icon ?
        <Icon size={16} 
          icon={icon}
          className="m-r-10 f-c-light" />
        :
        <span className="m-r-15 m-l-5 f-w-900">•</span>
      }
      {children}
      {iconAfter ?
        <div className={styles.iconAfter}>
          <FontAwesomeIcon size="1x" 
            icon={iconAfter}
            color={"#a680ad"}
          />
        </div>  
        :
        null
      }
    </div>
  );
};

const SidebarLinkIntra = (props) => {
  const {to, icon, iconAfter, children} = props;
  const [,setSidebarOpen] = useContext(SidebarContext);
  const match = useRouteMatch(to);

  const _isLinkActive = () =>  !(!match);

  const linkClick = () => {
    if (window.outerWidth <= 550) {
      setSidebarOpen(false);
    }
  };

  return (
    <Link to={to} className={`${styles.sidebarLink} ${_isLinkActive() ? styles.selected : ""}`} onClick={linkClick}>
      {icon ?
        <Icon size={16} 
          icon={icon}
          className="m-r-10 f-c-light" />
        :
        <span className="m-r-15 m-l-5 f-w-900">•</span>
      }
      {children}
      {iconAfter ?
        <div className={styles.iconAfter}>
          <FontAwesomeIcon size="1x" 
            icon={iconAfter}
            color={"#a680ad"}
          />
        </div>  
        :
        null
      }
    </Link>
  );
};

SidebarLinkWrapper.propTypes = {
  icon: PropTypes.elementType,
  to: PropTypes.string,
  onClick: PropTypes.func,
  iconAfter: PropTypes.any,
  children: PropTypes.node.isRequired
};
SidebarLinkAction.propTypes = {
  icon: PropTypes.elementType,
  to: PropTypes.string,
  onClick: PropTypes.func,
  iconAfter: PropTypes.any,
  children: PropTypes.node.isRequired
};
SidebarLinkIntra.propTypes = {
  icon: PropTypes.elementType,
  to: PropTypes.string,
  onClick: PropTypes.func,
  iconAfter: PropTypes.any,
  children: PropTypes.node.isRequired
};

export default SidebarLinkWrapper;