import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getActusForEtab = async ({ui}, err) => {
  try {
    const res = await firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("blocks")
                        .doc("actus")
                        .collection("actus")
                        .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

export const getActuById = async ({ui, id}, err) => {
  try {
    const res = await firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("blocks")
                        .doc("actus")
                        .collection("actus")
                        .doc(id)
                        .get();
    if (!res.exists) return 0;
    return {uid: res.id, ...res.data()};
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};
export const getNewsByIdAndEtab = async({etabId, actuId},err)=>{
  try{
      const res = await firestore()
              .collection("establishments")
              .doc(etabId)
              .collection("blocks")
              .doc("actus")
              .collection("actus")
              .doc(actuId)
              .get();
      if (!res.exists) return 0;
      return {uid: res.id, ...res.data()};           
  }catch (e) {
      console.error(e);
      if (err) err(e);
      else toast.error("Une erreur est survenue");
    }
};
//--------------------------  -------------------------------//

export const getActusForEtab_rt = ({ui}, cb, err) => {
  const unsubscribe = firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("blocks")
                        .doc("actus")
                        .collection("actus")
                        .onSnapshot(snap => {
                          let _data = [];
                          snap.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
                          cb(_data);
                        }, err ?? (() => {}));
  return unsubscribe;
};

export const getActusForNational_rt = ({ui}, cb, err) => {
  if(ui?.groupement){
    let scope = ["national"];
    if(ui.establishment.region)scope.push(ui.establishment.region);
    const subscribe = firestore().collection("groupements").doc(ui.groupement.uid)
    .collection("actus").where("type","in", scope).onSnapshot(snap => {
      let _data = [];
      snap.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
      cb(_data);
    }, (e) => {
      err(e);
    });
    return subscribe;
  }
};

export const getActuById_rt = ({ui, id}, cb, err) => {
  const unsubscribe = firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("blocks")
                        .doc("actus")
                        .collection("actus")
                        .doc(id)
                        .onSnapshot(snap => {
                          cb({uid: snap.id, ...snap.data()});
                        }, err ?? (() => {}));
  return unsubscribe;
};

//--------------------------  -------------------------------//

export const createActu = async ({ui, data}, err) => {
  try {
    const res = await firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("blocks")
                        .doc("actus")
                        .collection("actus")
                        .add({
                          ...data,
                          date: new Date(),
                          updatedAt: new Date(),
                        });
    return {uid: res.id, ...data};
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

//--------------------------  -------------------------------//

export const updateActu = async ({ui, id, data}, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("actus")
      .collection("actus")
      .doc(id)
      .update({
        ...data,
        updatedAt: new Date(),
      });
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

//--------------------------  -------------------------------//

export const removeActu = async ({ui, id}, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("actus")
      .collection("actus")
      .doc(id)
      .delete();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};