import {useState, useEffect, useMemo} from "react";
import {Card, FileUpload, Container, Row, Col, Input, MultiSelect, Button, FormGroup, Spinner, Select, Tab, GeocodeAutocomplete} from "lib/components";
import { useParams, useHistory } from "react-router-dom";
import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";
import useUI from "hooks/ui.hook";
import useGeocode from "hooks/useGeocode";
import featuresOptions from "enums/features";
import moment from "moment";
import md5 from "md5";
import stringSimilarity from "string-similarity";
import { getUserById } from "services/ressources/user";
import { getEstablishmentById, updateEstablishmentById } from "services/ressources/establishment";
import useBornes from "../Bornes/useBornes";
import useMicroService from "@/helpers/microService";
import MODE from "@/env";

const firestore = firebase.firestore;

const selectMenu = [{value:"binaryChoice", label: "par menu"},{value:"multipleChoice", label: "par plat"}];
const templateMenuOptions = [{value:"generic", label: "Générique"},{value:"happySenior", label: "Happy Senior"},{value:"jasmins", label: "Les Jasmins"},{value: "marpa_le_canalet", label: "Marpa Le Canalet"},];

const templatePlanningOptions = [
  {value: "generic", label: "Générique"}, 
  {value: "ekipage_le_rheu", label: "Ekip'Age - Le Rheu"}, 
  {value: "village_bleu_st_apollinaire", label: "Vill'Age Bleu - St Apollinaire"},
  {value:"jasmins", label: "Les Jasmins"},
  {value: "ovelia", label: "Ovelia"},
  {value: "heurus", label: "Heurus"},
];

const fillDataMenu = {
  "entrée" : ["Endives", "Carottes râpées", "Toasts", "Gougères", "Avocat", "Asperges", "Céleris", "Taboulet", "Salade"],
  "plat" : ["Steak", "Escalope", "Rôti", "Gratin", "Colin d'alaska", "Cabillaud", "Saumon", "Quiche", "Boeuf bourguignon", "Coq au vin", "Gigot"],
  "accompagnement": ["Carottes", "Purée", "Frites", "Pommes de terre", "Riz", "Choux fleurs", "Blé", "Salade", "Champignons"],
  "fromage": ["Brie", "Camenbert", "Comté", "Emmental", "Chèvre frais", "Beaufort", "Gouda", "Morbier", "Bleu"],
  "dessert": ["Yaourt", "Fromage blanc", "Fruits", "Tarte aux fraises", "Compote", "Mousse au chocolat", "Moelleux au chocolat", "Crème brûlée", "Crumble aux pommes"],
  };

const fillDataMenuDev = {
  "entrée" : ["Oeuf meurette (7-12)80gr (œuf poché +sauce vin rouge + lardons+ croutons)", "Carottes râpées", "Betterave vinaigrette moutarde (12-13) 80gr", "Gougères", "Salade de lentilles, vinaigrette aux herbes (12-13)", "Asperges"],
  "plat" : ["Poisson du jour, sauce persillade (2)", "Tajine de boulette d'agneau (6)", "Bœuf bourguignon (6-12)", "Poisson du jour, beurre blanc (2-10-12)", "Colin d'alaska", "Cabillaud", "Saumon", "Quiche"],
  "accompagnement": ["Mousseline butternut,potimarron rôti (10)", "Courgettes poivron", "Oignon grelot, champignon, riz", "Pommes de terre"],
  "fromage": ["Brie", "Camenbert", "Comté", "Emmental", "Chèvre frais", "Beaufort", "Gouda", "Morbier", "Bleu"],
  "dessert": ["Qutre quart a l'orange et pépites de chocolat (6-7-10)", "Poire à la vigneronne (12)80gr", "Salade d'agrumes sirop au citron vert 80gr", "Tarte aux fraises", "Entremet au chocolat (6-7-10) 80gr", "Mousse au chocolat"],  
};

const fillDataAnimations = {
  title: ["Jeux de sociétés", "Ecoute musicale", "Gym Douce", "Yoga", "Peinture", "Jeux de cartes", "Promenade en forêt", "Anniversaire", "Concert", "Quiz musical", "Massage", "Aquarelle", "Petit bac", "Film", "Jeu de mémoire", "Pièce de théâtre"],
  description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod neque et diam gravida, quis semper ante ornare. Mauris gravida odio nunc, in interdum nibh dapibus in. Sed eu fermentum felis. Curabitur a sapien in nulla suscipit tincidunt. Quisque ullamcorper tristique nibh, ut placerat mi aliquet et. Nunc lacinia leo risus, vitae varius sem lacinia ut. Mauris gravida vitae felis eu iaculis. Sed sit amet varius orci.",
  place: ["Paris", "Salle 34", "Salle A2", "Extérieur", "Salle 42", "Amphithéatre", "Salle 25", "Salle primevère"],
  matin: [8, 9, 10, 11],
  aprem: [14,15,16],
  duration: [1, 1, 1, 2, 2, 3]
};

const Settings = () => {
  const params = useParams();
  const [ui, dispatch] = useUI();
  const {service} = useGeocode();
  const history = useHistory();
  const execMicroService = useMicroService();

  const [loading, setLoading] = useState(true);

  const [oldData, setOldData] = useState({});

  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState(0);
  const [id, setId] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [poster, setPoster] = useState(null);
  const [societe, setSociete] = useState("");
  const [features, setFeatures] = useState([]);
  const [groupement, setGroupement] = useState(null);

  const [template, setTemplate] = useState(null);
  const [labels, setLabels] = useState(null);
  const [categories, setCategories] = useState(null);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [region, setRegion] = useState(null);

  const [idState, setIdState] = useState(null);

  const [menuTemplate, setMenuTemplate] = useState(null);
  const [menuType, setMenuType] = useState(selectMenu[0]);

  const [planningTemplate, setPlanningTemplate] = useState(null);

  const bornesSearch = useMemo(() => ({filter: {establishment: name}}), [name]);
  const bornes = useBornes(bornesSearch);

  // console.log(bornes);

  useEffect(() => {
    (async () => {
      try {
        let est = await firestore().collection("establishments").doc(params.id).get();
        est = ({uid: est.id, ...est.data()});
        
        setOldData(est);
        if(est.capacity) setCapacity(est.capacity);
        if(est.societe) setSociete(est.societe);
        if(est.id) setId(est.id);
        if(est.name) setName(est.name);
        if(est.profileImage) setProfileImage(est.profileImage);
        if(est.poster) setPoster(est.poster);
        if(est.menuTemplate) setMenuTemplate(est.menuTemplate);
        if (est.planningTemplate) setPlanningTemplate(est.planningTemplate);
        if (est.template) setTemplate(est.template);
        if (est.labels) setLabels(est.labels);
        if (est.region) setRegion(est.region);
        if (est.position){
          setLatitude(est.position.lat);
          setLongitude(est.position.long);
        }

        if(est.menuType){
          let optionMenu = null;
          if(est.menuType == "binaryChoice")optionMenu = selectMenu[0];
          else optionMenu = selectMenu[1];
          setMenuType(optionMenu);
        }
        let _features = [];
        featuresOptions.map(f => {
          if (est.features && est.features.includes(f.value)) {
            _features.push(f);
          }
        });
        setFeatures(_features);

        //groupement
        let _groupement = await firestore().collection("groupements").where("establishments", "array-contains", est.uid).get();
        if(_groupement.docs.length > 0) {
          _groupement = {..._groupement.docs[0].data(), uid: _groupement.docs[0].id};
          setGroupement(_groupement);
        } else {
          setGroupement(null);
        }
        setLoading(false);

        firestore().collection("establishments").doc(params.id)
                .collection("blocks").doc("planning")
                .collection("categories").onSnapshot((docs) => {
                  const data = docs.docs.map(doc => ({
                    uid: doc.id,
                    ...doc.data()
                  }));
                  setCategories(data);
                });
      } catch (e) {
        console.error(e.message);
        toast.error(e.message);
      }
    })();
  }, [params]);

  const submit = async () => {
    try {
      if (idState && idState !== "success") {
        toast.error("L'id d'établissement est invalide ou est en cours de vérification");
        return;
      }
      setLoading(true);
      let object = {
        name: name ?? "",
        capacity: capacity ?? 0,
        id: id ?? "",
        profileImage: profileImage ?? null,
        poster: poster ?? null,
        societe: societe ?? "",
        features: features ? features.map(i => i.value) : [],
        menuTemplate: menuTemplate ?? null,
        planningTemplate: planningTemplate ?? null,
      };
      if(region)object["region"] = region;
      if(latitude && longitude)object["position"] = {
        lat: latitude,
        long: longitude
      };

      await updateEstablishmentById({id: params.id, data: object});
      toast.success("Modification effectuée");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };

  const _takeControl = async () => {
    try {
      let _est = await getEstablishmentById({id: params.id});
      let _user = {...ui.user}; 

      let _owner = _est.owner;
      if(_owner){
        _owner = await getUserById({id: _owner});
        _est.owner = _owner;
      }

      let groupement = null;
      // ajout du groupement
      if(_est.uid){
        let groupementDoc = await firestore().collection("groupements").where("establishments", "array-contains", _est.uid).get();
        if(groupementDoc.docs.length > 0){
          groupement = {uid: groupementDoc.docs[0].id, ...groupementDoc.docs[0].data()};

          if(groupementDoc.docs.length > 1)console.warn("this etab is in more than 1 groupement");
        }
      }

      _user.role = "owner";
      _user.establishment = params.id;
      _user.superadmin = true;
      dispatch({type: "takeControl", user: _user, establishment: _est, groupement: groupement});
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (oldData.id === id) {
        setIdState(null);
      } else if (id.length !== 6) {
        setIdState("warn");
      } else {
        setIdState("warn");
        let res = await firestore().collection("establishments").where("id", "==", id).get();
        let _data = [];
        res.forEach(doc => _data.push(doc.data()));
        if (_data.length > 0) {
          setIdState("danger");
        } else {
          setIdState("success");
        }
      }
    })();
  }, [id, oldData]);

  const _cleanMenu = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer les menu ? Cette action est irréversible.")) {
      try {
        let days = await firestore().collection("establishments").doc(params.id).collection("blocks").doc("menu").collection("menu").get();
        await Promise.all(days.docs.map(async day => {
          let dayDoc = {uid: day.id, ...day.data()};
          console.log(dayDoc);
          //sub delete participants collection
          let participants = await firestore().collection("establishments").doc(params.id).collection("blocks").doc("menu").collection("menu").doc(dayDoc.uid).collection("participants").get();
          await Promise.all(participants.docs.map(async participant => {
            await firestore().collection("establishments").doc(params.id).collection("blocks").doc("menu").collection("menu").doc(dayDoc.uid).collection("participants").doc(participant.id).delete();
          }));
          //delete day
          await firestore().collection("establishments").doc(params.id).collection("blocks").doc("menu").collection("menu").doc(dayDoc.uid).delete();
        }));
        toast.success("Menu supprimé");
      } catch (e) {
        console.error(e);
        toast.error(e.message);
      }
    }
  };

  const getNearestList = (key, list) => {
    const _list = Object.keys(list);

    let score = -1;
    let result = [];
    _list.forEach(element => {
      const _score = stringSimilarity.compareTwoStrings(key, element);
      if(_score > score){
        score = _score;
        result= list[element];
      }
    });
    return result;
  };

  const getRandomFromList = (array) => {
    return array[Math.floor(Math.random()*array.length)];
  };

  const fillMenu = () => {
    if (window.confirm("Attention, cela supprime les données existantes de la semaine courante.")) {
      let _data = [{created: true, published: true}, {created: true, published: true}, {created: true, published: true}, {created: true, published: true}, {created: true, published: true}, {created: true, published: true}, {created: true, published: true}];
      
      Object.entries(template).forEach(([_repas, _dataRepas]) => {
        Object.entries(_dataRepas.menus).forEach(([_menu, _dataMenu]) => {
          if(_dataMenu.type === "daily"){
            
            _dataMenu.categories.forEach(_categ => {
              const _list = getNearestList(_categ, MODE === "DEV" ? fillDataMenuDev : fillDataMenu);
              
              for(let i=0; i<7; i++){
                const nbElements = Math.floor(Math.random()*2 +1);
                for(let j=0; j<nbElements; j++){
                  const _choice = getRandomFromList(_list);
                  const hash = md5(`${i}-${j}-${_menu}-${_categ}-${_choice}`);

                  let _label = null;
                  if(labels && Math.random() > 0.6 && Object.keys(labels).length > 0){
                    _label = getRandomFromList(Object.keys(labels));
                  }
  
                  if(!_data[i][_repas]) _data[i][_repas] = {};
                  if(!_data[i][_repas][_categ]) _data[i][_repas][_categ] = {};
  
                  _data[i][_repas][_categ][hash] = {
                    label: _label,
                    name: _choice,
                    origin: _menu,
                    order: j+1
                  };
                }
              }
            });
          }
          if(_dataMenu.type === "weekly"){
            _dataMenu.categories.forEach(_categ => {
              const _list = getNearestList(_categ, MODE === "DEV" ? fillDataMenuDev : fillDataMenu);
                const nbElements = Math.floor(Math.random()*2 +1);
                for(let j=0; j<nbElements; j++){
                  const _choice = getRandomFromList(_list);
                  const hash = md5(_choice);
                  let _label = null;
                  if(Math.random() > 0.6 && Object.keys(labels).length > 0){
                    _label = getRandomFromList(Object.keys(labels));
                  }
  
                  for(let i=0; i<7; i++){
                    if(!_data[i][_repas]) _data[i][_repas] = {};
                    if(!_data[i][_repas][_categ]) _data[i][_repas][_categ] = {};
    
                    _data[i][_repas][_categ][hash] = {
                      label: _label ?? null,
                      name: _choice,
                      origin: _menu,
                      order: j+1,
                    };
                  }
                  
                }
            });
            
          }
        });
      });
      
      const batch = firestore().batch();


      const startOfWeek = moment().startOf("week");
      for(let i=0; i<7; i++){
        const _date = startOfWeek.clone().add(i, "days");
        _data[i].day = _date.toDate();
        const docId = _date.format("YYYY-MM-DD");
        const docRef = firestore().collection("establishments").doc(params.id).collection("blocks").doc("menu").collection("menu").doc(docId);

        batch.set(docRef, _data[i]);
      }

      batch.commit();
    }
    
  };

  const fillAnimations = async () => {
    if (window.confirm("Attention, êtes vous sûrs de vouloir ajouter des animations de démos ?")) {
      const startOfWeek = moment().startOf("week");
      let events = [];

      for(let i=0; i<7; i++){
        const _date = startOfWeek.clone().add(i, "days");
        const period = ["matin", "aprem"];

        period.forEach(element => {
          if(Math.random() < 0.85){
            const _start = _date.clone().add(getRandomFromList(fillDataAnimations[element]),"hours");
            const title = getRandomFromList(fillDataAnimations.title);
            let categ = getRandomFromList(categories);

            events.push({
              eventType: "custom",
              categoryId: categ ? categ.uid ?? null : null,
              title: title,
              description: fillDataAnimations.description,
              place: getRandomFromList(fillDataAnimations.place),
              image: execMicroService("unsplashSearch", {search: title}),
              start: _start.toDate(),
              end: _start.clone().add(getRandomFromList(fillDataAnimations.duration),"hours").toDate(),
              closeSubscribe: 0,
              disableSubscribe: false,
              openSubscribe: 7,
              maxParticipants: 0,
              isPublished: true
            });
          }
          
        });


      }
      const batch = firestore().batch();
      events =await Promise.all(events.map(async _event => {
        const image = await _event.image
        return {
          ..._event,
          image: image.data.results[0].urls.regular
        }
      }));
      
      events.forEach(_event => {
        console.log("event", _event)
        const docRef = firestore().collection("establishments").doc(params.id).collection("blocks").doc("planning").collection("events").doc();
        batch.set(docRef, _event);
      });

      await batch.commit();
      toast.success("Animations ajoutées");
    }
  };

  if (loading) return <Spinner />;
  return (
    <div style={{width: "100%"}}>
      <Tab activeTab={0} onClick={console.log} render={[
        {title: "Paramètres", overrideOnClick: () => history.push(`/dashboard/establishments/${params.id}/settings`), content: 
          <Container>
            <Row>
              <Col>
                  <ArrowLeft color="#300438" size={42} onClick={()=> history.goBack()}/>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Card>
                  <FormGroup>
                    <Input type="text" label="Name" value={name} onChange={e => setName(e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <Input type="text" label="id (6 caractères)" value={id} onChange={e => setId(e.target.value)} 
                      style={{borderColor: idState === null ? "#ced4da" :
                        idState === "warn" ? "yellow" :
                          idState === "danger" ? "red" :
                            idState === "success" ? "lightgreen" : "#ced4da",
                      borderWidth: idState !== null ? 2 : 1}}/>
                  </FormGroup>
                  {groupement?.regions?.length > 0 ? 
                    <FormGroup>
                      <Select label="Région" options={groupement?.regions.map(r => ({label: r, value: r}))}
                        onChange={e => setRegion(e?.value ?? null)} value={{label: region, value: region}} />
                    </FormGroup>
                  : null}
                  <FormGroup>
                    <Input type="text" label="Image de profil" disabled value={profileImage} />
                  </FormGroup>
                  <FormGroup>
                    <FileUpload label="Poster" value={poster} onChange={setPoster} />
                  </FormGroup>
                  <FormGroup>
                    <GeocodeAutocomplete service={service} setCoordinates={(coord) => {
                      setLatitude(coord.lat);
                      setLongitude(coord.lng);
                    }}/>
                    <Input label="Latitude" type="number" value={latitude} onChange={e => setLatitude(parseFloat(e.target.value))} />
                    <Input label="Longitude" type="number" value={longitude} onChange={e => setLongitude(parseFloat(e.target.value))} />
                  </FormGroup>
                  <FormGroup>
                    <MultiSelect label="Fonctionnalités" options={featuresOptions} multi onChange={setFeatures} value={features} />
                  </FormGroup>
                  <FormGroup>
                    <Select label="Template menu" options={templateMenuOptions}
                      onChange={e => setMenuTemplate(e?.value ?? null)} value={templateMenuOptions.find(i => i.value === menuTemplate) ?? templateMenuOptions[0]} />
                  </FormGroup>
                  <FormGroup>
                    <Select label="Template planning" options={templatePlanningOptions}
                      onChange={e => setPlanningTemplate(e?.value ?? null)} value={templatePlanningOptions.find(i => i.value === planningTemplate) ?? templatePlanningOptions[0]} />
                  </FormGroup>
                  <div style={{marginTop: 15}}>
                    <Button color="primary" onClick={submit}>Enregistrer</Button>
                  </div>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <h4>Groupement</h4>
                  {groupement ? (
                    <span>{groupement.name}</span>
                  ) : null}
                </Card>
                <Card>
                  <Button color="danger" onClick={_takeControl}>Prise de controle</Button>{" "}
                </Card>
                <Card>
                  <h4>Bornes ({bornes.length})</h4>
                  {bornes.length > 0 && bornes.length < 50 ? (
                    <>
                      {bornes.map(i => (
                        <div key={i.address} style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 5}}>
                          <span>{i.name}({i.address})</span>
                          <div style={{width: 25, height: 25, backgroundColor: i.vpnState?.color, borderRadius: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{width: 15, height: 15, backgroundColor: i.appState?.color, borderRadius: 50}}>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <span>Aucune borne relié à cet étab</span>
                  )}
                </Card>
                <Card style={{marginTop: 30}}>
                  <h4>Outils Démo</h4>
                  <p>Permet de remplir la semaine courante.</p>
                  <Button color="primary" style={{marginTop: 15}} onClick={fillMenu}>Remplissage Menu Démo</Button>{" "}
                  <Button color="primary" style={{marginTop: 15}} onClick={fillAnimations}>Remplissage Animations Démo</Button>{" "}
                </Card>
                <Button color="danger" onClick={_cleanMenu} style={{position: "absolute", bottom: 25, right: "25%"}}>Nettoyage des menus</Button>
              </Col>
            </Row>

          </Container>
        }, 
        {title: "Menu", content: <></>, overrideOnClick: () => history.push(`/dashboard/establishments/${params.id}/menu`)},
        {title: "Abonnement", content: <></>, overrideOnClick: () => history.push(`/dashboard/establishments/${params.id}/subscription`)},
        {title: "Statistiques", content: <div></div>, overrideOnClick: () => history.push(`/dashboard/establishments/${params.id}/statistics`)}
        ]} />
    </div>
  );
};

export default Settings;