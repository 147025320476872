import React from "react";
import PropTypes from "prop-types";

import styles from "../../assets/scss/component/card.module.scss";

const CardBody = (props) => (
  <div {...props} className={`${styles.cardBody} ${props.className ?? ""}`}>
    {props.children}
  </div>
);

CardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default CardBody;