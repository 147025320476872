import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
  Button,
  Checkbox,
  FormGroup,
  Select
} from "lib/components";
import moment from "moment";
import { useMenuReservation } from "../../../router_context";
import { DishCard, SupplementRestaurant } from "../../../router_components";
import PropTypes from "prop-types";
import { sortDishesWithMenu } from "pages/Major/Menu/helpers/operations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import styles from "../../../Basic/Assets/scss/categorySelector.module.scss";
import PotageIcon from "assets/images/potage.png";
import SaladeIcon from "assets/images/salade.png";
import PlatIcon from "assets/images/dinde.png";
import AccompagnementIcon from "assets/images/carotte.png";
import FromageIcon from "assets/images/fromage.png";
import DessertIcon from "assets/images/cake.png";
import BoissonIcon from "assets/images/drink.png";
import CocotteIcon from "assets/images/cookingPot.png";
import useFeature from "@/hooks/useFeature";

// const fixe = ["assiette de fromage", "faisselle"];


const CategorySelector = (props) => {
  const { page, dataReservation, updateSelection } = props;
  const categoryIconMap = {
    "potage": PotageIcon,
    "entrée": SaladeIcon,
    "plat": PlatIcon,
    "accompagnement": AccompagnementIcon,
    "garniture": AccompagnementIcon,
    "fromage": FromageIcon,
    "supplément": FromageIcon,
    "cocotte": CocotteIcon,
    "produit laitier": FromageIcon,
    "laitage": FromageIcon,
    "dessert": DessertIcon,
    "boisson": BoissonIcon,
};
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();
  const template = ui.establishment.template;
  const dataDay = ctx?.data[ctx?.modalInfos?.date];
  const _repas = dataDay && dataDay[ctx.modalInfos.repas] ? dataDay[ctx.modalInfos.repas] : null;
  const isCocotte = moment(ctx.modalInfos.date).weekday() === 6 && ctx.modalInfos.repas === "Déjeuner";
  const [isModalSupplementOpen, setIsModalSupplementOpen] = useState(false);
  const hasFeatureRestaurant = useFeature("restaurant");


  const handleClick = (cat, dishId) => {
    
    if (dataReservation[cat] === dishId && cat !== "garniture")
      updateSelection(cat, null);
    else
      updateSelection(cat, dishId);
  };

  const getDishes = useCallback((category) => {

    if (category === "supplément" || category === "dessert") {
      if (!_repas) return [];
      let _category = _repas["dessert"]; if (!_category) return [];

      const _plats = sortDishesWithMenu(template, ctx.modalInfos.repas, Object.entries(_category));
      // _plats.push([fixe[0], { name: fixe[0] }]);
      // _plats.push([fixe[1], { name: fixe[1] }]);
      return _plats;

    } else {
      if (!_repas) return [];
      let _category = _repas[category]; if (!_category) return [];

      const _plats = sortDishesWithMenu(template, ctx.modalInfos.repas, Object.entries(_category));
      return _plats;
    }

  }, [dataDay, dataReservation, ctx]);

  const isDishDisabled = useCallback((cat, dishId) => {
      if (cat === "supplément") {
      
      // on vérifie si ce plat est un dessert et si un dessert est déjà réservé dans la categ dessert.
      if (_repas && dataReservation && Object.keys(_repas["dessert"]).includes(dataReservation["dessert"]) && Object.keys(_repas["dessert"]).includes(dishId)) {
        return true;
      }else{
        if (dataReservation && dataReservation["dessert"] === dishId) {
          return true;
        }
      }

      return false;
    } else {
      return false;
    }
  }, [dataDay, dataReservation, ctx]);

  const isCategoryDisabled = useCallback((category) => {
    if (ctx.modalInfos.repas === "Dîner") {
      switch (category) {
        case "entrée":
          if (dataReservation && dataReservation["plat"] && dataReservation["dessert"]) return true;
          else return false;
        case "plat":
          if (dataReservation && dataReservation["entrée"] && dataReservation["dessert"]) return true;
          else return false;
        case "dessert":
          if (dataReservation && dataReservation["entrée"] && dataReservation["plat"]) return true;
          else return false;
        case "supplément":
          if (dataReservation && dataReservation["entrée"] && dataReservation["plat"] && dataReservation["dessert"]) return true;
          else return false;
        default:
          break;
      }
    } else {
      return false;
    }
  }, [dataDay, dataReservation, ctx]);

  const findIcon = (category) => {
    let _category = isCocotte && category === "plat" ? "cocotte" : category;

    const categList = Object.keys(categoryIconMap);
    const found = categList.find(_c => _c.includes(_category.toLowerCase()));
    if(found){
        return categoryIconMap[found];
    }else return null;
};

  const isSelected = (dataReservation, cat, dishId) => {
    if(["garniture", "dessert"].includes(cat) && dataReservation && dataReservation[cat] && dataReservation[cat].includes(dishId)) return true;
    if(dataReservation && dataReservation[cat] && dataReservation[cat] === dishId) return true;
    return false;
  };

  return (
    <div className={styles.container}>


      {[...template[ctx.modalInfos.repas]?.categories, hasFeatureRestaurant && "Suppléments Restaurant"].map((cat, indx) => (
        (isCocotte && !["garniture", "dessert"].includes(cat)) || !isCocotte? 
        <div key={indx} className={styles.categComponent}>
          <div className={styles.categTitle}>
            {cat && findIcon(cat) && <img className={styles.categIcon} src={findIcon(cat)} alt={cat} />}
            {isCocotte && cat === "plat" ? "cocotte" : cat}
            {cat === "Suppléments Restaurant" && 
              <div style={{cursor: "pointer", color: "#5F0D6D"}} onClick={() => setIsModalSupplementOpen(true)}>
                  <FontAwesomeIcon
                      icon={faEdit}
                      size="1x"
                      color="#5F0D6D"
                  />
                  Éditer
              </div>
            }
          </div>
          {cat !== "Suppléments Restaurant" ?
          <div className={styles.categChoices}>
            {
              dataDay && dataDay[ctx.modalInfos.repas] ?
                getDishes(cat).map(([dishId, dish], indx2) => (
                  <div key={indx2}>
                    <DishCard disabled={isCategoryDisabled(cat) || isDishDisabled(cat, dishId)} selected={isSelected(dataReservation, cat, dishId)} category={cat} dishId={dishId} dishInfo={dish} handleClick={(dishId) => handleClick(cat, dishId)} />
                  </div>
                ))
                : null
            }
          </div> : 
          hasFeatureRestaurant && <div className={styles.categChoices}>
            <SupplementRestaurant 
                isOpen={isModalSupplementOpen}
                onClose={() => setIsModalSupplementOpen(false)}
                onSubmit={(supplement) => {
                    updateSelection("suppléments restaurant", supplement);
                }} 
                supplements={dataReservation["suppléments restaurant"] ?? []}
            />
          </div>
          }
        </div>
        : null
      ))}
    </div>
  );
};


CategorySelector.propTypes = {
  dataReservation: PropTypes.object,
  updateSelection: PropTypes.func,
  page: PropTypes.number,
};


export default CategorySelector;
