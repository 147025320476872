import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "../../../hooks/useCultureQuizzInstance";
import { Button, Spinner } from "../../../lib/components";
import { useFullscreen } from "rooks";

import podium1 from "assets/images/CultureQuizz/podium1.png";
import podium2 from "assets/images/CultureQuizz/podium2.png";
import podium3 from "assets/images/CultureQuizz/podium3.png";
import styles from "assets/scss/pages/CutlureQuizz/QuizzResults.module.scss";

const QuizzResults = () => {
  const params = useParams();
  const {
    loading,
    instance,
    quizz,
    participants,
    abort
  } = useCultureQuizzInstance(params.uid);
  const {
    isFullscreenAvailable,
    isFullscreenEnabled,
    toggleFullscreen,
  } = useFullscreen();

  const [results, setResults] = useState(null);

  useEffect(() => {
    let _participants = [...participants];
    if (_participants.length === 0) {
      if (isFullscreenAvailable && isFullscreenEnabled) toggleFullscreen();
    }
    _participants = _participants.map(p => {
      let counter = 0;
      if(instance.answers){
        Object.values(instance.answers).forEach(a => {
          let _answer = a[p.uid];
          if (_answer && _answer.isCorrect) { 
            counter++;
          }
        });
      }
    
      return ({...p, score: counter});
    });
    //sort descending by score
    _participants.sort((a, b) => {return b.score - a.score;});
    setResults(_participants);
  }, [quizz, participants]);

  if (loading) return <Spinner />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}><h2>Fin de la partie</h2></div>
      </div>
      <div className={styles.podiums}>
        <div className={`${styles.podium} ${styles.podium2}`}>
          <p className={styles.participant}>{results.length >= 2 ? `${results[1].surname} ${results[1].name}` : "" }</p>
          <img src={podium2} alt="podium2" />
          <p className={styles.score}>{results.length >= 2 ? `${results[1].score}/${instance.currentQuestion - 1}` : "" }</p>
        </div>
        <div className={`${styles.podium} ${styles.podium1}`}>
          <p className={styles.participant}>{results.length >= 1 ? `${results[0].surname} ${results[0].name}` : "" }</p>
          <img src={podium1} alt="podium1" />
          <p className={styles.score}>{results.length >= 1 ? `${results[0].score}/${instance.currentQuestion - 1}` : "" }</p>
        </div>
        <div className={`${styles.podium} ${styles.podium3}`}>
          <p className={styles.participant}>{results.length >= 3 ? `${results[2].surname} ${results[2].name}` : "" }</p>
          <img src={podium3} alt="podium3" />
          <p className={styles.score}>{results.length >= 3 ? `${results[2].score}/${instance.currentQuestion - 1}` : "" }</p>
        </div>
      </div>
      <div className={styles.participants}>
        {results.length >= 4 ? results.slice(3).map((p,index) => (
          <div key={p.uid} className={styles.participant}>
            <p className={styles.title}>{index + 4 }. {`${p.surname} ${p.name} (${p.score}/${instance.currentQuestion - 1})`}</p>
          </div>
        )) : null}
      </div>
      <div style={{position: "absolute", zIndex: 1001, top: -95,left: "70px", right: "165px", height: "80px", display: "flex", justifyContent: "center"}}>
        <div style={{position: "relative", width: "95%", height: "100%", display: "flex", justifyContent: "space-between", alignItems:"center"}}>
          <span></span>
          <span></span>
          <Button onClick={() => {if (isFullscreenEnabled) exit();abort();}} style={{fontSize: "1.2vw", fontWeight:"bold"}}>Fin de la partie</Button>
        </div>
      </div>
    </div>
  );
};

export default QuizzResults;