import {useState, useEffect} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "hooks/ui.hook";

const firestore = firebase.firestore;

const useRegionNews = () => {
  const [ui] = useUI();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const subscribe = firestore().collection("groupements").doc(ui.user.groupement)
      .collection("actus").where("type", "==", ui.user.region).onSnapshot(snap => {
        let _data = [];
        snap.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
        setData(_data);
        setLoading(false);
      }, (e) => {
        setError(e);
      });
    return subscribe;
  }, []);

  return ({data, error, loading});
};

export default useRegionNews;