import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
    Button,
    Checkbox,
    FormGroup,
    Select
} from "lib/components";
import moment from "moment";
import { useMenuReservation } from "../../router_context";
import { DishCard, SupplementRestaurant } from "../../router_components";
import PropTypes from "prop-types";
import { sortDishesWithMenu } from "pages/Major/Menu/helpers/operations";
import {findSubscriptionForDate} from "helpers/subscription"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import styles from "../Assets/scss/categorySelector.module.scss";
import PotageIcon from "assets/images/potage.png";
import SaladeIcon from "assets/images/salade.png";
import PlatIcon from "assets/images/dinde.png";
import AccompagnementIcon from "assets/images/carotte.png";
import FromageIcon from "assets/images/fromage.png";
import DessertIcon from "assets/images/cake.png";
import BoissonIcon from "assets/images/drink.png";
import useFeature from "@/hooks/useFeature";

const CategorySelector = (props) => {
    const { page, dataReservation, updateSelection } = props;

    const [ui] = useUI();
    const [ctx, dispatch] = useMenuReservation();
    const categoryIconMap = {
        "potage": PotageIcon,
        "entrée": SaladeIcon,
        "plat": PlatIcon,
        "accompagnement": AccompagnementIcon,
        "garniture": AccompagnementIcon,
        "fromage": FromageIcon,
        "produit laitier": FromageIcon,
        "laitage": FromageIcon,
        "dessert": DessertIcon,
        "boisson": BoissonIcon,
    };
    const currentUser = ctx?.users[ctx?.modalInfos?.uid] ?? {};
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const subscription = findSubscriptionForDate(currentUser?.subscriptions, ctx?.modalInfos?.date);
    const dataDay = ctx?.data[ctx?.modalInfos?.date];
    const [isModalSupplementOpen, setIsModalSupplementOpen] = useState(false);
    const hasFeatureRestaurant = useFeature("restaurant");

    const handleClick = (cat, dishId) => {
        if (dataReservation[cat] === dishId)
            updateSelection(cat, null);
        else
            updateSelection(cat, dishId);
    };

    const isSupplement = useCallback((cat) => {
        if (page === 0 && template && templateSubscription && subscription?.subscription && templateSubscription[subscription?.subscription].repas[ctx?.modalInfos?.repas]) {
            // s'il y a un abonnement pour ce repas.
            if (!templateSubscription[subscription?.subscription].repas[ctx?.modalInfos?.repas].includes(cat)) return true;
        }
        return false;
    }, [page, dataReservation, ctx]);

    const findIcon = (category) => {
        const categList = Object.keys(categoryIconMap);
        const found = categList.find(_c => _c.includes(category.toLowerCase()));
        if(found){
            return categoryIconMap[found];
        }else return null;
    };

    return (
        <div className={styles.container}>


            {[...template[ctx.modalInfos.repas].categories, hasFeatureRestaurant && "Suppléments Restaurant"].map((cat, indx) => (

                <div key={indx} className={styles.categComponent}>
                    <div className={styles.categTitle}>
                        {cat && findIcon(cat) && <img className={styles.categIcon} src={findIcon(cat)} alt={cat} />}
                        {cat} {isSupplement(cat) ? <span className={styles.supplement}>(en supplément)</span> : null}
                        {cat === "Suppléments Restaurant" && 
                            <div style={{cursor: "pointer", color: "#5F0D6D"}} onClick={() => setIsModalSupplementOpen(true)}>
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    size="1x"
                                    color="#5F0D6D"
                                />
                                Éditer
                            </div>
                        }
                    </div>
                    {cat !== "Suppléments Restaurant" ?
                    <div className={styles.categChoices}>
                        {
                            dataDay && dataDay[ctx.modalInfos.repas] && dataDay[ctx.modalInfos.repas][cat] ?
                            sortDishesWithMenu(template, ctx?.modalInfos?.repas, Object.entries(dataDay[ctx.modalInfos.repas][cat])).map(([dishId, dish], indx2) => (
                                    <div key={indx2}>
                                        <DishCard selected={dataReservation && dataReservation[cat] ? dataReservation[cat] === dishId : false} dishId={dishId} dishInfo={dish} handleClick={(dishId) => handleClick(cat, dishId)} />
                                    </div>
                                ))
                                : null
                        }
                    </div> : 
                    hasFeatureRestaurant && <div className={styles.categChoices}>
                    <SupplementRestaurant 
                        isOpen={isModalSupplementOpen}
                        onClose={() => setIsModalSupplementOpen(false)}
                        onSubmit={(supplement) => {
                            updateSelection("suppléments restaurant", supplement);
                        }} 
                        supplements={dataReservation["suppléments restaurant"] ?? []}
                    />
                  </div>
                  }
                </div>
            ))}
        </div>
    );
};


CategorySelector.propTypes = {
    dataReservation: PropTypes.object,
    updateSelection: PropTypes.func,
    page: PropTypes.number,
};


export default CategorySelector;
