import React from "react";
import {Maximize} from "react-feather";
import PropTypes from "prop-types";

import styles from "../assets/scss/component/icons.module.scss";

const Icon = (props) => {
  const {
    icon=Maximize,
    size=18,
    separator= "none",
    className= "",
    onClick,
    color="primary"
  } = props;

  const colorClass = color === "primary"   ? styles.primary   :
    color === "secondary" ? styles.secondary :
      color === "success"   ? styles.success   :
        color === "warning"   ? styles.warning   :
          color === "info"      ? styles.info      :
            color === "danger"    ? styles.danger    : 
              color === "light"     ? styles.light     : 
                color === "dark"      ? styles.dark      : styles.primary;

  const styleSheet = {
    cursor      : onClick ? "pointer" : undefined,
    borderRight : (separator === "right" ? "1px solid var(--light-grey)" : ""),
  };

  const FeatherIcon = icon;

  return (
    <FeatherIcon
      style={styleSheet}
      className={`${styles.icon} ${colorClass} ${className ?? ""}`}
      size={size}
      onClick={onClick}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.any,
  size: PropTypes.number,
  separator: PropTypes.oneOf(["none", "right"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["primary", "secondary", "success", "warning", "info", "danger", "light", "dark"]),
};

export default Icon;