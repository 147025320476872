import React, {useState} from "react";
import PropTypes from "prop-types";

import styles from "../../assets/scss/component/stats/card.module.scss";
import {CSSTransition} from "react-transition-group";

import { Info } from "react-feather";

import "../../assets/scss/component/stats/flip-transition.css";


const Card = (props) => {
  const {
    title,
    info,
    className,
    size="md",
    children,
    footer,
    raw=false
  } = props;
  const [showInfo, setShowInfo] = useState(false);

  const sizeClass = size === "sm" ? styles.sm :
  size === "md" ? styles.md :
    size === "lg" ? styles.lg :
      size === "long" ? styles.long :
        size === "xl" ? styles.xl :
        size === "full" ? styles.full : styles.md;

  return (
    <div>
         <CSSTransition
                in={!showInfo}
                timeout={400}
                classNames='flip'
            >
            <div className={`${styles.card} ${sizeClass} ${className ?? ""}`}>
                <div className={styles.front} onClick={() => raw ? setShowInfo(!showInfo) : null}>
                   {!raw ?
                    <>
                      <div className={styles.header}>
                          <div></div>
                          <div className={styles.title}>{title}</div>
                          <div><Info size={25} onClick={()=>setShowInfo(!showInfo)} className={styles.icon}/></div>
                      </div>

                      <div className={styles.body}>
                          {children}
                      </div>

                      {footer ? 
                        <div className={styles.footer}>
                          {footer}
                        </div>
                      : null}
                    </>
                   : children}
                   
                </div>
                <div className={styles.back} onClick={()=>setShowInfo(!showInfo)}>
                  {!raw ? 
                    <div className={styles.info}>
                      {info}
                    </div>
                  : info}
                   
                </div>
                
            </div>
        </CSSTransition>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  footer: PropTypes.any,
  size: PropTypes.oneOf(["sm", "md", "lg", "long", "xl"]),
  raw: PropTypes.bool
};

export default Card;