import React, { useState, useEffect, useMemo } from "react";
import { Edit, Clipboard, Plus, Square, CheckSquare, Trash2 } from "react-feather";
import {
  Container, Row, Col,
  Table, Icon, Tag, Button,
  Card, CardHeader, CardBody, Input,Spinner
} from "lib/components";
import useUI from "hooks/ui.hook";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import useSearch from "hooks/useSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortNumericDown, faSortNumericUpAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./tableau.module.scss";
import { getAllInterventionsByEtab_rt, updateInterventionByEtab } from "services/ressources/interventions";
import { getEstablishmentById } from "services/ressources/establishment";
import { getUsersByEtab } from "services/ressources/user";
import moment from "moment";
import useAnimationEvents, { useAnimationCategories } from "../../../resources/animations";
import WaitingModal from "./WaitingModal";
import { deleteWaitingEvent, getWaitingListById, updateWaitingEvent } from "../../../services/ressources/waitingList";
import { getAnimationById } from "../../../services/ressources/animations";
import { getNewsByIdAndEtab, updateActu } from "../../../services/ressources/actus";

const parameters = [ "type", "title"];
const state = ["todo", "done"];

const WaitingList = () => {
  const [ui] = useUI();
  const { update } = useAnimationEvents();

  const [value, setValue] = useState("");
  const [inWaiting,setInWaiting] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading,setLoading]=useState(false);

  const [itemOpen,setItemOpen] = useState({});
  const [date,setDate] =useState(new Date());
  const [startTime,setStartTime] = useState({value:"",label:""});
  const [isModalVisible,setIsModalVisible] =useState(false);
  const colors = {"info":"#4A919E", "warning":"#f2994a","alert": "#FF7F7F"};
  const { categories, loading: categoriesLoading  } = useAnimationCategories();
  
  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO" || ui?.establishment?.uid === "A1LKT1bK4Aj4TAaqPsel";
  
  const searchInWaiting = useMemo(() => {
    if(!isAutourDeLHumain) return inWaiting;
    
    return inWaiting.map(i => ({
      ...i,
      type: i.type === "Animation" ? "Atelier" : i.type,
    }))
  }, [inWaiting, isAutourDeLHumain])
  
  const { filtered } = useSearch(searchInWaiting || [], value, parameters);

  const fetchWaitingData = async () => {
    setLoading(true);
    try {       
      const inwaiting = await getWaitingListById({ ui: ui });
      setInWaiting(inwaiting ?? []);
    } catch (e) {
      console.error(e.message);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    fetchWaitingData();
  }, []);


  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const getEventColorFromCategory = (categoryId) => {
    if (!categoryId) return "#f2994a";
    const category = categories.find((i) => i.uid === categoryId);
    if (!category) return "#f2994a";
    return category;
  };

  const sorted = useMemo(() => {
    if (!filtered) return [];

    return filtered.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.timing - b.timing;
      } else {
        return b.timing - a.timing;
      }
    }).sort((a, b) => state.indexOf(a.state) - state.indexOf(b.state));
  }, [filtered, sortOrder]);

  const _onDelete = async(item) => {    
    try {
      await deleteWaitingEvent({eventId: item.uid});  
      await fetchWaitingData();
      if( item.type === "Animation"){
        const animToUpdate = await getAnimationById({etabId:ui.user.establishment,animId:item.eventLinkedId});
        const date = new Date(animToUpdate.start.seconds * 1000);
        await update(animToUpdate.uid, { ...animToUpdate, isProgrammed:false,date,type:animToUpdate.eventType});
      } 
      if( item.type === "Actualité"){
        const actuToUpdate = await getNewsByIdAndEtab({etabId:ui.user.establishment,actuId:item.eventLinkedId});
        await  updateActu({ui, id: actuToUpdate.uid, data: {isProgrammed:false,isPublished:actuToUpdate.isPublished}});
      }  
      
      setIsModalVisible(false);
    } catch (e) {
      console.error(e.message);
    } 


  };

  const _updateWaitingTime = async () => {
    try {
      const [scheduleHours, scheduleMinutes] = startTime?.value.split('h').map(Number);
      let newDate = new Date(date);
      newDate.setHours(scheduleHours, scheduleMinutes);
      const { uid, ...itemOpenWithoutUid } = itemOpen;
      const updatedDate = { ...itemOpenWithoutUid, timing: newDate };

      await updateWaitingEvent({ waitingEventId: itemOpen.uid, data: updatedDate });
      await fetchWaitingData();
      setIsModalVisible(false);
    } catch (e) {
      console.error(e.message);
    }

  }
  
  const onModifyOpen = (item) => {
    setItemOpen(item);
    setIsModalVisible(true);
  };

  if (loading) return <Spinner />
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Card style={{ backgroundColor: "white" }}>
            <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center" }}>
                <h5 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>{"En attente d'envoi"}</h5>
              </div>
            </CardHeader>
            <CardBody>
                <div style={{ width: "60%", display: "flex", flexDirection: "row", alignItems: "center", gap: 5, marginBlock:"10px" }}>
                  <h6 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>Recherche :</h6>
                  <Input value={value} onChange={(e) => setValue(e.target.value)} />
                </div>
              <Row className={styles.titreColonne} >
                <div onClick={handleSort} style={{fontWeight: "bold", fontSize: "18px", flex: 1, marginRight: "15px", display: "flex",cursor: "pointer" }}>
                  <div>Date d'envoi</div>
                  <FontAwesomeIcon icon={sortOrder === "asc" ? faSortNumericDown : faSortNumericUpAlt} style={{ marginLeft: "6px", fontSize: "20px", width: "20px" }} />
                </div>
                <div style={{ fontWeight: "bold", display: "flex", justifyContent: "center", fontSize: "18px", flex: 1 }}>Type</div>
                <div style={{ fontWeight: "bold", display: "flex", justifyContent: "center", fontSize: "18px", flex: 1 }}>Categorie</div>
                <div style={{ flex: 1, display: "flex", fontWeight: "bold", justifyContent: "center", fontSize: "18px", width: "fit-content" }}>Titre</div>
                <div style={{ display: "flex", justifyContent: "flex-end", fontWeight: "bold", fontSize: "18px", flex: 1}}></div>
              </Row>
              <div style={{ borderBottom: "1px solid lightgray", marginBlock: "10px", width: "100%" }}></div>
              {sorted.map((_type, idx) =>  {
                let date= new Date(_type?.timing.seconds * 1000 + _type?.timing.nanoseconds / 1000000);
                return (
                  <Row key={idx} className={styles.rowContainer}>
                    <div className={styles.column}>
                      <Container className={styles.rowContainer}>
                        <div className={styles.column}>
                          <Container
                            className={`${styles.dayContainer} ${styles.select}`}
                            onClick={() => { onModifyOpen(_type); }}
                            style={{ paddingInline: "30px", paddingBlock: "10px" }}
                          >
                            <div className={styles.date}>
                              {moment(date).format("DD/MM/YYYY HH:mm")}
                            </div>
                            <div className={styles.type}>
                              {isAutourDeLHumain && _type.type === "Animation" ? "Atelier" : _type.type}
                            </div>
                            <div className={styles.info}>
                              {_type.type === "Bandeau Dynamique" &&_type.category !== undefined && _type.category !== " " ? (
                                <div className={styles.category} style={{ backgroundColor: colors[_type.category] }}>
                                  {_type.category === "info" ? "Information" : _type.category === "warning" ? "Attention" : _type.category === "alert"? "Alerte":null}
                                </div>
                              ) : (
                                _type.type === "Animation" && _type.categoryId && (() => {
                                  const eventColor = getEventColorFromCategory(_type.categoryId);
                                  return (
                                    <div className={styles.category} style={{ backgroundColor: eventColor.color }}>
                                      {eventColor.title}
                                      {eventColor.smiley && <img src={eventColor.smiley} width="22px" style={{ marginLeft: 10 }} />}
                                    </div>
                                  );
                                })()
                              )}
                            </div>
                            <div className={styles.info}>
                              {_type.title}
                            </div>
                            <Row className={styles.icons} onClick={(e) => e.stopPropagation()}>
                              {/* <Edit
                                color="blue"
                                size="20px"
                                style={{ marginLeft: "50px", marginRight: "15px" }}
                                onClick={() => { onModifyOpen(_type); }}
                              /> */}
                              <Trash2
                                color="red"
                                size="20px"
                                style={{ marginRight: "15px" }}
                                onClick={() => { _onDelete(_type); }}
                              />
                            </Row>
                          </Container>
                        </div>
                      </Container>
                    </div>
                  </Row>);
              })}
             
            </CardBody>
          </Card>
        </Col>
      </Row>

              <WaitingModal isOpen={isModalVisible} eventOpen={itemOpen} toggle={()=>setIsModalVisible(false)} setInfo={(startTime,date)=>{setStartTime(startTime); setDate(date);}} onValidate={()=>_updateWaitingTime()} ></WaitingModal>
              {/* <WaitingModal onValidate={()=>_onSubmit(isPublished)}></WaitingModal> */}

    </Container>
  );
};


export default WaitingList;