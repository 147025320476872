import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const sendNotifToCentreNotif = ({ ui, data }, err) => {
  //data au format suivant : En adaptant suivant le type de notification à envoyer
  /*
      {
          type: "", // ["animation", "menu", "service", ...] 
          action: "", // ["add", "update", "delete"]
          data: {
              userId: "", // l'id de l'utilisateur qui a fait l'action 
              date: Date(),
              name: "", // repas, animation name, prestataire
               
              // specificités :
              category: "", // catégorie de l'animations ou du service
              
              homeDelivery: false, // portage sur les menus
              quantity: 3, //nombre de réservations sur les menus (invités)
              
              prestation: [""], // préstations choisies pour les services
              end: "", //heure de fin du service / animation
          }
      }
  */
  try {
      firestore()
          .collection("establishments")
          .doc(ui.user.establishment)
          .collection("notifications")
          .add({
              ...data,
              createdAt: new Date(),
              source: "major",
              seenBy: [ui.user.uid]
          });
  } catch (e) {
      console.error(e);
  }
};

export const getNotificationsForEtab_rt = ({ui}, cb, err) => {
  const unsubscribe = firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("notifications")
                        .orderBy("createdAt", "desc")
                        .onSnapshot(snap => {
                          let _data = [];
                          snap.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
                          const filteredData = _data.filter(notification => !notification.sendTo);
                          cb(filteredData);
                        }, err ?? (() => {}));
  return unsubscribe;
};

export const updateNotificationById = async ({ui, id, data}, err) => {
  try {
    await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("notifications")
      .doc(id)
      .update({
        ...data,
        updatedAt: new Date(),
      }, {merge: true});
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};


export const updateNotificationBatch = async ({ui, notif}, err) => {
  try {
    const batch = firestore().batch();
    Object.entries(notif).forEach(([uid, data]) => {
      const _data = {...data,  updatedAt: new Date()};
      batch.update(
        firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("notifications")
        .doc(data.uid),
        _data,
      );
    });
    await batch.commit();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};