import React, { useState, useEffect, useMemo } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@/lib/components";
import styles from "../Assets/scss/supplementRestaurant.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import useSupplements from "../Stores/useSupplements";

// pas opti du tout, il faudrait faire un store zustand pour récup les suppléments. car là ça va get la BDD à chaque fois qu'on ouvre une modale ...

const SupplementRestaurant = (props) => {
  const {isOpen, onClose ,onSubmit, supplements} = props;
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const allSupplements = useSupplements(state => state.supplements);
  const [selectedSupplement, setSelectedSupplement] = useState([]);

  useEffect(() => {
    if(isOpen) {
      setSelectedSupplement(supplements ?? [])
    }
  }, [isOpen])

  const toogle = (supplement) => {
    if(!selectedSupplement.find(_sup => _sup.id === supplement.id))
        setSelectedSupplement([...selectedSupplement, {...supplement, number: 1}]);
  
  }

  const addNumber = (supplement) => {
    setSelectedSupplement(selectedSupplement.map(_sup => _sup.id === supplement.id ? {...supplement, number: _sup.number + 1} : _sup));
  }

  const removeNumber = (supplement) => {
    const newSelectedSupplement = selectedSupplement.map(_sup => _sup.id === supplement.id ? {...supplement, number: _sup.number - 1} : _sup);
    const filteredSelectedSupplement = newSelectedSupplement.filter(_sup => _sup.number > 0);
    setSelectedSupplement(filteredSelectedSupplement);
  }

  const groupedSupplements = useMemo(() => {
      let result = {}
      allSupplements.forEach(supplement => {
          const category = supplement.category?.replaceAll("|/|", " ") ?? "";
          if(category.length > 0){
              if(!result[category]) result[category] = [];
              result[category].push({ id: supplement.id, name: supplement.name, price: supplement.price })
          }
      });
      
      result["Autres"] = allSupplements
                          .filter(supplement => !supplement.category || supplement.category.length === 0)
                          .map(supplement => ({ id: supplement.id, name: supplement.name, price: supplement.price }));
                          
      return Object.fromEntries(Object.entries(result).sort(([key1], [key2]) => key1.localeCompare(key2)));
  },[allSupplements]);

  return (
    <>
      {/* <div onClick={() => setIsModalOpen(true)} className={styles.item}>
        <FontAwesomeIcon
          icon={faEdit}
          size="1x"
        />
        Éditer les suppléments
      </div> */}
      {supplements && supplements.map((_sup) => (
        <div key={_sup.id} className={styles.item}>
          {_sup.name}
          <div style={{fontWeight: "bold"}}>x{_sup.number}</div>
        </div>
      ))}
      <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal} size="xl">
        <ModalHeader>Suppléments Restaurant</ModalHeader>
        <ModalBody className={styles.modalBody}>
          {
            Object.entries(groupedSupplements).map(([_categ, _list]) => 
              { return _list.length > 0 ?
                (<div key={_categ} style={{marginBottom: 40}}>
                  <div style={{color: "purple", fontSize: 28, fontWeight: "bold", width: "100%", textAlign: "center"}}>{_categ}</div>
                  <div className={styles.supplementsContainer}>
                    {_list.sort((_a, _b) => _a.name.localeCompare(_b.name)).map(_sup => (
                      <div
                        className={styles.item}
                        key={_sup.id}
                        onClick={() => toogle(_sup)}
                      >
                        {_sup.name}
                        {selectedSupplement.find(s => s.id === _sup.id) && <div className={styles.quantitySelector}>
                          <FontAwesomeIcon
                            icon={faMinus}
                            color="white"
                            size="lg"
                            style={{cursor: "pointer", backgroundColor: "#300438", padding: 5, borderRadius: "100%"}}
                            onClick={() => removeNumber(_sup)}
                          />
                          <div style={{fontSize: 24}}>{selectedSupplement.find(s => s.id === _sup.id).number}</div>
                          <FontAwesomeIcon
                            icon={faPlus}
                            color="white"
                            size="lg"
                            style={{cursor: "pointer", backgroundColor: "#300438", padding: 5, borderRadius: "100%"}}
                            onClick={() => addNumber(_sup)}
                          />
                        </div>}
                      </div>
                    ))}
                  </div>
                </div>) : 
                null
              }  
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {onSubmit(selectedSupplement); onClose();}}>Valider</Button>
          <Button color="secondary" onClick={() => onClose()}>Annuler</Button>
        </ModalFooter>
      </Modal>
    </>
  )
};

export default SupplementRestaurant;