import {useState, useEffect} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "./ui.hook";
import { getGroupementById } from "services/ressources/groupement";
import { getEstablishmentById } from "services/ressources/establishment";

//DANGER only for role groupementAdmin
const useGroupement = () => {
  const [ui] = useUI();
  const [loading, setLoading] = useState(true);
  const [groupement, setGroupement] = useState({});
  const [isRegions, setIsRegions] = useState(false);
  const [establishments, setEstablishments] = useState([]);

  useEffect(() => {
    (async () => {
      if (!ui.user.groupement) return;
      
      //pull groupement
      let _groupement = await getGroupementById({id: ui.user.groupement});
      
      //pull establishments of groupement
      let _establishments = await Promise.all(_groupement.establishments.map(async e => {
        let establishment = await getEstablishmentById({id: e});
        return establishment;
      }));

      setGroupement(_groupement);
      if(_groupement.regions && _groupement.regions.length > 0){
        setIsRegions(true);
      }
      setEstablishments(_establishments);
      setLoading(false);
    })();
  }, [ui.user]);

  const getRegionsEstablishments = (region) => {
    if(isRegions){
      let list = [];

      establishments.forEach(_etab => {
        if(_etab.region === region)list.push(_etab.uid);
      });
      return list;
    }else{
      return establishments;
    }
  };

  return {groupement, establishments, isRegions, getRegionsEstablishments, loading};
}; 

export default useGroupement;