import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, Container, Row, Col, Card, CardHeader, CardBody, Tag, Modal, ModalHeader, ModalBody, ModalFooter, Select, Input, Button, FormGroup } from "../../lib/components";
import { useFirebaseCollectionOnce } from "lib/hooks/firebase";
import firebase from "firebase/app";
import 'firebase/firestore';
import { Edit, Plus, Trash, RotateCcw } from "react-feather";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import roles from "enums/roles";
import useUI from "hooks/ui.hook";
import useMicroService from "helpers/microService";
import useSearch from "hooks/useSearch";
import { getAllGroupements_rt } from "services/ressources/groupement";
import Paginate from "react-paginate";
import moment from "moment";

const firestore = firebase.firestore;
const parameters = ["name", "surname", "etabName"];

const Users = () => {
  const [ui] = useUI();
  const history = useHistory();
  const execMicroService = useMicroService();
  const [users, setUsers] = useState([]);
  const [establishmentsRef] = useState(firestore().collection("establishments"));
  const { data: establishments } = useFirebaseCollectionOnce(establishmentsRef, []);
  const [groupements, setGroupements] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [role, setRole] = useState(null);
  const [establishment, setEstablishment] = useState(null);
  const [groupement, setGroupement] = useState(null);
  const [region, setRegion] = useState(null);

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userFilter, setUserFilter] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [value, setValue] = useState("");
  const { filtered } = useSearch(filteredUsers, value, parameters);

  const itemsPerPage = 100;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(filtered.length / itemsPerPage);


  const handlePageChange = (event) => {
    setItemOffset((event.selected * itemsPerPage) % filtered.length);
    window.scrollTo(0, 0);
  };

  const filterUsers = (user) => {
    if (userFilter.length == 0)
      return true;
    else
      return userFilter.indexOf(user.role) != -1 ? true : false;
  };

  useEffect(() => getAllGroupements_rt(null, setGroupements), []);

  useEffect(() => {
    setFilteredUsers(users.filter(filterUsers));
  }, [users, userFilter]);

  useEffect(() => {
    setRegion(null);
    setGroupement(null);
  }, [role]);

  useEffect(() => {
    if (!isOpen) setPassword("");
  }, [isOpen]);

  useEffect(() => {
    if (establishments && establishments.length > 0) {
      (async () => {
        try {
          let res = await firestore().collection("users").get();
          let rdata = [];
          res.forEach(d => rdata.push({ uid: d.id, ...d.data() }));

          setUsers(rdata.map(_u => ({..._u, etabName: (establishments?.find(e => e?.uid === _u?.establishment) ?? {})?.name ?? ""})));
        } catch (e) {
          console.error(e);
        }
      })();
    }

  }, [establishments]);

  console.log(users);
  const _create = async () => {
    try {
      setLoading(true);
      //cloud function ? 
      let res = await execMicroService("createUserFromSuperadmin", {
        name,
        surname,
        mail,
        role: role.value,
        establishment: establishment ? establishment.value : null,
        groupement: groupement ? groupement.value : null,
        region: region ?? null,
      });
      if (res.status === 200 && !res.data.error) {
        toast.success("Utilisateur créé");
        setName("");
        setSurname("");
        setMail("");
        setRole(null);
        setEstablishment(null);
        setGroupement(null);
        setPassword(res.data.password);
      } else {
        console.error(res.data);
        toast.error(res.data.error ? res.data.error : "Erreur lors de la création de l'utilisateur");
      }
    } catch (e) {
      console.error(e.message);
      console.error(e.data);
      toast.error("Cette adresse mail est déjà prise ou est incorrecte");
    }
    setLoading(false);
  };

  const onDelete = async (uid) => {
    if (!window.confirm("Souhaitez vous vraiment supprimer cet utilisateur ? (cette action est irréversible)")) return;

    try {
      let res = await execMicroService("deleteUser", { uid: uid });
      if (res.status === 200) {
        toast.success("Utilisateur supprimé");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const onRestore = async (uid) => {
    if (!window.confirm("Souhaitez vous vraiment restaurer cet utilisateur ?")) return;

    try {
      await firestore().collection("users").doc(uid).update({ isDeleted: null, deletedAt: null });
      toast.success("Utilisateur restauré");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (
    <>
      <Container>
        <h5>Filtres : </h5>
        <RenderFilter userFilter={userFilter} setUserFilter={setUserFilter} />
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center" }}>
                  <h5 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>Liste des utilisateurs</h5>
                  <div style={{ width: "60%", display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                    <h6 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>Recherche :</h6>
                    <Input value={value} onChange={(e) => setValue(e.target.value)} />
                  </div>
                </div>
                <Button color="primary" onClick={() => setIsOpen(true)}><Plus /></Button>
              </CardHeader>
              <CardBody>
                <Table className="" loading={users.length === 0}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>Etablissement</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered.slice(itemOffset, endOffset).map(u => (
                      <tr key={u.uid} style={{ backgroundColor: u.isDeleted ? "#ff7996" : "" }}>
                        <td>{u.name}</td>
                        <td>{u.surname}</td>
                        <td>{(establishments.find(e => e.uid === u.establishment) ?? {}).name}</td>
                        <td>
                          <Tag style={{ backgroundColor: (roles.find(r => r.value === u.role) ?? {}).color }}>
                            {(roles.find(r => r.value === u.role) ?? {}).label ?? u.role}
                          </Tag>
                        </td>
                        <td>
                          {u.uid !== ui.user.uid ?
                            <>
                              {u.isDeleted && <RotateCcw size={20} style={{ color: "green", cursor: "pointer" }} onClick={() => onRestore(u.uid)} />}
                              <Trash size={20} style={{ color: "red", cursor: "pointer" }} onClick={() => onDelete(u.uid)} />
                              &nbsp;
                              <Edit size={20} onClick={() => history.push("/dashboard/users/" + u.uid)} color="#300438" style={{ cursor: "pointer" }} />
                            </>
                            : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 5 }}>
                  <Paginate
                    className="react-paginate"
                    breakLabel="..."
                    nextLabel="next"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <ModalHeader><h5>Ajouter un utilisateur</h5></ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input type="text" label="Nom" value={name} onChange={e => setName(e.target.value)} placeholder="Nom..." />
          </FormGroup>
          <FormGroup>
            <Input type="text" label="Prénom" value={surname} onChange={e => setSurname(e.target.value)} placeholder="Prénom..." />
          </FormGroup>
          <FormGroup>
            <Input type="email" label="Email" value={mail} onChange={e => setMail(e.target.value)} placeholder="Email..." />
          </FormGroup>
          <FormGroup>
            <Select label="Role" value={role} onChange={e => setRole(e)} options={roles} />
            {role && role.value === "owner" ?
              // eslint-disable-next-line react/no-unescaped-entities
              <span style={{ color: "red" }}>Cet utilisateur remplacera l'actuel propriétaire de l'établissement si il existe</span>
              : <></>}
          </FormGroup>
          {!role ? <></>
            : role.value === "superadmin" ? <></>
              : role.value === "groupementAdmin" ?
                <FormGroup>
                  <Select label="Groupement" value={groupement} onChange={e => setGroupement(e)} options={groupements.map(i => ({ label: i.name, value: i.uid }))} />
                </FormGroup>
                :
                role.value === "regionAdmin" ?
                  <>
                    <FormGroup>
                      <Select label="Groupement" value={groupement} onChange={e => setGroupement(e)} options={groupements.filter(g => g.regions != undefined && g.regions.length > 0).map(i => ({ label: i.name, value: i.uid }))} />
                    </FormGroup>
                    {groupement ?
                      <FormGroup>
                        <Select label="Région" value={{ value: region, label: region }} onChange={e => setRegion(e?.value ?? null)} options={groupements.find(g => g.uid === groupement.value).regions.map(r => ({ value: r, label: r }))} />
                      </FormGroup>
                      : null}
                  </>

                  :
                  <FormGroup>
                    <Select label="Établissement" value={establishment} onChange={e => setEstablishment(e)} options={establishments.map(i => ({ label: i.name, value: i.uid }))} />
                  </FormGroup>
          }
          {password ?
            <FormGroup>
              <p style={{ color: "red" }}>Mot de passe: {password}</p>
            </FormGroup>
            : <></>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpen(!isOpen)}>Annuler</Button>
          <Button color="primary" onClick={_create} loading={loading}>Créer</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const RenderFilter = ({ userFilter, setUserFilter }) => {

  const handleFilter = (value) => {
    let _userFilter = [...userFilter];

    if (value == "all") {
      _userFilter = [];
    } else {
      if (_userFilter.indexOf(value) == -1) {
        _userFilter.push(value);
      } else {
        _userFilter.splice(_userFilter.indexOf(value), 1);
      }
    }
    setUserFilter(_userFilter);
  };
  let retour = [];
  retour.push(<div style={{ backgroundColor: "#51075e", color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.length > 0 ? 0.35 : 1 }} onClick={() => handleFilter("all")}>Tous</div>);
  roles.forEach(element => {
    retour.push(<div style={{ backgroundColor: element.color, color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.indexOf(element.value) == -1 ? 0.35 : 1 }} onClick={() => handleFilter(element.value)}>{element.label}</div>);
  });

  return <div style={{ display: "flex", flex: 1, flexWrap: "wrap", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
    {retour}
  </div>;
};

RenderFilter.propTypes = {
  userFilter: PropTypes.array,
  setUserFilter: PropTypes.func,
};


export default Users;