import React, {useState, useEffect} from "react";
import {Card, Form, FormGroup, Input, Button} from "../lib/components";
import firebase from "firebase/app";
import 'firebase/auth';
import styles from "../lib/assets/scss/pages/login.module.scss";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const auth = firebase.auth;

const ForgetAction = () => {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const _onSubmit = async () => {
    try {
      setLoading(true);
      const code = new URLSearchParams(window.location.search).get("oobCode");
      const newPassword = password;
      await auth().confirmPasswordReset(code, newPassword);
      toast.success("Votre mot de passe a bien été modifié");
      history.push("/login");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.loginPage}>
      <Card className={styles.card} color="white">
        <h1>Réinitialiser mon mot de passe</h1>
        <Form>
          <FormGroup>
            <Input label={"Mot de passe"} type="password" value={password} onChange={e => setPassword(e.target.value)} onEnterPressed={_onSubmit} />
          </FormGroup>
          <FormGroup>
            <Input label={"Confirmer"} type='password' value={confirm} onChange={e => setConfirm(e.target.value)} onEnterPressed={_onSubmit} />
          </FormGroup>
          <FormGroup style={{textAlign: "center"}}>
            <Button onClick={_onSubmit} wd="300px" mg={{all: "0"}} loading={loading}>{"Se connecter"}</Button>
          </FormGroup>
        </Form>
      </Card>
    </div>
  );
};

export default ForgetAction;