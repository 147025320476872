import React, {useContext} from "react";
import {SidebarContext} from "../../context/dashboard/sidebar.context";
import PropTypes from "prop-types";

import styles from "../../assets/scss/layout/dashboard/header.module.scss";

const Header = ({children}) => {
  const [sidebarOpen, setSidebarOpen] = useContext(SidebarContext);

  return (
    <div className={`${styles.header} ${sidebarOpen ? styles.sidebarOpen : ""}`}>
      <div className={styles.burger} onClick={() => setSidebarOpen(!sidebarOpen)}>
        <div className={styles.burgerBar}></div>
        <div className={styles.burgerBar}></div>
        <div className={styles.burgerBar}></div>
      </div>

      <div className={styles.right}>
        {children}
      </div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default Header;