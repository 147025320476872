import React from "react";
import Creatable from "react-select/creatable";
import PropTypes from "prop-types";

const MultiSelect = (props) => {
  const {
    label, 
    options, 
    onChange, 
    value, 
    clearable=false, 
    disabled=false, 
    invalid,
    placeholder,
    onCreate,
  } = props;

  return (
    <>
      {label &&
        <label>
          {label}:
        </label>
      }
      <Creatable 
        onChange={onChange}
        value={value}
        options={options}
        isClearable={clearable}
        isDisabled={disabled}
        placeholder={placeholder}
        onCreateOption={onCreate}
        isSearchable
        isMulti
      />
      {invalid && typeof(invalid) === "string" ?
        <span style={{color: "red"}}>{invalid}</span>
        : ""}
    </>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  onChange : PropTypes.any,
  value : PropTypes.any,
  clearable : PropTypes.any,
  disabled : PropTypes.any,
  invalid : PropTypes.any,
  placeholder : PropTypes.any,
  onCreate : PropTypes.func,
};

export default MultiSelect;