import React from "react";
import useUI from "hooks/ui.hook";

import Basic_Details from "./Basic/Details";

import AutourDeLHumain_Details from "./Specific/autourDeLHumain/Details";

const Details = (props) => {
    const [ui] = useUI();

    const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO" || ui?.establishment?.uid === "A1LKT1bK4Aj4TAaqPsel";
  
    if(isAutourDeLHumain) return <AutourDeLHumain_Details {...props} />;
    return <Basic_Details {...props} />;
};



export { Details };