import React, {useState, useEffect} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Table, Card, CardHeader, CardBody, Input} from "../../lib/components";
import { Link } from "react-router-dom";
import { Edit, Plus } from "react-feather";
import useUI from "hooks/ui.hook";
import { toast } from "react-toastify";
import useSearch from "hooks/useSearch";
import { getUserById } from "services/ressources/user";
import { getAllEstablishments_rt } from "services/ressources/establishment";
import { getAllGroupements_rt } from "services/ressources/groupement";

const firestore = firebase.firestore;

const parameters = ["name"];


const Establishments = () => {
  const [ui, dispatch] = useUI();
  const [etablissements, setEtablissements] = useState([]);
  const [groupements, setGroupements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const { filtered } = useSearch(etablissements, value, parameters);

  function generateId() {
    var length = 6,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  useEffect(() => {
    getAllEstablishments_rt(null, async (data) => {
      let _data = await Promise.all(data.map(async i => ({
        ...i,
        owner: await getUserById({id: i.owner}),
      })));
      setEtablissements(_data);
      setLoading(false);
    });
    getAllGroupements_rt(null, setGroupements);
  }, []);

  const _create = async () => {
    try {
      let res = await firestore().collection("establishments").add({
        name,
        owner: ui.user.uid,
        features: [],
        id: generateId()
      });
      await Promise.all([
        (async () => {await firestore().collection("establishments").doc(res.id).collection("blocks").doc("menu").set({type: "menu"});})(),
        (async () => {await firestore().collection("establishments").doc(res.id).collection("blocks").doc("planning").set({blockType: "plan"});})(),
        (async () => {await firestore().collection("establishments").doc(res.id).collection("blocks").doc("actus").set({blockType: "actus"});})(),
      ]);
      toast.success("L'établissement a bien été créé");
      setIsModalOpen(false);
      setName("");
    } catch (e) {
      toast.error("Une erreur est survenue");
      console.error(e.message);
    }
  };

  const _takeControl = async (_id) => {
    try {
      let _est = await firestore().collection("establishments").doc(_id).get();
      let _user = {...ui.user}; 

      _est = ({uid: _est.id, ..._est.data()});
      
      let _owner = _est.owner;
      if(_owner){
        let res = await firestore().collection("users").doc(_owner).get();
        _owner = res.data();
        _est.owner = _owner;
      }

      let groupement = null;
      // ajout du groupement
      if(_est.uid){
        let groupementDoc = await firestore().collection("groupements").where("establishments", "array-contains", _est.uid).get();
        if(groupementDoc.docs.length > 0){
          groupement = {uid: groupementDoc.docs[0].id, ...groupementDoc.docs[0].data()};

          if(groupementDoc.docs.length > 1)console.warn("this etab is in more than 1 groupement");
        }
      }

      _user.role = "owner";
      _user.establishment = _id;
      _user.superadmin = true;
      dispatch({type: "takeControl", user: _user, establishment: _est, groupement: groupement});
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Container><Row><Col xs={12}>
        <Card>
          <CardHeader style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center"}}>
                <h5 style={{marginBottom: 0, whiteSpace: "nowrap"}}>Etablissements</h5>
                <div style={{width: "60%", display: "flex", flexDirection: "row", alignItems: "center", gap: 5}}>
                  <h6 style={{marginBottom: 0, whiteSpace: "nowrap"}}>Recherche :</h6>
                  <Input value={value} onChange={(e)=> setValue(e.target.value)}/>
                </div>
            </div>
            <Button color="primary" onClick={() => setIsModalOpen(true)}><Plus color="white" /></Button>
          </CardHeader>
          <CardBody>
            <Table loading={loading}>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Groupement</th>
                  <th>Propriétaire</th>
                  {/* <th>Features</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filtered && filtered.map(i => 
                  <tr key={i.uid}>
                    <th scope="row">{i.name}</th>
                    <th scope="row">{i.groupement ? groupements.find(_g => _g.uid === i.groupement)?.name : ""}</th>
                    <td>{i.owner.surname} {i.owner.name}</td>
                    {/* <td>{i.features && i.features.map(j => <p key={j}>{j}</p>)}</td> */}
                    <td>
                      <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: 30}}>
                        <Link to={`/dashboard/establishments/${i.uid}`}>
                          <Edit color="#4A0956" style={{cursor: "pointer"}}/>
                        </Link>
                        <Button color="danger" onClick={() => _takeControl(i.uid)}>Prise de controle</Button>{" "}
                      </div>
                      
                    </td>

                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col></Row></Container>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} size="lg">
        <ModalHeader>Créer un établissement</ModalHeader>
        <ModalBody>
          <Input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Nom d'établissement" />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>Annuler</Button>
          <Button color="primary" onClick={_create}>Créer</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Establishments;