import React from "react";
import PropTypes from "prop-types";
import {Button} from "../components";

const PolymerSubmit = (props) => {
  const {children} = props;

  return (
    <Button {...props} type="submit">{children}</Button>
  );
};

PolymerSubmit.propTypes = {
  children  : PropTypes.node,
};

export default PolymerSubmit;