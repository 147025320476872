import React from "react";
import { Input as InputBootstrap } from "reactstrap";
import PropTypes from "prop-types";

import styles from "../../assets/scss/component/Input.module.scss";

const Input = (props) => {
  let params = {...props};

  const {
    value,
    onChange,
    onClick,
    onEnterPressed,
    onBlur,
    label,
    invalid,
    theme="bootstrap",
    className,
    style,
    min,
    max,
    disabled = false,
  } = params;

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && onEnterPressed)
      onEnterPressed(e);
  };

  if (params.onChange)
    delete params.onChange;
  if (params.onEnterPressed)
    delete params.onEnterPressed;
  if (params.label)
    delete params.label;
  if (params.invalid)
    delete params.invalid;

  return (
    <div className={`${styles[theme]} ${styles.input} ${className ?? ""}`} style={style ?? {}}>
      {label ?
        <label>
          {label}:
        </label>
        : ""}
      <InputBootstrap {...params}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        value={value} 
        onKeyDown={handleKeyPress}
        min={min}
        max={max}
        disabled={disabled}
      />
      {invalid && typeof(invalid) === "string" ?
        <span className={styles.invalid}>{invalid}</span>
        : ""}
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onEnterPressed: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  theme: PropTypes.oneOf(["bootstrap", "grey"])
};

export default Input;