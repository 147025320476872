import React, { useState } from "react";
import { Button } from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";

import { getAllUsers } from "@/services/ressources/user";

const firestore = firebase.firestore;

export default function Animations() {



    // On commence par récupérer tous les menus.
    const handlePublishedEtab = async (etab) => {
        console.log(" ");
        console.log("attaque de " + etab?.name);

        const docs = await firestore()
            .collection("establishments")
            .doc(etab.uid)
            .collection("blocks")
            .doc("planning")
            .collection("events")
            .get();

        const animList = [];

        docs.forEach(doc => {
            const _anim = ({ ...doc.data(), uid: doc.id });
            // on garde uniquement ceux qui ont des réservations.
            if (_anim?.isPublished !== false && _anim?.isPublished !== true) {
                animList.push(_anim);
            }
        });

        console.log(animList);

        // on update les données dans firebase :
        const batchSize = 500;
        for (let i = 0; i < animList.length; i += batchSize) {
            const batch = firestore().batch();
            const batchDocs = animList.slice(i, i + batchSize);

            batchDocs.forEach((doc) => {
                const ref = firestore()
                    .collection("establishments")
                    .doc(etab.uid)
                    .collection("blocks")
                    .doc("planning")
                    .collection("events")
                    .doc(doc.uid);

                const _toUpdate = {
                    isPublished: true,
                };

                batch.update(ref, _toUpdate);
            });

            // Commit the batch
            await batch.commit().then(() => {
                console.log("Batch committed successfully. for " + etab?.name);
            }).catch((error) => {
                console.error("Error committing batch:", error);
            });
        }


    };

    const handlePublished = async () => {
        alert("désactivé par sécurité");
        return;

        const docs = await firestore()
            .collection("establishments")
            .get();

        const etabList = [];

        docs.forEach(doc => {
            etabList.push({ ...doc.data(), uid: doc.id });
        });
        
        // const sliced = etabList.slice(etabList.findIndex(_e => _e?.name === "Démo1"), etabList.length -1);

        for (const etab of etabList) {
            await handlePublishedEtab(etab);
        }

        // handleReservationEtab({ uid: "KcF49xhTQZXBtx72pw6G" }, users);
    };

    return (
        <div>
            <h2>Les Animations</h2>
            <Button onClick={handlePublished}>Update isPublished</Button>
        </div>
    );
}
