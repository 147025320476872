import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {createContext, useReducer, useContext} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import H from "@here/maps-api-for-javascript";

const firestore = firebase.firestore;

const Context = createContext();

const Default = {
    lat: 0,
    lng: 0,
    zoom: 0,
    mapRef: null,
    platform: null,
    service: null,
    mapUi: null,
    group: null,
    groupList: {},

    //markers
    basicMarkerList: [],
    bubbleMarkerList: [],

    //functions
    init: null,
    changeView: null,
    addBasicMarker: null,
    addBubbleMarker: null,
    removeBasicMarkers: null,


    cleanup: null,
};

function Reducer(state, action) {
  switch(action.type) {
  case "setProperty": return ({...state, [action.property]: action.value});
  case "addToList": return ({...state, [action.property]: [...state[action.property], action.value]});

  default: return {...state};
  }
}

const Provider = ({children}) => {
  const [ctx, dispatch] = useReducer(Reducer, Default);

  //   ctx.isSaved = isSaved;
  //   ctx.save = save;

    // init clickable group
    useEffect(()=>{
        if(ctx.mapRef && ctx.mapUi){
        // if(ctx.group)ctx.mapRef.removeObject(ctx.group);
        var group = new H.map.Group();

        ctx.mapRef.addObject(group);

        // add 'tap' event listener, that opens info bubble, to the group
        group.addEventListener("tap", function (evt) {
            // event target is the marker itself, group is a parent event target
            // for all objects that it contains
            var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData()
            });

            ctx.mapUi.addBubble(bubble);
        }, false);

        dispatch({type: "setProperty", property: "group", value: group});
        }
    },[ctx.mapRef,ctx.mapUi]);

    const init = (map, platform, service,  mapUi) => {
        dispatch({type: "setProperty", property: "mapRef", value: map});
        dispatch({type: "setProperty", property: "platform", value: platform});
        dispatch({type: "setProperty", property: "service", value: service});
        dispatch({type: "setProperty", property: "mapUi", value: mapUi});
    };

    const changeView = (lat, lng, zoom) => {
        dispatch({type: "setProperty", property: "lat", value: lat});
        dispatch({type: "setProperty", property: "lng", value: lng});
        dispatch({type: "setProperty", property: "zoom", value: zoom});
    };

    
    /**
     * Creates a new marker and adds it to the map
     * @param {Number} lat  The location of the marker
     * @param {Number} lng  The location of the marker
     * @param {String} color  The marker color
    * @param {String} groupName  The group to add the marker
     */
    function addBasicMarker(lat, lng, color, groupName) {
      const svg = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\""+color+"\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-map-pin\"><path d=\"M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z\"></path><circle cx=\"12\" cy=\"10\" r=\"3\"></circle></svg>";
      
      let markerIcon = new H.map.Icon(svg);
  
      let marker = new H.map.Marker({lat : lat, lng : lng},{icon: markerIcon});

      // console.log(marker, ctx.mapRef);

      if(groupName && ctx.groupList[groupName]){
        ctx.groupList[groupName].addObject(marker);
        dispatch({type: "setProperty", property: "groupList", value: ctx.groupList});
      }else{
        ctx.mapRef.addObject(marker);
      }
      
  }

    /**
     * Creates a new marker and adds it to the map
     * @param {H.geo.Point} coordinate  The location of the marker
     * @param {String} html  The Html code of the Bubble
     * @param {String} color  The marker color     
     * @param {String} groupName  The group to add the marker
     */
    const addBubbleMarker = (coordinate, html, color, groupName) => {
        let _color = color ?? "none";

        const svg = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\""+_color+"\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-map-pin\"><path d=\"M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z\"></path><circle cx=\"12\" cy=\"10\" r=\"3\"></circle></svg>";
      
        //"<div class=\"infoDiv\"><div>"+ name +"<div> <a href=\"/dashboard/one/"+id+"\"><div>Prendre le contrôle</div></a></div>"
      let markerIcon = new H.map.Icon(svg);

      let marker = new H.map.Marker(coordinate,{icon: markerIcon});

      marker.setData(html);
      // dispatch({type: "addToList", property: "bubbleMarkerList", value: marker});

      if(groupName && ctx.groupList[groupName]){
        ctx.groupList[groupName].addObject(marker);
      }else{
        ctx.group.addObject(marker);
      }
    };


    /**
     * Adding a new group of markers
     * @param {Array} names The names / id of the groups
     */
    const addGroups = (names) => {
      const _grouplist = {};
      console.log(names, ctx);
      names.forEach(name => {

        if(!ctx.groupList[name]){
          let group = new H.map.Group();
          // ctx.mapRef.addObject(group);
          _grouplist[name] = group;
        }
      });
    
      dispatch({type: "setProperty", property: "groupList", value: {...ctx.groupList, ..._grouplist}});
    };

    /**
     * Getting group of markers by name
     * @param {String} name  The name / id of the group
     */
    const getGroup = (name) => {
        return ctx.groupList[name];
    };

    /**
     * Hiding group of markers by name
     * @param {String} name  The name / id of the group
     */
    const hideGroup = (name) => {
      
      // ctx.groupList[name].removeObjects(ctx.groupList[name].getObjects());
      // console.log("done", ctx.groupList[name]);
      // ctx.mapRef.removeObjects(ctx.groupList[name]);
      ctx.groupList[name].setVisibility(false);

      // console.log(ctx.mapRef.getObjects());
      // ctx.mapRef.removeObject(ctx.groupList[name]);

  };

    const removeBasicMarkers = () => {
        ctx.mapRef.removeObjects(ctx.basicMarkerList);
    };
    
    ctx.init = init;
    ctx.changeView = changeView;

    ctx.addBasicMarker = addBasicMarker;
    ctx.addBubbleMarker = addBubbleMarker;

    ctx.removeBasicMarkers = removeBasicMarkers;
    // ctx.addBubbleMarker = addBubbleMarker;

    ctx.addGroups = addGroups;
    ctx.getGroup = getGroup;
    ctx.hideGroup = hideGroup;


    ctx.cleanup = () => {
      dispatch({type: "setProperty", property: "group", value: null});
      dispatch({type: "setProperty", property: "groupList", value: {}});
      dispatch({type: "setProperty", property: "mapRef", value: null});
      dispatch({type: "setProperty", property: "mapUi", value: null});
    };

  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};
const useMap = () => useContext(Context);
export default useMap;
export {Provider, Context};