import React from "react";
import PropTypes from "prop-types";

import Basic_ExportWeek from "./Basic/PDF/weekGeneric";
import Basic_ExportMonth from "./Basic/PDF/monthGeneric";


import Ekipage_ExportWeek from "./Specific/ekipage/PDF/week";

import Heurus_ExportWeek from "./Specific/heurus/PDF/week";
import Heurus_ExportMonth from "./Specific/heurus/PDF/month";

import Jasmins_ExportMonth from "./Specific/jasmins/PDF/month";

import Ovelia_ExportWeek from "./Specific/ovelia/PDF/week";

import VillageBleu_ExportWeek from "./Specific/villageBleu/PDF/week";

import APIProvence_ExportWeek from "./Specific/apiProvence/PDF/week";
import APIProvence_ExportMonth from "./Specific/apiProvence/PDF/month";

import AutourDeLHumain_ExportWeek from "./Specific/autourDeLHumain/PDF/week";

import ACPPA_ExportWeek from "./Specific/acppa/PDF/week";

const ExportWeek = (props) => {
    const { ui } = props;

    const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO" || ui?.establishment?.uid === "A1LKT1bK4Aj4TAaqPsel";

    if(isAutourDeLHumain) return <AutourDeLHumain_ExportWeek {...props} />;
    if (ui?.groupement?.specific === "ekipage") return <Ekipage_ExportWeek {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_ExportWeek {...props} />;
    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ExportWeek {...props} />;
    if (ui?.groupement?.specific === "villagebleu") return <VillageBleu_ExportWeek {...props} />;
    if(ui?.groupement?.specific === "apiprovence") return <APIProvence_ExportWeek {...props} />;
    if(ui?.groupement?.specific === "acppa") return <ACPPA_ExportWeek {...props} />;
    return <Basic_ExportWeek {...props} />;
};

const ExportMonth = (props) => {
    const { ui } = props;

    if (ui?.groupement?.specific === "heurus") return <Heurus_ExportMonth {...props} />;
    if (ui?.groupement?.specific === "jasmins") return <Jasmins_ExportMonth {...props} />;
    if(ui?.groupement?.specific === "apiprovence") return <APIProvence_ExportMonth {...props} />;

    return <Basic_ExportMonth {...props} />;
};


ExportWeek.propTypes = {
    ui: PropTypes.object,
};
ExportMonth.propTypes = {
    ui: PropTypes.object,
};




export { ExportWeek, ExportMonth};