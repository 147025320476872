import React, {createContext, useReducer, useEffect, useState} from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";
import { getEstablishmentById, updateEstablishmentById } from "services/ressources/establishment";

const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  step: 0,
  repas: null,
  template: {},
  labels: {},
};

function Reducer(state, action) {
  switch(action.type) {
  case "setValue": return ({...state, [action.key]: action.value});
  case "setState": return ({...state, ...action.state});
  default: return ({...state});
  }
}

const Provider = ({children, establishmentId}) => {
  const {id} = useParams();
  const [ctx, reducer] = useReducer(Reducer, Default);

  useEffect(() => {
    (async () => {
      let data = await getEstablishmentById({id: establishmentId});
      reducer({type: "setValue", key: "template", value: data?.template ?? {}});
      reducer({type: "setValue", key: "labels", value: data?.labels ?? {}});
    })();
  }, [establishmentId]);

  const copyFromEtab = (etab) => {
    reducer({type: "setValue", key: "template", value: etab?.template ?? {}});
    reducer({type: "setValue", key: "labels", value: etab?.labels ?? {}});
  };

  const save = async () => {
    try {
      //compute top level categories
      let _template = {...ctx.template};
      Object.keys(_template).forEach((repas) => {
        const apparitionOrder = {};

        Object.keys(_template[repas].menus).forEach((menu) => {
          _template[repas].menus[menu].categories.forEach((_categ, indx) => {
            if(!apparitionOrder[_categ] || apparitionOrder[_categ] < indx)apparitionOrder[_categ] = indx;
          });
        });

        let _categories = [];

        Object.entries(apparitionOrder).sort((a, b) => a[1] - b[1]).forEach(([_categ, _maxIndex]) => {
          _categories.push(_categ);
        });

        _template[repas].categories = _categories;
      });
      
      await updateEstablishmentById({id, data: {
        template: _template,
        labels: ctx.labels
      }});
      toast.success("Modifications enregistrées");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (
    <Context.Provider value={[
      {
        ...ctx,
        save,
        copyFromEtab
      },
      reducer
    ]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  establishmentId: PropTypes.string.isRequired
};

const useContext = () => React.useContext(Context);

export default useContext;
export {Provider};