import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import useUI from "hooks/ui.hook";
import Logo from "./assets/logo.png";

import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

import Calibri from "fonts/calibri/calibri-regular.ttf";
import CalibriBold from "fonts/calibri/calibri-bold.ttf";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

Font.register({
  family: "Calibri",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Calibri",
  format: "bold",
  src: RobotoBold,
});

const styles = StyleSheet.create({
  mainContainer: {
    border: 6,
    borderColor: "#b5cae7",
    backgroundColor: "#b5cae7",
  },
  title: {
    fontSize: 28,
    fontFamily: "Calibri",
    fontWeight: "bold",
    color: "#00526c",
    textAlign: "right"
  },
  titleContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  weekContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 6,
  },
  fromto: {
    fontFamily: "Calibri",
    backgroundColor: "#006786",
    color: "white",
    fontSize: 20,
    padding: 4,
    paddingTop: 7
  },
  container: {
    flexWrap: "nowrap",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 25,
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    right: -30,
    width: 160,
    height: 70,
  },
  logo: {
    width: "100%",
    objectFit: "contain",
  },
  day: {
    borderRadius: 2,
    backgroundColor: "#fafafa",
    margin: 2,
    width: "16%",
    height: "100%",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 5,
    position: "relative",
    top: -20,
  },
  dayString: {
    padding: 5,
    paddingTop: 8,
    backgroundColor: "#006786",
    margin: 5,
    color: "#ffffff",
    fontSize: 18,
    fontFamily: "Calibri",
    fontWeight: "bold",
    textAlign: "center",

  },
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    top: -20
  },
  event: {
    marginTop: 5,
    marginBottom: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  eventHour: {},
  eventTitleContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  eventPeriodContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around"
  },
  eventPeriod: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  separator: {
    width: "80%",
    borderBottom: "1px dotted black",
  },
  eventTitle: {
    paddingHorizontal: 2,
    fontFamily: "Calibri",
    fontWeight: "bold",
    textAlign: "center",
  },
  eventDescription: {
    fontFamily: "Calibri",
    fontSize: 11,
    marginLeft: 5,
    marginRight: 5,
    maxHeight: 20,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  eventImageContainer: {
    height: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  eventImage: {
    width: "auto",
    objectFit: "contain",
    marginBottom: 6,
  },
});

const WeekPlanningPdf = (props) => {
  const { ui, monday, events, logo, additionnalData } = props;
  const day = new Date(monday);

  console.log("daaata", events);
  const _getDays = () => {
    let _days = [];

    for (let i = 0; i < 7; i++) {
      let day = new Date(monday);
      day.setDate(day.getDate() + i);
      _days.push(day);
    }
    return _days;
  };
  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return events.filter((event) => event.start > start && event.end < end);
  };
  const _hourToString = (date) => {
    return moment(date).format("HH:mm");
  };

  const _fontSizer = (totalElements, text) => {

    if(!text){
        // 18 si totalElements = 1 ; 16 si totalElements = 2 ; 14 si totalElements >= 3
        return 19 - ((totalElements < 3 ? totalElements : 3) * 2);
    }else{
      // 18 si totalElements = 1 ; 16 si totalElements = 2 ; 14 si totalElements >= 3
      return 19 - ((totalElements < 3 ? totalElements : 3) * 2) - (Math.floor((text.length / 14)));
    }
   
  };


  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.mainContainer}>
          <View style={{ paddingLeft: 20, paddingRight: 20, display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 0, marginBottom: 0 }}>
            <View>
              <Image src={Logo} style={{ width: 240, height: 66, marginLeft: 10 }} />
              <Text style={styles.fromto}>
                Semaine du {moment(day).startOf("week").format("DD")} au{" "}
                {moment(day).endOf("week").format("ll")}
              </Text>
            </View>
            <View style={{ textAlign: "right", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-start" }}>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>Planning des animations</Text>
              </View>
              <Text style={{ color: "#00526c", fontSize: 26, textAlign: "right" }}>{ui.establishment.name}</Text>
            </View>
          </View>

          <View style={styles.container}>
            {_getDays().slice(0, 5).map((day) => (
              <View style={styles.day} key={day.toISOString()}>
                <View style={styles.dateContainer}>
                  <Text style={styles.dayString}>
                    {moment(day).format("dddd DD").toLocaleUpperCase()}
                  </Text>
                </View>
                <View style={styles.eventsWrapper}>

                  <View style={styles.eventPeriodContainer}>
                    <View style={styles.eventPeriod}>
                      {_getEventByDay(day).filter(_e => moment(_e?.start).hours() < 12).map((event, index) => (
                        index < 4 ?
                          <View style={styles.event} key={index}>
                            <Text style={[styles.eventHour, { fontSize: _fontSizer(_getEventByDay(day).filter(_e => moment(_e?.start).hours() < 12).length) }]}
                            >
                              {_hourToString(event.start)} -{" "}
                              {_hourToString(event.end)}
                            </Text>
                            <Text
                              style={[styles.eventTitle, { fontSize: _fontSizer(_getEventByDay(day).filter(_e => moment(_e?.start).hours() < 12).length, event.title) }]}
                            >
                              {event.title}
                            </Text>
                          </View> : null
                      ))}

                      {_getEventByDay(day).filter(_e => moment(_e?.start).hours() < 12).length > 2 ? //pas d'image si 3 events ou + dans la matinée 
                        null
                        : <>
                          {_getEventByDay(day).filter(e => e?.extendedProps?.image !== null && moment(e?.start).hours() < 12).length > 0 ?
                            <View style={styles.eventImageContainer}>
                              {/* Si Jamais il y a plusieurs images le même jour, on récupère l'image de l'événement le plus long */}
                              {_getEventByDay(day).filter(e => e?.extendedProps?.image !== null && moment(e?.start).hours() < 12).sort((a, b) => (b.end - b.start) - (a.end - a.start))[0]?.extendedProps.image ? (
                                <Image
                                  src={_getEventByDay(day).filter(e => e?.extendedProps?.image !== null && moment(e?.start).hours() < 12).sort((a, b) => (b.end - b.start) - (a.end - a.start))[0].extendedProps.image}
                                  style={styles.eventImage}
                                />
                              ) : (
                                <></>
                              )}
                            </View>
                            : <Text>{" "}</Text>}
                        </>}
                    </View>

                    <View style={styles.separator}>

                    </View>
                    <View style={[
                      styles.eventPeriod,
                      // _getEventByDay(day).filter(_e => moment(_e?.start).hours() >= 12).length > 2 ? {minHeight: 80} : {}
                    ]}>

                      {_getEventByDay(day).filter(_e => moment(_e?.start).hours() >= 12).map((event, index) => (
                        index < 4 ?
                          <View style={styles.event} key={index}>
                            <Text style={[styles.eventHour, { fontSize: _fontSizer(_getEventByDay(day).filter(_e => moment(_e?.start).hours() >= 12).length) - 1 }]}
                            >
                              {_hourToString(event.start)} -{" "}
                              {_hourToString(event.end)}
                            </Text>
                            <Text style={[styles.eventTitle, { fontSize: _fontSizer(_getEventByDay(day).filter(_e => moment(_e?.start).hours() >= 12).length, event.title) }]}
                            >
                              {event.title}
                            </Text>
                          </View> : null
                      ))}

                      {_getEventByDay(day).filter(_e => moment(_e?.start).hours() >= 12).length > 2 ? //pas d'image si 3 events ou + dans l'aprem 
                        null
                        : <>
                          {_getEventByDay(day).filter(e => e?.extendedProps?.image !== null && moment(e?.start).hours() >= 12).length > 0 ?

                            <View style={styles.eventImageContainer}>
                              {/* Si Jamais il y a plusieurs images le même jour, on récupère l'image de l'événement le plus long */}
                              {_getEventByDay(day).filter(e => e?.extendedProps?.image !== null && moment(e?.start).hours() >= 12).sort((a, b) => (b.end - b.start) - (a.end - a.start))[0]?.extendedProps.image ? (
                                <>
                                  <Image
                                    src={_getEventByDay(day).filter(e => e?.extendedProps?.image !== null && moment(e?.start).hours() >= 12).sort((a, b) => (b.end - b.start) - (a.end - a.start))[0].extendedProps.image}
                                    style={styles.eventImage}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </View>
                            : <Text>{" "}</Text>}

                        </>}

                    </View>

                  </View>

                </View>
              </View>
            ))}
            <View style={[styles.day, { backgroundColor: "#b5cae7", display: "flex", flexDirection: "column" }]}>
              <View style={{ backgroundColor: "#fafafa", width: "100%", flex: 1, marginBottom: 3 }}>
                <View style={styles.dateContainer}>
                  <Text style={styles.dayString}>{moment(_getDays()[5]).format("dddd DD").toLocaleUpperCase()}</Text>
                </View>
                <View style={styles.eventsWrapper}>
                  <View style={styles.eventTitleContainer}>
                    {_getEventByDay(_getDays()[5]).map((event, index) => (
                      index < 3 ?
                        <View style={styles.event} key={index}>
                          <Text style={[styles.eventHour, { fontSize: _fontSizer(_getEventByDay(_getDays()[5]).length)}]}
                          >
                            {_hourToString(event.start)} -{" "}
                            {_hourToString(event.end)}
                          </Text>
                          <Text style={[styles.eventTitle, { fontSize: _fontSizer(_getEventByDay(_getDays()[5]).length, event.title)}]}
                          >{event.title}</Text>
                           {}
                          {_getEventByDay(_getDays()[5]).length === 1 && event?.extendedProps?.image ?
                            <View style={[styles.eventImageContainer, {height: 70}]}>
                              <Image
                                src={event?.extendedProps?.image}
                                style={styles.eventImage}
                              />
                            </View>
                            : null}
                        </View> : null
                    ))}
                  </View>
                </View>
              </View>
              <View style={{ backgroundColor: "#fafafa", width: "100%", flex: 1, marginTop: 3 }}>
                <View style={styles.dateContainer}>
                  <Text style={styles.dayString}>{moment(_getDays()[6]).format("dddd DD").toLocaleUpperCase()}</Text>
                </View>
                <View style={styles.eventsWrapper}>
                  <View style={styles.eventTitleContainer}>
                    {_getEventByDay(_getDays()[6]).map((event, index) => (
                      index < 3 ?
                        <View style={styles.event} key={index}>
                          <Text style={[styles.eventHour, { fontSize: _fontSizer(_getEventByDay(_getDays()[6]).length)}]}>
                            {_hourToString(event.start)} -{" "}
                            {_hourToString(event.end)}
                          </Text>
                          <Text style={[styles.eventTitle, { fontSize: _fontSizer(_getEventByDay(_getDays()[6]).length, event.title)}]}>{event.title}</Text>

                          {_getEventByDay(_getDays()[6]).length === 1 && event?.extendedProps?.image ?
                            <View style={[styles.eventImageContainer, {height: 70}]}>
                              <Image
                                src={event?.extendedProps?.image}
                                style={styles.eventImage}
                              />
                            </View>
                            : null}
                        </View> : null
                    ))}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

WeekPlanningPdf.propTypes = {
  monday: PropTypes.any.isRequired,
  events: PropTypes.array.isRequired,
  logo: PropTypes.string,
  additionnalData: PropTypes.any,
  ui: PropTypes.object.isRequired,
};
export default WeekPlanningPdf;
