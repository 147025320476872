import React from "react";
import PropTypes from "prop-types";
import styles from "./multipleChoice.module.scss";
import { Circle, Square, Star, Triangle } from "react-feather";
import * as c from "../colors";

const Answering = ({ question }) => {
  const Icons = [
    <Circle key="circle" stroke={c.COLOR_PALETTE_1[0]} width={65} height={65} fill={c.COLOR_PALETTE_1[0]} />,
    <Square key="square" stroke={c.COLOR_PALETTE_1[1]} width={65} height={65} fill={c.COLOR_PALETTE_1[1]} />,
    <Star key="star" stroke={c.COLOR_PALETTE_1[2]} width={65} height={65} fill={c.COLOR_PALETTE_1[2]} />,
    <Triangle key="triangle" stroke={c.COLOR_PALETTE_1[3]} width={65} height={65} fill={c.COLOR_PALETTE_1[3]} />,
  ];
  return (
    <div className={styles.answering}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      <div className={styles.mediaDiv}>
        {question.media ? <img src={question.media} className={styles.media} /> : null}
      </div>
      <div className={styles.answers}>
        {question.answers.map((answer, index) => (
          <div
            key={index}
            className={`${styles.answer}`}
          >
            {Icons[index]}
            <span>{answer}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

Answering.propTypes = {
  question : PropTypes.object.isRequired,
};

export default Answering;
