import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Tab, Select, Container, FormGroup, Checkbox
} from "lib/components";
import moment from "moment";

import XLSX from "xlsx-js-style";
import useUI from "hooks/ui.hook";
import { DatePicker } from "lib/components";
import { toast } from "react-toastify";


const ExportDay = ({ onClose, dataGeneration, infos }) => {
  const [ui] = useUI();
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (infos != undefined) {
  //     if (infos.date != undefined) setDate(infos.date.toDate());
  //   }
  // }, [infos]);

  // const createSheetPortage = (data) => {
  //   let rows = [];
  //   let columnsSize = [9.43, 13.86, 15, 22, 9,  22.43, 14.57, 10, 10];
  //   // let columnsSize = [9, 43, 14, 22, 23, 15, 10, 10];
  //   columnsSize = columnsSize.map(_c => {return {width: _c};});
  //   let rowSize = [];

  //   let headerStyle = { alignment: baseAlignment, font: { sz: 9, bold: true } };

  //   const header = [];
  //   Object.keys(data.header).forEach(element => {
  //     header.push({ v: element, s: headerStyle });
  //   });
  //   rows.push(header);

  //   // on transforme les données au format excel
  //   let _data = [];

  //   data.data.forEach((array, _indx) => {
  //     let arr = [];
  //     array.forEach(element => {
  //       let dataStyle = { font: { sz: 9 } };

  //       if (_indx % 2 === 0)
  //         dataStyle["fill"] = { fgColor: { rgb: "ddecf4" } };

  //       arr.push({ v: element, s: dataStyle });
  //     });
  //     _data.push(arr);
  //   });

  //   // génération de la hauteur des lignes
  //   rowSize.push({ "hpt": 22 });

  //   for (let i = 1; i < _data.length; i++) {
  //     rowSize.push({ "hpt": 14.25 });
  //   }

  //   let sheet = XLSX.utils.aoa_to_sheet([...rows, ..._data]);
  //   sheet["!cols"] = columnsSize;
  //   sheet["!rows"] = rowSize;

  //   return sheet;
  // };


  const save = async () => {
    const wb = XLSX.utils.book_new();

    const _data = await dataGeneration(date);


    console.log(_data);

    Object.entries(_data).forEach(([sheetName, array]) => {
      let sheet = XLSX.utils.aoa_to_sheet(array);
      if(sheetName === "Totaux") 
        sheet["!cols"] = [24, 15, 15, 15, 15, 8, 15, 15, 15, 15, 8, 15, 15, 15, 15, 8, 15, 15, 15, 15, 8, 15, 15, 15, 15].map(_c => {return {width: _c};});
      else {
        sheet["!cols"] = [24, 24, 15, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 6, 25].map(_c => {return {width: _c};});
      }
      
      XLSX.utils.book_append_sheet(wb, sheet, sheetName);
    });

    // const _type = infos.type === "menu" || infos.type === "menuDishes" ? "menus" : infos.type === "restaurant" ? "restaurant" : infos.type === "event" ? "animations" : "";
    const filename = "Export_jour_"+moment(date).format("DD-MMMM-YYYY");
    try {
      XLSX.writeFile(wb, filename + ".xlsx");
      
    } catch (error) {
      toast.warning("Aucune donnée à ce jour");
    }

    setLoading(false);
    onClose();
  };


  return (
    <Modal isOpen={true} size="lg" toggle={onClose}>
      <ModalHeader>
        {infos.title}
      </ModalHeader>
      <ModalBody>
        <h5>Choix de la date</h5>
        <DatePicker value={date} onChange={setDate}/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Annuler</Button>
        <Button color="primary" onClick={() => {setLoading(true);save();}} loading={loading}>Télécharger</Button>
      </ModalFooter>
    </Modal>
  );
};
ExportDay.propTypes = {
  onClose: PropTypes.func.isRequired,
  dataGeneration: PropTypes.func.isRequired,
  infos: PropTypes.object.isRequired,
};

export default ExportDay;