import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const eventsInit = async ({ui}, err) => {
  try {
    const doc  = await firestore()
            .collection("establishments")
            .doc(ui.user.establishment)
            .collection("blocks")
            .doc("planning")
            .get();
    if (!doc.exists) {
      await firestore()
              .collection("establishments")
              .doc(ui.user.establishment)
              .collection("blocks")
              .doc("planning")
              .set({
                blockType: "plan"
              });
    }
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

//--------------------------  -------------------------------//

export const getAllAnimationByEtab_rt = ({id, start, end}, cb, err) => {
  const subscribe = firestore()
                      .collection("establishments")
                      .doc(id)
                      .collection("blocks")
                      .doc("planning")
                      .collection("events")
                      .where("start", ">=", start)
                      .where("start", "<=", end)
                      .onSnapshot(snap => {
                        const data = snap.docs.map(doc => ({
                          uid: doc.id,
                          ...doc.data()
                        }));
                        cb(data);
                      }, err ?? (() => {}));
  return subscribe;
};

//--------------------------  -------------------------------//

export const getAllAnimationByEtab = async({id, start, end},err) =>{
  try {
    let ref = firestore()
    .collection("establishments")
    .doc(id)
    .collection("blocks")
    .doc("planning")
    .collection("events");

    if(start && end){
      ref = ref.where("start", ">=", start).where("start", "<=", end);
    }

    let res = await ref.get();
    
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

  //--------------------------  -------------------------------//

  export const updateAnimationByEtab = async({etabId, animId, data},err)=>{
    try{
        await firestore()
                .collection("establishments")
                .doc(etabId)
                .collection("blocks")
                .doc("planning")
                .collection("events")
                .doc(animId)
                .update(data);
                 
    }catch (e) {
        console.error(e);
        if (err) err(e);
        else toast.error("Une erreur est survenue");
      }
  };

   //--------------------------  -------------------------------//

   export const getAnimationById = async({etabId, animId},err)=>{
    try{
        const res = await firestore()
                .collection("establishments")
                .doc(etabId)
                .collection("blocks")
                .doc("planning")
                .collection("events")
                .doc(animId)
                .get();
        if (!res.exists) return 0;
        return {uid: res.id, ...res.data()};
    }catch (e) {
        console.error(e);
        if (err) err(e);
        else toast.error("Une erreur est survenue");
      }
  };

  //--------------------------  -------------------------------//

  export const createAnimation = async ({ui,data},err) =>{
    try {
      const res = await firestore()
                          .collection("establishments")
                          .doc(ui.user.establishment)
                          .collection("blocks")
                          .doc("planning")
                          .collection("events")
                          .add({
                            ...data,
                          });
      return {uid: res.id, ...data};
    } catch (e) {
      console.error(e);
      if (err) err(e);
      else toast.error("Une erreur est survenue");
      return null;
    }
  };

    //--------------------------  -------------------------------//
 
  export const removeAnimation = async ({ui,id},err) => {
    try {
      await firestore()
            .collection("establishments").doc(ui.user.establishment)
            .collection("blocks").doc("planning")
            .collection("events").doc(id)
            .delete();
    } catch (e) {
      console.error(e);
      if (err) err(e);
      else toast.error("Une erreur est survenue");
      return null;
    }
  };